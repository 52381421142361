import React, { useState, useEffect, useCallback } from 'react';
import { axiosInstance, getRole } from './auth';
import jsyaml from 'js-yaml';
import {
  Typography, CircularProgress, Button, List, ListItem, ListItemText,
  Dialog, DialogTitle, DialogContent, DialogActions, Container, Snackbar,
  Card, CardContent, Grid, Chip, Box, TextField, LinearProgress,
  InputAdornment, IconButton, Tabs, Tab, Paper
} from '@mui/material';
import { DragIndicator as DragIndicatorIcon } from '@mui/icons-material';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Assignment as AssignmentIcon,
  PieChart as PieChartIcon,
  QuestionAnswer as QuestionAnswerIcon,
  CompareArrows as CompareArrowsIcon,
  Search as SearchIcon,
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
  Close as CloseIcon,
  ExpandMore
} from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { PieChart, Pie, Cell, Legend, Tooltip as RechartsTooltip, ResponsiveContainer } from 'recharts';

const categoryEmojis = {
  'Humiliation and Degradation': '😳',
  'Fetish Wear': '👠',
  'Impact Play': '🫸',
  'Domestic Service': '🧹',
  'Bondage and Restraints': '⛓️',
  'Roleplay and Power Exchange': '🎭',
  'Extreme Practices': '🚽',
  'Femdom': '👑',
  'Pain and Intense Sensation': '🫦',
  'Sensory Play': '🌟',
  'Anal Play': '🍆',
  'Exhibitionism and Voyeurism': '👀',
  'Body Modification and Marking': '✒️',
  'Mental and Emotional Play': '🧠',
  'Toys and Devices': '🤖',
  'Group Activities': '🫂',
  'Uncategorized': '📝'
};

const Surveys = ({ role }) => {
  // State declarations
  const [surveys, setSurveys] = useState([]);
  const [currentSurvey, setCurrentSurvey] = useState(null);
  const [responses, setResponses] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showResultsDialog, setShowResultsDialog] = useState(false);
  const [surveyResults, setSurveyResults] = useState(null);
  const [surveyMatchPercentages, setSurveyMatchPercentages] = useState({});
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [kinks, setKinks] = useState([]);
  const [completedStages, setCompletedStages] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [expandedCategories, setExpandedCategories] = useState({});

  const [tabValue, setTabValue] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    switch (hash) {
      default:
        setTabValue(0);
    }
  }, [location]);

  const fetchInitialData = async () => {
    setIsLoading(true);
    try {
      const [surveysResponse, kinksResponse, stagesResponse] = await Promise.all([
        axiosInstance.get('/surveys'),
        axiosInstance.get('/kinks'),
        axiosInstance.get('/completed-survey-stages')
      ]);
      
      const surveys = surveysResponse.data || [];
      setSurveys(surveys);
      setKinks(kinksResponse.data || []);
      setCompletedStages(stagesResponse.data || {});
  
      // Fetch match percentages for completed surveys
      const completedSurveys = surveys.filter(s => s.completed_dom && s.completed_sub);
      const matchPercentages = {};
      
      await Promise.all(
        completedSurveys.map(async (survey) => {
          try {
            const response = await axiosInstance.get(`/survey-results/${survey.id}`);
            matchPercentages[survey.id] = Math.round(response.data.match_percentage);
          } catch (error) {
            console.error(`Failed to fetch match percentage for survey ${survey.id}:`, error);
          }
        })
      );
  
      setSurveyMatchPercentages(matchPercentages);
    } catch (error) {
      console.error('Failed to fetch initial data:', error);
      setError('Failed to fetch data. Please try again.');
      setSurveys([]);
      setKinks([]);
      setCompletedStages({});
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewResults = async (surveyId) => {
    try {
      const [resultsResponse] = await Promise.all([
        axiosInstance.get(`/survey-results/${surveyId}`),
        axiosInstance.post(`/survey-results/${surveyId}/mark-viewed`)
      ]);
      setSurveyResults(resultsResponse.data);
      setShowResultsDialog(true);
      fetchInitialData();
    } catch (error) {
      console.error('Failed to fetch survey results:', error);
      setError('Failed to fetch survey results. Please try again.');
    }
  };

const renderSurveyItem = (survey) => {
    const hasUnviewedResults = survey.completed_dom && 
                              survey.completed_sub && 
                              !survey.viewed;
    
    const surveyDescription = survey.description || 
      (kinks.find(kink => kink.name.toLowerCase() === (survey.kink || '').toLowerCase())?.description) || 
      'No description available';
    
    // Determine who still needs to complete the survey
    const getCompletionStatus = () => {
      if (survey.completed_dom && !survey.completed_sub) {
        return "Awaiting Submissive";
      } else if (!survey.completed_dom && survey.completed_sub) {
        return "Awaiting Dominant";
      }
      return null;
    };

    // Get the completion status
    const completionStatus = getCompletionStatus();
    
    // Determine if the current user should see the Fill button
    const showFillButton = survey.assigned && 
      ((role === 'dominant' && !survey.completed_dom) || 
       (role === 'submissive' && !survey.completed_sub));

    return (
      <ListItem 
        key={survey.id}
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'stretch', sm: 'center' },
          gap: 1,
          py: 0.2,
          px: 1
        }}
      >
        <ListItemText
          primary={
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              width: '100%'
            }}>
              <Typography sx={{ 
                flexGrow: 1, 
                fontSize: '0.875rem',
                fontWeight: 500,
                mr: 1
              }}>
                {survey.title}
              </Typography>
                {survey.completed_dom && survey.completed_sub && surveyMatchPercentages[survey.id] && (
                <Chip 
                  label={`${surveyMatchPercentages[survey.id]}% Match`}
                  size="small"
                  color={
                    surveyMatchPercentages[survey.id] > 70
                      ? 'success'
                      : surveyMatchPercentages[survey.id] > 30
                      ? 'warning'
                      : 'default'
                  }
                  sx={{ ml: 1 }}
                />
              )}
              {role === 'dominant' && (
                <>
                  {!survey.assigned && (
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => handleAssignSurvey(survey.id)}
                      sx={{ 
                        p: 0.5, 
                        width: 32, 
                        height: 32 
                      }}
                    >
                      <AssignmentIcon fontSize="small" />
                    </IconButton>
                  )}
                </>
              )}
              {hasUnviewedResults && (
                <Chip 
                  label="New Results" 
                  color="secondary" 
                  size="small" 
                  sx={{ ml: 1 }}
                />
              )}
              {survey.stage > 1 && (
                <Chip 
                  label={`Stage ${survey.stage}`} 
                  size="small" 
                  sx={{ ml: 1 }}
                />
              )}
            </Box>
          }
          secondary={
            <Box>
              <Typography 
                variant="body2" 
                sx={{ 
                  fontSize: '0.7rem', 
                  color: 'text.secondary',
                  mt: 0.5 
                }}
              >
                {surveyDescription}
              </Typography>
              {survey.recommended && (
                <Chip 
                  label="Recommended Next Survey" 
                  color="primary" 
                  size="small" 
                  sx={{ mt: 0.5 }}
                />
              )}
            </Box>
          }
        />
        <Box sx={{ 
          display: 'flex', 
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          width: { xs: '100%', sm: 'auto' }
        }}>
        {survey.assigned && (
          <>
            {showFillButton && (
              <Button
                size="small"
                variant="contained"
                color="primary"
                startIcon={<QuestionAnswerIcon fontSize="small" />}
                onClick={() => handleFillSurvey(survey.id)}
                sx={{ minWidth: 'auto', px: 1 }}
              >
                Fill
              </Button>
            )}
            {completionStatus && (
              <Button
                size="small"
                variant="outlined"
                disabled
                sx={{ minWidth: 'auto', px: 1 }}
              >
                {completionStatus}
              </Button>
            )}
            {survey.completed_dom && survey.completed_sub && (
              <>
                <Button
                  size="small"
                  variant="contained"
                  color="info"
                  startIcon={<PieChartIcon fontSize="small" />}
                  onClick={() => handleViewResults(survey.id)}
                  sx={{ minWidth: 'auto', px: 1 }}
                >
                  Results {hasUnviewedResults && '(New)'}
                </Button>
                {role === 'dominant' && (
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    startIcon={<CompareArrowsIcon fontSize="small" />}
                    onClick={() => handleReassignSurvey(survey.id)}
                    sx={{ minWidth: 'auto', px: 1 }}
                  >
                    Re-assign
                  </Button>
                )}
              </>
            )}
          </>
        )}
        </Box>
      </ListItem>
    );
  };
     
  const renderSurveyProgress = () => {
    const totalQuestions = currentSurvey?.questions?.length || 0;
    const currentQuestion = currentQuestionIndex + 1;
    
    return (
      <Box sx={{ mt: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography variant="body2">
            Question {currentQuestion} of {totalQuestions}
          </Typography>
          <Typography variant="body2">
            {Math.round(progress)}% Complete
          </Typography>
        </Box>
        <LinearProgress 
          variant="determinate" 
          value={progress} 
          sx={{ height: 8, borderRadius: 4 }}
        />
      </Box>
    );
  };

const calculateKendallTau = (ranking1, ranking2) => {
  if (!ranking1?.length || !ranking2?.length || ranking1.length !== ranking2.length) {
    return 0;
  }

  const n = ranking1.length;
  let concordant = 0;
  let discordant = 0;

  // Create position maps for both rankings
  const pos1 = {};
  const pos2 = {};
  ranking1.forEach((item, i) => pos1[item] = i);
  ranking2.forEach((item, i) => pos2[item] = i);

  // Count concordant and discordant pairs
  for (let i = 0; i < n - 1; i++) {
    for (let j = i + 1; j < n; j++) {
      const item1_i = ranking1[i];
      const item1_j = ranking1[j];
      const pos2_i = pos2[item1_i];
      const pos2_j = pos2[item1_j];

      if ((pos2_i < pos2_j && i < j) || (pos2_i > pos2_j && i > j)) {
        concordant++;
      } else {
        discordant++;
      }
    }
  }

  const totalPairs = (n * (n - 1)) / 2;
  return (concordant - discordant) / totalPairs;
};

  const renderQuestionResponse = (question) => {
    const getMatchColor = (score) => {
      if (score >= 0.8) return 'success.light';
      if (score >= 0.5) return 'warning.light';
      return 'error.light';
    };
  
    const getMatchLabel = (score) => {
      if (score >= 0.8) return 'Perfect Match';
      if (score >= 0.5) return 'Partial Match';
      return 'No Match';
    };
  
    if (question.type === 'select' && question.match_type === 'opposite') {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Dominant:</strong> {question.dominant_response}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Submissive:</strong> {question.submissive_response}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Chip
              icon={question.match_score >= 0.8 ? <CheckCircleIcon /> : <CancelIcon />}
              label={`${getMatchLabel(question.match_score)} (${Math.round(question.match_score * 100)}%)`}
              sx={{ bgcolor: getMatchColor(question.match_score) }}
            />
          </Grid>
        </Grid>
      );
    }

    if (question.type === 'ranking') {
  const domRanking = question.dominant_response || [];
  const subRanking = question.submissive_response || [];
  
  const kendallTau = calculateKendallTau(domRanking, subRanking);
  const matchScore = (kendallTau + 1) / 2; // Convert from [-1,1] to [0,1] range
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant="subtitle1" gutterBottom><strong>Dominant's Ranking:</strong></Typography>
        <List>
          {domRanking.map((item, index) => (
            <ListItem key={index} sx={{
              bgcolor: subRanking[index] === item ? 'success.light' : 'transparent',
              borderRadius: 1,
              mb: 0.5
            }}>
              <ListItemText>
                {index + 1}. {item}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="subtitle1" gutterBottom><strong>Submissive's Ranking:</strong></Typography>
        <List>
          {subRanking.map((item, index) => (
            <ListItem key={index} sx={{
              bgcolor: domRanking[index] === item ? 'success.light' : 'transparent',
              borderRadius: 1,
              mb: 0.5
            }}>
              <ListItemText>
                {index + 1}. {item}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" gutterBottom>
          Ranking Alignment: {Math.round(matchScore * 100)}%
        </Typography>
        <Chip
          label={getMatchLabel(matchScore)}
          sx={{ bgcolor: getMatchColor(matchScore) }}
        />
      </Grid>
    </Grid>
  );
}
  
    if (Array.isArray(question.dominant_response) || Array.isArray(question.submissive_response)) {
      const domAnswers = Array.isArray(question.dominant_response) ? question.dominant_response : [question.dominant_response];
      const subAnswers = Array.isArray(question.submissive_response) ? question.submissive_response : [question.submissive_response];
      const matchingAnswers = domAnswers.filter(answer => subAnswers.includes(answer));
  
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom><strong>Dominant's Selections:</strong></Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {domAnswers.map((answer, i) => (
                <Chip
                  key={i}
                  label={answer}
                  sx={{
                    bgcolor: matchingAnswers.includes(answer) ? 'success.light' : 'error.light',
                    '& .MuiChip-label': { whiteSpace: 'normal' }
                  }}
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom><strong>Submissive's Selections:</strong></Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {subAnswers.map((answer, i) => (
                <Chip
                  key={i}
                  label={answer}
                  sx={{
                    bgcolor: matchingAnswers.includes(answer) ? 'success.light' : 'error.light',
                    '& .MuiChip-label': { whiteSpace: 'normal' }
                  }}
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center">
              Match Score: {Math.round(question.match_score * 100)}%
            </Typography>
          </Grid>
        </Grid>
      );
    }
  
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">
            <strong>Dominant:</strong> {question.dominant_response || 'No response'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">
            <strong>Submissive:</strong> {question.submissive_response || 'No response'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Chip
            label={getMatchLabel(question.match_score)}
            sx={{ bgcolor: getMatchColor(question.match_score) }}
          />
        </Grid>
      </Grid>
    );
  };

const renderSurveySection = (title, categorizedSurveys, defaultExpanded = false, sectionType = '') => {
  if (Object.keys(categorizedSurveys).length === 0) return null;

  const sortedCategories = sortCategories(categorizedSurveys);

  return (
    <Paper 
      elevation={2} 
      sx={{ 
        mb: 3,
        overflow: 'hidden'
      }}
    >
      <Box 
        sx={{ 
          p: 1, 
          bgcolor: 'background.default',
          borderBottom: 1,
          borderColor: 'divider'
        }}
      >
        <Typography variant="h6" sx={{ fontSize: '1rem' }}>{title}</Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        {sortedCategories.map(([category, surveys]) => (
          <Paper
            key={category}
            elevation={1}
            sx={{
              mb: 1,
              overflow: 'hidden',
              '&:last-child': {
                mb: 0
              }
            }}
          >
            <Box 
              sx={{ 
                py: 1,  // Reduced vertical padding from 2 to 1 (16px)
                px: 2,  // Keep horizontal padding at 2 for alignment
                borderBottom: expandedCategories[category] ? 1 : 0,
                borderColor: 'divider',
                cursor: 'pointer'
              }}
              onClick={() => {
                setExpandedCategories((prev) => ({
                  ...prev,
                  [category]: !prev[category],
                }));
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {renderCategoryHeader(category, surveys, sectionType)}
                <ExpandMore 
                  sx={{ 
                    transform: expandedCategories[category] ? 'rotate(180deg)' : 'rotate(0)',
                    transition: 'transform 0.3s'
                  }} 
                />
              </Box>
            </Box>
            {expandedCategories[category] && (
              <List sx={{ py: 0 }}>
                {surveys.map((survey) => renderSurveyItem(survey))}
              </List>
            )}
          </Paper>
        ))}
      </Box>
    </Paper>
  );
};

  const renderSurveyQuestion = (question, index) => {
    const isCurrentQuestion = index === currentQuestionIndex;
    
    return (
      <Box
        key={question.id}
        sx={{
          display: isCurrentQuestion ? 'block' : 'none',
          minHeight: '200px',
          p: 2
        }}
      >
        <Typography variant="h6" gutterBottom>{question.text}</Typography>
        {question.type === 'select' && (
          <Grid container spacing={2}>
            {[...question.options, 'Unsure'].map((option) => (
              <Grid item xs={12} sm={6} key={option}>
                <Button
                  fullWidth
                  variant={responses[question.id] === option ? "contained" : "outlined"}
                  onClick={() => handleResponseChange(question.id, option)}
                  sx={{ mb: 1 }}
                >
                  {option}
                </Button>
              </Grid>
            ))}
          </Grid>
        )}
        {question.type === 'checkbox' && (
          <Grid container spacing={2}>
            {[...question.options, 'Unsure'].map((option) => (
              <Grid item xs={12} sm={6} key={option}>
                <Button
                  fullWidth
                  variant={(responses[question.id] || []).includes(option) ? "contained" : "outlined"}
                  onClick={() => handleResponseChange(question.id, option, true)}
                  sx={{ mb: 1 }}
                >
                  {option}
                </Button>
              </Grid>
            ))}
          </Grid>
        )}
{question.type === 'ranking' && (
  <Box sx={{ mt: 2 }}>
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {responses[question.id] ? 
        responses[question.id].map((option, index) => (
          <ListItem
            key={option}
            sx={{
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              mb: 1,
              bgcolor: 'background.default'
            }}
          >
            <ListItemText>
              <Typography variant="body1">
                {index + 1}. {option}
              </Typography>
            </ListItemText>
          </ListItem>
        ))
        :
        question.options.map((option, index) => (
          <ListItem
            key={option}
            sx={{
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              mb: 1,
              bgcolor: 'background.default',
              cursor: 'move',
              userSelect: 'none', // Prevent text selection during drag
              '&:hover': {
                bgcolor: 'action.hover'
              }
            }}
            draggable
            onDragStart={(e) => {
              e.dataTransfer.effectAllowed = 'move';
              e.dataTransfer.setData('text/plain', option);
            }}
            onDragOver={(e) => {
              e.preventDefault();
              e.currentTarget.style.borderTop = 
                e.clientY < e.currentTarget.getBoundingClientRect().top + e.currentTarget.offsetHeight / 2
                  ? '2px solid #1976d2'
                  : 'none';
              e.currentTarget.style.borderBottom = 
                e.clientY >= e.currentTarget.getBoundingClientRect().top + e.currentTarget.offsetHeight / 2
                  ? '2px solid #1976d2'
                  : 'none';
            }}
            onDragLeave={(e) => {
              e.currentTarget.style.borderTop = 'none';
              e.currentTarget.style.borderBottom = 'none';
            }}
            onDrop={(e) => {
              e.preventDefault();
              const draggedOption = e.dataTransfer.getData('text/plain');
              const options = question.options.slice();
              const targetIndex = options.indexOf(option);
              const sourceIndex = options.indexOf(draggedOption);

              // Remove drag indicators
              e.currentTarget.style.borderTop = 'none';
              e.currentTarget.style.borderBottom = 'none';

              if (sourceIndex === -1 || targetIndex === -1) return;

              // Create new array with reordered items
              const newOrder = options.slice();
              newOrder.splice(sourceIndex, 1);
              newOrder.splice(targetIndex, 0, draggedOption);

              setResponses(prev => ({
                ...prev,
                [question.id]: newOrder
              }));
            }}
          >
            <DragIndicatorIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <ListItemText>
              <Typography variant="body1">
                {index + 1}. {option}
              </Typography>
            </ListItemText>
          </ListItem>
        ))
      }
    </List>
    <Button
      variant={responses[`${question.id}_unsure`] ? "contained" : "outlined"}
      onClick={() => {
        setResponses(prev => ({
          ...prev,
          [`${question.id}_unsure`]: !prev[`${question.id}_unsure`],
          [question.id]: prev[`${question.id}_unsure`] ? question.options : undefined
        }));
      }}
    >
      Unsure
    </Button>
  </Box>
)}
        {question.type === 'text' && (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              multiline={question.long_answer}
              rows={question.long_answer ? 4 : 1}
              value={responses[question.id] || ''}
              onChange={(e) => handleResponseChange(question.id, e.target.value)}
              disabled={responses[`${question.id}_unsure`]}
            />
            <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
              <Button
                variant={responses[`${question.id}_unsure`] ? "contained" : "outlined"}
                onClick={() => {
                  const isUnsure = !responses[`${question.id}_unsure`];
                  setResponses(prev => ({
                    ...prev,
                    [`${question.id}_unsure`]: isUnsure,
                    [question.id]: isUnsure ? 'Unsure' : ''
                  }));
                }}
                sx={{ mr: 1 }}
              >
                Unsure
              </Button>
              {responses[`${question.id}_unsure`] && (
                <Typography variant="body2" color="text.secondary">
                  Text input disabled while "Unsure" is selected
                </Typography>
              )}
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const handleResponseChange = (questionId, value, isCheckbox = false) => {
    setResponses(prev => {
      const newResponses = isCheckbox
        ? {
            ...prev,
            [questionId]: prev[questionId]?.includes(value)
              ? prev[questionId].filter(v => v !== value)
              : [...(prev[questionId] || []), value]
          }
        : { ...prev, [questionId]: value };
      
      const totalQuestions = currentSurvey?.questions?.length || 0;
      const answeredQuestions = Object.keys(newResponses).length;
      const newProgress = (answeredQuestions / totalQuestions) * 100;
      setProgress(newProgress);
      return newResponses;
    });
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < (currentSurvey?.questions?.length || 0) - 1) {
      setCurrentQuestionIndex(prev => prev + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prev => prev - 1);
    }
  };

  const handleSubmitSurvey = async () => {
    try {
      await axiosInstance.post(`/submit-survey`, {
        surveyId: currentSurvey.id,
        responses,
        role
      });
      setResponses({});
      setShowSurveyModal(false);
      setCurrentQuestionIndex(0);
      setProgress(0);
      fetchInitialData();
      setError('Survey submitted successfully');
    } catch (error) {
      setError('Failed to submit survey. Please try again.');
    }
  };

  const renderSearchBar = () => (
    <TextField
      fullWidth
      variant="outlined"
      placeholder="Search surveys..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      sx={{ mb: 2 }}
    />
  );

const renderCategoryHeader = (category, surveys, sectionType) => {
  const ratingInfo = getCategoryRatingInfo(category);

  // Calculate category alignment for completed surveys using actual match percentages
  const completedSurveyAlignment = sectionType === 'completed' 
    ? surveys.reduce((total, survey) => {
        return total + (surveyMatchPercentages[survey.id] || 0);
      }, 0) / surveys.length 
    : null;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      {/* Left-aligned content remains the same */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, flexGrow: 1 }}>
        <Typography sx={{ fontSize: '1.2rem' }}>
          {categoryEmojis[category] || '📝'}
        </Typography>
        <Typography variant="subtitle1" sx={{ fontSize: '0.8rem' }}>
          {category}
        </Typography>
      </Box>

      {/* Right-aligned content using actual match percentages */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 'auto' }}>
        {sectionType === 'completed' && completedSurveyAlignment !== null && (
          <Chip
            size="small"
            label={`${Math.round(completedSurveyAlignment)}%`}
            color={
              completedSurveyAlignment > 70
                ? 'success'
                : completedSurveyAlignment > 30
                ? 'warning'
                : 'default'
            }
          />
        )}
        {sectionType !== 'completed' && ratingInfo && (
          <Chip
            size="small"
            label={`${Math.round(ratingInfo.alignment * 100)}% Match`}
            color={
              ratingInfo.alignment > 0.7
                ? 'success'
                : ratingInfo.alignment > 0.3
                ? 'warning'
                : 'default'
            }
          />
        )}
        <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
          {surveys.length} {surveys.length === 1 ? 'survey' : 'surveys'}
        </Typography>
      </Box>
    </Box>
  );
};

  useEffect(() => {
    fetchInitialData();
  }, [role]);

  const getCategoryRatingInfo = (category) => {
    const categoryKinks = kinks.filter(kink => kink.category === category);
    if (categoryKinks.length === 0) return null;

    const matchingKinks = categoryKinks.filter(kink => {
      const domRating = kink.dominant_giving_rating;
      const subRating = kink.submissive_receiving_rating;
      return (domRating === 'very much yes' || domRating === 'yes') &&
             (subRating === 'please' || subRating === 'yes');
    });

    return {
      total: categoryKinks.length,
      matching: matchingKinks.length,
      alignment: matchingKinks.length / categoryKinks.length
    };
  };

  const isRecommendedSurvey = (survey) => {
    if (!survey.category) return false;
    const categoryKinks = kinks.filter(kink => kink.category === survey.category);
    return categoryKinks.some(kink => {
      const domRating = kink.dominant_giving_rating;
      const subRating = kink.submissive_receiving_rating;
      return ['yes', 'very much yes', 'please'].includes(domRating) ||
             ['yes', 'very much yes', 'please'].includes(subRating);
    });
  };

  const groupSurveys = (surveys) => {
    const assigned = surveys.filter(s => s.assigned && (!s.completed_dom || !s.completed_sub));
    const completed = surveys.filter(s => s.assigned && s.completed_dom && s.completed_sub);
    const unassigned = surveys.filter(s => !s.assigned);
    
    // Split unassigned into recommended and other
    const recommended = unassigned.filter(isRecommendedSurvey);
    const other = unassigned.filter(s => !isRecommendedSurvey(s));

    // Helper function to group surveys by category
    const groupByCategory = (surveyList) => {
      return surveyList.reduce((acc, survey) => {
        const category = survey.category || 'Uncategorized';
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(survey);
        return acc;
      }, {});
    };

    return {
      assigned: groupByCategory(assigned),
      completed: groupByCategory(completed),
      recommended: groupByCategory(recommended),
      unassigned: groupByCategory(other)
    };
  };

  const getFilteredSurveys = useCallback(() => {
    return surveys.filter(survey => {
      // For submissive users, only show assigned surveys
      if (role === 'submissive' && !survey.assigned) {
        return false;
      }
      // Filter by search term
      if (searchTerm && !survey.title.toLowerCase().includes(searchTerm.toLowerCase())) {
        return false;
      }
      // Check stage requirements
      if (survey.stage > 1) {
        const previousStage = survey.stage - 1;
        if (!completedStages[previousStage]) {
          return false;
        }
      }
      return true;
    });
  }, [surveys, role, searchTerm, completedStages]);

  const sortCategories = (categories) => {
    return Object.entries(categories).sort(([catA, surveysA], [catB, surveysB]) => {
      const ratingA = getCategoryRatingInfo(catA)?.alignment || 0;
      const ratingB = getCategoryRatingInfo(catB)?.alignment || 0;
      return ratingB - ratingA;
    });
  };

  const handleAssignSurvey = async (surveyId) => {
    try {
      await axiosInstance.post(`/assign-survey`, { surveyId });
      setError('Survey assigned successfully');
      fetchInitialData();
    } catch (error) {
      console.error('Failed to assign survey:', error);
      setError('Failed to assign survey. Please try again.');
    }
  };

  const handleReassignSurvey = async (surveyId) => {
    try {
      await axiosInstance.post(`/reassign-survey`, { surveyId });
      setError('Survey reassigned successfully');
      fetchInitialData();
    } catch (error) {
      console.error('Failed to reassign survey:', error);
      setError('Failed to reassign survey. Please try again.');
    }
  };

  const handleFillSurvey = async (surveyId) => {
    try {
      const response = await axiosInstance.get(`/current-survey?survey_id=${surveyId}`);
      
      let surveyData;
      if (response.headers['content-type'].includes('application/x-yaml')) {
        const yamlText = await response.data;
        surveyData = jsyaml.load(yamlText);
      } else {
        surveyData = response.data;
      }
      const currentUserRole = getRole();
      surveyData.id = surveyId;
      if (surveyData.questions) {
        surveyData.questions = surveyData.questions.map(question => {
          if ('text_dom' in question && 'text_sub' in question) {
            question.text = currentUserRole === 'dominant' ? question.text_dom : question.text_sub;
            delete question.text_dom;
            delete question.text_sub;
          }
          return question;
        });
      }
      setCurrentSurvey(surveyData);
      setCurrentQuestionIndex(0);
      setResponses({});
      setProgress(0);
      setShowSurveyModal(true);
    } catch (error) {
      console.error('Error fetching current survey:', error);
      setError('Failed to fetch survey. Please try again.');
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (isLoading) return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
      <CircularProgress />
    </Box>
  );

  const { assigned, completed, recommended, unassigned } = groupSurveys(getFilteredSurveys());

  return (
    <Container maxWidth="md">
      <Box sx={{ borderBottom: 1, borderColor: 'divider', overflowX: 'auto', maxWidth: '100%' }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
          sx={{ width: '100%' }}
        >
        <Tab 
          label="Surveys" 
          sx={{ 
            flexGrow: 1,
            justifyContent: 'center',
          }} 
        />
        </Tabs>
      </Box>
      {renderSearchBar()}
      
      {role === 'dominant' && renderSurveySection("Recommended Surveys", recommended, false, 'recommended')}
      {renderSurveySection("Assigned Surveys", assigned, true, 'assigned')}
      {renderSurveySection("Completed Surveys", completed, true, 'completed')}
      {role === 'dominant' && renderSurveySection("Unassigned Surveys", unassigned, false, 'unassigned')}

      {/* Survey Modal */}
      <Dialog 
        open={showSurveyModal} 
        onClose={() => setShowSurveyModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">{currentSurvey?.title}</Typography>
            <IconButton onClick={() => setShowSurveyModal(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: 1 }}>
          {currentSurvey?.questions?.map((question, index) => renderSurveyQuestion(question, index))}
          {renderSurveyProgress()}
        </DialogContent>
        <DialogActions sx={{ p: 1 }}>
          <Button
            onClick={handlePreviousQuestion}
            disabled={currentQuestionIndex === 0}
            startIcon={<NavigateBeforeIcon />}
          >
            Previous
          </Button>
          <Box sx={{ flex: 1 }} />
          {currentQuestionIndex < (currentSurvey?.questions?.length || 0) - 1 ? (
            <Button
              onClick={handleNextQuestion}
              variant="contained"
              endIcon={<NavigateNextIcon />}
            >
              Next
            </Button>
          ) : (
            <Button
              onClick={handleSubmitSurvey}
              variant="contained"
              color="primary"
              disabled={progress < 100}
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Results Dialog */}
      <Dialog 
        open={showResultsDialog} 
        onClose={() => setShowResultsDialog(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Survey Results</Typography>
            <IconButton onClick={() => setShowResultsDialog(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
          <DialogContent sx={{ p: 1 }}>
            {surveyResults && (
              <Box>
                <Typography variant="h6" gutterBottom>Overall Alignment</Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Pie
                      data={[
                        { name: 'Matching', value: surveyResults.matching_count },
                        { name: 'Different', value: surveyResults.total_questions - surveyResults.matching_count }
                      ]}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      <Cell fill="#4caf50" />
                      <Cell fill="#f44336" />
                    </Pie>
                    <RechartsTooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
                
                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>Detailed Responses</Typography>
                {surveyResults.questions.map((question, index) => (
                  <Card key={index} sx={{ mb: 1 }}>
                    <CardContent sx={{ p: 1 }}>
                      <Typography variant="h6" gutterBottom>{question.text}</Typography>
                      {renderQuestionResponse(question)}
                    </CardContent>
                  </Card>
                ))}
              </Box>
            )}
          </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowResultsDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!error}
        autoHideDuration={2000}
        onClose={() => setError(null)}
        message={error}
      />
    </Container>
  );
};

export default Surveys;