import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ChastitySettings } from './chastity-settings';
import { axiosInstance, isAuthenticated } from './auth';
import { 
  Box, 
  Tab, 
  Tabs, 
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  FormControlLabel,
  Switch,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
  Snackbar,
  CircularProgress,
  Paper,
  Button,
  Divider,
  Stack,
  IconButton,
  Tooltip,
  Checkbox, 
  Slider,
} from '@mui/material';
import { 
  Palette,
  Notifications,
  ToggleOn,
  Settings as SettingsIcon,
  Lock,
  AdminPanelSettings,
  PhotoCamera,
  FormatSize,
  DarkMode,
  Help,
  NotificationsActive,
  Apps,
  Assignment,
  AccessTime,
  List,
  ViewKanban,
  Star,
  LockOpen,
  CleaningServices,
  Speed,
  CalendarToday,
  Chat as ChatIcon
} from '@mui/icons-material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import { 
  getThemeNames, 
  fetchUserTheme, 
  fetchSubmissiveTheme 
} from './theme';

const SettingsContext = createContext();
export const useSettings = () => useContext(SettingsContext);

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({
    user_theme: 'light',
    partner_theme: 'light',
    font_size: 'medium',
    enable_dark_mode: true,
    
    feature_chat: true,
    feature_kanban: true,
    feature_automation: true,
    feature_monopoly: true,
    feature_wishlist: true,
    
    task_points_complete: 10,
    task_points_incomplete: -5,
    task_self_completion: false,
    task_proof_required: true,

    punishment_points: 20,
    punishment_auto_approve: false,
    punishment_proof_required: true,

    reward_points_required: 100,
    reward_auto_approve: false,

    submissive_can_change_theme: false,
    submissive_can_edit_schedule: false,
    submissive_can_create_tasks: false,
    submissive_can_request_rewards: true,

    chastity_schedule: {},
    chastity_enabled: false,
    is_permanent_chastity: false,
    chastity_wheel: false,
    chastity_mode: 'countdown',
    
    schedule_monday: true,
    schedule_tuesday: true,
    schedule_wednesday: true,
    schedule_thursday: true,
    schedule_friday: true,
    schedule_saturday: false,
    schedule_sunday: false,

    chastity_cycle_value: 1,
    chastity_cycle_unit: 'weeks',
    freedom_cycle_value: 2,
    freedom_cycle_unit: 'days',
    
    lock_time: '09:00',
    unlock_time: '22:00',

    cleaning_enabled: false,
    cleaning_frequency_value: 3,
    cleaning_frequency_unit: 'days',

    relief_enabled: false,
    relief_frequency_value: 1,
    relief_frequency_unit: 'weeks',
    relief_type: 'ruined',
    relief_auto_assign: true,

    spot_checks_enabled: false,
    spot_checks_start_hour: 9,
    spot_checks_end_hour: 22,
    spot_checks_mode: 'fixed',
    spot_checks_value: 4,
    spot_checks_unit: 'hours',
    spot_checks_min_value: 4,
    spot_checks_max_value: 8,

    notify_tasks_enabled: true,
    notify_tasks_frequency: 'daily',
    notify_chat_enabled: true,
    notify_chat_frequency: 'immediate',
    notify_calendar_enabled: true,
    notify_calendar_frequency: 'daily',
    notify_system_enabled: true,
    notify_system_frequency: 'immediate',

    keyholder_lock_enabled: true,
    keyholder_lock_minutes: 15,
    keyholder_unlock_enabled: true,
    keyholder_unlock_minutes: 15,
    keyholder_cleaning_enabled: true,
    keyholder_cleaning_minutes: 15,
    keyholder_relief_enabled: true,
    keyholder_relief_minutes: 15,

    submissive_lock_enabled: true,
    submissive_lock_minutes: 15,
    submissive_unlock_enabled: true,
    submissive_unlock_minutes: 15,
    submissive_cleaning_enabled: true,
    submissive_cleaning_minutes: 15,
    submissive_relief_enabled: true,
    submissive_relief_minutes: 15
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get('/settings');
        setSettings(prevSettings => ({ ...prevSettings, ...response.data }));
      } catch (error) {
        setError('Failed to fetch settings');
        console.error('Failed to fetch settings:', error);
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated()) {
      fetchSettings();
    }
  }, []);

  const updateSettings = useCallback(async (newSettings) => {
    try {
      setLoading(true);
      const response = await axiosInstance.put('/settings', newSettings);
      setSettings(prevSettings => ({ ...prevSettings, ...response.data }));
      return response.data;
    } catch (error) {
      setError('Failed to update settings');
      console.error('Failed to update settings:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <SettingsContext.Provider value={{ 
      settings,
      setSettings,
      updateSettings, 
      loading,
      error,
      setError 
    }}>
      {children}
    </SettingsContext.Provider>
  );
};

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const ThemePreview = ({ theme, selected, onSelect, previewUrl }) => (
  <Card 
    sx={{ 
      cursor: 'pointer',
      border: selected ? 2 : 0,
      borderColor: 'primary.main',
      height: '100%'
    }}
    onClick={onSelect}
  >
    <CardActionArea>
      <CardMedia
        component="img"
        height="180"
        image={previewUrl}
        alt={`${theme} theme preview`}
      />
      <CardContent sx={{ pt: 1, pb: 1 }}>
        <Typography variant="subtitle1" align="center" sx={{ 
          textTransform: 'capitalize',
          mt: 0,
          mb: 0
        }}>
          {theme}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);

const AppearanceSettings = ({ settings, onChange, currentTheme, setCurrentTheme, submissiveTheme, setSubmissiveTheme, role }) => {
  const themeNames = getThemeNames();
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Divider sx={{ my: 3 }} />
        <Typography variant="h6" gutterBottom>Display Options</Typography>
        <Grid container spacing={2}>
           <Grid item xs={12} sm={6}>
             <FormControl fullWidth>
               <InputLabel>Font Size</InputLabel>
               <Select
                 value={settings.font_size}
                 onChange={(e) => onChange('font_size', e.target.value)}
                 label="Font Size"
               >
                 <MenuItem value="small">Small</MenuItem>
                 <MenuItem value="medium">Medium</MenuItem>
                 <MenuItem value="large">Large</MenuItem>
               </Select>
             </FormControl>
           </Grid>
        </Grid>
      </Grid>  
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>Theme Selection</Typography>
        <Grid container spacing={2}>
          {themeNames.map((themeName) => (
            <Grid item xs={12} sm={6} md={3} key={themeName}>
              <ThemePreview 
                theme={themeName}
                selected={role === 'dominant' ? currentTheme === themeName : submissiveTheme === themeName}
                onSelect={() => {
                  if (role === 'dominant') {
                    setCurrentTheme(themeName);
                    onChange('user_theme', themeName);
                  } else if (settings.submissive_can_change_theme) {
                    setSubmissiveTheme(themeName);
                    onChange('partner_theme', themeName);
                  }
                }}
                previewUrl={`/theme-previews/${themeName}.png`}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

const NotificationSettings = ({ settings, onChange }) => {
  const notificationTypes = {
    tasks: { icon: <Assignment />, label: "Tasks" },
    chat: { icon: <ChatIcon />, label: "Chat" },
    calendar: { icon: <CalendarToday />, label: "Calendar" },
    system: { icon: <NotificationsActive />, label: "System" }
  };

  const handleNotificationChange = (key, field, value) => {
    const updatedNotifications = {
      ...settings.notifications,
      [key]: {
        ...settings.notifications[key],
        [field]: value
      }
    };
    onChange('notifications', updatedNotifications);
  };

  return (
    <Grid container spacing={2}>
      {Object.entries(notificationTypes).map(([key, {icon, label}]) => (
        <Grid item xs={12} sm={6} md={4} key={key}>
          <Card>
            <CardContent>
              <Stack spacing={1}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {icon}
                  <Typography>{label}</Typography>
                </Box>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={settings[`notify_${key}_enabled`]}
                      onChange={(e) => onChange(`notify_${key}_enabled`, e.target.checked)}
                    />
                  }
                  label="Enable"
                />
                <FormControl fullWidth size="small">
                  <InputLabel>Frequency</InputLabel>
                  <Select
                    value={settings[`notify_${key}_frequency`]}
                    onChange={(e) => onChange(`notify_${key}_frequency`, e.target.value)}
                    label="Frequency"
                  >
                    <MenuItem value="immediate">Immediate</MenuItem>
                    <MenuItem value="hourly">Hourly Summary</MenuItem>
                    <MenuItem value="daily">Daily Summary</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

const FeatureSettings = ({ settings, onChange }) => {
  const features = {
    chat: {
      enabled: settings.feature_chat,
      icon: <ChatIcon />,
      label: "Chat"
    },
    kanban: {
      enabled: settings.feature_kanban,
      icon: <ViewKanban />,
      label: "Kanban Board"
    },
    automation: {
      enabled: settings.feature_automation,
      icon: <AutoFixHighIcon />,
      label: "Automation"
    },
    monopoly: {
      enabled: settings.feature_monopoly,
      icon: <VideogameAssetIcon />,
      label: "Game"
    },
    wishlist: {
      enabled: settings.feature_wishlist,
      icon: <List />,
      label: "Wishlist"
    }
  };

  return (
    <Grid container spacing={3}>
      {Object.entries(features).map(([key, feature]) => (
        <Grid item xs={12} sm={6} key={key}>
          <Card>
            <CardContent>
              <Stack direction="row" spacing={2} alignItems="center">
                <IconButton color="primary" size="small">
                  {feature.icon}
                </IconButton>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="subtitle1">
                    {feature.label}
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={feature.enabled}
                        onChange={(e) => onChange(`feature_${key}`, e.target.checked)}
                      />
                    }
                    label={feature.enabled ? 'Enabled' : 'Disabled'}
                  />
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

const DefaultSettings = ({ settings, onChange }) => {
  const POINT_PRESETS = [1, 5, 10, 20, 30, 40, 50];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>Task Defaults</Typography>
            
            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
                <Box sx={{ minWidth: '100px' }}>
                  <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Points</Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Complete</Typography>
                </Box>
                <Slider
                  value={settings.task_points_complete}
                  onChange={(_, value) => onChange('task_points_complete', value)}
                  step={null}
                  marks={POINT_PRESETS.map(point => ({
                    value: point,
                    label: point.toString()
                  }))}
                  min={POINT_PRESETS[0]}
                  max={POINT_PRESETS[POINT_PRESETS.length - 1]}
                  sx={{ flex: 1 }}
                />
                <TextField
                  type="number"
                  value={settings.task_points_complete}
                  onChange={(e) => {
                    const value = e.target.value;
                    onChange('task_points_complete', 
                      value === '' ? '' : Math.max(0, parseInt(value) || 0)
                    );
                  }}
                  size="small"
                  sx={{ width: '70px' }}
                  inputProps={{
                    min: 0,
                    max: 9999,
                    inputMode: 'numeric',
                    pattern: '[0-9]*'
                  }}
                />
              </Box>
            </Box>

            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
                <Box sx={{ minWidth: '100px' }}>
                  <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Points</Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Missed</Typography>
                </Box>
                <Slider
                  value={Math.abs(settings.task_points_incomplete)}
                  onChange={(_, value) => onChange('task_points_incomplete', -value)}
                  step={null}
                  marks={POINT_PRESETS.map(point => ({
                    value: point,
                    label: point.toString()
                  }))}
                  min={POINT_PRESETS[0]}
                  max={POINT_PRESETS[POINT_PRESETS.length - 1]}
                  sx={{ flex: 1 }}
                />
                <TextField
                  type="number"
                  value={Math.abs(settings.task_points_incomplete)}
                  onChange={(e) => {
                    const value = e.target.value;
                    onChange('task_points_incomplete',
                      value === '' ? '' : -Math.max(0, parseInt(value) || 0)
                    );
                  }}
                  size="small"
                  sx={{ width: '70px' }}
                  inputProps={{
                    min: 0,
                    max: 9999,
                    inputMode: 'numeric',
                    pattern: '[0-9]*'
                  }}
                />
              </Box>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={settings.task_proof_required}
                      onChange={(e) => onChange('task_proof_required', e.target.checked)}
                    />
                  }
                  label="Proof Required by Default"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={settings.task_self_completion}
                      onChange={(e) => onChange('task_self_completion', e.target.checked)}
                    />
                  }
                  label="Allow Self Completion"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>Punishment Defaults</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
              <Box sx={{ minWidth: '100px' }}>
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Points</Typography>
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Value</Typography>
              </Box>
              <Slider
                value={settings.punishment_points}
                onChange={(_, value) => onChange('punishment_points', value)}
                step={null}
                marks={POINT_PRESETS.map(point => ({
                  value: point,
                  label: point.toString()
                }))}
                min={POINT_PRESETS[0]}
                max={POINT_PRESETS[POINT_PRESETS.length - 1]}
                sx={{ flex: 1 }}
              />
              <TextField
                type="number"
                value={settings.punishment_points}
                onChange={(e) => {
                  const value = e.target.value;
                  onChange('punishment_points',
                    value === '' ? '' : Math.max(0, parseInt(value) || 0)
                  );
                }}
                size="small"
                sx={{ width: '70px' }}
                inputProps={{
                  min: 0,
                  max: 9999,
                  inputMode: 'numeric',
                  pattern: '[0-9]*'
                }}
              />
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={settings.punishment_proof_required}
                  onChange={(e) => onChange('punishment_proof_required', e.target.checked)}
                />
              }
              label="Proof Required by Default"
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>Reward Defaults</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
              <Box sx={{ minWidth: '100px' }}>
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Points</Typography>
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Required</Typography>
              </Box>
              <Slider
                value={settings.reward_points_required}
                onChange={(_, value) => onChange('reward_points_required', value)}
                step={null}
                marks={POINT_PRESETS.map(point => ({
                  value: point,
                  label: point.toString()
                }))}
                min={POINT_PRESETS[0]}
                max={POINT_PRESETS[POINT_PRESETS.length - 1]}
                sx={{ flex: 1 }}
              />
              <TextField
                type="number"
                value={settings.reward_points_required}
                onChange={(e) => {
                  const value = e.target.value;
                  onChange('reward_points_required',
                    value === '' ? '' : Math.max(0, parseInt(value) || 0)
                  );
                }}
                size="small"
                sx={{ width: '70px' }}
                inputProps={{
                  min: 0,
                  max: 9999,
                  inputMode: 'numeric',
                  pattern: '[0-9]*'
                }}
              />
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={settings.reward_auto_approve}
                  onChange={(e) => onChange('reward_auto_approve', e.target.checked)}
                />
              }
              label="Auto-approve Rewards"
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

const SubmissiveControlSettings = ({ settings, onChange }) => {
  const permissions = {
    canChangeTheme: {
      enabled: settings.submissive_can_change_theme,
      icon: <Palette />,
      label: "Change Theme"
    },
    canEditSchedule: {
      enabled: settings.submissive_can_edit_schedule,
      icon: <AccessTime />,
      label: "Edit Schedule"
    },
    canCreateTasks: {
      enabled: settings.submissive_can_create_tasks,
      icon: <Assignment />,
      label: "Create Tasks"
    },
    canRequestRewards: {
      enabled: settings.submissive_can_request_rewards,
      icon: <Star />,
      label: "Request Rewards"
    }
  };

  return (
    <Grid container spacing={3}>
      {Object.entries(permissions).map(([key, permission]) => (
        <Grid item xs={12} sm={6} key={key}>
          <Card>
            <CardContent>
              <Stack direction="row" spacing={2} alignItems="center">
                <IconButton color="primary" size="small">
                  {permission.icon}
                </IconButton>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="subtitle1">
                    {permission.label}
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={permission.enabled}
                        onChange={(e) => {
                          const settingKey = `submissive_${key.replace(/([A-Z])/g, '_$1').toLowerCase()}`;
                          onChange(settingKey, e.target.checked);
                        }}
                      />
                    }
                    label={permission.enabled ? 'Allowed' : 'Not Allowed'}
                  />
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

const Settings = ({ role, currentTheme, setCurrentTheme, submissiveTheme, setSubmissiveTheme }) => {
  const { settings, setSettings, updateSettings, loading, error, setError } = useSettings();
  const [tabValue, setTabValue] = useState(0);
  const [success, setSuccess] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSettingChange = async (key, value) => {
    try {
      const updatedSettings = await updateSettings({ [key]: value });
      setSuccess('Settings updated successfully');
      return updatedSettings;
    } catch (error) {
      setError('Failed to update settings');
      throw error;
    }
  };

  const handleEnableTutorials = async () => {
    try {
      await axiosInstance.post('/enable-tutorials');
      setSuccess('Tutorials have been re-enabled. They will show on your next login.');
    } catch (error) {
      console.error('Failed to enable tutorials:', error);
      setError('Failed to enable tutorials. Please try again.');
    }
  };

  const handleEnableFirstLoginWizard = async () => {
    try {
      await axiosInstance.post('/enable-first-login-wizard');
      setSuccess('First Login Wizard has been re-enabled. It will show on your next login.');
    } catch (error) {
      console.error('Failed to enable First Login Wizard:', error);
      setError('Failed to enable First Login Wizard. Please try again.');
    }
  };

  const handleTimezoneChange = async (event) => {
    const newTimezone = event.target.value;
    try {
      await axiosInstance.post('/timezone', { timezone: newTimezone });
      setSuccess('Timezone updated successfully');
      handleSettingChange('timezone', newTimezone);
    } catch (error) {
      console.error('Error updating timezone:', error);
      setError('Failed to update timezone');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box sx={{ width: '100%' }}>
      <Tabs 
        value={tabValue} 
        onChange={handleTabChange}
        variant={isMobile ? "scrollable" : "fullWidth"}
        scrollButtons={isMobile ? "auto" : false}
        sx={{ 
          borderBottom: 1, 
          borderColor: 'divider',
          '& .MuiTab-root': {
            minWidth: isMobile ? '40px' : 'auto',
            padding: isMobile ? '6px' : '12px',
            minHeight: isMobile ? '48px' : '64px'
          }
        }}
      >
        <Tab 
          icon={<Palette />} 
          aria-label="Appearance"
          sx={{ '& .MuiTab-iconWrapper': { mb: 0 } }}
        />
        <Tab 
          icon={<Notifications />} 
          aria-label="Notifications"
          sx={{ '& .MuiTab-iconWrapper': { mb: 0 } }}
        />
        <Tab 
          icon={<ToggleOn />} 
          aria-label="Features"
          sx={{ '& .MuiTab-iconWrapper': { mb: 0 } }}
        />
        <Tab 
          icon={<SettingsIcon />} 
          aria-label="Defaults"
          sx={{ '& .MuiTab-iconWrapper': { mb: 0 } }}
        />
        <Tab 
          icon={<Lock />} 
          aria-label="Chastity"
          sx={{ '& .MuiTab-iconWrapper': { mb: 0 } }}
        />
        {role === 'dominant' && (
          <Tab 
            icon={<AdminPanelSettings />} 
            aria-label="Submissive Control"
            sx={{ '& .MuiTab-iconWrapper': { mb: 0 } }}
          />
        )}
        <Tab 
          icon={<Help />} 
          aria-label="Tutorials & Timezone"
          sx={{ '& .MuiTab-iconWrapper': { mb: 0 } }}
        />
      </Tabs>

        <TabPanel value={tabValue} index={0}>
          <AppearanceSettings 
            settings={settings}
            onChange={handleSettingChange}
            currentTheme={currentTheme}
            setCurrentTheme={setCurrentTheme}
            submissiveTheme={submissiveTheme}
            setSubmissiveTheme={setSubmissiveTheme}
            role={role}
          />
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <NotificationSettings 
            settings={settings}
            onChange={handleSettingChange}
          />
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <FeatureSettings 
            settings={settings}
            onChange={handleSettingChange}
          />
        </TabPanel>

        <TabPanel value={tabValue} index={3}>
          <DefaultSettings 
            settings={settings}
            onChange={handleSettingChange}
          />
        </TabPanel>

        <TabPanel value={tabValue} index={4}>
          <ChastitySettings 
            settings={settings}
            onUpdate={handleSettingChange}
          />
        </TabPanel>

        {role === 'dominant' && (
          <TabPanel value={tabValue} index={5}>
            <SubmissiveControlSettings 
              settings={settings}
              onChange={handleSettingChange}
            />
          </TabPanel>
        )}

        <TabPanel value={tabValue} index={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>Tutorial Settings</Typography>
              <Button 
                onClick={handleEnableTutorials} 
                variant="contained" 
                color="primary"
                sx={{ mr: 2 }}
              >
                Re-enable Tutorials
              </Button>
              <Button 
                onClick={handleEnableFirstLoginWizard} 
                variant="contained" 
                color="primary"
              >
                Re-enable First Login Wizard
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>Timezone</Typography>
              <FormControl fullWidth>
                <InputLabel>Timezone</InputLabel>
                <Select
                  value={settings.timezone}
                  onChange={handleTimezoneChange}
                  label="Timezone"
                >
                  {Intl.supportedValuesOf('timeZone').map((tz) => (
                    <MenuItem key={tz} value={tz}>{tz}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </TabPanel>

        <Snackbar
          open={!!error || !!success}
          autoHideDuration={3000}
          onClose={() => {
            setError('');
            setSuccess('');
          }}
        >
          <Alert 
            severity={error ? 'error' : 'success'} 
            onClose={() => {
              setError('');
              setSuccess('');
            }}
            sx={{ width: '100%' }}
          >
            {error || success}
          </Alert>
        </Snackbar>
      </Box>
    </LocalizationProvider>
  );
};

export default Settings;