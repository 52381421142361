import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Checkbox, FormControlLabel } from '@mui/material';
import { axiosInstance } from './auth';

const TermsOfUse = ({ onAgree }) => {
  const [agreed, setAgreed] = useState(false);
  const [existingAgreement, setExistingAgreement] = useState(null);

  useEffect(() => {
    const checkExistingAgreement = async () => {
      try {
        const response = await axiosInstance.get('/agreements/status');
        if (response.data.terms_of_use) {
          setExistingAgreement(true);
          setAgreed(true);
          onAgree(true); // Automatically mark as agreed if previously accepted
        }
      } catch (error) {
        console.error('Error checking agreement status:', error);
      }
    };
    checkExistingAgreement();
  }, [onAgree]);

  const handleAgree = async () => {
    if (!existingAgreement) {
      try {
        await onAgree(true);
        setAgreed(true);
      } catch (error) {
        console.error('Error submitting agreement:', error);
      }
    }
  };

  return (
    <Box sx={{ color: 'text.primary' }}>
      {existingAgreement ? (
        <Typography variant="body1" sx={{ mb: 2, color: 'success.main' }}>
          You have already agreed to the Terms of Use.
        </Typography>
      ) : (
        <Box sx={{ mb: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreed}
                onChange={(e) => setAgreed(e.target.checked)}
                sx={{
                  color: 'primary.main',
                  '&.Mui-checked': {
                    color: 'primary.main',
                  },
                }}
              />
            }
            label="I agree to the Terms of Use"
          />
          <Button
            variant="contained"
            color="primary"
            disabled={!agreed}
            onClick={handleAgree}
            sx={{ mt: 1 }}
          >
            Accept Terms
          </Button>
        </Box>
      )}

      <Typography variant="body1" paragraph>
      Acceptance of Terms

By downloading, installing, accessing or using the Strictly application (hereinafter referred to as "the App"), you agree to be bound by these Terms of Service and Liability Waiver (hereinafter referred to as "Terms"). If you do not agree to these Terms, you must not access or use the App. These Terms constitute a legally binding agreement between you and Strictly, the company that owns and operates the App.

Description of Service

The App provides a platform for consenting adults to engage in BDSM and kink-related activities, including but not limited to task management, rule setting, and reward/punishment systems. The content and features of the App are intended for mature audiences only and may include explicit or sensitive material.

Age Restriction and Legal Capacity

You must be at least 18 years old or the age of legal majority in your jurisdiction, whichever is higher, to access or use the App. By using the App, you represent and warrant that you have the legal capacity to enter into these Terms and to use the App in accordance with all applicable laws.

User Accounts

4.1 You may be required to create an account to access certain features of the App. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
4.2 You are responsible for safeguarding the password that you use to access the App and for any activities or actions under your account. You agree not to disclose your password to any third party.
4.3 You agree to notify us immediately of any unauthorized use of your account or any other breach of security.

User Conduct and Content

5.1 You are solely responsible for all activities, actions, and content that you post, upload, or transmit through the App.
5.2 You agree not to use the App for any unlawful purpose or in any way that violates these Terms or any applicable laws or regulations.
5.3 You agree not to upload, post, or transmit any content that is illegal, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, invasive of another's privacy, or otherwise objectionable.
5.4 Strictly reserves the right, but not the obligation, to monitor, edit, or remove any content that violates these Terms or is deemed inappropriate by Strictly in its sole discretion.

Intellectual Property Rights

6.1 The App and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by Strictly, its licensors, or other providers of such material and are protected by copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
6.2 You may not copy, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our App, except as incidental to normal use of the App.

Privacy Policy

Your use of the App is also governed by our Privacy Policy, which is incorporated into these Terms by reference. Please review our Privacy Policy to understand our practices regarding the collection, use, and disclosure of your personal information.

Modifications to the App and Terms

8.1 Strictly reserves the right to modify or discontinue, temporarily or permanently, the App or any features or portions thereof without prior notice.
8.2 Strictly may revise and update these Terms from time to time in our sole discretion. All changes are effective immediately when we post them. Your continued use of the App following the posting of revised Terms means that you accept and agree to the changes.

Termination

9.1 Strictly may terminate or suspend your access to all or part of the App, without notice, for any conduct that Strictly, in its sole discretion, believes is in violation of these Terms or any applicable law, or is harmful to the interests of another user, a third-party, or Strictly.
9.2 Upon termination, your right to use the App will immediately cease.

Disclaimers

10.1 THE APP IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
10.2 STRICTLY DOES NOT WARRANT THAT THE APP WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE.
10.3 STRICTLY DOES NOT ENDORSE, SUPPORT, REPRESENT, OR GUARANTEE THE COMPLETENESS, TRUTHFULNESS, ACCURACY, OR RELIABILITY OF ANY CONTENT OR COMMUNICATIONS POSTED VIA THE APP.

Limitation of Liability

11.1 IN NO EVENT SHALL STRICTLY, ITS DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS, OR AFFILIATES BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM:
(a) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE APP;
(b) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE APP;
(c) ANY CONTENT OBTAINED FROM THE APP; AND
(d) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT,
WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, WHETHER OR NOT WE HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE.

Indemnification

You agree to defend, indemnify, and hold harmless Strictly, its officers, directors, employees, and agents, from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms or your use of the App.

Governing Law and Jurisdiction

These Terms shall be governed by and construed in accordance with the laws of [insert applicable jurisdiction], without regard to its conflict of law provisions. You agree to submit to the personal jurisdiction of the courts located within [insert applicable jurisdiction] for the purpose of litigating all such claims or disputes.

Severability

If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law, and the remaining provisions will continue in full force and effect.

Waiver

The failure of Strictly to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision.

Entire Agreement

These Terms, our Privacy Policy, and any other legal notices published by Strictly in connection with the App shall constitute the entire agreement between you and Strictly concerning the App.
      </Typography>
    </Box>
  );
};

export default TermsOfUse;