import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText,
  DialogActions, 
  Button, 
  Tooltip,
  Fade
} from '@mui/material';
import { axiosInstance, isAuthenticated } from './auth';
// check that we're still calling these as they're important maybe?
// import DominantQuestions from './DominantQuestions';
// import SubmissiveQuestions from './SubmissiveQuestions';


const TutorialManager = ({ role, children }) => {
  const [tutorialContent, setTutorialContent] = useState({});
  const [currentTutorial, setCurrentTutorial] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [demoItemId, setDemoItemId] = useState(null);
  const [tutorialEnabled, setTutorialEnabled] = useState(true);
  const [showSkipDialog, setShowSkipDialog] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log('TutorialManager: tutorialContent', tutorialContent);
    // console.log('TutorialManager: currentTutorial', currentTutorial);
    // console.log('TutorialManager: currentStep', currentStep);
    // console.log('TutorialManager: tutorialEnabled', tutorialEnabled);
    // console.log('TutorialManager: isAuthenticated', isAuthenticated());
    // console.log('TutorialManager: location', location);
  }, [tutorialContent, currentTutorial, currentStep, tutorialEnabled, location]);

  useEffect(() => {
    const loadTutorialContent = async () => {
      if (!isAuthenticated() || location.pathname === '/') {
        setTutorialContent({});
        return;
      }

      try {
        const [contentResponse, progressResponse] = await Promise.all([
          axiosInstance.get('/tutorial-content'),
          axiosInstance.get('/tutorial-progress')
        ]);
        setTutorialContent(contentResponse.data);
        setTutorialEnabled(!progressResponse.data.completed);
        setCurrentStep(progressResponse.data.current_step);
      } catch (error) {
        console.error('Failed to load tutorial content or progress:', error);
      }
    };

    loadTutorialContent();
  }, [location]);

  useEffect(() => {
    const tutorialId = location.pathname.split('/')[1] || 'dashboard';
    if (tutorialContent[tutorialId] && tutorialEnabled) {
      setCurrentTutorial(tutorialContent[tutorialId]);
    } else {
      setCurrentTutorial(null);
    }
  }, [location, tutorialContent, tutorialEnabled]);

  const updateTutorialProgress = async (step, completed = false) => {
    try {
      await axiosInstance.post('/tutorial-progress', { 
        current_step: step,
        completed: completed,
        task_created: currentTutorial.id === 'tasks-and-completions' && step >= 2,
        punishment_created: currentTutorial.id === 'rewards-and-punishments' && step >= 2,
        completion_reviewed: currentTutorial.id === 'tasks-and-completions' && step >= 4
      });
    } catch (error) {
      console.error('Failed to update tutorial progress:', error);
    }
  };

  const nextStep = async () => {
    if (currentStep < currentTutorial.steps.length - 1) {
      const nextStepIndex = currentStep + 1;
      setCurrentStep(nextStepIndex);
      await updateTutorialProgress(nextStepIndex);

      const nextStep = currentTutorial.steps[nextStepIndex];
      if (nextStep.route && nextStep.route !== location.pathname) {
        navigate(nextStep.route);
      }

      if (nextStep.action) {
        await performAction(nextStep.action);
      }
    } else {
      endTutorial(true);
    }
  };

  const prevStep = async () => {
    if (currentStep > 0) {
      const prevStepIndex = currentStep - 1;
      setCurrentStep(prevStepIndex);
      await updateTutorialProgress(prevStepIndex);

      const prevStep = currentTutorial.steps[prevStepIndex];
      if (prevStep.route && prevStep.route !== location.pathname) {
        navigate(prevStep.route);
      }
    }
  };

  const skipTutorial = () => {
    setShowSkipDialog(true);
  };

  const handleSkipDialogClose = () => {
    setShowSkipDialog(false);
  };

  const handleSkipConfirm = (showNextTime) => {
    setShowSkipDialog(false);
    if (showNextTime) {
      endTutorial(false);
    } else {
      disableTutorials();
    }
  };

  const endTutorial = async (completed) => {
    await updateTutorialProgress(-1, completed);
    if (demoItemId) {
      await cleanupDemoItem();
    }
    setCurrentTutorial(null);
    setCurrentStep(0);
    setIsTooltipVisible(false);
  };

  const disableTutorials = async () => {
    try {
      await axiosInstance.post('/disable-tutorials');
      setTutorialEnabled(false);
    } catch (error) {
      console.error('Failed to disable tutorials:', error);
    }
  };

  const performAction = async (action) => {
    const element = document.querySelector(action.target);
    if (!element) {
      console.warn(`Element not found: ${action.target}`);
      return;
    }

    switch (action.type) {
      case 'click':
        element.click();
        break;
      case 'form-fill':
        if (element.tagName === 'FORM') {
          const inputs = element.querySelectorAll('input, select, textarea');
          inputs.forEach(input => {
            if (input.type === 'checkbox' || input.type === 'radio') {
              input.checked = true;
            } else {
              input.value = 'Sample Text';
            }
            input.dispatchEvent(new Event('input', { bubbles: true }));
            input.dispatchEvent(new Event('change', { bubbles: true }));
          });
        }
        break;
      case 'submit-form':
        if (element.tagName === 'FORM') {
          const submitEvent = new Event('submit', { bubbles: true, cancelable: true });
          element.dispatchEvent(submitEvent);
          
          // Wait for the form submission to complete
          await new Promise(resolve => setTimeout(resolve, 1000));

          // Get the ID of the newly created item
          const newItemId = await getNewlyCreatedItemId();
          if (newItemId) {
            setDemoItemId(newItemId);
          }
        }
        break;
      default:
        console.warn(`Unsupported action type: ${action.type}`);
    }
  };

  const getNewlyCreatedItemId = async () => {
    // This function should be implemented to get the ID of the newly created item
    // It might involve making an API call or checking the state of your application
    // Return null if unable to get the ID
    return null;
  };

  const cleanupDemoItem = async () => {
    if (demoItemId) {
      try {
        // Make an API call to delete the demo item
        await axiosInstance.delete(`/${currentTutorial.id}/${demoItemId}`);
        setDemoItemId(null);
      } catch (error) {
        console.error('Failed to cleanup demo item:', error);
      }
    }
  };

  const getTooltipPosition = (target) => {
    const element = document.querySelector(target);
    if (element) {
      const rect = element.getBoundingClientRect();
      return {
        top: `${rect.bottom + window.scrollY}px`,
        left: `${rect.left + window.scrollX}px`,
      };
    }
    return { top: '0px', left: '0px' };
  };

  const renderTooltip = () => {
    if (!currentTutorial || !currentTutorial.steps[currentStep] || !currentTutorial.steps[currentStep].tooltip) {
      return null;
    }

    const { tooltip } = currentTutorial.steps[currentStep];

    return (
      <Tooltip
        open={isTooltipVisible}
        title={tooltip.content}
        placement="bottom"
        arrow
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
      >
        <div style={{
          position: 'absolute',
          ...getTooltipPosition(tooltip.target),
          width: '10px',
          height: '10px',
          pointerEvents: 'none'
        }}></div>
      </Tooltip>
    );
  };

  const renderTutorialContent = () => {
    if (!currentTutorial || !isAuthenticated() || location.pathname === '/' || !tutorialEnabled) {
      return children;
    }

    const currentStepData = currentTutorial.steps[currentStep];

    if (!currentStepData) {
      console.error('TutorialManager: Current step data is undefined');
      return children;
    }

    if (currentStepData.role && currentStepData.role !== role) {
      return children;
    }

    return (
      <>
        {children}
        {renderTooltip()}
        <Dialog 
          open={true} 
          onClose={() => skipTutorial()}
          PaperProps={{
            style: {
              position: 'fixed',
              bottom: 20,
              left: '50%',
              transform: 'translateX(-50%)',
              maxWidth: '90%',
              width: '500px'
            }
          }}
        >
          <DialogTitle>{currentStepData.title || 'Tutorial'}</DialogTitle>
          <DialogContent>
            {currentStepData.content || 'No content available for this step.'}
          </DialogContent>
          <DialogActions>
            <Button onClick={prevStep} disabled={currentStep === 0}>Previous</Button>
            <Button onClick={nextStep}>
              {currentStep === currentTutorial.steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
            <Button onClick={skipTutorial} color="secondary">Skip</Button>
          </DialogActions>
        </Dialog>

        {/* Skip Confirmation Dialog */}
        <Dialog
          open={showSkipDialog}
          onClose={handleSkipDialogClose}
          aria-labelledby="skip-dialog-title"
          aria-describedby="skip-dialog-description"
        >
          <DialogTitle id="skip-dialog-title">
            Skip Tutorial
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="skip-dialog-description">
              Would you like to see this tutorial next time?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleSkipConfirm(false)} color="secondary">
              Never Show Again
            </Button>
            <Button onClick={() => handleSkipConfirm(true)} color="primary" autoFocus>
              Show Next Time
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const handleComplete = (data) => {
    // Handle the completion of DominantQuestions or SubmissiveQuestions
    //console.log('Completed with data:', data);
    // You might want to send this data to your backend or update app state
    nextStep();
  };

  return renderTutorialContent();
};

export default TutorialManager;