// useActionCounts.js
import { useState, useEffect } from 'react';
import { axiosInstance } from './auth';

export const useActionCounts = (role) => {
  const [counts, setCounts] = useState({
    tasks: 0,
    completions: 0,
    rewards: 0,
    punishments: 0,
    spotChecks: 0,
    wishlist: 0,
    surveys: 0,
    kinks: 0,
    rules: 0,
    monopoly: 0
  });

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const response = await axiosInstance.get('/pending-actions');
        setCounts(response.data);
      } catch (error) {
        console.error('Error fetching action counts:', error);
        setError(error.message || 'Failed to fetch action counts');
      } finally {
        setIsLoading(false);
      }
    };

    if (role) {
      fetchCounts();
      // Poll every 30 seconds for updates
      const intervalId = setInterval(fetchCounts, 30000);
      return () => clearInterval(intervalId);
    }
  }, [role]);

  // Helper function to get combined counts for mobile navigation
  const getCombinedTaskCount = () => {
    if (role === 'dominant') {
      // For dominant: task requests + completions requiring approval
      return counts.tasks + counts.completions;
    } else {
      // For submissive: tasks to complete + rules to accept
      return counts.tasks + counts.rules;
    }
  };

  const getCombinedRewardCount = () => {
    // Both roles: rewards + punishments + wishlist
    return counts.rewards + counts.punishments + counts.wishlist;
  };

  const getSpotCheckCount = () => {
    // Count is the same for both roles
    return counts.spotChecks;
  };

  const getGameCount = () => {
    // Returns 1 if it's the user's turn in monopoly, 0 otherwise
    return counts.monopoly;
  };

  const getSurveyCount = () => {
    // Returns combined count of surveys and kinks needing attention
    return counts.surveys + counts.kinks;
  };

  return {
    ...counts,
    isLoading,
    error,
    getCombinedTaskCount,
    getCombinedRewardCount,
    getSpotCheckCount,
    getGameCount,
    getSurveyCount
  };
};