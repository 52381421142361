import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from './auth';
import { 
  Box, 
  Grid,
  Paper, 
  Typography, 
  Button, 
  List, 
  ListItem, 
  ListItemText,
  ListItemIcon,
  Alert,
  CircularProgress,
  Badge,
  Card,
  CardContent,
  Tooltip,
  Zoom,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  Warning,
  CheckCircle,
  Assignment,
  EmojiEvents,
  Gavel,
  Quiz,
  Favorite,
  School,
  WbSunny,
  Brightness4,
  Brightness5,
  Brightness6,
  Brightness7,
  NightsStay,
  Casino,
  Assessment,
  Schedule,
  GavelOutlined,
  EmojiEventsOutlined,
  Lock,
  Poll,
  Bed,
  ContactPage

} from '@mui/icons-material';

const capitalize = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const TimeBasedBackground = () => {
  const [timeOfDay, setTimeOfDay] = useState('');
  
  useEffect(() => {
    const updateTimeOfDay = () => {
      const hour = new Date().getHours();
      
      if (hour >= 5 && hour < 12) {
        setTimeOfDay('morning');
      } else if (hour >= 12 && hour < 15) {
        setTimeOfDay('midday');
      } else if (hour >= 15 && hour < 19) {
        setTimeOfDay('afternoon');
      } else {
        setTimeOfDay('night');
      }
    };
    
    updateTimeOfDay();
    const interval = setInterval(updateTimeOfDay, 60000);
    
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `url('/assets/checkin/${timeOfDay}.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 0.95,
        transition: 'opacity 1s ease-in-out',
        zIndex: 0
      }}
    />
  );
};

const getTimeIcon = () => {
  const hour = new Date().getHours();
  if (hour >= 5 && hour < 8) return <WbSunny color="warning" />;
  if (hour >= 8 && hour < 12) return <Brightness7 color="warning" />;
  if (hour >= 12 && hour < 15) return <Brightness6 color="warning" />;
  if (hour >= 15 && hour < 19) return <Brightness5 color="warning" />;
  if (hour >= 19 && hour < 22) return <Brightness4 color="primary" />;
  return <NightsStay color="primary" />;
};

const getGreeting = (name) => {
  const hour = new Date().getHours();
  const timeIcon = getTimeIcon();
  
  let greeting;
  if (hour >= 5 && hour < 12) {
    greeting = `Rise and shine ${name}!`;
  } else if (hour >= 12 && hour < 15) {
    greeting = `Good afternoon ${name}!`;
  } else if (hour >= 15 && hour < 19) {
    greeting = `Hey there ${name}!`;
  } else if (hour >= 19 && hour < 22) {
    greeting = `Good evening ${name}!`;
  } else {
    greeting = `Sweet dreams ${name}!`;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100% !important', mb: '0'}}>
      {timeIcon}
      <Typography variant="h5" component="span" sx={{ color: 'white', textTransform: 'capitalize' }}>
        {greeting}
      </Typography>
    </Box>
  );
};

const ActionOverview = ({ role, onCreatePunishment }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(theme.breakpoints.down('md'));
  const [isLoading, setIsLoading] = useState(true);

  const replaceNames = useCallback((text, submissiveName, dominantName) => {
    if (typeof text !== 'string') {
      console.warn('Invalid text input:', text);
      return '';
    }
    
    const capitalizedSubmissive = submissiveName 
      ? submissiveName.charAt(0).toUpperCase() + submissiveName.slice(1) 
      : 'Submissive';
    
    const capitalizedDominant = dominantName
      ? dominantName.charAt(0).toUpperCase() + dominantName.slice(1)
      : 'Dominant';

    const result = text
      .replace(/\b(submissive|Sub|sub|sub's)\b/g, (match) => {
        if (match === "sub's") return `${capitalizedSubmissive}'s`;
        return capitalizedSubmissive;
      })
      .replace(/\b(dom|dominant|Dom|Dom's|dom's)\b/g, (match) => {
        if (match === "Dom's" || match === "dom's") return `${capitalizedDominant}'s`;
        return capitalizedDominant;
      });

    return result;
  }, []);

  const [data, setData] = useState({
    submissivePoints: 0,
    pendingCompletions: 0,
    todaysTasks: 0,
    pendingActions: 0,
    pendingPunishments: 0,
    pendingRewards: 0,
    dominantActions: [],
    onboardingSteps: [],
    pendingSurveys: 0,
    unalignedKinks: 0,
    submissiveMood: null,
    submissiveHorny: null,
    dominantMood: null,
    dominantHorny: null,
    unratedKinks: 0,
    hasRules: false,
    assignedSurveysCount: 0,
    relationshipSurveyCompleted: false,
    hasAssignedKinks: false,
    hasAssignedPunishments: false,
    hasAssignedRewards: false,
    newSurveyResults: false,
    recommendedSurvey: null,
    monopolyTurn: false,
    missedTasks: 0,
    taskRequests: 0,
    completionReviews: 0,
    unscheduledToday: 0,
    unscheduledTomorrow: 0,
    ruleViolations: 0,
    pointsBelowZero: false,
    pointsAbove500: false,
    daysSincePunishment: null,
    daysSinceReward: null,
    punishmentReviews: 0,
    rewardReviews: 0,
    assignableRewards: 0,
    chastityEnabled: false,
    chastityDaysRemaining: null,
    submissiveName: '',
    submissivePronouns: ''
  });

  useEffect(() => {
      const fetchOverviewData = async () => {
          setIsLoading(true);
          try {
              const endpoint = role === 'dominant' ? '/dominant-overview' : '/submissive-overview';
              const response = await axiosInstance.get(endpoint);
              setData(response.data);
          } catch (error) {
              console.error('Error fetching overview data:', error);
          } finally {
              setIsLoading(false);
          }
      };
  
      fetchOverviewData();
  }, [role]);

    const getMoodIcon = (rating) => {
    switch(rating) {
      case 1: return '😢';
      case 2: return '😔';
      case 3: return '😐';
      case 4: return '😊';
      case 5: return '🥰';
      default: return '😐';
    }
  };

  const getHornyIcon = (rating) => {
    switch(rating) {
      case 2: return '😔';
      case 2: return '😳';
      case 3: return '😐';
      case 4: return '😊';
      case 5: return '🥰';
      default: return '😈';
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box 
      sx={{ 
        width: '100vw',
        position: 'relative',
        padding: '0',
        overflow: 'hidden',
        top: '0px',
        // left: '-16px',
        right: isDesktop ? '0' : '20',
        mb: 2,
        // transform: isDesktop ? 'none' : 'translateX(-40%)',
        //px: isDesktop ? 0 : 1
        px: isDesktop ? 0 : 1
      }}
    >
      <Card 
        sx={{ 
          position: 'relative',
          overflow: 'hidden',
          height: 'auto',
          minHeight: '150px',
          width: '100%',
          borderRadius: '75 10 25% 25%', // This creates the rounded bottom
          borderBottomLeftRadius: '5%', // Ensures the arc is tallest in the center
          borderBottomRightRadius: '5%', // Ensures the arc is tallest in the center
        }}
      >
        <TimeBasedBackground />
        <CardContent sx={{ position: 'relative', zIndex: 1 }}>

  {/* Show naughty suggestion if both users are horny */}
  {((role === 'dominant' && data.submissiveHorny >= 4 && data.dominantHorny >= 4) ||
    (role === 'submissive' && data.dominantHorny >= 4 && data.submissiveHorny >= 4)) && (
    <Alert 
      severity="info"
      icon={<Favorite />}
      sx={{ mb: 2 }}
    >
      Looks like today's a good day to do something naughty...
    </Alert>
  )}

    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
    <Zoom in={true}>
        {getGreeting(data[role === 'submissive' ? 'submissiveName' : 'dominantName'])}
    </Zoom>
      {/* Add mood/horny icons here */}
      {role === 'dominant' ? (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Tooltip title={replaceNames(`${data.submissiveName}'s Mood`, data.submissiveName, data.dominantName)}>
            <Box>{getMoodIcon(data.submissiveMood)}</Box>
          </Tooltip>
          <Tooltip title={replaceNames(`${data.submissiveName}'s Arousal`, data.submissiveName, data.dominantName)}>
            <Box>{getHornyIcon(data.submissiveHorny)}</Box>
          </Tooltip>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Tooltip title={replaceNames(`${data.dominantName}'s Mood`, data.submissiveName, data.dominantName)}>
            <Box>{getMoodIcon(data.dominantMood)}</Box>  {/* Changed from data.dominantMood */}
          </Tooltip>
          <Tooltip title={replaceNames(`${data.dominantName}'s Arousal`, data.submissiveName, data.dominantName)}>
            <Box>{getHornyIcon(data.dominantHorny)}</Box>  {/* Changed from data.dominantHorny */}
          </Tooltip>
        </Box>
      )}
    </Box>

          {/* Responsive Grid Layout for Main Content */}
          <Grid container spacing={2} sx={{ mt: 3 }}>
            {/* Onboarding Items Section - Takes full width on mobile, half on desktop */}
            {role === 'dominant' && (
              <Grid item xs={12} md={6}>
                {(data.unratedKinks > 0 || !data.hasRules || !data.relationshipSurveyCompleted || 
                  !data.hasAssignedKinks || !data.hasAssignedPunishments || !data.hasAssignedRewards) && (
                  <Paper elevation={3} sx={{ p: 1, height: '100%' }}>
                    <Typography variant="h6" gutterBottom>
                      Onboarding Items
                    </Typography>
    {(data.unratedKinks > 0 || !data.hasRules || !data.relationshipSurveyCompleted) && (
      <Alert severity="warning" sx={{ mb: 1 }}>
        Complete the following actions for an optimal Strictly experience
      </Alert>
    )}
    <List dense>
      {data.unratedKinks > 0 && (
        <ListItem>
          <ListItemIcon>
            <ContactPage color="error" />
          </ListItemIcon>
          <ListItemText primary="Kink profile incomplete" />
        </ListItem>
      )}

      {data.unalignedKinks > 0 && (
        <ListItem>
          <ListItemIcon>
            <Badge badgeContent={data.unalignedKinks} color="error">
              <Favorite />
            </Badge>
          </ListItemIcon>
          <ListItemText primary={`${data.unalignedKinks} kink${data.unalignedKinks !== 1 ? 's' : ''} left unrated`} />
          <Button 
            size="small"
            onClick={() => navigate('/kinks')}
          >
            Rate
          </Button>
        </ListItem>
      )}
      
      {!data.hasRules && (
        <ListItem>
          <ListItemIcon>
            <Gavel />
          </ListItemIcon>
          <ListItemText primary="No rules or behaviors added" />
          <Button 
            size="small"
            onClick={() => navigate('/rules')}
          >
            Create
          </Button>
        </ListItem>
      )}

      {!data.hasAssignedKinks && (
        <ListItem>
          <ListItemIcon>
            <Bed />
          </ListItemIcon>
          <ListItemText primary="No kink specific surveys have been assigned yet" />
          <Button 
            size="small"
            onClick={() => navigate('/surveys')}
          >
            Assign
          </Button>
        </ListItem>
      )}

      {!data.relationshipSurveyCompleted && (
        <ListItem>
          <ListItemIcon>
            <Poll />
          </ListItemIcon>
          <ListItemText primary="Relationship survey not yet filled" />
          <Button 
            size="small"
            onClick={() => navigate('/surveys')}
          >
            Fill
          </Button>
        </ListItem>
      )}

    </List>
                  </Paper>
                )}
              </Grid>
            )}

            {/* Action Items Section - Takes full width on mobile, half on desktop */}
            <Grid item xs={12} md={role === 'dominant' ? 6 : 12}>
              <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" gutterBottom>
                  Action Items
                </Typography>
      
      {/* Dominant-specific content */}
      {role === 'dominant' && (
        <>
          {/* Survey Section */}
          {(data.newSurveyResults || data.recommendedSurvey) && (
            <Box sx={{ mb: 2 }}>
              {data.newSurveyResults && (
                <Alert 
                  severity="info" 
                  action={
                    <Button 
                      color="inherit" 
                      size="small"
                      onClick={() => navigate('/surveys')}
                    >
                      View Results
                    </Button>
                  }
                >
                  Survey results are in, check it out!
                </Alert>
              )}
              
              {data.recommendedSurvey && (
                <Alert 
                  severity="info" 
                  action={
                    <Button 
                      color="inherit" 
                      size="small"
                      onClick={() => navigate(`/surveys/${data.recommendedSurvey.id}/assign`)}
                    >
                      Assign Now
                    </Button>
                  }
                >
                  Recommended survey: {data.recommendedSurvey.title}
                </Alert>
              )}
            </Box>
          )}

          {/* Chastity Section */}
          {data.chastityEnabled && data.chastityDaysRemaining !== null && (
            <Alert 
              icon={<Lock />}
              severity="info"
              action={
                <Button 
                  color="inherit" 
                  size="small"
                  onClick={() => navigate('/chastity')}
                >
                  Extend
                </Button>
              }
              sx={{ mb: 2 }}
            >
              Chastity ends in {data.chastityDaysRemaining} days
            </Alert>
          )}

          {/* Monopoly Turn */}
          {data.monopolyTurn && (
            <Alert 
              icon={<Casino />}
              severity="info" 
              action={
                <Button 
                  color="inherit" 
                  size="small"
                  onClick={() => navigate('/monopoly')}
                >
                  Play
                </Button>
              }
              sx={{ mb: 2 }}
            >
              Kinkopoly move awaits
            </Alert>
          )}
    
          {/* Points and Basic Alerts */}
          {data.pointsBelowZero && (
            <Alert 
              severity="warning" 
              action={
                <Button 
                  color="inherit" 
                  size="small"
                  onClick={() => {
                    navigate('/rewards-and-punishments/#punishments');
                    if (onCreatePunishment) onCreatePunishment();
                  }}
                >
                  Punish Them!
                </Button>
              }
              sx={{ mb: 2 }}
            >
              {capitalize(data.submissiveName)}'s points have dropped below 0 ({data.submissivePoints} points)
            </Alert>
          )}
          
          {/* Tasks Section */}
          <List dense sx={{ p: 0 }}>
            {data.missedTasks > 0 && (
              <ListItem>
                <ListItemIcon><Warning color="error" /></ListItemIcon>
                <ListItemText 
                  primary={`${capitalize(data.submissiveName)} missed ${data.missedTasks} tasks this week`}
                />
                <Button 
                  size="small"
                  onClick={() => navigate('/rewards-and-punishments')}
                >
                  Punish them
                </Button>
              </ListItem>
            )}
      
            {data.taskRequests > 0 && (
              <ListItem>
                <ListItemIcon>
                  <Badge badgeContent={data.taskRequests} color="error">
                    <Assignment />
                  </Badge>
                </ListItemIcon>
                <ListItemText primary={`${data.taskRequests} task requests for review`} />
                <Button 
                  size="small"
                  onClick={() => navigate('/tasks-and-completions')}
                >
                  Review
                </Button>
              </ListItem>
            )}
      
            {data.completionReviews > 0 && (
              <ListItem>
                <ListItemIcon>
                  <Badge badgeContent={data.completionReviews} color="warning">
                    <Assessment />
                  </Badge>
                </ListItemIcon>
                <ListItemText primary={`${data.completionReviews} completions for review`} />
                <Button 
                  size="small"
                  onClick={() => navigate('/tasks-and-completions')}
                >
                  Review
                </Button>
              </ListItem>
            )}
      
            {(data.unscheduledToday > 0 || data.unscheduledTomorrow > 0) && (
              <ListItem>
                <ListItemIcon>
                  <Schedule color="warning" />
                </ListItemIcon>
                <ListItemText 
                  primary={`${data.unscheduledToday} tasks need scheduling today, ${data.unscheduledTomorrow} tomorrow`}
                />
                <Button 
                  size="small"
                  onClick={() => navigate('/tasks-and-completions')}
                >
                  Schedule
                </Button>
              </ListItem>
            )}
      
          {/* Rules Section */}
          {data.ruleViolations > 0 && (
              <ListItem>
                <ListItemIcon>
                  <Schedule color="warning" />
                </ListItemIcon>
                <ListItemText 
              primary={`${capitalize(data.submissiveName)} reported {data.ruleViolations} rule violations this week`}
              />
                <Button 
                  size="small"
                  onClick={() => navigate('/tasks-and-completions')}
                >
                  Schedule
                </Button>
              </ListItem>
            )}
      
            {data.daysSincePunishment !== null && (
              <ListItem>
                <ListItemIcon><GavelOutlined /></ListItemIcon>
                <ListItemText 
                  primary={data.daysSincePunishment === null 
                    ? "You are yet to create or assign any punishments"
                    : `${data.daysSincePunishment} days since last punishment`
                  }
                />
                <Button 
                  size="small"
                  onClick={() => navigate('/rewards-and-punishments')}
                >
                  {data.daysSincePunishment === null ? 'Create' : 'Assign'}
                </Button>
              </ListItem>
            )}
      
            {data.punishmentReviews > 0 && (
              <ListItem>
                <ListItemIcon>
                  <Badge badgeContent={data.punishmentReviews} color="error">
                    <Gavel />
                  </Badge>
                </ListItemIcon>
                <ListItemText 
                  primary={`${data.punishmentReviews} punishment completions require review`}
                />
                <Button 
                  size="small"
                  onClick={() => navigate('/rewards-and-punishments')}
                >
                  Review
                </Button>
              </ListItem>
            )}
      
            {data.pointsAbove500 && (
              <ListItem>
                <ListItemIcon><EmojiEventsOutlined color="success" /></ListItemIcon>
                <ListItemText 
                  primary={`${capitalize(data.submissiveName)}'s points are over 500`}
                />
                <Button 
                  size="small"
                  onClick={() => navigate('/rewards-and-punishments')}
                >
                  Reward them
                </Button>
              </ListItem>
            )}
      
            {data.daysSinceReward !== null && (
              <ListItem>
                <ListItemIcon><EmojiEvents /></ListItemIcon>
                <ListItemText 
                  primary={data.daysSinceReward === null
                    ? "You are yet to create or assign any rewards"
                    : `${data.daysSinceReward} days since last reward`
                  }
                />
                <Button 
                  size="small"
                  onClick={() => navigate('/rewards-and-punishments')}
                >
                  {data.daysSinceReward === null ? 'Create' : 'Assign'}
                </Button>
              </ListItem>
            )}
      
            {data.rewardReviews > 0 && (
              <ListItem>
                <ListItemIcon>
                  <Badge badgeContent={data.rewardReviews} color="success">
                    <EmojiEvents />
                  </Badge>
                </ListItemIcon>
                <ListItemText 
                  primary={`${data.rewardReviews} reward completions require review`}
                />
                <Button 
                  size="small"
                  onClick={() => navigate('/rewards-and-punishments')}
                >
                  Review
                </Button>
              </ListItem>
            )}
          </List>
        </>
      )}
    
      {/* Submissive-specific content */}
      {role === 'submissive' && (
        <>
          {/* Chastity Section - only show if days remaining > 0 */}
          {data.chastityEnabled && data.chastityDaysRemaining > 0 && (
            <Alert 
              icon={<Lock />}
              severity="info"
              sx={{ mb: 2 }}
            >
              Chastity ends in {data.chastityDaysRemaining} days
            </Alert>
          )}
      
          {/* Monopoly Turn */}
          {data.monopolyTurn && (
            <Alert 
              icon={<Casino />}
              severity="info"
              action={
                <Button 
                  color="inherit" 
                  size="small"
                  onClick={() => navigate('/monopoly')}
                >
                  Play
                </Button>
              }
              sx={{ mb: 2 }}
            >
              Kinkopoly is awaiting your move
            </Alert>
          )}
      
          {/* Points Warning */}
          {data.submissivePoints < 0 && (
            <Alert 
              icon={<Warning />}
              severity="warning"
              sx={{ mb: 2 }}
            >
              Your points have dropped below 0 ({data.submissivePoints} points)
            </Alert>
          )}
      
          {/* Today's Tasks */}
          {data.todaysTasks > 0 && (
            <Alert 
              icon={<Assignment />}
              severity="info"
              action={
                <Button 
                  color="inherit" 
                  size="small"
                  onClick={() => navigate('/tasks-and-completions')}
                >
                  View
                </Button>
              }
              sx={{ mb: 2 }}
            >
              {data.todaysTasks} task{data.todaysTasks !== 1 ? 's' : ''} due today
            </Alert>
          )}
      
          {/* Unrated Kinks Warning */}
          {data.unratedKinks > 0 && (
            <Alert 
              icon={<Favorite />}
              severity="warning"
              action={
                <Button 
                  color="inherit" 
                  size="small"
                  onClick={() => navigate('/kinks')}
                >
                  Rate Now
                </Button>
              }
              sx={{ mb: 2 }}
            >
              {data.unratedKinks} kink{data.unratedKinks !== 1 ? 's' : ''} need rating
            </Alert>
          )}
      
          {/* Pending Punishments/Rewards */}
          {(data.pendingPunishments > 0 || data.pendingRewards > 0) && (
            <Alert 
              icon={data.pendingPunishments > data.pendingRewards ? <Gavel /> : <EmojiEvents />}
              severity="warning"
              action={
                <Button 
                  color="inherit" 
                  size="small"
                  onClick={() => navigate('/rewards-and-punishments')}
                >
                  View
                </Button>
              }
              sx={{ mb: 2 }}
            >
              {data.pendingPunishments} punishment{data.pendingPunishments !== 1 ? 's' : ''} and {data.pendingRewards} reward{data.pendingRewards !== 1 ? 's' : ''} pending
            </Alert>
          )}
      
          {/* Surveys */}
          {data.pendingSurveys > 0 && (
            <Alert 
              icon={<Quiz />}
              severity="info"
              action={
                <Button 
                  color="inherit" 
                  size="small"
                  onClick={() => navigate('/surveys')}
                >
                  Start
                </Button>
              }
              sx={{ mb: 2 }}
            >
              {data.pendingSurveys} assigned survey{data.pendingSurveys !== 1 ? 's' : ''} to complete
            </Alert>
          )}
      
          {/* If nothing to show */}
          {!data.monopolyTurn && 
           data.todaysTasks === 0 && 
           data.pendingPunishments === 0 && 
           data.pendingRewards === 0 && 
           data.pendingSurveys === 0 && 
           data.unratedKinks === 0 && 
           data.submissivePoints >= 0 && 
           (!data.chastityEnabled || data.chastityDaysRemaining === 0) && (
            <Alert 
              severity="success" 
              icon={<CheckCircle />}
            >
              You're all caught up!
            </Alert>
          )}
        </>
      )}
    
      {/* Common content for both roles */}
      {(data.pendingSurveys > 0 || data.unalignedKinks > 0 || data.onboardingSteps.length > 0) && (
        <>
          <List dense>
            {data.onboardingSteps.length > 0 && (
              <ListItem>
                <ListItemIcon><School /></ListItemIcon>
                <ListItemText primary="Complete onboarding steps" />
                <Button 
                  size="small"
                  onClick={() => navigate('/dashboard')}
                >
                  Continue
                </Button>
              </ListItem>
            )}
    
            {data.pendingSurveys > 0 && (
              <ListItem>
                <ListItemIcon>
                  <Badge badgeContent={data.pendingSurveys} color="primary">
                    <Quiz />
                  </Badge>
                </ListItemIcon>
                <ListItemText primary={`${data.pendingSurveys} assigned survey${data.pendingSurveys !== 1 ? 's' : ''} to complete`} />
                <Button 
                  size="small"
                  onClick={() => navigate('/surveys')}
                >
                  Start
                </Button>
              </ListItem>
            )}
  
          </List>
        </>
      )}
    
      {/* Show message if no actions needed */}
      {data.submissivePoints >= 0 && 
       data.pendingCompletions === 0 && 
       data.todaysTasks === 0 &&
       data.pendingPunishments === 0 &&
       data.pendingRewards === 0 &&
       data.pendingSurveys === 0 &&
       data.unalignedKinks === 0 &&
       data.onboardingSteps.length === 0 && (
        <Alert severity="success" icon={<CheckCircle />}>
          No pending actions at this time
        </Alert>
      )}
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ActionOverview;