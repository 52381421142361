import React, { useState, useEffect, useCallback } from 'react';
import { axiosInstance } from './auth';
import {
  Container, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, CircularProgress, Snackbar, Card, CardContent, IconButton,
  Box, Chip, Rating, Grid, Select, MenuItem, FormControl, InputLabel,
  Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel,
  Fab, Tooltip, useTheme, useMediaQuery, List, ListItem, ListItemText,
  Collapse, Paper
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { 
  PlayArrow, Pause, Stop, ExpandMore, Add, FilterList,
  DateRange, Close as CloseIcon, CheckCircleOutline,
  Cancel, RateReview
} from '@mui/icons-material';
import { getToken } from './auth';

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const Timer = ({ onTimerUpdate }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
  const intervalRef = React.useRef(null);

  const startTimer = () => {
    if (!isRunning) {
      setIsRunning(true);
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
  };

  const pauseTimer = () => {
    if (isRunning) {
      clearInterval(intervalRef.current);
      setIsRunning(false);
    }
  };

  const stopTimer = () => {
    clearInterval(intervalRef.current);
    setIsRunning(false);
    onTimerUpdate(time);
    setTime(0);
  };

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6">Timer: {formatTime(time)}</Typography>
      <Button startIcon={<PlayArrow />} onClick={startTimer} disabled={isRunning}>Start</Button>
      <Button startIcon={<Pause />} onClick={pauseTimer} disabled={!isRunning}>Pause</Button>
      <Button startIcon={<Stop />} onClick={stopTimer}>Stop</Button>
    </Box>
  );
};

export const CompletionForm = ({ onSubmit, onClose, item, itemType, requireProof }) => {
  const [proof, setProof] = useState(null);
  const [note, setNote] = useState('');
  const [enjoymentRating, setEnjoymentRating] = useState(0);
  const [difficultyRating, setDifficultyRating] = useState(0);
  const [humiliationRating, setHumiliationRating] = useState(0);
  const [timerDuration, setTimerDuration] = useState(0);
  const [orgasmCountSub, setOrgasmCountSub] = useState(0);
  const [orgasmCountDom, setOrgasmCountDom] = useState(0);
  const [location, setLocation] = useState(null);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ text: '', severity: 'info' });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setErrorMessage('');
  };

  useEffect(() => {
    if (errorMessage) {
      setSnackbarOpen(true);
    }
  }, [errorMessage]);

  const requestLocationPermission = async () => {
    try {
      const result = await navigator.permissions.query({ name: 'geolocation' });
      if (result.state === 'denied') {
        throw new Error('Location permission denied');
      }
      return true;
    } catch (error) {
      console.error('Location permission error:', error);
      return false;
    }
  };

  useEffect(() => {
    if (item.gps_check_in) {
      const setupLocation = async () => {
        const hasPermission = await requestLocationPermission();
        if (!hasPermission) {
          setErrorMessage('Location permission is required for this task');
          return;
        }
        
        setIsLoadingLocation(true);
        
        if ('serviceWorker' in navigator) {
          const registerServiceWorker = async () => {
            try {
              await navigator.serviceWorker.ready;
              
              if (navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage({
                  type: 'GET_LOCATION'
                });
              } else {
                setErrorMessage('Service worker not ready. Please refresh the page.');
                setIsLoadingLocation(false);
              }
            } catch (error) {
              console.error('Service worker registration failed:', error);
              setErrorMessage('Failed to initialize location services');
              setIsLoadingLocation(false);
            }
          };
  
          registerServiceWorker();
        } else {
          setErrorMessage('Service workers are not supported in this browser');
          setIsLoadingLocation(false);
        }
      };

      const handleServiceWorkerMessage = (event) => {
        if (event.data && event.data.type === 'LOCATION_RESULT') {
          setLocation({
            latitude: event.data.latitude,
            longitude: event.data.longitude,
            accuracy: event.data.accuracy
          });
          setIsLoadingLocation(false);
        } else if (event.data && event.data.type === 'LOCATION_ERROR') {
          console.error('Location error:', event.data.error);
          setIsLoadingLocation(false);
          setErrorMessage('Failed to get location: ' + event.data.error);
        }
      };

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);
      }
      
      setupLocation();

      return () => {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
        }
      };
    }
  }, [item.gps_check_in]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('id', item.id);
    formData.append('type', itemType);
    if (proof) formData.append('proof', proof);
    const finalNote = item.tags && item.tags.includes('exercise')
      ? `${note}\nExercise duration: ${formatTime(timerDuration)}`
      : note;
    formData.append('note', finalNote);
    formData.append('enjoyment_rating', enjoymentRating);
    formData.append('difficulty_rating', difficultyRating);
    formData.append('humiliation_rating', humiliationRating);
    if (item.tags && item.tags.includes('exercise') && timerDuration > 0) {
      formData.append('timer_duration', timerDuration);
    }
    formData.append('orgasm_count_sub', orgasmCountSub);
    formData.append('orgasm_count_dom', orgasmCountDom);
    if (location) {
      formData.append('latitude', location.latitude);
      formData.append('longitude', location.longitude);
    }
    onSubmit(formData);
  };

  const isExerciseTask = item && item.tags && item.tags.includes('exercise');

  return (
    <Dialog 
      open={true} 
      onClose={onClose}
      fullScreen={isMobile}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          ...(isMobile && {
            position: 'fixed',
            bottom: 0,
            maxHeight: 'calc(100% - 100px)',
            height: 'auto',
            borderRadius: 0,
          })
        }
      }}
    >
      <Box sx={{ 
        position: 'sticky', 
        top: 0, 
        bgcolor: 'background.paper',
        zIndex: 1,
        borderBottom: 1,
        borderColor: 'divider'
      }}>
        <DialogTitle>Submit Completion</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          {requireProof && (
            <TextField
              type="file"
              accept="image/*,video/*"
              onChange={(e) => setProof(e.target.files[0])}
              fullWidth
              margin="normal"
              required
            />
          )}
          {isExerciseTask && <Timer onTimerUpdate={setTimerDuration} />}
          <TextField
            label="Note"
            multiline
            rows={3}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            fullWidth
            margin="normal"
          />

          <Box sx={{ mt: 2 }}>
            <Typography>Enjoyment:</Typography>
            <Rating
              name="enjoyment"
              value={enjoymentRating}
              onChange={(event, newValue) => setEnjoymentRating(newValue)}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography>Difficulty:</Typography>
            <Rating
              name="difficulty"
              value={difficultyRating}
              onChange={(event, newValue) => setDifficultyRating(newValue)}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography>Humiliation:</Typography>
            <Rating
              name="humiliation"
              value={humiliationRating}
              onChange={(event, newValue) => setHumiliationRating(newValue)}
            />
          </Box>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>Advanced Options</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                type="number"
                label="Submissive Orgasm Count"
                value={orgasmCountSub}
                onChange={(e) => setOrgasmCountSub(Number(e.target.value))}
                fullWidth
                margin="normal"
              />
              <TextField
                type="number"
                label="Dominant Orgasm Count"
                value={orgasmCountDom}
                onChange={(e) => setOrgasmCountDom(Number(e.target.value))}
                fullWidth
                margin="normal"
              />
              {item.gps_check_in && (
                <Box mt={2}>
                  <Typography>Location Status:</Typography>
                  {isLoadingLocation ? (
                    <Box display="flex" alignItems="center" gap={1}>
                      <CircularProgress size={20} />
                      <Typography>Getting location...</Typography>
                    </Box>
                  ) : location ? (
                    <Typography>
                      Location acquired: {location.latitude.toFixed(6)}, {location.longitude.toFixed(6)}
                      {location.accuracy && ` (±${Math.round(location.accuracy)}m)`}
                    </Typography>
                  ) : (
                    <Typography color="error">
                      {errorMessage || 'Unable to get location. Please enable location services.'}
                    </Typography>
                  )}
                </Box>
              )}
            </AccordionDetails>
          </Accordion>

          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            Submit Completion
          </Button>
        </form>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          message={errorMessage}
          severity="error"
        />
      </DialogContent>
    </Dialog>
  );
};

const FeedbackModal = ({ open, onClose, onSubmit, completion }) => {
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(0);
  const [orgasmCountSub, setOrgasmCountSub] = useState(completion?.orgasm_count_sub || 0);
  const [orgasmCountDom, setOrgasmCountDom] = useState(completion?.orgasm_count_dom || 0);
  const [pointsAwarded, setPointsAwarded] = useState(
      completion?.task_id ? completion?.points_complete :
      completion?.punishment_id ? completion?.points_earned :
      completion?.points_awarded || 0
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (completion) {
      setOrgasmCountSub(completion.orgasm_count_sub || 0);
      setOrgasmCountDom(completion.orgasm_count_dom || 0);
      setPointsAwarded(completion.points_complete || 0);
    }
  }, [completion]);

  const handleSubmit = (approved) => {
    onSubmit(completion.id, approved, feedback, rating, orgasmCountSub, orgasmCountDom, pointsAwarded);
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      fullScreen={isMobile}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          ...(isMobile && {
            position: 'fixed',
            bottom: 0,
            maxHeight: 'calc(100% - 100px)',
            height: 'auto',
            borderRadius: 0,
          })
        }
      }}
    >
      <Box sx={{ 
        position: 'sticky', 
        top: 0, 
        bgcolor: 'background.paper',
        zIndex: 1,
        borderBottom: 1,
        borderColor: 'divider'
      }}>
        <DialogTitle>Provide Feedback</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Feedback"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
        <Box mt={2}>
          <Typography component="legend">Rating</Typography>
          <Rating
            name="rating"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
        </Box>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Advanced Options</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              margin="dense"
              label="Submissive Orgasm Count"
              type="number"
              fullWidth
              value={orgasmCountSub}
              onChange={(e) => setOrgasmCountSub(Number(e.target.value))}
            />
            <TextField
              margin="dense"
              label="Dominant Orgasm Count"
              type="number"
              fullWidth
              value={orgasmCountDom}
              onChange={(e) => setOrgasmCountDom(Number(e.target.value))}
            />
            <TextField
              margin="dense"
              label="Points Awarded"
              type="number"
              fullWidth
              value={pointsAwarded}
              onChange={(e) => setPointsAwarded(Number(e.target.value))}
            />
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSubmit(false)} color="secondary">Deny</Button>
        <Button onClick={() => handleSubmit(true)} color="primary">Approve</Button>
      </DialogActions>
    </Dialog>
  );
};

const CompletionListItem = ({ completion, role, onReview, onApprove, onDeny, secureFileUrls, getEmojiForRating }) => {
  const [expanded, setExpanded] = useState(false);
  const handleToggle = () => setExpanded(!expanded);

  return (
    <ListItem
      alignItems="flex-start"
      disablePadding
      sx={{ borderBottom: 1, borderColor: 'divider', py: 1 }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', px: 1 }}>
          <Typography
            variant="body2"
            sx={{
              cursor: 'pointer',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              flex: 1,
              mr: 1
            }}
            onClick={handleToggle}
          >
            {completion.item_name}
          </Typography>
          <Box sx={{ display: 'flex', minWidth: 'fit-content' }}>
            {role === 'dominant' && completion.status === 'pending' && (
              <>
                <IconButton 
                  size="small" 
                  onClick={() => onApprove(completion.id)}
                  sx={{ p: 0.5 }}
                >
                  <CheckCircleOutline fontSize="small" />
                </IconButton>
                <IconButton 
                  size="small" 
                  onClick={() => onDeny(completion.id)}
                  sx={{ p: 0.5 }}
                >
                  <Cancel fontSize="small" />
                </IconButton>
                <IconButton 
                  size="small" 
                  onClick={() => onReview(completion)}
                  sx={{ p: 0.5 }}
                >
                  <RateReview fontSize="small" />
                </IconButton>
              </>
            )}
            <IconButton size="small" onClick={handleToggle} sx={{ p: 0.5 }}>
              {expanded ? <ExpandMore /> : <ExpandMore sx={{ transform: 'rotate(-90deg)' }} />}
            </IconButton>
          </Box>
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={{ py: 1, px: 2 }}>
            <Typography variant="caption" color="text.secondary" display="block">
              Completed: {new Date(completion.completion_date).toLocaleString()}
            </Typography>
            
            {completion.note && (
              <Typography variant="body2" sx={{ mt: 0.5, fontSize: '0.875rem' }}>
                {completion.note}
              </Typography>
            )}

            {completion.proof && secureFileUrls[completion.proof] && (
              <Box sx={{ mt: 1 }}>
                {completion.proof_type === 'video' ? (
                  <video width="100%" controls>
                    <source src={secureFileUrls[completion.proof]} type="video/mp4" />
                  </video>
                ) : completion.proof_type === 'audio' ? (
                  <audio controls>
                    <source src={secureFileUrls[completion.proof]} type="audio/mpeg" />
                  </audio>
                ) : (
                  <img src={secureFileUrls[completion.proof]} alt="Proof" style={{maxWidth: '100%', height: 'auto'}} />
                )}
              </Box>
            )}

            <Grid container spacing={1} sx={{ mt: 0.5 }}>
              <Grid item xs={4}>
                <Typography variant="caption" display="block">
                  Enjoyment: {getEmojiForRating(completion.enjoyment_rating)} {completion.enjoyment_rating}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption" display="block">
                  Difficulty: {getEmojiForRating(completion.difficulty_rating)} {completion.difficulty_rating}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption" display="block">
                  Humiliation: {getEmojiForRating(completion.humiliation_rating)} {completion.humiliation_rating}
                </Typography>
              </Grid>
            </Grid>

            {completion.latitude && completion.longitude && (
              <Typography variant="caption" display="block" sx={{ mt: 0.5 }}>
                Location: {completion.latitude.toFixed(6)}, {completion.longitude.toFixed(6)}
              </Typography>
            )}

            <Box sx={{ mt: 1, display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
              <Chip 
                label={completion.status} 
                size="small" 
                color={
                  completion.status === 'approved' ? 'success' : 
                  completion.status === 'denied' ? 'error' : 
                  'default'
                }
              />
              {completion.tags?.map((tag, index) => (
                <Chip key={index} label={tag} size="small" />
              ))}
            </Box>
          </Box>
        </Collapse>
      </Box>
    </ListItem>
  );
};

const Completions = ({ role, setPoints }) => {
  const [completions, setCompletions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [selectedCompletion, setSelectedCompletion] = useState(null);
  const [secureFileUrls, setSecureFileUrls] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [filterProof, setFilterProof] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sortBy, setSortBy] = useState('date');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchCompletions = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/completions`);
      setCompletions(response.data);
    } catch (error) {
      console.error('Error fetching completions:', error);
      setErrorMessage('Failed to fetch completions. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCompletions();
  }, [fetchCompletions]);

  const fetchSecureFileUrl = useCallback(async (filename) => {
    try {
      const token = getToken();
      const response = await axiosInstance.get(`/get-file-url/${filename}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const secureUrl = `${response.data.url}&jwt=${encodeURIComponent(token)}`;
      return secureUrl;
    } catch (error) {
      console.error('Error fetching secure file URL:', error);
      return null;
    }
  }, []);

  useEffect(() => {
    const fetchFileUrls = async () => {
      const urls = {};
      for (const completion of completions) {
        if (completion.proof) {
          urls[completion.proof] = await fetchSecureFileUrl(completion.proof);
        }
      }
      setSecureFileUrls(urls);
    };
    fetchFileUrls();
  }, [completions, fetchSecureFileUrl]);

const handleProcessCompletion = async (completionId, approved, feedback, rating, orgasmCountSub, orgasmCountDom, pointsAwarded) => {
  try {
    const completion = completions.find(c => c.id === completionId);
    const points = approved ? (pointsAwarded || completion.points_complete) : 10;
    
    const response = await axiosInstance.post(`/completions/${completionId}/process`, {
      status: approved ? 'approved' : 'denied',
      dominant_note: feedback,
      rating,
      orgasm_count_sub: orgasmCountSub,
      orgasm_count_dom: orgasmCountDom,
      points_awarded: points
    });
    
    fetchCompletions();
    setPoints(response.data.new_points);
    setErrorMessage({
      text: approved ? 'Completion approved successfully' : 'Completion denied successfully',
      severity: 'success' // This will trigger the confetti
    });
  } catch (error) {
    console.error('Error processing completion:', error);
    setErrorMessage({
      text: 'Failed to process completion. Please try again.',
      severity: 'error'
    });
  }
};

  const getEmojiForRating = (value) => {
    if (value <= 1) return "😢";
    if (value <= 2) return "😕";
    if (value <= 3) return "😐";
    if (value <= 4) return "🙂";
    return "😄";
  };

  const filterAndSortCompletions = (completions) => {
    let filtered = [...completions];

    // Apply filters
    if (filterProof) {
      filtered = filtered.filter(c => c.proof);
    }

    if (startDate) {
      filtered = filtered.filter(c => new Date(c.completion_date) >= startDate);
    }

    if (endDate) {
      filtered = filtered.filter(c => new Date(c.completion_date) <= endDate);
    }

    // Sort
    filtered.sort((a, b) => {
      switch (sortBy) {
        case 'date':
          return new Date(b.completion_date) - new Date(a.completion_date);
        case 'points':
          return b.points_awarded - a.points_awarded;
        case 'status':
          return a.status.localeCompare(b.status);
        default:
          return 0;
      }
    });

    return filtered;
  };

  const groupCompletions = (completions) => {
    const pending = completions.filter(c => c.status === 'pending');
    const others = completions.filter(c => c.status !== 'pending');
  
    // Group other completions by date
    const groupedByDate = others.reduce((acc, completion) => {
      const date = new Date(completion.completion_date).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(completion);
      return acc;
    }, {});
  
    // Create final groups object
    const groups = { 'Pending Review': pending };
    
    // Add date groups
    Object.entries(groupedByDate)
      .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
      .forEach(([date, completions]) => {
        groups[date] = completions;
      });
  
    return groups;
  };

  if (isLoading) return <CircularProgress />;

  const filteredAndSortedCompletions = filterAndSortCompletions(completions);
  const groupedCompletions = groupCompletions(filteredAndSortedCompletions);

// Replace just the return section in your Completions component
return (
  <Container maxWidth="md">
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
      <Typography variant="h4">Completions</Typography>
      <Box>
        <Tooltip title="Show Filters">
          <IconButton onClick={() => setShowFilters(!showFilters)}>
            <FilterList />
          </IconButton>
        </Tooltip>
        <Tooltip title="Date Range">
          <IconButton onClick={() => setShowFilters(!showFilters)}>
            <DateRange />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>

    <Collapse in={showFilters}>
      <Box sx={{ mb: 2 }}>
        <FormControl sx={{ mr: 2, minWidth: 120 }}>
          <InputLabel>Sort By</InputLabel>
          <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
            <MenuItem value="date">Date</MenuItem>
            <MenuItem value="points">Points</MenuItem>
            <MenuItem value="status">Status</MenuItem>
          </Select>
        </FormControl>

        <FormControlLabel
          control={<Checkbox checked={filterProof} onChange={(e) => setFilterProof(e.target.checked)} />}
          label="Has Proof"
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={setStartDate}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={setEndDate}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </Box>
        </LocalizationProvider>
      </Box>
    </Collapse>

    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {Object.entries(groupedCompletions).map(([group, completions]) => (
        completions.length > 0 && (
          <Paper 
            key={group}
            elevation={1} 
            sx={{ 
              borderRadius: 2,
              overflow: 'hidden'
            }}
          >
            <Accordion defaultExpanded={group === 'Pending Review'} sx={{ boxShadow: 'none' }}>
              <AccordionSummary 
                expandIcon={<ExpandMore />}
                sx={{ 
                  py: 0.25,
                  px: 2,
                  minHeight: 'unset',
                  '& .MuiAccordionSummary-content': {
                    my: 0
                  }
                }}
              >
                <Typography variant="h6" sx={{ fontSize: '1.1rem' }}>{group} ({completions.length})</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ px: 1, py: 0 }}>
                <List>
                  {completions.map((completion) => (
                    <CompletionListItem
                      key={completion.id}
                      completion={completion}
                      role={role}
                      onReview={(completion) => {
                        setSelectedCompletion(completion);
                        setFeedbackModalOpen(true);
                      }}
                      onApprove={(completionId) => {
                        const completion = completions.find(c => c.id === completionId);
                        handleProcessCompletion(
                          completionId, 
                          true, 
                          '', 
                          0, 
                          completion.orgasm_count_sub, 
                          completion.orgasm_count_dom, 
                          completion.points_complete
                        );
                      }}
                      onDeny={(completionId) => handleProcessCompletion(
                        completionId, 
                        false, 
                        '', 
                        0, 
                        completion.orgasm_count_sub, 
                        completion.orgasm_count_dom, 
                        -10
                      )}
                      secureFileUrls={secureFileUrls}
                      getEmojiForRating={getEmojiForRating}
                    />
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </Paper>
        )
      ))}
    </Box>

    <FeedbackModal
      open={feedbackModalOpen}
      onClose={() => setFeedbackModalOpen(false)}
      onSubmit={handleProcessCompletion}
      completion={selectedCompletion}
    />

    <Snackbar
      open={!!errorMessage.text}
      autoHideDuration={2000}
      onClose={() => setErrorMessage({ text: '', severity: 'info' })}
      message={errorMessage.text}
      severity={errorMessage.severity}
    />
  </Container>
);
};

export default Completions;