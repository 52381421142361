import React, { useState, useEffect } from 'react';
import {
  Container, Typography, TextField, Button, Card, CardContent, Snackbar, Box,
  Tabs, Tab, Divider, useTheme
} from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';
import { login, registerAccount, loginWithGoogle } from './auth';
import './starfield.css'; 

const Login = ({ setAuth }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (tabValue === 0) {
        // Login
        await login(username, password);
        setAuth(true);
      } else {
        // Register
        const result = await registerAccount(username, email, password);
        if (result.success) {
          setSuccessMessage('Registration successful. Please log in.');
          setTabValue(0); // Switch back to login tab
          setUsername('');
          setPassword('');
          setEmail('');
        } else {
          setError(result.message);
        }
      }
    } catch (error) {
      console.error('Authentication failed:', error);
      setError(tabValue === 0 ? 'Login failed. Please check your credentials.' : 'Registration failed. Please try again.');
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await loginWithGoogle();
      if (result.success) {
        setSuccessMessage('Google sign-in simulation successful. Please use regular login.');
      } else {
        setError(result.message);
      }
    } catch (error) {
      console.error('Google login failed:', error);
      setError('Google login failed. Please try again.');
    }
  };

  const numberOfStars = 300; // Adjust this number as needed
  const starsArray = Array.from({ length: numberOfStars });

  return (
    <Container component="main" maxWidth="xs">
      <div className={`starfield ${theme.palette.mode}`}>
        {starsArray.map((_, index) => (
          <div
            key={index}
            className="stars"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animationDuration: `${Math.random() * 5 + 2}s`, // Animation duration between 2 and 7 seconds
            }}
          />
        ))}
      </div>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '80vh',
        }}
      >
        <Card sx={{ marginTop: 8, padding: 2, width: '100%' }}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <img
                src="/login-white.png"
                alt="Strictly Logo"
                style={{ width: '120px', height: '140px' }}
              />
            </Box>
            <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} centered sx={{ mb: 2 }}>
              <Tab label="Login" />
              <Tab label="Register" />
            </Tabs>
            <form onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              {tabValue === 1 && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              )}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
              >
                {tabValue === 0 ? 'Sign In' : 'Register'}
              </Button>
            </form>
            <Divider sx={{ my: 2 }}>OR</Divider>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleLogin}
              sx={{ mt: 1, mb: 2 }}
            >
              Sign in with Google
            </Button>
          </CardContent>
        </Card>
        <Box sx={{ mt: 'auto', mb: 4, textAlign: 'center' }}>
          <Typography variant="body3" color="text.secondary">
            Version 0.1072
          </Typography>
        </Box>
      </Box>
      <Snackbar
        open={!!error}
        autoHideDuration={2000}
        onClose={() => setError('')}
        message={error}
      />
      <Snackbar
        open={!!successMessage}
        autoHideDuration={2000}
        onClose={() => setSuccessMessage('')}
        message={successMessage}
      />
    </Container>
  );
};

export default Login;