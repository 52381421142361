import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CircularProgress, Container, Tabs, Tab, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Rewards from './rewards';
import Punishments from './punishments';
import Wishlist from './wishlist';
import { axiosInstance } from './auth';

const RewardsAndPunishments = ({ role, points, setPoints }) => {
  const [tabValue, setTabValue] = useState(0);
  const [sortBy, setSortBy] = useState('dateCreated');
  const [groupBy, setGroupBy] = useState('none');
  const [isLoading, setIsLoading] = useState(true);
  const [kinks, setKinks] = useState([]);

  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    switch (hash) {
      case 'rewards':
        setTabValue(0);
        break;
      case 'punishments':
        setTabValue(1);
        break;
      case 'wishlist':
        setTabValue(2);
        break;
      default:
        setTabValue(0);
    }
  }, [location]);

  useEffect(() => {
    const fetchKinks = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get('/kinks');
        setKinks(response.data);
      } catch (error) {
        console.error('Error fetching kinks:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchKinks();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  const handleGroupChange = (event) => {
    setGroupBy(event.target.value);
  };

  return (
    <Container maxWidth={false} sx={{ px: '0px' }}>
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="Rewards" />
        <Tab label="Punishments" />
        <Tab label="Wishlist" />
      </Tabs>
      {tabValue !== 2 && (
        <Box sx={{ mt: 1.5, display: 'flex', justifyContent: 'space-between' }}>
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel>Sort By</InputLabel>
            <Select value={sortBy} onChange={handleSortChange}>
              <MenuItem value="dateCreated">Date Created</MenuItem>
              <MenuItem value="points">Points</MenuItem>
              <MenuItem value="name">Name</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel>Group By</InputLabel>
            <Select value={groupBy} onChange={handleGroupChange}>
              <MenuItem value="none">None</MenuItem>
              <MenuItem value="tag">Tag</MenuItem>
              <MenuItem value="status">Status</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}
      <Box sx={{ mt: 1.5 }}>
        {tabValue === 0 && (
          isLoading ? (
            <CircularProgress />
          ) : (
            <Rewards 
              role={role} 
              points={points} 
              setPoints={setPoints} 
              kinks={kinks}
              sortBy={sortBy} 
              groupBy={groupBy} 
            />
          )
        )}
        {tabValue === 1 && (
          <Punishments 
            role={role} 
            sortBy={sortBy} 
            groupBy={groupBy} 
          />
        )}
        {tabValue === 2 && <Wishlist role={role} />}
      </Box>
    </Container>
  );
};

export default RewardsAndPunishments;