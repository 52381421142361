import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { axiosInstance } from './auth';
import {
  Typography, Box, IconButton, Switch,
  FormControl, Select, MenuItem, Card, CardContent,
  Button, Fade, CircularProgress,
  useMediaQuery, Tooltip, Snackbar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavigateBefore, NavigateNext, Close, ArrowBack, Check } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import kinkCategoriesData from './kinkCategories.json';
import { useSwipeable } from 'react-swipeable';

const ratingEmojis = {
  'select': '',
  'hard no': '🚫',
  'uncertain': '❓',
  'fantasy/roleplay only': '🎭',
  'if forced': '😬',
  'maybe': '🤷',
  'yes': '😊',
  'please': '😍',
  'willing to try': '🤨',
  'unsure but interested': '🤔',
  'very much yes': '🥰',
  'need': '💖',
  'mandatory': '💖'
};

const FullScreenContainer = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 9999,
  backgroundColor: '#121212',
  overflow: 'hidden'
});

const CategoryList = styled('div')({
  height: '100vh',
  overflowY: 'auto',
  scrollSnapType: 'y mandatory',
  '&::-webkit-scrollbar': {
  display: 'none'
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none'
});

const CategoryItem = styled(Typography)(({ theme, scale = 1, opacity = 1 }) => ({
  textAlign: 'right',
  padding: theme.spacing(1), // reduced padding
  transform: `scale(${scale})`,
  opacity,
  transition: 'all 0.3s ease',
  cursor: 'pointer',
  scrollSnapAlign: 'center',
  color: theme.palette.common.white,
  fontSize: '1.5rem',
  fontFamily: "'Playfair Display', serif",
  marginRight: theme.spacing(4), // added right margin
  paddingRight: '15%', // adjust text alignment
}));

const NavigationButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '16px',
  zIndex: 1000,
  color: theme.palette.common.white,
  '&:hover': {
  backgroundColor: 'rgba(255, 255, 255, 0.1)'
  }
}));

const CloseButton = styled(NavigationButton)({
  right: '16px',
});

const BackButton = styled(NavigationButton)({
  left: '16px',
});

const submissiveRatingOptions = [
  'select',
  'hard no',
  'uncertain',
  'unsure but interested',
  'if forced',
  'maybe',
  'fantasy/roleplay only',
  'yes',
  'please',
  'need',
];

const dominantRatingOptions = [
  'select',
  'hard no',
  'uncertain',
  'unsure but interested',
  'willing to try',
  'maybe',
  'fantasy/roleplay only',
  'yes',
  'very much yes',
  'mandatory'
];

const KinkDescription = styled(Typography)(({ theme }) => ({
  fontFamily: "'Lora', serif",
  fontSize: '1rem',
  lineHeight: 1,
  letterSpacing: '0.015em',
  color: 'rgba(255, 255, 255, 0.9)',
  marginBottom: theme.spacing(4),
  padding: theme.spacing(2),
  borderLeft: '3px solid rgba(255, 255, 255, 0.2)',
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  borderRadius: theme.spacing(1),
  transition: 'all 0.3s ease',
  '&:hover': {
    borderLeft: '3px solid rgba(255, 255, 255, 0.4)',
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  }
}));

const RatingButton = styled(Button)(({ theme }) => ({
  fontFamily: "'Lora', serif",
  fontSize: '1rem',
  background: 'rgba(255, 255, 255, 0.05)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1, 2),
  minWidth: '140px',
  transition: 'all 0.3s ease',
  color: 'rgba(255, 255, 255, 0.8)',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.1)',
    borderColor: 'rgba(255, 255, 255, 0.2)',
    transform: 'translateY(-2px)',
  },
  '&.selected': {
    background: 'rgba(255, 255, 255, 0.15)',
    borderColor: 'rgba(255, 255, 255, 0.3)',
    color: 'white',
  }
}));

const KinkQuestion = styled(Typography)(({ theme }) => ({
  fontFamily: "'Playfair Display', serif",
  fontSize: '1.0rem',
  textAlign: 'center',
  color: 'rgba(255, 255, 255, 0.9)',
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  position: 'relative',
  '&::before': {
    content: '"\\201C"',
    position: 'absolute',
    top: -10,
    left: -10,
    fontSize: '2rem',
    color: theme.palette.primary.main,
    opacity: 0.4
  },
  '&::after': {
    content: '"\\201D"',
    position: 'absolute',
    bottom: -30,
    right: -10,
    fontSize: '2rem',
    color: theme.palette.primary.main,
    opacity: 0.4
  }
}));

const KinkCard = styled(motion.div)(({ theme }) => ({
  width: '100%',
  maxWidth: 800,
  margin: '0 auto',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  backgroundColor: 'transparent'
}));

const ImageContainer = styled(Box)(({ theme, nsfw, revealed }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: '56.25%',
  overflow: 'hidden',
  filter: nsfw && !revealed ? 'blur(20px)' : 'none',
  transition: 'filter 0.3s ease',
  cursor: nsfw && !revealed ? 'pointer' : 'default',
}));

const RevealOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  opacity: 0,
  transition: 'opacity 0.3s ease',
  zIndex: 1,
});



const Discovery = () => {
const theme = useTheme();
const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
const [roleChoice, setRoleChoice] = useState('');
const [categories, setCategories] = useState([]);
const [activeIndex, setActiveIndex] = useState(0);
const [currentKink, setCurrentKink] = useState(null);
const [showNSFW, setShowNSFW] = useState(false);
const [imageRevealed, setImageRevealed] = useState(false);
const [loading, setLoading] = useState(true);
const [userData, setUserData] = useState(null);
const [answeredKinks, setAnsweredKinks] = useState(new Set());
const [error, setError] = useState(null);
const [errorMessage, setErrorMessage] = useState('');
const [showCategories, setShowCategories] = useState(true);
const [userNames, setUserNames] = useState({
  submissive: { username: '', id: null },
  dominant: { username: '', id: null }
});

const fetchUserNames = useCallback(async () => {
  try {
    const response = await axiosInstance.get('/user-names');
    setUserNames(response.data);
} catch (error) {
  console.error('Error fetching usernames:', error);
  setErrorMessage('Failed to fetch user names');
}
}, []);

useEffect(() => {
  let isMounted = true;

  const fetchUserData = async () => {
    try {
      const response = await axiosInstance.get('/user-profile');
      if (isMounted) {
        setUserData(response.data);
        if (!response.data.is_switch) {
          setRoleChoice(response.data.user_type);
        }
        setLoading(false);
      }
    } catch (error) {
      if (isMounted) {
        console.error('Error fetching user data:', error);
        setErrorMessage('Failed to fetch user data');
        setLoading(false);
      }
    }
  };

  fetchUserData();
  fetchUserNames();

  return () => {
    isMounted = false;
  };
}, [fetchUserNames]);

useEffect(() => {
  if (!roleChoice) return;
  
  let isMounted = true;

  const fetchKinkData = async () => {
    try {
      const response = await axiosInstance.get('/kinks');
      if (!isMounted) return;

      const userKinks = response.data;
      const kinkRatingsMap = new Map(
        userKinks.map(kink => [kink.name, kink])
      );

      const categoriesArray = Object.entries(kinkCategoriesData).map(([name, kinks]) => ({
        name,
        kinks: kinks.map(kink => ({
          ...kink,
          existingRating: kinkRatingsMap.get(kink.name),
          id: kinkRatingsMap.get(kink.name)?.id
        })).filter(kink => !answeredKinks.has(kink.name))
      }));

      if (!isMounted) return;

      const filteredCategories = categoriesArray.filter(category => category.kinks.length > 0);
      setCategories(filteredCategories);

      if (filteredCategories.length > 0 && filteredCategories[0].kinks.length > 0) {
        setCurrentKink(filteredCategories[0].kinks[0]);
      }

      setLoading(false);
    } catch (error) {
      if (isMounted) {
        console.error('Error fetching kink data:', error);
        setErrorMessage('Failed to fetch kink data');
        setLoading(false);
      }
    }
  };

  fetchKinkData();

  return () => {
    isMounted = false;
  };
}, [roleChoice, answeredKinks]);

const handleAnswer = useCallback(
  debounce(async (answer) => {
    if (!currentKink) return;

  try {
    let response;
    if (currentKink.id) {
      response = await axiosInstance.put('/kinks', {
        id: currentKink.id,
        rating: answer,
        aspect: roleChoice === 'dominant' ? 'giving' : 'receiving'
      });
    } else {
      response = await axiosInstance.post('/kinks', {
        name: currentKink.name,
        category: categories[activeIndex].name,
        description: currentKink.description
      });

      if (response.data.id) {
        response = await axiosInstance.put('/kinks', {
          id: response.data.id,
          rating: answer,
          aspect: roleChoice === 'dominant' ? 'giving' : 'receiving'
        });
      }
    }

setCurrentKink(prev => ({
      ...prev,
      existingRating: response.data
    }));

    if (answer !== 'select') {
      setAnsweredKinks(prev => new Set(prev).add(currentKink.name));
    }

    handleNext();
    } catch (error) {
      console.error('Error saving kink response:', error);
      setErrorMessage('Failed to save your response. Please try again.');
    }
  }, 300),
  [currentKink, categories, activeIndex, roleChoice] 
);

const handleNext = useCallback(() => {
  const currentCategory = categories[activeIndex];
  const currentKinkIndex = currentCategory.kinks.findIndex(k => k.name === currentKink.name);
  
  if (currentKinkIndex < currentCategory.kinks.length - 1) {
    setCurrentKink(currentCategory.kinks[currentKinkIndex + 1]);
  } else if (activeIndex < categories.length - 1) {
    setActiveIndex(activeIndex + 1);
    setCurrentKink(categories[activeIndex + 1].kinks[0]);
  }
  setImageRevealed(false);
}, [categories, activeIndex, currentKink]);

const handlePrevious = useCallback(() => {
  const currentCategory = categories[activeIndex];
  const currentKinkIndex = currentCategory.kinks.findIndex(k => k.name === currentKink.name);
  
  if (currentKinkIndex > 0) {
    setCurrentKink(currentCategory.kinks[currentKinkIndex - 1]);
  } else if (activeIndex > 0) {
    setActiveIndex(activeIndex - 1);
    const prevCategory = categories[activeIndex - 1];
    setCurrentKink(prevCategory.kinks[prevCategory.kinks.length - 1]);
  }
  setImageRevealed(false);
}, [categories, activeIndex, currentKink]);

const formatKinkText = useCallback((text) => {
  if (!text) return '';
  
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const submissiveName = capitalizeFirstLetter(userNames.submissive.username || 'your submissive');
  const dominantName = capitalizeFirstLetter(userNames.dominant.username || 'your dominant');

  return text
    .replace(/{submissiveUsername}/g, submissiveName)
    .replace(/{dominantUsername}/g, dominantName);
}, [userNames]);

const CategoryScroller = () => {
  const [selectedIndex, setSelectedIndex] = useState(Math.floor(categories.length / 2));
  const [touchStart, setTouchStart] = useState(null);
  const containerRef = useRef(null);
  const visiblePositions = Math.min(20, categories.length);
  
  const normalizeIndex = useCallback((index) => {
    const len = categories.length;
    if (len === 0) return 0;
    return ((index % len) + len) % len;
  }, [categories.length]);

  const handleCategoryClick = (index) => {
    setSelectedIndex(index);
    setActiveIndex(index);
    setCurrentKink(categories[index].kinks[0]);
    setShowCategories(false);
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    if (!touchStart) return;

    const currentTouch = e.touches[0].clientY;
    const diff = touchStart - currentTouch;
    const sensitivity = 0.015;

    setSelectedIndex(prev => {
      const newIndex = prev + (diff * sensitivity);
      return normalizeIndex(Math.round(newIndex));
    });

    setTouchStart(currentTouch);
  };

  const handleTouchEnd = () => {
    setTouchStart(null);
  };

  const handleWheel = useCallback((e) => {
    e.preventDefault();
    const delta = e.deltaY > 0 ? 1 : -1;
    const sensitivity = 0.5;
    setSelectedIndex(prev => normalizeIndex(Math.round(prev + (delta * sensitivity))));
  }, [normalizeIndex]);

  const getCategoryStyle = (displayPosition) => {
    const centerPosition = Math.floor(visiblePositions / 2);
    const distance = Math.abs(displayPosition - centerPosition);
    
    // Enhanced scaling and opacity calculations
    const scale = Math.max(0.7, 1.2 - (distance * 0.1));
    const opacity = Math.max(0.3, 1 - (distance * 0.1));
    
    // Adjusted font sizes
    const maxFontSize = 2.6;
    const minFontSize = 0.9;
    const fontSize = Math.max(minFontSize, maxFontSize - (distance * 0.3));
    
    // Increased spacing values
    const maxSpacing = 120;
    const minSpacing = 40;
    
    // Less aggressive spacing compression
    const spacingMultiplier = Math.pow(0.85, distance);
    const spacing = Math.max(minSpacing, maxSpacing * spacingMultiplier);
    
    // Brighter text for better visibility
    const brightness = Math.max(35, 95 - (distance * 15));
    
    // Calculate vertical position with maintained minimum spacing
    const direction = displayPosition >= centerPosition ? 1 : -1;
    const distanceFromCenter = Math.abs(displayPosition - centerPosition);
    let yOffset = 0;
    
    // Accumulate spacing with minimum threshold
    for (let i = 0; i < distanceFromCenter; i++) {
      const currentSpacing = Math.max(minSpacing, maxSpacing * Math.pow(0.85, i));
      yOffset += currentSpacing * direction;
    }

    return {
      position: 'absolute',
      cursor: 'pointer',
      color: `hsl(0, 0%, ${brightness}%)`,
      textAlign: 'center',
      fontFamily: "'Playfair Display', serif",
      fontSize: `${fontSize}rem`,
      fontWeight: distance === 0 ? 600 : 400,
      transform: `translateY(${yOffset}px) scale(${scale})`,
      opacity,
      padding: '8px',
      transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
      willChange: 'transform, opacity',
      userSelect: 'none',
      whiteSpace: 'normal',
      maxWidth: '80vw',
      textShadow: distance === 0 ? '0 0 15px rgba(255,255,255,0.2)' : 'none',
      letterSpacing: distance === 0 ? '0.02em' : 'normal',
      lineHeight: 1.2,
    };
  };

  const getVisibleCategories = useCallback(() => {
    if (categories.length === 0) return [];
    
    const halfVisible = Math.floor(visiblePositions / 2);
    const result = [];

    for (let i = -halfVisible; i <= halfVisible; i++) {
      const categoryIndex = normalizeIndex(selectedIndex + i);
      result.push({
        category: categories[categoryIndex],
        categoryIndex,
        displayPosition: i + halfVisible
      });
    }

    return result;
  }, [categories.length, normalizeIndex, selectedIndex, visiblePositions]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedIndex(prev => normalizeIndex(prev - 1));
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedIndex(prev => normalizeIndex(prev + 1));
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [normalizeIndex]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('wheel', handleWheel, { passive: false });
      return () => container.removeEventListener('wheel', handleWheel);
    }
  }, [handleWheel]);

  return (
    <Box
      sx={{
        height: '100vh',
        background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('/kink-images/discovery-background.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backdropFilter: 'blur(8px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        overscrollBehavior: 'none',
        touchAction: 'none',
      }}
    >
      <Box
        ref={containerRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        sx={{
          position: 'relative',
          height: '100vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          overflowY: 'hidden',
        }}
      >
        {getVisibleCategories().map(({ category, categoryIndex, displayPosition }) => {
          if (!category) return null;

          return (
            <Typography
              key={`${category.name}-${categoryIndex}-${displayPosition}`}
              onClick={() => handleCategoryClick(categoryIndex)}
              sx={getCategoryStyle(displayPosition)}
            >
              {category.name}
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
};

const KinkNavigationDots = ({ category, currentKinkIndex, answeredKinks }) => {
  const totalKinks = category.kinks.length;
  
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 20,
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        gap: 1,
        zIndex: 1000,
      }}
    >
      {category.kinks.map((kink, index) => (
        <Box
          key={index}
          sx={{
            width: 12,
            height: 12,
            borderRadius: '50%',
            bgcolor: currentKinkIndex === index ? 'primary.main' : 'rgba(255,255,255,0.3)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {answeredKinks.has(kink.name) && (
            <Check sx={{ fontSize: 10, color: 'white' }} />
          )}
        </Box>
      ))}
    </Box>
  );
};

const KinkDisplay = ({ 
  handleNext, 
  handlePrevious, 
  currentKink, 
  roleChoice, 
  categories, 
  activeIndex, 
  theme, 
  handleAnswer, 
  answeredKinks 
}) => {
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrevious(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
    delta: 20,
    swipeDuration: 250,
    touchEventOptions: { passive: false }
  });

  if (!currentKink) return null;

  const question = currentKink.questions[roleChoice];
  const formattedQuestion = formatKinkText(question);
  const ratingOptions = roleChoice === 'dominant' ? dominantRatingOptions : submissiveRatingOptions;
  
  const existingRating = currentKink.existingRating?.[
    roleChoice === 'dominant' 
      ? 'dominant_giving_rating' 
      : 'submissive_receiving_rating'
  ];

  const currentCategory = categories[activeIndex];
  const currentKinkIndex = currentCategory.kinks.findIndex(k => k.name === currentKink.name);

  return (
    <KinkCard
      key={currentKink?.name}
      {...swipeHandlers}
      initial={{ opacity: 0, x: 0 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ 
        type: "spring",
        stiffness: 300,
        damping: 30
      }}
    >
      <Card elevation={3} sx={{ backgroundColor: 'rgba(18, 18, 18, 0.95)' }}>
        <CardContent>
          <Typography
            variant="h5"
            component={motion.h5}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            sx={{ 
              fontFamily: "'Playfair Display', serif",
              mb: 2,
              textAlign: 'center',
              color: 'common.white'
            }}
          >
            {currentKink.name}
          </Typography>

          <ImageContainer 
            nsfw={!showNSFW} 
            revealed={imageRevealed}
            onClick={() => !showNSFW && !imageRevealed && setImageRevealed(true)}
          >
            {currentKink.image && (
              <Box
                component="img"
                src={`/kink-images/${currentKink.image}`}
                alt={currentKink.name}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            )}
            {!showNSFW && !imageRevealed && (
              <RevealOverlay className="reveal-overlay">
                <Typography variant="h6" color="common.white">
                  Tap to Reveal
                </Typography>
              </RevealOverlay>
            )}
          </ImageContainer>

          <Box sx={{ mt: 3 }}>
            <KinkDescription
              component={motion.div}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              {formatKinkText(currentKink.description)}
            </KinkDescription>

            {existingRating && existingRating !== 'select' && (
              <Typography
                variant="subtitle2"
                component={motion.div}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                sx={{
                  fontFamily: "'Lora', serif",
                  textAlign: 'center',
                  mb: 2,
                  color: 'rgba(255, 255, 255, 0.7)',
                  padding: 1,
                  borderRadius: 1,
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  display: 'inline-block',
                  margin: '0 auto',
                  px: 2,
                }}
              >
                Current rating: <span style={{ color: theme.palette.primary.light }}>{existingRating}</span>
              </Typography>
            )}

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
                gap: 1.5,
                mt: 3,
                px: 2,
              }}
            >
              {ratingOptions.filter(option => option !== 'select').map((option) => (
                <RatingButton
                  key={option}
                  onClick={() => handleAnswer(option)}
                  className={existingRating === option ? 'selected' : ''}
                  component={motion.button}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center',
                    gap: 0.5 
                  }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '0.6rem', // Adjust this value
                        fontFamily: "'Playfair Display', serif",
                        letterSpacing: '0.02em',
                        fontWeight: existingRating === option ? 500 : 400,
                      }}
                    >
                      {option}
                    </Typography>
                  </Box>
                </RatingButton>
              ))}
            </Box>

            <KinkQuestion
              component={motion.div}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              {formattedQuestion}
            </KinkQuestion>


          </Box>
        </CardContent>
      </Card>

      <Box
        sx={{
          position: 'fixed',
          bottom: 20,
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          gap: 1,
          zIndex: 1000,
        }}
      >
        {currentCategory.kinks.map((kink, index) => (
          <Box
            key={index}
            sx={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              bgcolor: currentKinkIndex === index ? 'primary.main' : 'rgba(255,255,255,0.3)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {answeredKinks.has(kink.name) && (
              <Check sx={{ fontSize: 10, color: 'white' }} />
            )}
          </Box>
        ))}
      </Box>
    </KinkCard>
  );
};

const getButtonColor = (rating) => {
  switch (rating) {
    case 'hard no':
      return 'error';
    case 'uncertain':
    case 'unsure but interested':
      return 'warning';
    case 'if forced':
    case 'willing to try':
    case 'maybe':
    case 'fantasy/roleplay only':
      return 'info';
    case 'yes':
    case 'please':
    case 'need':
    case 'very much yes':
      return 'success';
    default:
      return 'primary';
  }
};

const RoleSelector = () => (
  <Fade in={userData?.is_switch && !roleChoice}>
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'background.default',
        zIndex: 10
      }}
    >

    <Typography
        variant="h4"
        component={motion.h4}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        sx={{
          fontFamily: "'Playfair Display', serif",
          mb: 4,
          textAlign: 'center',
          color: 'common.white'
        }}
      >
        How would you like to explore?
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          gap: 3
        }}
      >
        <Button
          variant="contained"
          onClick={() => setRoleChoice('dominant')}
          component={motion.button}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          sx={{ minWidth: 200 }}
        >
          As Dominant
        </Button>
        <Button
          variant="contained"
          onClick={() => setRoleChoice('submissive')}
          component={motion.button}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          sx={{ minWidth: 200 }}
        >
          As Submissive
        </Button>
      </Box>
    </Box>
  </Fade>
);

const LoadingScreen = () => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      bgcolor: 'background.default',
      zIndex: 2000
    }}
  >
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <CircularProgress size={60} thickness={4} />
    </motion.div>
  </Box>
);

const NSFWToggle = () => (
  <Box
    sx={{
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 1000,
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      bgcolor: 'rgba(18, 18, 18, 0.9)',
      padding: 1,
      borderRadius: 1,
    }}
  >
    <Typography variant="caption" sx={{ color: 'common.white' }}>
      NSFW
    </Typography>
    <Switch
      checked={showNSFW}
      onChange={(e) => setShowNSFW(e.target.checked)}
      color="primary"
      size="small"
    />
  </Box>
);

const MemoizedKinkDisplay = useMemo(() => (
    <KinkDisplay 
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      currentKink={currentKink}
      roleChoice={roleChoice}
      categories={categories}
      activeIndex={activeIndex}
      theme={theme}
      handleAnswer={handleAnswer}
      answeredKinks={answeredKinks}
      showNSFW={showNSFW}
      setImageRevealed={setImageRevealed}
      imageRevealed={imageRevealed}
    />
  ), [currentKink, roleChoice, categories, activeIndex, handleAnswer, answeredKinks, showNSFW, imageRevealed]);

return (
  <FullScreenContainer>
    {loading ? (
      <LoadingScreen />
    ) : (
      <>
        <CloseButton onClick={() => window.opener ? window.close() : window.history.back()}>
          <Close />
        </CloseButton>

        {!showCategories && (
          <BackButton onClick={() => setShowCategories(true)}>
            <ArrowBack />
          </BackButton>
        )}

        <NSFWToggle />

        {userData?.is_switch && !roleChoice ? (
          <RoleSelector />
        ) : (
          <AnimatePresence mode="wait">
            {showCategories ? (
              <CategoryScroller />
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                {MemoizedKinkDisplay}
              </motion.div>
            )}
          </AnimatePresence>
        )}

        <Snackbar
          open={!!errorMessage}
          autoHideDuration={6000}
          onClose={() => setErrorMessage('')}
          message={errorMessage}
          sx={{
            '& .MuiSnackbarContent-root': {
              bgcolor: 'error.dark',
              color: 'common.white'
            }
          }}
        />
      </>
    )}
  </FullScreenContainer>
);
};

export default Discovery;