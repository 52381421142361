import React, { useState, useEffect, useCallback } from 'react';
import { axiosInstance } from './auth';
import {
  Container, Typography, Button, Dialog, DialogTitle, DialogContent,
  TextField, CircularProgress, Snackbar, Fab, Card, CardContent,
  Box, Select, MenuItem, FormControl, InputLabel, Chip,
  Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText,
  Tooltip, IconButton, ListItemSecondaryAction, useTheme, useMediaQuery,
  Grid, ListSubheader, alpha, Tabs, Tab
} from '@mui/material';
import {
  Add, ExpandMore, Edit, Delete, CheckCircle, Cancel, Warning
} from '@mui/icons-material';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip as RechartsTooltip } from 'recharts';
import { examples } from './examples';
import ConfettiSnackbar from './ConfettiSnackbar';
import { useLocation } from 'react-router-dom';

const ratingEmojis = {
  'select': '🤔',
  'hard no': '🚫',
  'uncertain': '❓',
  'fantasy/roleplay only': '🎭',
  'if forced': '😬',
  'maybe': '🤷',
  'yes': '😊',
  'please': '😍',
  'willing to try': '🤨',
  'unsure but interested': '🤔',
  'very much yes': '🥰',
  'need': '💖'
};

const getKinkEmoji = (rating) => ratingEmojis[rating] || ratingEmojis['select'];

const RuleForm = ({ onSubmit, rule = null, onClose, kinks = [], userNames }) => {
  const [text, setText] = useState(rule ? rule.text : '');
  const [category, setCategory] = useState(rule ? rule.category : '');
  const theme = useTheme();
  
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      text,
      category,
      id: rule ? rule.id : undefined
    });
    onClose();
  };

  // Group and sort kinks by category
  const groupedKinks = useCallback(() => {
    const groups = kinks.reduce((acc, kink) => {
      // Extract or create category
      const category = kink.category || 'Uncategorized';
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(kink);
      return acc;
    }, {});

    // Sort kinks within each category
    Object.keys(groups).forEach(category => {
      groups[category].sort((a, b) => a.name.localeCompare(b.name));
    });

    // Sort categories
    return Object.keys(groups)
      .sort()
      .map(category => ({
        category,
        kinks: groups[category]
      }));
  }, [kinks]);

  const getFilteredExamples = useCallback(() => {
    if (!examples || !examples.rules || !Array.isArray(examples.rules)) {
      console.error('Invalid or missing rules in examples.js');
      return [];
    }

    let filteredExamples = examples.rules.filter(example =>
      kinks.some(kink => kink.name.toLowerCase() === example.category.toLowerCase() && 
                 kink.submissive_rating !== 'hard no' && 
                 kink.dominant_rating !== 'hard no')
    );

    return filteredExamples.sort((a, b) => {
      const aKink = kinks.find(k => k.name.toLowerCase() === a.category.toLowerCase());
      const bKink = kinks.find(k => k.name.toLowerCase() === b.category.toLowerCase());
      const aRelevance = (aKink && aKink.dominant_rating === 'very much yes' && aKink.submissive_rating === 'please') ? 2 :
                         (aKink && (aKink.dominant_rating === 'yes' || aKink.submissive_rating === 'yes')) ? 1 : 0;
      const bRelevance = (bKink && bKink.dominant_rating === 'very much yes' && bKink.submissive_rating === 'please') ? 2 :
                         (bKink && (bKink.dominant_rating === 'yes' || bKink.submissive_rating === 'yes')) ? 1 : 0;
      return bRelevance - aRelevance;
    });
  }, [kinks]);

  const filteredExamples = getFilteredExamples();

  const handleExampleClick = (example) => {
    setText(example.text);
    setCategory(example.category);
  };

  const getKinkAlignment = (kink) => {
    if (!kink || !userNames.dominant || !userNames.submissive) return theme.palette.background.paper;
  
    const isUserSwitch = userNames.dominant?.is_switch || false;
    const isPartnerSwitch = userNames.submissive?.is_switch || false;
  
    // Get primary role ratings
    const domGiving = kink.dominant_giving_rating;
    const subReceiving = kink.submissive_receiving_rating;
  
    // Get switch role ratings if applicable
    const domReceiving = kink.dominant_receiving_rating;
    const subGiving = kink.submissive_giving_rating;
  
    // Check for hard limits in primary roles
    if (['hard no'].includes(domGiving) || 
        ['hard no'].includes(subReceiving)) {
      return '#ffcdd2'; // Light red
    }
  
    // Check for hard limits in switch roles if applicable
    if ((isUserSwitch && ['hard no'].includes(domReceiving)) || 
        (isPartnerSwitch && ['hard no'].includes(subGiving))) {
      return '#ffcdd2'; // Light red
    }
  
    // Check for strong alignment in primary roles
    const primaryAligned = (
      ['yes', 'very much yes'].includes(domGiving) && 
      ['yes', 'please', 'need'].includes(subReceiving)
    );
  
    // Check for strong alignment in switch roles if both are switches
    const switchAligned = isUserSwitch && isPartnerSwitch && (
      ['yes', 'very much yes'].includes(domReceiving) && 
      ['yes', 'please', 'need'].includes(subGiving)
    );
  
    if (primaryAligned || switchAligned) {
      return '#c8e6c9'; // Light green
    }
  
    // Check for partial alignment in primary roles
    const partialPrimaryMatch = (
      ['maybe', 'willing to try', 'uncertain but interested', 'fantasy/roleplay only'].includes(domGiving) &&
      ['maybe', 'if forced', 'uncertain but interested', 'fantasy/roleplay only'].includes(subReceiving)
    );
  
    // Check for partial alignment in switch roles if applicable
    const partialSwitchMatch = isUserSwitch && isPartnerSwitch && (
      ['maybe', 'willing to try', 'uncertain but interested', 'fantasy/roleplay only'].includes(domReceiving) &&
      ['maybe', 'if forced', 'uncertain but interested', 'fantasy/roleplay only'].includes(subGiving)
    );
  
    if (partialPrimaryMatch || partialSwitchMatch) {
      return '#ffe0b2'; // Light orange
    }
  
    return theme.palette.background.paper;
  };

  return (
    <form onSubmit={handleSubmit}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">Example Rules</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
            {kinks.filter(kink => 
              kink.dominant_rating !== 'hard no' && 
              kink.submissive_rating !== 'hard no' &&
              filteredExamples.some(example => example.category.toLowerCase() === kink.name.toLowerCase())
            ).map((kink) => (
              <Accordion key={kink.name}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Typography sx={{ flexGrow: 1, color: getKinkAlignment(kink) }}>{kink.name}</Typography>
                    <Tooltip title={`${userNames.dominant.username}: ${kink.dominant_rating}, ${userNames.submissive.username}: ${kink.submissive_rating}`}>
                      <Typography>
                        {getKinkEmoji(kink.dominant_rating)} {getKinkEmoji(kink.submissive_rating)}
                      </Typography>
                    </Tooltip>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {filteredExamples
                      .filter(example => example.category.toLowerCase() === kink.name.toLowerCase())
                      .map((example, index) => (
                        <ListItem key={index} button onClick={() => handleExampleClick(example)}>
                          <ListItemText primary={example.text} />
                        </ListItem>
                      ))
                    }
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
      <TextField
        fullWidth
        label="Rule Text"
        value={text}
        onChange={(e) => setText(e.target.value)}
        margin="normal"
        required
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Category</InputLabel>
        <Select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          label="Category"
        >
          <MenuItem value="Custom">
            <em>Custom</em>
          </MenuItem>
          
          {groupedKinks().map(group => [
            <ListSubheader key={`header-${group.category}`}>
              {group.category}
            </ListSubheader>,
            ...group.kinks.map(kink => (
              <MenuItem 
                key={kink.name} 
                value={kink.name}
                sx={{
                  pl: 4,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <span>{kink.name}</span>
                <Tooltip title={`${userNames.dominant.username}: ${kink.dominant_rating}, ${userNames.submissive.username}: ${kink.submissive_rating}`}>
                  <span style={{ marginLeft: '8px' }}>
                    {getKinkEmoji(kink.dominant_rating)} {getKinkEmoji(kink.submissive_rating)}
                  </span>
                </Tooltip>
              </MenuItem>
            ))
          ])}
        </Select>
      </FormControl>
      <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
        {rule ? 'Update Rule' : 'Create Rule'}
      </Button>
    </form>
  );
};

const ViolationModal = ({ rule, open, onClose, onSubmit, role }) => {
  const [description, setDescription] = useState('');
  const [violationDate, setViolationDate] = useState(new Date().toISOString().split('T')[0]);
  const theme = useTheme();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      rule_id: rule.id,
      description,
      violation_date: new Date(violationDate).toISOString()
    });
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-end',
        },
        '& .MuiDialog-paper': {
          width: '100%',
          margin: 0,
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          maxHeight: '90vh',
          overflowY: 'auto'
        }
      }}
    >
      <DialogTitle 
        sx={{ 
          pb: 1,
          background: (theme) => `linear-gradient(135deg, ${alpha(theme.palette.primary.light, 0.1)} 0%, ${alpha(theme.palette.primary.main, 0.1)} 100%)`
        }}
      >
        <Typography variant="h6" component="div">
          Record Rule Violation
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" sx={{ mt: 1 }}>
          {rule?.text}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label={role === 'submissive' ? "Explain yourself!" : "What happened?"}
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            margin="normal"
            required
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.8)
              }
            }}
          />
          <TextField
            fullWidth
            type="date"
            label="Date of Violation"
            value={violationDate}
            onChange={(e) => setViolationDate(e.target.value)}
            margin="normal"
            required
            InputLabelProps={{ shrink: true }}
            sx={{ 
              mt: 2,
              '& .MuiInputBase-root': {
                backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.8)
              }
            }}
          />
          <Button 
            type="submit" 
            variant="contained" 
            color="primary" 
            fullWidth 
            sx={{ 
              mt: 3,
              mb: 2,
              py: 1.5,
              borderRadius: '8px',
              boxShadow: (theme) => `0 4px 6px -1px ${alpha(theme.palette.primary.main, 0.2)}`
            }}
          >
            Submit Violation
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
const Rules = ({ role, sortBy, filterBy }) => {
  const [rules, setRules] = useState([]);
  const [sortedAndFilteredRules, setSortedAndFilteredRules] = useState([]);
  const [kinks, setKinks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showRuleModal, setShowRuleModal] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [userNames, setUserNames] = useState({ submissive: '', dominant: '' });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [violations, setViolations] = useState([]);
  const [showViolationModal, setShowViolationModal] = useState(false);
  const [selectedRuleForViolation, setSelectedRuleForViolation] = useState(null);

  const [showSuccessConfetti, setShowSuccessConfetti] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const [tabValue, setTabValue] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    switch (hash) {
      default:
        setTabValue(0);
    }
  }, [location]);

  useEffect(() => {
    const fetchViolations = async () => {
      try {
        const response = await axiosInstance.get('/rule-violations');
        setViolations(response.data);
      } catch (error) {
        console.error('Error fetching violations:', error);
        setErrorMessage('Failed to fetch violations');
      }
    };
    
    fetchViolations();
  }, []);

  const handleRecordViolation = async (violationData) => {
    try {
      const response = await axiosInstance.post('/rule-violations', violationData);
      if (response.status === 201) {
        setViolations([...violations, response.data]);
        setErrorMessage('');
      }
    } catch (error) {
      console.error('Error recording violation:', error);
      setErrorMessage('Failed to record violation');
    }
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [rulesResponse, kinksResponse, userNamesResponse] = await Promise.all([
        axiosInstance.get(`/rules`),
        axiosInstance.get(`/kinks`),
        axiosInstance.get('/user-names')
      ]);
      setRules(rulesResponse.data || []);
      setKinks(kinksResponse.data || []);
      setUserNames(userNamesResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setErrorMessage('One or more endpoints not found. Please check server configuration.');
      } else {
        setErrorMessage('Failed to fetch data. Please try again.');
      }
      setRules([]);
      setKinks([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    let filteredRules = rules;

    // Apply filtering
    if (filterBy === 'accepted') {
      filteredRules = rules.filter(rule => rule.accepted);
    } else if (filterBy === 'not_accepted') {
      filteredRules = rules.filter(rule => !rule.accepted);
    }

    // Apply sorting
    const sortedRules = [...filteredRules].sort((a, b) => {
      if (sortBy === 'dateCreated') {
        return new Date(b.created_at) - new Date(a.created_at);
      } else if (sortBy === 'name') {
        return a.text.localeCompare(b.text);
      }
      return 0;
    });

    setSortedAndFilteredRules(sortedRules);
  }, [rules, sortBy, filterBy]);

  const handleCreateOrEditRule = async (ruleData) => {
    try {
      let response;
      if (ruleData.id) {
        response = await axiosInstance.put(`/rules`, ruleData);
      } else {
        response = await axiosInstance.post(`/rules`, ruleData);
      }
      if (response.status === 200 || response.status === 201) {
        // Close modal first
        setShowRuleModal(false);
        setSelectedRule(null);
        
        // Set success state
        setSuccessMessage(ruleData.id ? 'Rule updated successfully!' : 'New rule created!');
        setShowSuccessConfetti(true);
        
        // Delay the data refresh to allow animation to be visible
        setTimeout(() => {
          fetchData();
        }, 500); // Increased delay to ensure animation is visible
        
        setErrorMessage('');
      }
    } catch (error) {
      console.error('Error creating/editing rule:', error);
      setErrorMessage('Failed to create/edit rule. Please try again.');
    }
  };

  const handleAcceptRule = async (ruleId) => {
    try {
      const response = await axiosInstance.put(`/rules`, { id: ruleId, accepted: true });
      if (response.status === 200) {
        setSuccessMessage('No turning back now!');
        setShowSuccessConfetti(true);
        
        // Delay the data refresh
        setTimeout(() => {
          fetchData();
        }, 500);
        
        setErrorMessage('');
      }
    } catch (error) {
      console.error('Error accepting rule:', error);
      setErrorMessage('Failed to accept rule. Please try again.');
    }
  };

  const handleDeleteRule = async (ruleId) => {
    try {
      const response = await axiosInstance.delete(`/rules/${ruleId}`);
      if (response.status === 200) {
        fetchData();
        setErrorMessage('');
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error deleting rule:', error);
      setErrorMessage('Failed to delete rule. Please try again.');
    }
  };

  const getKinkAlignment = (kink) => {
    if (kink.dominant_rating === 'very much yes' && kink.submissive_rating === 'please') return theme.palette.success.main;
    if (kink.dominant_rating === 'yes' && kink.submissive_rating === 'yes') return theme.palette.success.light;
    if (kink.dominant_rating === 'maybe' && kink.submissive_rating === 'maybe') return theme.palette.warning.main;
    return theme.palette.background.paper;
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (isLoading) return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
      <CircularProgress />
    </Box>
  );

  if (rules.length === 0) {
  return (
   <Container maxWidth="md">
     <Box sx={{ borderBottom: 1, borderColor: 'divider', overflowX: 'auto', maxWidth: '100%' }}>
       <Tabs
         value={tabValue}
         onChange={handleTabChange}
         variant="scrollable"
         scrollButtons="auto"
         aria-label="scrollable auto tabs"
         sx={{ width: '100%' }}
       >
       <Tab 
         label="Rules and Behaviors" 
         sx={{ 
           flexGrow: 1,
           justifyContent: 'center',
         }} 
       />
       </Tabs>
     </Box>
        <Card>
          <CardContent>
            <Typography variant="body1" align="center">
              {role === 'dominant' 
                ? "Rules and behaviors is the perfect place to define how you want your sub to act and what they can and cannot do. Once one created, it must be approved by the sub and any violotions of that rule or behavior can be recorded by either user. Touch the + button to get started, create your own or browse the examples tailored to your kink profile."
                : `No rules created yet. You'll need to wait for ${userNames.dominant.username} to do that.`
              }
            </Typography>
          </CardContent>
        </Card>

        {role === 'dominant' && (
          <Fab 
            color="primary" 
            aria-label="add" 
            sx={{ 
              position: 'fixed', 
              bottom: isMobile ? 80 : 16,
              right: 16,
              zIndex: (theme) => theme.zIndex.drawer + 2
            }}
            onClick={() => setShowRuleModal(true)}
          >
            <Add />
          </Fab>
        )}

        <Dialog open={showRuleModal} onClose={() => setShowRuleModal(false)}>
          <DialogTitle>Create New Rule</DialogTitle>
          <DialogContent>
            <RuleForm
              onSubmit={handleCreateOrEditRule}
              onClose={() => setShowRuleModal(false)}
              kinks={kinks}
              userNames={userNames}
            />
          </DialogContent>
        </Dialog>

        <Snackbar
          open={!!errorMessage}
          autoHideDuration={2000}
          onClose={() => setErrorMessage('')}
          message={errorMessage}
        />
      </Container>
    );
  }

  const renderRuleItem = (rule) => {
    const kink = kinks.find(k => k.name === rule.category);
    const backgroundColor = kink ? getKinkAlignment(kink) : theme.palette.background.paper;
    const violationCount = violations.filter(v => v.rule_id === rule.id).length;
    
    const renderKinkRatings = (kink) => {
      if (!kink || !userNames.dominant || !userNames.submissive) return null;
  
      const isUserSwitch = userNames.dominant?.is_switch || false;
      const isPartnerSwitch = userNames.submissive?.is_switch || false;
  
      return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {/* Dominant Ratings */}
          <Tooltip title={`${userNames.dominant.username}'s ratings`} arrow>
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              {ratingEmojis[kink.dominant_giving_rating] && (
                <Chip
                  label={ratingEmojis[kink.dominant_giving_rating]}
                  size="small"
                  sx={{ mr: 0.5 }}
                />
              )}
              {isUserSwitch && ratingEmojis[kink.dominant_receiving_rating] && (
                <Chip
                  label={ratingEmojis[kink.dominant_receiving_rating]}
                  size="small"
                  variant="outlined"
                />
              )}
            </Box>
          </Tooltip>
  
          {/* Submissive Ratings */}
          <Tooltip title={`${userNames.submissive.username}'s ratings`} arrow>
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              {isPartnerSwitch && ratingEmojis[kink.submissive_giving_rating] && (
                <Chip
                  label={ratingEmojis[kink.submissive_giving_rating]}
                  size="small"
                  variant="outlined"
                />
              )}
              {ratingEmojis[kink.submissive_receiving_rating] && (
                <Chip
                  label={ratingEmojis[kink.submissive_receiving_rating]}
                  size="small"
                />
              )}
            </Box>
          </Tooltip>
        </Box>
      );
    };
  
    return (
      <ListItem
        key={rule.id}
        sx={{
          mb: 1,
          borderRadius: 1,
          backgroundColor,
          '&:hover': { backgroundColor: theme.palette.action.hover },
        }}
      >
        <ListItemText
          primary={rule.text}
          secondary={
            <React.Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Chip label={rule.category} size="small" />
                  {rule.accepted && (
                    <Tooltip title="Record Violation">
                      <Chip 
                        icon={<Warning />}
                        label={`Violations: ${violationCount}`}
                        size="small"
                        color={violationCount > 0 ? "error" : "default"}
                        onClick={() => {
                          setSelectedRuleForViolation(rule);
                          setShowViolationModal(true);
                        }}
                        sx={{ cursor: 'pointer' }}
                      />
                    </Tooltip>
                  )}
                </Box>
                {kink && renderKinkRatings(kink)}
              </Box>
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
          {role === 'dominant' ? (
            <React.Fragment>
              <IconButton edge="end" aria-label="edit" onClick={() => {
                setSelectedRule(rule);
                setShowRuleModal(true);
              }}>
                <Edit />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteRule(rule.id)}>
                <Delete />
              </IconButton>
            </React.Fragment>
          ) : (
            !rule.accepted && (
              <IconButton edge="end" aria-label="accept" onClick={() => handleAcceptRule(rule.id)}>
                <CheckCircle />
              </IconButton>
            )
          )}
          <Tooltip title={rule.accepted ? "Accepted" : "Not accepted"}>
            <IconButton edge="end" aria-label="status">
              {rule.accepted ? <CheckCircle color="success" /> : <Cancel color="error" />}
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const renderCategoryPieChart = () => {
    // Create a color array from theme colors
    const themeColors = [
      theme.palette.primary.main,
      theme.palette.primary.light,
      theme.palette.secondary.main,
      theme.palette.secondary.light,
      theme.palette.primary.dark,
      theme.palette.secondary.dark,
    ];

    const data = kinks.map(kink => ({
      name: kink.name,
      value: rules.filter(rule => rule.category === kink.name).length
    })).filter(item => item.value > 0);

    return (
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            paddingAngle={2}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell 
                key={`cell-${index}`} 
                fill={themeColors[index % themeColors.length]}
                stroke={theme.palette.background.paper}
                strokeWidth={1}
                style={{
                  filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1))',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease'
                }}
              />
            ))}
          </Pie>
          <Legend
            verticalAlign="bottom"
            height={36}
            formatter={(value, entry) => (
              <span style={{ color: theme.palette.text.primary, fontSize: '0.875rem' }}>
                {value}
              </span>
            )}
          />
          <RechartsTooltip
            contentStyle={{
              backgroundColor: theme.palette.background.paper,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '8px',
              color: theme.palette.text.primary
            }}
          />
        </PieChart>
      </ResponsiveContainer>
    );
  };

  const renderAcceptedPieChart = () => {
    const acceptedRules = rules.filter(rule => rule.accepted).length;
    const notAcceptedRules = rules.filter(rule => !rule.accepted).length;

    if (acceptedRules === rules.length) {
      return null;
    }

    const data = [
      { name: 'Accepted', value: acceptedRules },
      { name: 'Not Accepted', value: notAcceptedRules }
    ];

    return (
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            paddingAngle={2}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell 
                key={`cell-${index}`} 
                fill={index === 0 ? theme.palette.primary.main : theme.palette.secondary.main}
                stroke={theme.palette.background.paper}
                strokeWidth={1}
                style={{
                  filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1))',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease'
                }}
              />
            ))}
          </Pie>
          <Legend
            verticalAlign="bottom"
            height={36}
            formatter={(value, entry) => (
              <span style={{ color: theme.palette.text.primary, fontSize: '0.875rem' }}>
                {value}
              </span>
            )}
          />
          <RechartsTooltip
            contentStyle={{
              backgroundColor: theme.palette.background.paper,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '8px',
              color: theme.palette.text.primary
            }}
          />
        </PieChart>
      </ResponsiveContainer>
    );
  };

  if (isLoading) return <CircularProgress />;

  const acceptedChart = renderAcceptedPieChart();

  return (
    <Container maxWidth="lg" sx={{ px: { xs: 1, sm: 2, md: 3 }, overflowX: 'hidden' }}>

      <ConfettiSnackbar
        open={showSuccessConfetti}
        message={successMessage}
        onClose={() => setShowSuccessConfetti(false)}
        duration={2000}
      />

      <Typography variant="h4" gutterBottom>Rules</Typography>
      
      <Card 
        sx={{ 
          mb: 2, 
          overflowX: 'hidden',
          borderRadius: '12px',
          boxShadow: `0 4px 6px -1px ${alpha(theme.palette.primary.main, 0.1)}, 0 2px 4px -1px ${alpha(theme.palette.primary.main, 0.06)}`,
        }}
      >
        <CardContent sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={acceptedChart ? 6 : 12}>
              <Box 
                sx={{ 
                  p: 3, 
                  borderRadius: '8px',
                  background: `linear-gradient(135deg, ${alpha(theme.palette.primary.light, 0.1)} 0%, ${alpha(theme.palette.primary.main, 0.1)} 100%)`,
                }}
              >
                <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                  Rules by Category
                </Typography>
                <Box sx={{ height: 300, width: '100%' }}>
                  {renderCategoryPieChart()}
                </Box>
              </Box>
            </Grid>
            {acceptedChart && (
              <Grid item xs={12} md={6}>
                <Box 
                  sx={{ 
                    p: 3, 
                    borderRadius: '8px',
                    background: `linear-gradient(135deg, ${alpha(theme.palette.secondary.light, 0.1)} 0%, ${alpha(theme.palette.secondary.main, 0.1)} 100%)`,
                  }}
                >
                  <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                    Rules Accepted
                  </Typography>
                  <Box sx={{ height: 300, width: '100%' }}>
                    {acceptedChart}
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>

    <Card 
      sx={{ 
        overflowX: 'hidden',
        borderRadius: '12px',
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
      }}
    >
      <CardContent sx={{ p: 3 }}>
        <List>
          {sortedAndFilteredRules
            .filter(rule => rule && typeof rule === 'object' && rule.id !== undefined && rule.id !== null)
            .map(renderRuleItem)}
        </List>
      </CardContent>
    </Card>

      <Dialog open={showRuleModal} onClose={() => {
        setShowRuleModal(false);
        setSelectedRule(null);
      }}>
        <DialogTitle>{selectedRule ? 'Edit Rule' : 'Create New Rule'}</DialogTitle>
        <DialogContent>
          <RuleForm
            onSubmit={handleCreateOrEditRule}
            onClose={() => {
              setShowRuleModal(false);
              setSelectedRule(null);
            }}
            rule={selectedRule}
            kinks={kinks}
            userNames={userNames}
          />
        </DialogContent>
      </Dialog>

      <ViolationModal
        rule={selectedRuleForViolation}
        open={showViolationModal}
        onClose={() => {
          setShowViolationModal(false);
          setSelectedRuleForViolation(null);
        }}
        onSubmit={handleRecordViolation}
        role={role}
      />

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={2000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />

      {role === 'dominant' && (
        <Fab 
          color="primary" 
          aria-label="add" 
          sx={{ 
            position: 'fixed', 
            bottom: isMobile ? 80 : 16,
            right: 16,
            zIndex: (theme) => theme.zIndex.drawer + 2
          }}
          onClick={() => setShowRuleModal(true)}
        >
          <Add />
        </Fab>
      )}
    </Container>
  );
};

export default Rules;