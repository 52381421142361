import React, { useState, useEffect, useCallback } from 'react';
import { axiosInstance } from './auth';
import { useMediaQuery, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import {
  Container, Grid, Paper, Typography, Box, CircularProgress, Snackbar
} from '@mui/material';
import {
  SentimentVeryDissatisfied, SentimentDissatisfied, SentimentNeutral,
  SentimentSatisfied, SentimentVerySatisfied, LockClock, Timer,
  AssignmentTurnedIn, AssignmentLate, EmojiEvents, Gavel,
  Celebration, SportsScore, Mood
} from '@mui/icons-material';
import { Pie, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import KanbanView from './KanbanView';
import ActionOverview from './ActionOverview';

// Register Chart.js components
ChartJS.register(
  ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title
);

const Dashboard = ({ role, points, chastityDays, setPoints, setChastityDays, currentTheme, navStyle }) => {
  const [stats, setStats] = useState({
    totalChastityDays: 0,
    longestChastityPeriod: 0,
    tasksCompleted: 0,
    tasksMissed: 0,
    rewardsReceived: 0,
    punishmentsReceived: 0,
    mostCommonPunishment: '',
    avgEnjoyment: 0,
    avgDifficulty: 0,
    avgHumiliation: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    switch (hash) {
      case 'kanban':
        setTabValue(0);
        break;
      case 'dashboard':
        setTabValue(1);
        break;
      default:
        setTabValue(1);
    }
  }, [location]);

  const fetchStats = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(`/stats`);
      setStats(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching stats:', error);
      setError('Failed to fetch statistics. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchStats();
  }, [fetchStats]);

  const renderSmileyFace = (value) => {
    const icons = [SentimentVeryDissatisfied, SentimentDissatisfied, SentimentNeutral, SentimentSatisfied, SentimentVerySatisfied];
    const index = Math.min(Math.max(Math.floor(value) - 1, 0), 4);
    const Icon = icons[index];
    return <Icon fontSize="small" />;
  };

  // Pie Chart Data for Task Completion
  const taskData = {
    labels: ['Completed', 'Missed'],
    datasets: [
      {
        data: [stats.tasksCompleted, stats.tasksMissed],
        backgroundColor: ['#4caf50', '#f44336'],
        hoverBackgroundColor: ['#66bb6a', '#ef5350'],
        borderWidth: 0,
      },
    ],
  };

  // Bar Chart Data for Rewards and Punishments
  const rewardPunishmentData = {
    labels: ['Rewards', 'Punishments'],
    datasets: [
      {
        label: 'Count',
        data: [stats.rewardsReceived, stats.punishmentsReceived],
        backgroundColor: ['#4caf50', '#f44336'],
        borderWidth: 0,
      },
    ],
  };

  // Bar Chart Data for Average Ratings
  const ratingData = {
    labels: ['Enjoyment', 'Difficulty', 'Humiliation'],
    datasets: [
      {
        label: 'Average Rating',
        data: [stats.avgEnjoyment, stats.avgDifficulty, stats.avgHumiliation],
        backgroundColor: '#82ca9d',
        borderWidth: 0,
      },
    ],
  };

  if (isLoading) return <CircularProgress />;

  return (
    <Container 
      maxWidth={false} 
      disableGutters 
      sx={{
        height: '100%',
        overflow: 'hidden',
        padding: theme.spacing(0),
        paddingBottom: isSmallScreen ? theme.spacing(8) : theme.spacing(2)
      }}
    >
      <Snackbar
        open={!!error}
        autoHideDuration={2000}
        onClose={() => setError(null)}
        message={error}
      />
      
      {((!isSmallScreen && location.hash === '#kanban') || (isSmallScreen && tabValue === 0)) && (
          <KanbanView 
            role={role}
            currentTheme={currentTheme}
            navStyle={navStyle}
          />
      )}

      {((!isSmallScreen && location.hash !== '#kanban') || (isSmallScreen && tabValue === 1)) && (
            <>
              <ActionOverview role={role} />
              <Grid container spacing={2} sx={{padding: theme.spacing(2)}}>
              <Grid item xs={6} sm={4} md={3}>
                <Paper elevation={3} sx={{ p: 1, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <LockClock fontSize="small" color="primary" />
                  <Typography variant="body2">Total Chastity Days</Typography>
                  <Typography variant="h6">{stats.totalChastityDays}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Paper elevation={3} sx={{ p: 1, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Timer fontSize="small" color="secondary" />
                  <Typography variant="body2">Longest Chastity</Typography>
                  <Typography variant="h6">{stats.longestChastityPeriod} days</Typography>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Paper elevation={3} sx={{ p: 1, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <AssignmentTurnedIn fontSize="small" color="success" />
                  <Typography variant="body2">Tasks Completed</Typography>
                  <Typography variant="h6">{stats.tasksCompleted}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Paper elevation={3} sx={{ p: 1, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <AssignmentLate fontSize="small" color="error" />
                  <Typography variant="body2">Tasks Missed</Typography>
                  <Typography variant="h6">{stats.tasksMissed}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Paper elevation={3} sx={{ p: 1, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <EmojiEvents fontSize="small" color="primary" />
                  <Typography variant="body2">Rewards</Typography>
                  <Typography variant="h6">{stats.rewardsReceived}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Paper elevation={3} sx={{ p: 1, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Gavel fontSize="small" color="secondary" />
                  <Typography variant="body2">Punishments</Typography>
                  <Typography variant="h6">{stats.punishmentsReceived}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Paper elevation={3} sx={{ p: 1, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Celebration fontSize="small" color="primary" />
                  <Typography variant="body2">Current Points</Typography>
                  <Typography variant="h6">{points}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Paper elevation={3} sx={{ p: 1, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <SportsScore fontSize="small" color="secondary" />
                  <Typography variant="body2">Current Chastity</Typography>
                  <Typography variant="h6">{chastityDays} days</Typography>
                </Paper>
              </Grid>

                {/* Task Completion Pie Chart */}
                <Grid item xs={12} md={6}>
                  <Paper elevation={3} sx={{ p: 1, opacity: 0.80, height: '100%' }}>
                    <Typography variant="body2">Task Completion</Typography>
                    <Box sx={{ height: '200px' }}>
                      <Pie
                        data={taskData}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            legend: { position: 'bottom' },
                            tooltip: { enabled: true },
                          },
                        }}
                      />
                    </Box>
                  </Paper>
                </Grid>

                {/* Rewards and Punishments Bar Chart */}
                <Grid item xs={12} md={6}>
                  <Paper elevation={3} sx={{ p: 1, opacity: 0.80, height: '100%' }}>
                    <Typography variant="body2">Rewards and Punishments</Typography>
                    <Box sx={{ height: '200px' }}>
                      <Bar
                        data={rewardPunishmentData}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          scales: {
                            y: { beginAtZero: true },
                          },
                          plugins: {
                            legend: { display: false },
                            tooltip: { enabled: true },
                          },
                        }}
                      />
                    </Box>
                  </Paper>
                </Grid>

                {/* Average Ratings Bar Chart */}
                <Grid item xs={12}>
                  <Paper elevation={3} sx={{ p: 1, opacity: 0.80, height: '100%' }}>
                    <Typography variant="body2">Average Ratings</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mt: 2 }}>
                      {ratingData.labels.map((label, index) => (
                        <Box key={label} sx={{ textAlign: 'center' }}>
                          <Typography variant="body2">{label}</Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {renderSmileyFace(ratingData.datasets[0].data[index])}
                            <Typography variant="body2" sx={{ ml: 1 }}>
                              {ratingData.datasets[0].data[index].toFixed(1)}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                    <Box sx={{ height: '200px' }}>
                      <Bar
                        data={ratingData}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          scales: {
                            y: { beginAtZero: true, max: 5 },
                          },
                          plugins: {
                            legend: { display: false },
                            tooltip: { enabled: true },
                          },
                        }}
                      />
                    </Box>
                  </Paper>
                </Grid>

                {/* Most Common Punishment (unchanged) */}
                <Grid item xs={12}>
                  <Paper elevation={3} sx={{ p: 1, opacity: 0.80, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Mood fontSize="small" color="primary" />
                    <Typography variant="body2">Most Common Punishment</Typography>
                    <Typography variant="h6">{stats.mostCommonPunishment}</Typography>
                  </Paper>
                </Grid>
            </Grid>
        </>
      )}
    </Container>
  );
};

export default Dashboard;