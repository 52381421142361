import React, { useState, useEffect, useRef, useCallback } from 'react';
import { 
    axiosInstance, 
    getToken, 
    logout, 
    isAuthenticated, 
    getRole,
    setupTokenRefresh,
    clearTokenRefresh
} from './auth';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import {
  Drawer, CssBaseline, Container, AppBar, Toolbar, Typography, IconButton, Tooltip,
  Box, BottomNavigation, BottomNavigationAction, List, ListItem, ListItemIcon, ListItemText,
  Snackbar, TextField, LinearProgress, CircularProgress, Badge, Avatar, MenuItem, Menu,
  ButtonBase
} from '@mui/material';
import StarfieldBackground from './StarfieldBackground';
import BubbleBackground from './BubbleBackground';
import {
  Assignment, EmojiEvents, ExitToApp,
  Home, Add, Remove, List as ListIcon, Edit,
  Brightness4, Brightness7,
  CalendarToday, Dashboard as DashboardIcon, CheckCircle,
  VisibilityOff, Gavel, Menu as MenuIcon,
  Favorite, Cake, Toys as ToysIcon, Chat as ChatIcon,
  Poll, Settings as SettingsIcon, ViewKanban,
  Face,
  EmojiEmotions,
  Pets,
  Star,
  MusicNote,
  SportsBasketball,
  SportsEsports,
  Book,
  Movie,
  Restaurant,
  Nature,
  Work,
  School,
  ShoppingCart,
  FlightTakeoff,
  BeachAccess,
  Celebration,
  Mood,
  Spa,
  FitnessCenter,
  LocalCafe,
  Person,
} from '@mui/icons-material';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { useActionCounts } from './useActionCounts';
import { AchievementProvider } from './AchievementSystem';

import { getTheme, fetchUserTheme, updateUserTheme, fetchSubmissiveTheme, initializeTheme } from './theme';
import { fetchPoints, updatePoints, incrementPoints, decrementPoints } from './points';
import { fetchChastityPeriod, updateChastityPeriod, incrementChastity, decrementChastity } from './chastity';
import { fetchWelcomeMessage, updateWelcomeMessage } from './welcomeMessage';
import Dashboard from './dashboard';
import RewardsAndPunishments from './RewardsAndPunishments';
import TasksAndCompletions from './TasksAndCompletions';
import NotificationBell from './notifications';
import { getPushNotificationStatus, subscribeUserToPush } from './pushnotifications';
import Login from './login';
import Chat from './chat';
import SpotChecks from './spotChecks';
import FirstLoginWizard from './FirstLoginWizard';
import WizardPortal from './WizardPortal';
import { SettingsProvider, useSettings } from './settings';
import CenteredLoading from './CenteredLoading';
import TutorialManager from './TutorialManager';
import { useUnreadMessages } from './useUnreadMessages';
import WorkflowBuilder from './WorkflowBuilder';
import SafeBoardGame from './BoardGame';
import UserProfile from './UserProfile';
import CalendarView from './CalendarView';
import Rules from './rules';
import Kinks from './kinks';
import Discovery from './Discovery';
import Toys from './toys';
import Surveys from './surveys';
import Settings from './settings';
import SpecialDays from './SpecialDays';
// import DailyCheckin from './DailyCheckIn-backgrounds';
import DailyCheckin from './DailyCheckIn';
import TickTickImport from './TickTickImport';

// Redis cache
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30000, // Data considered fresh for 30 seconds
      cacheTime: 5 * 60 * 1000, // Keep unused data in cache for 5 minutes
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    },
  },
});

const PUBLIC_AVATARS_URL = 'https://api.strictlytasks.com/avatars/';

const MATERIAL_ICONS = [
  { icon: Person, name: 'person' },
  { icon: Face, name: 'face' },
  { icon: EmojiEmotions, name: 'emoji' },
  { icon: Pets, name: 'pets' },
  { icon: Star, name: 'star' },
  { icon: Favorite, name: 'heart' },
  { icon: MusicNote, name: 'music' },
  { icon: SportsBasketball, name: 'sports' },
  { icon: SportsEsports, name: 'games' },
  { icon: Book, name: 'book' },
  { icon: Movie, name: 'movie' },
  { icon: Restaurant, name: 'food' },
  { icon: Nature, name: 'nature' },
  { icon: Work, name: 'work' },
  { icon: School, name: 'school' },
  { icon: Home, name: 'home' },
  { icon: ShoppingCart, name: 'shopping' },
  { icon: FlightTakeoff, name: 'travel' },
  { icon: BeachAccess, name: 'beach' },
  { icon: Celebration, name: 'party' },
  { icon: Cake, name: 'celebration' },
  { icon: Mood, name: 'mood' },
  { icon: Spa, name: 'spa' },
  { icon: FitnessCenter, name: 'fitness' },
  { icon: LocalCafe, name: 'cafe' }
];

const FeatureGuard = ({ feature, children }) => {
  const { settings } = useSettings();
  
  if (!settings[`feature_${feature}`]) {
    return null;
  }
  
  return children;
};

const verifyImageUrl = async (url, maxAttempts = 5) => {
  for (let attempt = 0; attempt < maxAttempts; attempt++) {
    try {
      const response = await fetch(url);
      if (response.ok) {
        return url;
      }
    } catch (error) {
      console.log(`Attempt ${attempt + 1} failed for avatar image`);
    }
    // Wait 1 seconds between attempts
    await new Promise(resolve => setTimeout(resolve, 1000));
  }
  return null;
};

const MoreMenu = ({ open, anchorEl, onClose, navigate, handleLogout, role }) => {
  const actionCounts = useActionCounts(role);
  
  const moreMenuItems = [
    { 
      label: 'Rules', 
      icon: (
        <Badge 
        badgeContent={role === 'submissive' ? actionCounts?.rules : 0} 
        color="error"
          sx={{ 
            '& .MuiBadge-badge': { 
              display: actionCounts?.rules === 0 ? 'none' : 'flex',
              top: 2,
              right: 2,
              transform: 'scale(0.9) translate(50%, -50%)'
            }
          }}
        >
          <Gavel />
        </Badge>
      ), 
      path: '/rules'
    },
    { 
      label: 'Kinks', 
      icon: (
        <Badge 
        badgeContent={actionCounts?.kinks} 
        color="error"
          sx={{ 
            '& .MuiBadge-badge': { 
              display: actionCounts?.kinks === 0 ? 'none' : 'flex',
              top: 2,
              right: 2,
              transform: 'scale(0.9) translate(50%, -50%)'
            }
          }}
        >
          <Favorite />
        </Badge>
      ), 
      path: '/kinks' 
    },
    { label: 'Special Days', icon: <Cake />, path: '/special-days' },
    { label: 'Toys', icon: <ToysIcon />, path: '/toys' },
    { 
      label: 'Surveys', 
      icon: (
        <Badge 
        badgeContent={actionCounts?.surveys} 
        color="error"
          sx={{ 
            '& .MuiBadge-badge': { 
              display: actionCounts?.surveys === 0 ? 'none' : 'flex',
              top: 2,
              right: 2,
              transform: 'scale(0.9) translate(50%, -50%)'
            }
          }}
        >
          <Poll />
        </Badge>
      ), 
      path: '/surveys' 
    },
    { label: 'Kanban', icon: <ViewKanban />, path: '/dashboard#kanban' },
    { label: 'Workflow', icon: <AutoFixHighIcon />, path: '/workflowbuilder' },
    { 
      label: 'Game', 
      icon: (
        <Badge 
          badgeContent={actionCounts?.monopoly === 1 ? '★' : null}
          color="error"
          sx={{ 
            '& .MuiBadge-badge': { 
              display: actionCounts?.monopoly === 0 ? 'none' : 'flex',
              top: 2,
              right: 2,
              transform: 'scale(0.9) translate(50%, -50%)',
              fontSize: '12px',
              padding: '0 4px',
              minWidth: '16px',
              height: '16px'
            }
          }}
        >
          <VideogameAssetIcon />
        </Badge>
      ), 
      path: '/boardgame' 
    },
    { label: 'Settings', icon: <SettingsIcon />, path: '/settings' },
    { label: 'Logout', icon: <ExitToApp />, path: '/logout' }
  ];

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      PaperProps={{
        sx: {
          mt: -1,
          mb: 5,
          '& .MuiList-root': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            padding: 0.5,
            overflowX: 'auto',
          }
        }
      }}
    >
      {moreMenuItems.map((item) => (
        <MenuItem
          key={item.label}
          onClick={() => {
            if (item.path === '/logout') {
              handleLogout();
            } else {
              navigate(item.path);
            }
            onClose();
          }}
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            gap: 0.5,
            minWidth: 'auto',
          }}
        >
          {item.icon}
          <Typography variant="caption">{item.label}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};

const NavigationRail = ({ navigate, role }) => {
  const [unreadCount] = useUnreadMessages();
  const actionCounts = useActionCounts(role);
  const theme = useTheme();
  const { settings } = useSettings();
  const userRole = role;

  const chatMenuItem = {
    label: 'Chat',
    icon: (
      <Badge 
        badgeContent={unreadCount} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: unreadCount === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <ChatIcon />
      </Badge>
    ),
    path: '/chat'
  };

const dominantMenuItems = [
  { label: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard#dashboard' },
  { label: 'Calendar', icon: <CalendarToday />, path: '/calendar' },
  { label: 'Kanban', icon: <ViewKanban />, path: '/dashboard#kanban' },
  { label: 'WorkflowBuilder', icon: <AutoFixHighIcon />, path: '/workflowbuilder' }, 
  chatMenuItem,
  { 
    label: 'Tasks', 
    icon: (
      <Badge 
      badgeContent={actionCounts.tasks} 
      color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts.tasks === 0 ? 'none' : 'flex',
            top: 2,
            right: 4,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <Assignment />
      </Badge>
    ), 
    path: '/tasks-and-completions#tasks' 
  },
  { 
    label: 'Completions', 
    icon: (
      <Badge 
      badgeContent={actionCounts.completions} 
      color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts.completions === 0 ? 'none' : 'flex',
            top: 2,
            right: 4,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <CheckCircle />
      </Badge>
    ), 
    path: '/tasks-and-completions#completions' 
  },
  { 
    label: 'Spot Checks', 
    icon: (
      <Badge 
      badgeContent={actionCounts.spotChecks} 
      color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts.spotChecks === 0 ? 'none' : 'flex',
            top: 2,
            right: 4,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <VisibilityOff />
      </Badge>
    ), 
    path: '/tasks-and-completions#spot-checks' 
  },
  { 
    label: 'Rewards', 
    icon: (
      <Badge 
      badgeContent={actionCounts.rewards} 
      color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts.rewards === 0 ? 'none' : 'flex',
            top: 2,
            right: 4,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <EmojiEvents />
      </Badge>
    ), 
    path: '/rewards-and-punishments#rewards' 
  },
  { 
    label: 'Punishments', 
    icon: (
      <Badge 
      badgeContent={actionCounts.punishments} 
      color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts.punishments === 0 ? 'none' : 'flex',
            top: 2,
            right: 4,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <Gavel />
      </Badge>
    ), 
    path: '/rewards-and-punishments#punishments' 
  },
  { 
    label: 'Wishlist', 
    icon: (
      <Badge 
      badgeContent={actionCounts.wishlist} 
      color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts.wishlist === 0 ? 'none' : 'flex',
            top: 2,
            right: 4,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <ListIcon />
      </Badge>
    ), 
    path: '/rewards-and-punishments#wishlist' 
  },
  { 
    label: 'Rules', 
    icon: (
      <Badge 
      badgeContent={userRole === 'submissive' ? actionCounts.rules : 0} 
      color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts.rules === 0 ? 'none' : 'flex',
            top: 2,
            right: 4,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <Gavel />
      </Badge>
    ), 
    path: '/rules' 
  },
  { 
    label: 'Kinks', 
    icon: (
      <Badge 
      badgeContent={actionCounts.kinks} 
      color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts.kinks === 0 ? 'none' : 'flex',
            top: 2,
            right: 4,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <Favorite />
      </Badge>
    ), 
    path: '/kinks' 
  },
  { label: 'Special Days', icon: <Cake />, path: '/special-days' },
  { label: 'Toys', icon: <ToysIcon />, path: '/toys' },
  { 
    label: 'Surveys', 
    icon: (
      <Badge 
      badgeContent={actionCounts.surveys} 
      color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts.surveys === 0 ? 'none' : 'flex',
            top: 2,
            right: 4,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <Poll />
      </Badge>
    ), 
    path: '/surveys' 
  },
  { label: 'Settings', icon: <SettingsIcon />, path: '/settings' },
  { 
    label: 'BoardGame', 
    icon: (
      <Badge 
      badgeContent={actionCounts.monopoly} 
      color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts.monopoly === 0 ? 'none' : 'flex',
            top: 2,
            right: 42,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <VideogameAssetIcon />
      </Badge>
    ), 
    path: '/boardgame' 
  },
];

const submissiveMenuItems = [
  { label: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard#dashboard' },
  { label: 'Calendar', icon: <CalendarToday />, path: '/calendar' },
  { label: 'Kanban', icon: <ViewKanban />, path: '/dashboard#kanban' },
  chatMenuItem,
  { 
    label: 'Tasks', 
    icon: (
      <Badge 
      badgeContent={actionCounts.tasks} 
      color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts.tasks === 0 ? 'none' : 'flex',
            top: 2,
            right: 4,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <Assignment />
      </Badge>
    ), 
    path: '/tasks-and-completions#tasks' 
  },
  { 
    label: 'Spot Checks', 
    icon: (
      <Badge 
      badgeContent={actionCounts.spotChecks} 
      color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts.spotChecks === 0 ? 'none' : 'flex',
            top: 2,
            right: 4,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <VisibilityOff />
      </Badge>
    ), 
    path: '/tasks-and-completions#spot-checks' 
  },
  { 
    label: 'Rewards', 
    icon: (
      <Badge 
      badgeContent={actionCounts.rewards} 
      color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts.rewards === 0 ? 'none' : 'flex',
            top: 2,
            right: 4,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <EmojiEvents />
      </Badge>
    ), 
    path: '/rewards-and-punishments#rewards' 
  },
  { 
    label: 'Punishments', 
    icon: (
      <Badge 
      badgeContent={actionCounts.punishments} 
      color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts.punishments === 0 ? 'none' : 'flex',
            top: 2,
            right: 4,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <Gavel />
      </Badge>
    ), 
    path: '/rewards-and-punishments#punishments' 
  },
  { 
    label: 'Wishlist', 
    icon: (
      <Badge 
      badgeContent={actionCounts.wishlist} 
      color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts.wishlist === 0 ? 'none' : 'flex',
            top: 2,
            right: 4,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <ListIcon />
      </Badge>
    ), 
    path: '/rewards-and-punishments#wishlist' 
  },
  { 
    label: 'Rules', 
    icon: (
      <Badge 
      badgeContent={actionCounts.rules} 
      color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts.rules === 0 ? 'none' : 'flex',
            top: 2,
            right: 4,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <Gavel />
      </Badge>
    ), 
    path: '/rules' 
  },
  { 
    label: 'Kinks', 
    icon: (
      <Badge 
      badgeContent={actionCounts.kinks} 
      color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts.kinks === 0 ? 'none' : 'flex',
            top: 2,
            right: 4,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <Favorite />
      </Badge>
    ), 
    path: '/kinks' 
  },
  { label: 'Special Days', icon: <Cake />, path: '/special-days' },
  { label: 'Toys', icon: <ToysIcon />, path: '/toys' },
  { 
    label: 'Surveys', 
    icon: (
      <Badge 
      badgeContent={actionCounts.surveys} 
      color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts.surveys === 0 ? 'none' : 'flex',
            top: 2,
            right: 4,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <Poll />
      </Badge>
    ), 
    path: '/surveys' 
  },
  { 
    label: 'BoardGame', 
    icon: (
      <Badge 
      badgeContent={actionCounts.monopoly} 
      color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts.monopoly === 0 ? 'none' : 'flex',
            top: 2,
            right: 4,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <VideogameAssetIcon />
      </Badge>
    ), 
    path: '/boardgame' 
  },
];

  const menuItems = role === 'dominant' ? dominantMenuItems : 
      submissiveMenuItems.filter(item => {
        if (item.feature && !settings[`feature_${item.feature}`]) {
          return false;
        }
        if (item.requiresPermission && 
            !settings[`submissive_can_${item.requiresPermission}`]) {
          return false; 
        }
        return true;
      });

  return (
    <Box sx={{
      width: 40,
      borderRight: 1,
      borderColor: 'divider',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      py: 0.5,
      gap: 0.1,
      bgcolor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      position: 'fixed',
      top: 65,
      bottom: 5,
      zIndex: 1200,
      '& .MuiIconButton-root': {
        color: theme.palette.primary.contrastText,
        '&:hover': {
          bgcolor: theme.palette.primary.dark,
        },
      }
    }}>
      {menuItems.map((item) => (
        <Tooltip key={item.label} title={item.label} placement="right">
          <IconButton
            onClick={() => navigate(item.path)}
            sx={{ 
              width: 52,
              height: 52,
              borderRadius: 1,
            }}
          >
            {item.icon}
          </IconButton>
        </Tooltip>
      ))}
    </Box>
  );
};

const DrawerMenu = ({ navigate, role, userProfile, secureAvatarUrl }) => {
  const [unreadCount] = useUnreadMessages();
  const actionCounts = useActionCounts(role);
  const theme = useTheme();
  const { settings } = useSettings();

  const chatMenuItem = {
    label: 'Chat',
    icon: (
      <Badge 
        badgeContent={unreadCount} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: unreadCount === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
      >
        <ChatIcon />
      </Badge>
    ),
    path: '/chat'
  };

  const getAvatarDisplay = () => {
    if (userProfile?.avatar_type === 'image') {
      return (
        <Avatar
          src={`${PUBLIC_AVATARS_URL}${userProfile.avatar}`}
          sx={{ 
            width: 120, 
            height: 120,
            mb: 2,
            border: `2px solid ${theme.palette.primary.contrastText}`
          }}
        />
      );
    } else if (userProfile?.avatar_type === 'icon') {
      return (
        <Avatar
          sx={{ 
            width: 120, 
            height: 120,
            mb: 2,
            border: `2px solid ${theme.palette.primary.contrastText}`
          }}
        >
          {React.createElement(MATERIAL_ICONS.find(i => i.name === userProfile?.avatar)?.icon || Person, 
            { sx: { fontSize: 40 } })}
        </Avatar>
      );
    } else {
      return (
        <Avatar
          sx={{ 
            width: 120, 
            height: 120,
            mb: 2,
            border: `2px solid ${theme.palette.primary.contrastText}`
          }}
        >
          <Person sx={{ fontSize: 40 }} />
        </Avatar>
      );
    }
  };

  const dominantMenuItems = [
    { label: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard#dashboard' },
    { label: 'Calendar', icon: <CalendarToday />, path: '/calendar' },
    { label: 'Kanban', icon: <ViewKanban />, path: '/dashboard#kanban' },
    { label: 'WorkflowBuilder', icon: <AutoFixHighIcon />, path: '/workflowbuilder' }, 
    chatMenuItem,
    { 
      label: 'Tasks', 
      icon: (
        <Badge 
        badgeContent={actionCounts?.tasks} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts?.tasks === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
        >
          <Assignment />
        </Badge>
      ), 
      path: '/tasks-and-completions#tasks' 
    },
    { 
      label: 'Completions', 
      icon: (
        <Badge 
        badgeContent={actionCounts?.completions} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts?.completions === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
        >
          <CheckCircle />
        </Badge>
      ), 
      path: '/tasks-and-completions#completions' 
    },
    { 
      label: 'Spot Checks', 
      icon: (
        <Badge 
        badgeContent={actionCounts?.spotChecks} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts?.spotChecks === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
        >
          <VisibilityOff />
        </Badge>
      ), 
      path: '/tasks-and-completions#spot-checks' 
    },
    { 
      label: 'Rewards', 
      icon: (
        <Badge 
        badgeContent={actionCounts?.rewards} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts?.rewards === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
        >
          <EmojiEvents />
        </Badge>
      ), 
      path: '/rewards-and-punishments#rewards' 
    },
    { 
      label: 'Punishments', 
      icon: (
        <Badge 
        badgeContent={actionCounts?.punishments} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts?.punishments === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
        >
          <Gavel />
        </Badge>
      ), 
      path: '/rewards-and-punishments#punishments' 
    },
    { 
      label: 'Wishlist', 
      icon: (
        <Badge 
        badgeContent={actionCounts?.wishlist} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts?.wishlist === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
        >
          <ListIcon />
        </Badge>
      ), 
      path: '/rewards-and-punishments#wishlist' 
    },
    { 
      label: 'Rules', 
      icon: (
        <Badge 
        badgeContent={role === 'submissive' ? actionCounts?.rules : 0} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts?.rules === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
        >
          <Gavel />
        </Badge>
      ), 
      path: '/rules' 
    },
    { 
      label: 'Kinks', 
      icon: (
        <Badge 
        badgeContent={actionCounts?.kinks} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts?.kinks === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
        >
          <Favorite />
        </Badge>
      ), 
      path: '/kinks' 
    },
    { label: 'Special Days', icon: <Cake />, path: '/special-days' },
    { label: 'Toys', icon: <ToysIcon />, path: '/toys' },
    { 
      label: 'Surveys', 
      icon: (
        <Badge 
        badgeContent={actionCounts?.surveys} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts?.surveys === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
        >
          <Poll />
        </Badge>
      ), 
      path: '/surveys' 
    },
    { label: 'Settings', icon: <SettingsIcon />, path: '/settings' },
    { 
      label: 'BoardGame', 
      icon: (
        <Badge 
        badgeContent={actionCounts?.monopoly} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts?.monopoly === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
        >
          <VideogameAssetIcon />
        </Badge>
      ), 
      path: '/boardgame' 
    },
  ];

  const submissiveMenuItems = [
    { label: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard#dashboard' },
    { label: 'Calendar', icon: <CalendarToday />, path: '/calendar' },
    { label: 'Kanban', icon: <ViewKanban />, path: '/dashboard#kanban' },
    chatMenuItem,
    { 
      label: 'Tasks', 
      icon: (
        <Badge 
        badgeContent={actionCounts?.tasks} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts?.tasks === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
        >
          <Assignment />
        </Badge>
      ), 
      path: '/tasks-and-completions#tasks' 
    },
    { 
      label: 'Spot Checks', 
      icon: (
        <Badge 
        badgeContent={actionCounts?.spotChecks} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts?.spotChecks === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
        >
          <VisibilityOff />
        </Badge>
      ), 
      path: '/tasks-and-completions#spot-checks' 
    },
    { 
      label: 'Rewards', 
      icon: (
        <Badge 
        badgeContent={actionCounts?.rewards} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts?.rewards === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
        >
          <EmojiEvents />
        </Badge>
      ), 
      path: '/rewards-and-punishments#rewards' 
    },
    { 
      label: 'Punishments', 
      icon: (
        <Badge 
        badgeContent={actionCounts?.punishments} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts?.punishments === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
        >
          <Gavel />
        </Badge>
      ), 
      path: '/rewards-and-punishments#punishments' 
    },
    { 
      label: 'Wishlist', 
      icon: (
        <Badge 
        badgeContent={actionCounts?.wishlist} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts?.wishlist === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
        >
          <ListIcon />
        </Badge>
      ), 
      path: '/rewards-and-punishments#wishlist' 
    },
    { 
      label: 'Rules', 
      icon: (
        <Badge 
        badgeContent={actionCounts?.rules} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts?.rules === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
        >
          <Gavel />
        </Badge>
      ), 
      path: '/rules' 
    },
    { 
      label: 'Kinks', 
      icon: (
        <Badge 
        badgeContent={actionCounts?.kinks} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts?.kinks === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
        >
          <Favorite />
        </Badge>
      ), 
      path: '/kinks' 
    },
    { label: 'Special Days', icon: <Cake />, path: '/special-days' },
    { label: 'Toys', icon: <ToysIcon />, path: '/toys' },
    { 
      label: 'Surveys', 
      icon: (
        <Badge 
        badgeContent={actionCounts?.surveys} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts?.surveys === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
        >
          <Poll />
        </Badge>
      ), 
      path: '/surveys' 
    },
    { 
      label: 'BoardGame', 
      icon: (
        <Badge 
        badgeContent={actionCounts?.monopoly} 
        color="error"
        sx={{ 
          '& .MuiBadge-badge': { 
            display: actionCounts?.monopoly === 0 ? 'none' : 'flex',
            top: 2,
            right: 2,
            transform: 'scale(0.9) translate(50%, -50%)'
          }
        }}
        >
          <VideogameAssetIcon />
        </Badge>
      ), 
      path: '/boardgame' 
    },
  ];

  const menuItems = role === 'dominant' ? dominantMenuItems : 
      submissiveMenuItems.filter(item => {
        if (item.feature && !settings[`feature_${item.feature}`]) {
          return false;
        }
        if (item.requiresPermission && 
            !settings[`submissive_can_${item.requiresPermission}`]) {
          return false; 
        }
        return true;
      });

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '280px',
      bgcolor: theme.palette.primary.dark,
    }}>
      {/* Profile Section */}
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 2,
        borderBottom: `1px solid ${theme.palette.divider}`
      }}>
        {/* Profile Image */}
        {getAvatarDisplay()}
        
        {/* Action Buttons */}
        <Box sx={{ display: 'flex', gap: 1, width: '100%', mb: 1 }}>
          <ButtonBase
            onClick={() => navigate('/profile')}
            sx={{
              flex: 1,
              p: 1,
              borderRadius: 1,
              bgcolor: 'primary.main',
              color: 'primary.contrastText',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              '&:hover': {
                bgcolor: 'primary.dark',
              }
            }}
          >
            <Edit fontSize="small" />
            <Typography variant="button">Profile</Typography>
          </ButtonBase>
          {role === 'dominant' && (
            <ButtonBase
              onClick={() => navigate('/settings')}
              sx={{
                flex: 1,
                p: 1,
                borderRadius: 1,
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                '&:hover': {
                  bgcolor: 'primary.dark',
                }
              }}
            >
              <SettingsIcon fontSize="small" />
              <Typography variant="button">Settings</Typography>
            </ButtonBase>
          )}
        </Box>
      </Box>

      {/* Navigation Links */}
      <List sx={{
        overflowY: 'auto',
        flex: 1,
        '& .MuiListItem-root': {
          '&:hover': {
            bgcolor: theme.palette.primary.contrastText,
          },
        },
        '& .MuiListItemIcon-root': {
          color: theme.palette.primary.contrastText,
        },
        '& .MuiListItemText-root': {
          color: theme.palette.primary.contrastText,
        }
      }}>
        {menuItems.map((item) => (
          <ListItem 
            button 
            key={item.label} 
            onClick={() => navigate(item.path)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const PointsEditor = ({ points, setPoints, role, isLoading }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newPoints, setNewPoints] = useState(points);

  useEffect(() => {
    setNewPoints(points);
  }, [points]);

  if (isLoading) {
    return <CircularProgress size={20} />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedPoints = await updatePoints(newPoints);
      setPoints(updatedPoints);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating points:', error);
    }
  };

  const handleIncrement = async (amount) => {
    try {
      const updatedPoints = await incrementPoints(amount);
      setPoints(updatedPoints);
    } catch (error) {
      console.error('Error incrementing points:', error);
    }
  };

  const handleDecrement = async (amount) => {
    try {
      const updatedPoints = await decrementPoints(amount);
      setPoints(updatedPoints);
    } catch (error) {
      console.error('Error decrementing points:', error);
    }
  };

  if (isLoading) {
    return <CenteredLoading />;
  }

return (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.2, mr: 1.5 }}>
    {role === 'dominant' && (
      <>
        <IconButton 
          onClick={() => handleDecrement(10)} 
          size="medium" 
          sx={{ color: 'white', p: 0.5 }}
        >
          <Remove fontSize="small" />
        </IconButton>
        <IconButton 
          onClick={() => handleDecrement(1)} 
          size="small" 
          sx={{ color: 'white', p: 0.2 }}
        >
          <Remove fontSize="small" />
        </IconButton>
      </>
    )}
    {isEditing && role === 'dominant' ? (
      <form onSubmit={handleSubmit} style={{ display: 'inline' }}>
        <TextField
          type="number"
          value={newPoints}
          onChange={(e) => setNewPoints(parseInt(e.target.value))}
          onBlur={() => setIsEditing(false)}
          size="small"
          sx={{ width: 60, mx: 1 }}
          autoFocus
        />
      </form>
    ) : (
      <Typography
        variant="body3"
        component="span"
        onClick={() => role === 'dominant' && setIsEditing(true)}
        sx={{ 
          mx: { xs: 0.2, sm: 1 }, // Reduced margin on mobile
          cursor: role === 'dominant' ? 'pointer' : 'default', 
          color: 'white',
          fontSize: { xs: '0.9rem', sm: '1.1rem' },
          whiteSpace: 'nowrap', // Prevent line breaks
        }}
      >
        {points}{'\u00A0'}pts {/* Using non-breaking space and abbreviated "pts" */}
      </Typography>
    )}
    {role === 'dominant' && (
      <>
        <IconButton 
          onClick={() => handleIncrement(1)} 
          size="small" 
          sx={{ color: 'white', p: 0.2 }}
        >
          <Add fontSize="small" />
        </IconButton>
        <IconButton 
          onClick={() => handleIncrement(10)} 
          size="small" 
          sx={{ color: 'white', p: 0.5 }}
        >
          <Add />
        </IconButton>
      </>
    )}
  </Box>
  );
};

export const ChastityEditor = ({ chastityDays, setChastityDays, role, isLoading }) => {
  const { settings } = useSettings();
  const [isEditing, setIsEditing] = useState(false);
  const [newDays, setNewDays] = useState(chastityDays);

  useEffect(() => {
    setNewDays(chastityDays);
  }, [chastityDays]);

  if (isLoading) {
    return <CircularProgress size={20} />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateChastityPeriod(newDays);
      setChastityDays(response.days);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating chastity period:', error);
    }
  };

  const handleIncrement = async (amount) => {
    try {
      const response = await incrementChastity(amount);
      setChastityDays(response.days);
    } catch (error) {
      console.error('Error incrementing chastity period:', error);
    }
  };

  const handleDecrement = async (amount) => {
    try {
      const response = await decrementChastity(amount);
      setChastityDays(response.days);
    } catch (error) {
      console.error('Error decrementing chastity period:', error);
    }
  };

   if (!settings.chastity_enabled) {
     return null;
   }

   const label = settings.is_permanent_chastity ? 'Lock in' : 'Chastity';

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.2, mr: 1.5 }}>
      {role === 'dominant' && (
        <>
          <IconButton 
            onClick={() => handleDecrement(7)} 
            size="small" 
            disabled={chastityDays < 7}
            sx={{ color: 'white' }}
          >
            <Remove fontSize="small" />
          </IconButton>
          <IconButton 
            onClick={() => handleDecrement(1)} 
            size="small" 
            disabled={chastityDays === 0}
            sx={{ color: 'white' }}
          >
            <Remove fontSize="small" />
          </IconButton>
        </>
      )}
      {isEditing && role === 'dominant' ? (
        <form onSubmit={handleSubmit} style={{ display: 'inline' }}>
          <TextField
            type="number"
            value={newDays}
            onChange={(e) => setNewDays(Math.max(0, parseInt(e.target.value)))}
            onBlur={() => setIsEditing(false)}
            size="small"
            sx={{ width: 60, mx: 1 }}
            autoFocus
          />
        </form>
      ) : (
        <Typography
          variant="body3"
          component="span"
          onClick={() => role === 'dominant' && setIsEditing(true)}
          sx={{ 
            mx: { xs: 0.2, sm: 1 }, // Reduced margin on mobile
            cursor: role === 'dominant' ? 'pointer' : 'default', 
            color: 'white',
            fontSize: { xs: '0.9rem', sm: '1.1rem' },
            whiteSpace: 'nowrap', // Prevent line breaks
          }}
        >
          {label}:{'\u00A0'}{chastityDays}d {/* Using non-breaking space and abbreviated "d" */}
        </Typography>
      )}
      {role === 'dominant' && (
        <>
          <IconButton 
            onClick={() => handleIncrement(1)} 
            size="small"
            sx={{ color: 'white' }}
          >
            <Add fontSize="small" />
          </IconButton>
          <IconButton 
            onClick={() => handleIncrement(7)} 
            size="small"
            sx={{ color: 'white' }}
          >
            <Add fontSize="small" />
          </IconButton>
        </>
      )}
    </Box>
  );
};

const usePollStats = (isAuth, interval = 10000) => {
  const [stats, setStats] = useState({
    points: 0,
    chastityDays: 0,
    isLoading: true,
    error: null
  });

  const updateStats = useCallback((newStats) => {
    setStats(prevStats => {
      // Only update if values have changed
      if (prevStats.points !== newStats.points || 
          prevStats.chastityDays !== newStats.chastityDays) {
        return {
          ...prevStats,
          ...newStats,
          isLoading: false,
          error: null
        };
      }
      return prevStats;
    });
  }, []);

  const updatePoints = useCallback((newPoints) => {
    setStats(prevStats => ({
      ...prevStats,
      points: newPoints
    }));
  }, []);

  const updateChastityDays = useCallback((newDays) => {
    setStats(prevStats => ({
      ...prevStats,
      chastityDays: newDays
    }));
  }, []);

  useEffect(() => {
    let isSubscribed = true;

    const fetchStats = async () => {
      if (!isAuth) return;

      try {
        const [pointsResponse, chastityResponse] = await Promise.all([
          fetchPoints(),
          fetchChastityPeriod()
        ]);

        if (isSubscribed) {
          updateStats({
            points: pointsResponse,
            chastityDays: chastityResponse.days
          });
        }
      } catch (error) {
        if (isSubscribed) {
          setStats(prev => ({
            ...prev,
            isLoading: false,
            error: 'Failed to fetch stats'
          }));
        }
      }
    };

    fetchStats(); // Initial fetch
    const intervalId = setInterval(fetchStats, interval);

    return () => {
      isSubscribed = false;
      clearInterval(intervalId);
    };
  }, [isAuth, interval, updateStats]);

  return {
    ...stats,
    updatePoints,
    updateChastityDays
  };
};

const AppContent = ({ isAuth, setIsAuth, userRole, setUserRole, handleLogout, showWizard, setShowWizard }) => {
  const { settings, updateSettings } = useSettings();
  const [isSettingsLoaded, setIsSettingsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isPointsLoading, setIsPointsLoading] = useState(true);
  const [isChastityLoading, setIsChastityLoading] = useState(true);
  const { points, chastityDays, updatePoints, updateChastityDays } = usePollStats(isAuth);
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isEditingWelcome, setIsEditingWelcome] = useState(false);
  const [newWelcomeMessage, setNewWelcomeMessage] = useState('');
  const [currentTheme, setCurrentTheme] = useState('dark');
  const [originalTheme, setOriginalTheme] = useState('light');
  const [partnerTheme, setPartnerTheme] = useState('dark');
  const navigate = useNavigate();
  const location = useLocation();
  const contentRef = useRef(null);
  const [navStyle, setNavStyle] = useState('rail');
  const [unreadCount] = useUnreadMessages();
  const [userProfile, setUserProfile] = useState(null);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLandscape = useMediaQuery('(orientation: landscape)');
  
  const [secureAvatarUrl, setSecureAvatarUrl] = useState(null);

  const [moreAnchorEl, setMoreAnchorEl] = useState(null);
  const [drawerAnchorEl, setDrawerAnchorEl] = useState(null);

  const [showDailyCheckin, setShowDailyCheckin] = useState(true);
  const [testMode, setTestMode] = useState(false);

  const actionCounts = useActionCounts(userRole);
  
  const fetchSecureAvatarUrl = async (filename) => {
    try {
      const token = getToken();
      const response = await axiosInstance.get(`/get-file-url/${filename}`);
      if (response.data && response.data.url) {
        const secureUrl = `${response.data.url}&jwt=${encodeURIComponent(token)}`;
        // Verify and retry if needed
        return await verifyImageUrl(secureUrl);
      }
      return null;
    } catch (error) {
      console.error('Error fetching secure avatar URL:', error);
      return null;
    }
  };

  const fetchSettings = async () => {
    try {
      const response = await axiosInstance.get('/settings');
      updateSettings(response.data);
      setIsSettingsLoaded(true);
    } catch (error) {
      console.error('Failed to fetch settings:', error);
      setIsSettingsLoaded(true);
    }
  };
  
  const fetchUserProfile = async () => {
    try {
      const response = await axiosInstance.get('/user-profile');
      setUserProfile(response.data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const checkDailyCheckIn = async () => {
    if (isAuth && !showWizard) {
      try {
        const response = await axiosInstance.get('/daily-checkin/status');
        setShowDailyCheckin(!response.data.completed); // Invert !respond.data.completed for testing?
      } catch (error) {
        console.error('Error checking daily check-in status:', error);
      }
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        if (isAuth) {
          await fetchSettings();
          await checkDailyCheckIn();
          const { points: initialPoints, chastityDays: initialChastityDays } = await fetchPointsAndChastity() || {};
          await fetchWelcomeMessageData();
          await checkFirstLogin();
          await fetchPointsAndChastity();
          await fetchUserProfile();
          
          //console.log('Setting initial data:', { initialPoints, initialChastityDays });
          if (initialPoints !== undefined) updatePoints(initialPoints);
          if (initialChastityDays !== undefined) updateChastityDays(initialChastityDays);
          setDataLoaded(true);
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setErrorMessage('Failed to load initial data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchInitialData();
  }, [isAuth]);

  useEffect(() => {
    const fetchThemes = async () => {
      if (isAuth) {
        // Initialize theme system with change handler
        const { initialTheme, cleanup } = initializeTheme((newSystemTheme) => {
          // Only update if we're using system theme
          if (currentTheme === 'system') {
            setCurrentTheme(newSystemTheme);
          }
        });
  
        // Fetch user's theme preference
        const userTheme = await fetchUserTheme(userRole);
        
        // If user theme is 'system', use the system theme
        const effectiveTheme = userTheme === 'system' ? initialTheme : userTheme;
        
        setCurrentTheme(effectiveTheme);
        setOriginalTheme(userTheme); // Store 'system' if that's what user chose
  
        if (userRole === 'dominant') {
          const subTheme = await fetchSubmissiveTheme();
          setPartnerTheme(subTheme);
        }
      }
    };
    
    fetchThemes();
  }, [isAuth, userRole]);
  
  const toggleTheme = async () => {
    // If current theme is system, switch to light/dark explicitly
    const currentSystemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    
    let newTheme;
    if (originalTheme === 'system') {
      // If we're using system theme, toggle between light and dark explicitly
      newTheme = currentTheme === 'dark' ? 'light' : 'dark';
    } else {
      // If we're using a specific theme, toggle between that and system
      newTheme = currentTheme === originalTheme ? 'system' : originalTheme;
    }
  
    setCurrentTheme(newTheme === 'system' ? currentSystemTheme : newTheme);
    setOriginalTheme(newTheme);
  
    if (userRole === 'dominant') {
      try {
        await updateUserTheme(newTheme);
      } catch (error) {
        console.error('Error updating theme:', error);
      }
    }
  };

  // If the eventListener fails just check every 120 secs
  useEffect(() => {
    const refreshSettings = () => {
      if (isAuth) {
        fetchSettings();
      }
    };
  
    const intervalId = setInterval(refreshSettings, 120000); // Refresh every 120s
  
    return () => clearInterval(intervalId);
  }, [isAuth]);

 // Check for settings
  useEffect(() => {
    const handleSettingsChange = () => {
      fetchSettings();
      fetchPointsAndChastity();
    };
  
    window.addEventListener('settingsChanged', handleSettingsChange);
  
    return () => {
      window.removeEventListener('settingsChanged', handleSettingsChange);
    };
  }, []);

  // Fetch the profile image
  useEffect(() => {
    const updateAvatarUrl = async () => {
      if (userProfile?.avatar && userProfile.avatar_type === 'image') {
        const url = await fetchSecureAvatarUrl(userProfile.avatar);
        setSecureAvatarUrl(url);
      }
    };
    updateAvatarUrl();
  }, [userProfile?.avatar]);

  const handleDailyCheckinComplete = () => {
    setShowDailyCheckin(false);
    // If first login wizard needs to be shown, show it after check-in
    if (showWizard) {
      setShowWizard(true);
    }
  };

  const checkFirstLogin = async () => {
    try {
      const response = await axiosInstance.get('/check-first-login');
      setShowWizard(response.data.is_first_login);
    } catch (error) {
      console.error('Failed to check first login status:', error);
    }
  };

  const handleWizardComplete = async () => {
    try {
      await axiosInstance.post('/complete-wizard');
      setShowWizard(false);
      // Refresh user data after wizard completion
      fetchPointsAndChastity();
      fetchWelcomeMessageData();
      fetchUserTheme(userRole).then(setCurrentTheme);
    } catch (error) {
      console.error('Failed to complete wizard:', error);
    }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data && event.data.type === 'GET_AUTH_TOKEN') {
        const token = localStorage.getItem('token');
        event.ports[0].postMessage({ type: 'AUTH_TOKEN', token: token });
      } else if (event.data && event.data.type === 'TOKEN_EXPIRED') {
        handleLogout();
      }
    };
  }, []);

  useEffect(() => {
    const hash = location.hash;
    if (hash && contentRef.current) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    }
  }, [location]);

  const setPointsWithCheck = useCallback((newPoints) => {
    updatePoints(prevPoints => {
      // Only update if the value has changed
      if (prevPoints !== newPoints) {
        return newPoints;
      }
      return prevPoints;
    });
  }, []);
  
  const setChastityDaysWithCheck = useCallback((newDays) => {
    updateChastityDays(prevDays => {
      // Only update if the value has changed
      if (prevDays !== newDays) {
        return newDays;
      }
      return prevDays;
    });
  }, []);

  const fetchPointsAndChastity = async () => {
    try {
      setIsPointsLoading(true);
      setIsChastityLoading(true);
      
      const [pointsResponse, chastityResponse] = await Promise.all([
        fetchPoints(),
        fetchChastityPeriod()
      ]);
  
      // Use the new state setters that check for changes
      setPointsWithCheck(pointsResponse);
      setChastityDaysWithCheck(chastityResponse.days);
      
      return { 
        points: pointsResponse, 
        chastityDays: chastityResponse.days 
      };
    } catch (error) {
      console.error('Error fetching points and chastity:', error);
      setErrorMessage('Failed to fetch points and chastity. Please try again.');
      return null;
    } finally {
      setIsPointsLoading(false);
      setIsChastityLoading(false);
    }
  };

  const fetchWelcomeMessageData = async () => {
    try {
      const message = await fetchWelcomeMessage();
      setWelcomeMessage(message);
      setNewWelcomeMessage(message);
    } catch (error) {
      setErrorMessage('Failed to fetch welcome message. Please try again.');
    }
  };

  const handleUpdateWelcomeMessage = async () => {
    try {
      const updatedMessage = await updateWelcomeMessage(newWelcomeMessage);
      setWelcomeMessage(updatedMessage);
      setIsEditingWelcome(false);
    } catch (error) {
      setErrorMessage('Failed to update welcome message. Please try again.');
    }
  };

  const HeaderAvatar = ({ userProfile, onClick }) => {
    if (userProfile?.avatar_type === 'image') {
      return (
        <IconButton onClick={onClick} sx={{ padding: 0.5 }}>
          <Avatar
            src={`${PUBLIC_AVATARS_URL}${userProfile.avatar}`}
            sx={{ width: 32, height: 32 }}
          />
        </IconButton>
      );
    } else if (userProfile?.avatar_type === 'icon') {
      return (
        <IconButton onClick={onClick} sx={{ padding: 0.5 }}>
          <Avatar sx={{ width: 32, height: 32 }}>
            {React.createElement(
              MATERIAL_ICONS.find(i => i.name === userProfile?.avatar)?.icon || Person, 
              { sx: { fontSize: 20 } }
            )}
          </Avatar>
        </IconButton>
      );
    } else {
      return (
        <IconButton onClick={onClick} sx={{ padding: 0.5 }}>
          <Avatar sx={{ width: 32, height: 32 }}>
            <Person sx={{ fontSize: 20 }} />
          </Avatar>
        </IconButton>
      );
    }
  };

  if (isLoading || !dataLoaded) {
    return <CenteredLoading />;
  }

return (
  <SettingsProvider>
    <ThemeProvider theme={getTheme(currentTheme)}>
      <CssBaseline />
      {currentTheme === 'sissy' ? (
        <BubbleBackground isSissy={true} />
      ) : currentTheme === 'pastel' ? (
        <BubbleBackground isSissy={false} />
      ) : (
        <StarfieldBackground />
      )}
      {!isAuth ? (
        <Login setAuth={(auth) => {
          setIsAuth(auth);
          setUserRole(getRole());
        }} />
      ) : (
        <>
          {showDailyCheckin && (
            <DailyCheckin 
              onComplete={handleDailyCheckinComplete}
            />
          )}
          {showWizard ? (
            <WizardPortal>
              <FirstLoginWizard onComplete={handleWizardComplete} />
            </WizardPortal>
          ) : (
            <TutorialManager role={userRole}>
              <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                {isLargeScreen && (
                  navStyle === 'drawer' ? (
                    <Drawer
                      variant="permanent"
                      sx={{
                        width: 280,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                          width: 280,
                          boxSizing: 'border-box',
                          bgcolor: 'primary.dark',
                          color: 'primary.contrastText',
                          position: 'fixed',
                          top: { xs: 40, md: 64 },
                          height: 'calc(100%)',
                          zIndex: 1200
                        },
                      }}
                    >
                      <Toolbar />
                      <DrawerMenu navigate={navigate} role={userRole} userProfile={userProfile} secureAvatarUrl={secureAvatarUrl} />
                    </Drawer>
                  ) : (
                    <NavigationRail navigate={navigate} role={userRole} unreadCount={unreadCount} />
                  )
                )}
                <Box sx={{ 
                  display: 'flex',
                  flexDirection: 'column', 
                  width: '100%',
                  marginLeft: isLargeScreen ? (navStyle === 'rail' ? '50px' : '20px') : 0,
                  marginTop: { xs: '40px', md: '65px' }, //Appbar height
                  overflow: 'hidden'
                }}>
              <AppBar 
                position="fixed"
                sx={{ 
                  width: isLargeScreen && navStyle === 'rail' ? `100%` : '100%',
                  zIndex: 1300
                }}
              >
                {isLargeScreen ? (
                  // Desktop header
                  <Toolbar sx={{ minHeight: 50, display: 'flex', justifyContent: 'space-between' }}>
                    
                    {/* Left side with menu and welcome message */}
                    {/* Disable welcoem message
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 2, 
                      minWidth: 0, // Allows text truncation
                      flex: 1 // Takes available space
                    }}>
                      <IconButton 
                        color="inherit" 
                        onClick={() => setNavStyle(prev => prev === 'rail' ? 'drawer' : 'rail')}
                      >
                        <Menu />
                      </IconButton>
                      {isEditingWelcome && userRole === 'dominant' ? (
                        <TextField
                          value={newWelcomeMessage}
                          onChange={(e) => setNewWelcomeMessage(e.target.value)}
                          onBlur={handleUpdateWelcomeMessage}
                          variant="standard"
                          sx={{ 
                            minWidth: 200,
                            flex: 1,
                            maxWidth: '50%' // Prevents TextField from pushing controls off screen
                          }}
                          autoFocus
                        />
                      ) : (
                        <Typography 
                          variant="body3" 
                          component="div"
                          onClick={() => userRole === 'dominant' && setIsEditingWelcome(true)}
                          sx={{ 
                            cursor: userRole === 'dominant' ? 'pointer' : 'default',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            flex: 1
                          }}
                        >
                          {welcomeMessage}
                        </Typography>
                      )}
                    </Box>
                    */}

                    <IconButton 
                      onClick={(event) => setDrawerAnchorEl(event.currentTarget)}
                      sx={{ padding: 0 }}
                    >
                      <img 
                        src="/transparent-logo.png" 
                        alt="Strictly Logo" 
                        style={{ 
                          height: '40px',
                          width: 'auto',
                        }} 
                      />
                    </IconButton>
                  
                    {/* Right side with controls */}
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 2,
                      ml: 2,
                      flexShrink: 0
                    }}>
                      <PointsEditor 
                        points={points} 
                        setPoints={updatePoints} 
                        role={userRole} 
                        isLoading={isPointsLoading} 
                      />
                      <ChastityEditor 
                        chastityDays={chastityDays} 
                        setChastityDays={updateChastityDays} 
                        role={userRole} 
                        isLoading={isChastityLoading}
                      />
                      <IconButton color="inherit" onClick={toggleTheme}>
                        {currentTheme === 'dark' ? <Brightness7 /> : <Brightness4 />}
                      </IconButton>
                      {userRole === 'dominant' && (
                        <IconButton 
                          color="inherit" 
                          onClick={() => navigate('/settings')}
                        >
                          <SettingsIcon />
                        </IconButton>
                      )}
                      <NotificationBell role={userRole} />
                      <IconButton color="inherit" onClick={handleLogout}>
                        <ExitToApp />
                      </IconButton>
                      <HeaderAvatar 
                        userProfile={userProfile}
                        onClick={() => navigate('/profile')}
                      />
                    </Box>
                  </Toolbar>
                ) : (
                  // Mobile Header
                  <>
                    <Toolbar sx={{ 
                      minHeight: { xs: 32, sm: 40 }, // Reduced height
                      padding: { xs: '0 4px', sm: '0 16px' }, // Reduced padding on mobile
                      width: '100%',
                    }}>
                      {/* Left side - Logo that triggers drawer */}
                      <IconButton 
                        onClick={(event) => setDrawerAnchorEl(event.currentTarget)}
                        sx={{ padding: 0 }}
                      >
                        <img 
                          src="/transparent-logo.png" 
                          alt="Logo" 
                          style={{ 
                            height: '25px',
                            width: 'auto',
                          }} 
                        />
                      </IconButton>


                      {/* Right side controls */}
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        marginLeft: 'auto',
                        gap: 0.8
                      }}>
  
                        {/* temporarily disable dark mode on mobile for dominant*/}
                        {userRole !== 'dominant' && ( 
                          <IconButton color="inherit" onClick={toggleTheme}>
                            {currentTheme === 'dark' ? <Brightness7 /> : <Brightness4 />}
                          </IconButton>
                        )}
  
                        {/* temporarily disable settings button on mobile for dominant*/}
                        {/*
                        {userRole === 'dominant' && (
                           <IconButton 
                             color="inherit" 
                             onClick={() => navigate('/settings')}
                           >
                             <SettingsIcon />
                           </IconButton>
                        )}
                        */}
  
                        <IconButton 
                          color="inherit" 
                          onClick={() => navigate('/chat')}
                          sx={{ position: 'relative' }}
                        >
                          <Badge 
                            badgeContent={unreadCount} 
                            color="error"
                              sx={{ 
                                '& .MuiBadge-badge': { 
                                  display: unreadCount === 0 ? 'none' : 'flex',
                                  top: 6,
                                  right: 2,
                                  transform: 'scale(0.9) translate(50%, -50%)'
                                }
                              }}
                          >
                            <ChatIcon />
                          </Badge>
                        </IconButton>
                        <NotificationBell role={userRole} />
  
                        {/* temporarily disable logout on mobile*/}
                        <IconButton color="inherit" onClick={handleLogout}>
                          <ExitToApp />
                        </IconButton>
  
                        <HeaderAvatar 
                          userProfile={userProfile}
                          onClick={() => navigate('/profile')}
                        />

                      </Box>
                    </Toolbar>

                    {/* Commented out to disable points and days editor (and linear progress) in header */}
                    {/*
                    <Toolbar sx={{ 
                      minHeight: { xs: 32, sm: 40 }, // Reduced height
                      padding: { xs: '0 4px', sm: '0 16px' }, // Reduced padding on mobile
                      width: '100%',
                    }}>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        width: '100%', 
                        gap: { xs: 0.5, sm: 0.5 } // Reduced gap on mobile
                      }}>
                        <PointsEditor 
                          points={points} 
                          setPoints={updatePoints} 
                          role={userRole} 
                          isLoading={isPointsLoading} 
                        />
                        <ChastityEditor 
                          chastityDays={chastityDays} 
                          setChastityDays={updateChastityDays} 
                          role={userRole} 
                          isLoading={isChastityLoading}
                        />
                      </Box>
                    </Toolbar> 
                    */}

                    <LinearProgress 
                      variant="determinate" 
                      value={(chastityDays / 30) * 100} 
                      sx={{ 
                        height: { xs: 3, sm: 4 },
                        width: '100%'
                      }} 
                    />

                    <Drawer
                      anchor="left"
                      open={Boolean(drawerAnchorEl)}
                      onClose={() => setDrawerAnchorEl(null)}
                      PaperProps={{
                        sx: {
                          marginTop: '40px', // Match AppBar height
                          height: 'calc(100% - 40px)',
                        }
                      }}
                    >
                      <DrawerMenu 
                        navigate={(path) => {
                          navigate(path);
                          setDrawerAnchorEl(null);
                        }}
                         role={userRole}
                         userProfile={userProfile}
                         secureAvatarUrl={secureAvatarUrl}
                      />
                    </Drawer>

                  </>
                )}
              </AppBar>
                <Container
                disableGutters 
                  sx={{ 
                    mt: 0, 
                    mb: { 
                      xs: /iPhone/i.test(navigator.userAgent) ? 'calc(56px + 25px)' : '56px',
                      sm: '56px',
                      md: 0 
                    },
                    flexGrow: 1,
                    paddingBottom: { xs: 2, sm: 2, md: 0 },
                    maxWidth: '100% !important',
                    maxWidth: '100vw',
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden'
                  }} 
                  ref={contentRef}
                >
                {errorMessage && (
                  <Snackbar
                    open={!!errorMessage}
                    autoHideDuration={2000}
                    onClose={() => setErrorMessage('')}
                    message={errorMessage}
                  />
                )}
                 <Routes>
                  <Route 
                    path="/" 
                    element={
                      isAuth ? (
                        showWizard ? (
                          <WizardPortal>
                            <FirstLoginWizard onComplete={handleWizardComplete} />
                          </WizardPortal>
                        ) : (
                          window.location.pathname === '/tickticksync' ? null : <Navigate to="/dashboard" />
                        )
                      ) : (
                        <Login setAuth={(auth) => {
                          setIsAuth(auth);
                          setUserRole(getRole());
                        }} />
                      )
                    } 
                  />
                  <Route
                    path="/tickticksync"
                    element={
                      isAuth ? (
                        <TickTickImport
                          role={userRole}
                        />
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/calendar"
                    element={
                      isAuth ? (
                        <CalendarView
                          role={userRole}
                        />
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      isAuth ? (
                        <Dashboard
                          role={userRole}
                          points={points}
                          chastityDays={chastityDays}
                          setPoints={updatePoints}
                          setChastityDays={updateChastityDays}
                          currentTheme={currentTheme}
                          navStyle={navStyle}
                        />
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/tasks-and-completions"
                    element={
                      isAuth ? (
                        <TasksAndCompletions
                          role={userRole}
                          points={points}
                          setPoints={updatePoints}
                        />
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/rewards-and-punishments"
                    element={
                      isAuth ? (
                        <RewardsAndPunishments
                          role={userRole}
                          points={points}
                          setPoints={updatePoints}
                        />
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/rules"
                    element={
                      isAuth ? (
                        <Rules
                        role={userRole}
                        />
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/kinks"
                    element={
                      isAuth ? (
                        <Kinks
                        role={userRole}
                        />
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/discovery"
                    element={
                      isAuth ? (
                        <Discovery
                        role={userRole}
                        />
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/surveys"
                    element={
                      isAuth ? (
                        <Surveys
                        role={userRole}
                        />
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/settings"
                    element={
                      isAuth ? (
                        <Settings
                        role={userRole}
                        currentTheme={currentTheme}
                        setCurrentTheme={setCurrentTheme}
                        welcomeMessage={welcomeMessage}
                        setWelcomeMessage={setWelcomeMessage}
                        />
                      ) : <Navigate to="/" />
                    }
                  /> 
                  <Route
                    path="/special-days"
                    element={
                      isAuth ? (
                        <SpecialDays
                        role={userRole}
                        />
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/toys"
                    element={
                      isAuth ? (
                        <Toys
                        role={userRole}
                        />
                      ) : <Navigate to="/" />
                    }
                  />                                                                 
                  <Route
                    path="/chat"
                    element={
                      isAuth ? (
                        <FeatureGuard feature="chat">
                          <Chat
                          role={userRole}
                          currentTheme={currentTheme}
                          navStyle={navStyle}
                          />
                        </FeatureGuard>
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/spot-checks"
                    element={
                      isAuth && userRole === 'dominant' ? (
                        <SpotChecks />
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/workflowbuilder"
                    element={
                      isAuth && userRole === 'dominant' ? (
                        <FeatureGuard feature="automation">
                          <WorkflowBuilder 
                          role={userRole}
                          currentTheme={currentTheme}
                          navStyle={navStyle}
                          />
                        </FeatureGuard>
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/boardgame"
                    element={
                      isAuth ? (
                        <FeatureGuard feature="monopoly">
                          <SafeBoardGame
                          role={userRole}
                          currentTheme={currentTheme}
                          />
                        </FeatureGuard>
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      isAuth ? (
                        <UserProfile onComplete={(updatedProfile) => setUserProfile(updatedProfile)} />
                      ) : <Navigate to="/" />
                    }
                  />
                </Routes>
              </Container>

              {!isLargeScreen && (
  <>
    <BottomNavigation
      value={location.pathname}
      onChange={(event, newValue) => {
        if (newValue !== 'more') {
          navigate(newValue);
        }
      }}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        height: {
          xs: /iPhone/i.test(navigator.userAgent) ? 'calc(56px + 25px)' : '56px'
        },
        paddingBottom: {
          xs: /iPhone/i.test(navigator.userAgent) ? '25px' : '0px'
        },
        bgcolor: 'primary.main',
        borderTop: 1,
        borderColor: 'divider',
        '& .MuiBottomNavigationAction-root': {
          color: 'primary.contrastText',
          '& .MuiSvgIcon-root': {
            color: 'primary.contrastText',
          },
          '&.Mui-selected': {
            color: 'primary.contrastText',
            '& .MuiSvgIcon-root': {
              color: 'primary.contrastText',
            },
          },
        },
      }}
    >
      <BottomNavigationAction 
        label="Home" 
        icon={<Home />} 
        value="/dashboard" 
      />
      <BottomNavigationAction 
        label="Calendar" 
        icon={<CalendarToday />} 
        value="/calendar"
      />
      <BottomNavigationAction 
        label="Tasks" 
        icon={
        <Badge 
          badgeContent={actionCounts?.getCombinedTaskCount?.() || 0}
          color="error"
          sx={{ 
            '& .MuiBadge-badge': { 
              display: actionCounts?.getCombinedTaskCount?.() === 0 ? 'none' : 'flex',
              top: 2,
              right: 2,
              transform: 'scale(0.9) translate(50%, -50%)'
            }
          }}
          >
            <Assignment />
          </Badge>
        } 
        value="/tasks-and-completions" 
      />
      <BottomNavigationAction 
        label="Rewards" 
        icon={
        <Badge 
          badgeContent={actionCounts?.getCombinedRewardCount?.() || 0}
          color="error"
          sx={{ 
            '& .MuiBadge-badge': { 
              display: actionCounts?.getCombinedRewardCount?.() === 0 ? 'none' : 'flex',
              top: 2,
              right: 2,
              transform: 'scale(0.9) translate(50%, -50%)'
            }
          }}
          >
            <EmojiEvents />
          </Badge>
        } 
        value="/rewards-and-punishments" 
      />
      <BottomNavigationAction 
        label="More" 
        icon={<MenuIcon />} 
        onClick={(event) => setMoreAnchorEl(event.currentTarget)} 
      />
    </BottomNavigation>
    <MoreMenu 
      open={Boolean(moreAnchorEl)}
      anchorEl={moreAnchorEl}
      onClose={() => setMoreAnchorEl(null)}
      navigate={navigate}
      handleLogout={handleLogout}
      role={userRole}
                  />
                </>
              )}
            </Box>
          </Box>
        </TutorialManager>
      )}
    </>
  )}
  </ThemeProvider>
</SettingsProvider>
);
};

const App = () => {
  const [isAuth, setIsAuth] = useState(isAuthenticated());
  const [userRole, setUserRole] = useState(getRole());
  const [showWizard, setShowWizard] = useState(false);
  const [showDailyCheckin, setShowDailyCheckin] = useState(false);

  useEffect(() => {
    let mounted = true;

    if (mounted && isAuthenticated()) {
      setupTokenRefresh();
    }

    return () => {
      mounted = false;
      clearTokenRefresh();
    };
  }, []);

  useEffect(() => {
    const initializePushNotifications = async () => {
      try {
        const status = await getPushNotificationStatus();
        if (status.isSupported && !status.isSubscribed) {
          await subscribeUserToPush();
        }
      } catch (error) {
        console.error('Error initializing push notifications:', error);
      }
    };

    if (isAuthenticated()) {
      initializePushNotifications();
    }
  }, []);

  const handleLogout = () => {
    logout();
    setIsAuth(false);
    setUserRole(null);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <AchievementProvider 
        showWizard={showWizard}
        showDailyCheckin={showDailyCheckin}
      >
        {isAuth ? (
          <SettingsProvider>
            <AppContent 
              isAuth={isAuth} 
              setIsAuth={setIsAuth} 
              userRole={userRole} 
              setUserRole={setUserRole} 
              handleLogout={handleLogout}
              showWizard={showWizard}
              setShowWizard={setShowWizard}
              showDailyCheckin={showDailyCheckin}
              setShowDailyCheckin={setShowDailyCheckin}
            />
          </SettingsProvider>
        ) : (
          <ThemeProvider theme={getTheme('dark')}>
            <Login setAuth={(auth) => {
              setIsAuth(auth);
              setUserRole(getRole());
            }} />
          </ThemeProvider>
        )}
      </AchievementProvider>
    </QueryClientProvider>
  );
};

export default App;