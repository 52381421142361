import React, { useState, useEffect, useRef } from 'react';
import { 
  Dialog, DialogTitle, DialogContent, Button, List, ListItem, ListItemText,
  Checkbox, CircularProgress, Typography, Alert, TextField, Switch,
  FormControlLabel, Slider, Box, Tab, Tabs, Paper, Stack
} from '@mui/material';
import { ArrowBack, Check } from '@mui/icons-material';
import { axiosInstance } from './auth';
import { useNavigate } from 'react-router-dom';

const POINT_PRESETS = [1, 5, 10, 20, 30, 40, 50];

const parseRecurrencePattern = (repeatFlag) => {
  if (!repeatFlag) return { is_recurring: false };
  
  const parts = repeatFlag.split(':')[1].split(';')
    .reduce((acc, part) => {
      const [key, value] = part.split('=');
      acc[key] = value;
      return acc;
    }, {});

  switch (parts.FREQ) {
    case 'DAILY':
      return {
        is_recurring: true,
        recurrence_pattern: 'daily'
      };
    case 'WEEKLY':
      return {
        is_recurring: true,
        recurrence_pattern: 'weekly',
        week_day: new Date().toLocaleDateString('en-US', { weekday: 'monday' }).toLowerCase()
      };
    case 'MONTHLY':
      return {
        is_recurring: true,
        recurrence_pattern: 'monthly'
      };
    default:
      return { is_recurring: false };
  }
};

const TaskMappingEditor = ({ task, index, onChange, role }) => {
  return (
    <Paper 
      elevation={1}
      sx={{ 
        borderRadius: 2,
        overflow: 'hidden',
        p: 2
      }}
    >
      <Typography variant="h6" gutterBottom>
        Task {index + 1}
      </Typography>
      
      <TextField
        label="Title"
        value={task.title}
        onChange={(e) => onChange(index, { ...task, title: e.target.value })}
        fullWidth
        margin="normal"
      />
      
      <TextField
        label="Description"
        value={task.description}
        onChange={(e) => onChange(index, { ...task, description: e.target.value })}
        multiline
        minRows={3}
        maxRows={6}
        fullWidth
        margin="normal"
      />

      {role === 'dominant' && (
        <>
          <Box sx={{ mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
              <Box sx={{ minWidth: '60px' }}>
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Points</Typography>
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Complete</Typography>
              </Box>
              <Slider
                value={task.points_complete}
                onChange={(_, value) => onChange(index, { ...task, points_complete: value })}
                step={null}
                marks={POINT_PRESETS.map(point => ({
                  value: point,
                  label: point.toString()
                }))}
                min={POINT_PRESETS[0]}
                max={POINT_PRESETS[POINT_PRESETS.length - 1]}
                sx={{ flex: 1 }}
              />
              <TextField
                type="number"
                value={task.points_complete}
                onChange={(e) => {
                  const value = e.target.value;
                  onChange(index, { 
                    ...task, 
                    points_complete: value === '' ? '' : Math.max(0, parseInt(value) || 0)
                  });
                }}
                size="small"
                sx={{ width: '60px' }}
                inputProps={{
                  min: 0,
                  max: 9999,
                  inputMode: 'numeric',
                  pattern: '[0-9]*'
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box sx={{ minWidth: '60px' }}>
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Points</Typography>
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Missed</Typography>
              </Box>
              <Slider
                value={task.points_incomplete}
                onChange={(_, value) => onChange(index, { ...task, points_incomplete: value })}
                step={null}
                marks={POINT_PRESETS.map(point => ({
                  value: point,
                  label: point.toString()
                }))}
                min={POINT_PRESETS[0]}
                max={POINT_PRESETS[POINT_PRESETS.length - 1]}
                sx={{ flex: 1 }}
              />
              <TextField
                type="number"
                value={task.points_incomplete}
                onChange={(e) => {
                  const value = e.target.value;
                  onChange(index, { 
                    ...task, 
                    points_incomplete: value === '' ? '' : Math.max(0, parseInt(value) || 0)
                  });
                }}
                size="small"
                sx={{ width: '60px' }}
                inputProps={{
                  min: 0,
                  max: 9999,
                  inputMode: 'numeric',
                  pattern: '[0-9]*'
                }}
              />
            </Box>
          </Box>

          <Stack spacing={1}>
            <FormControlLabel
              control={
                <Switch
                  checked={task.require_proof}
                  onChange={(e) => onChange(index, { ...task, require_proof: e.target.checked })}
                />
              }
              label="Require Proof"
            />
            
            <FormControlLabel
              control={
                <Switch
                  checked={task.send_notification}
                  onChange={(e) => onChange(index, { ...task, send_notification: e.target.checked })}
                />
              }
              label="Send Notification"
            />
            
            <FormControlLabel
              control={
                <Switch
                  checked={task.gps_check_in}
                  onChange={(e) => onChange(index, { ...task, gps_check_in: e.target.checked })}
                />
              }
              label="GPS Check-in"
            />
            
            <FormControlLabel
              control={
                <Switch
                  checked={task.submissive_can_complete}
                  onChange={(e) => onChange(index, { 
                    ...task, 
                    submissive_can_complete: e.target.checked 
                  })}
                />
              }
              label="Self-Complete"
            />
          </Stack>
        </>
      )}

      <TextField
        label="Tags (comma-separated)"
        value={task.tags.join(', ')}
        onChange={(e) => onChange(index, { 
          ...task, 
          tags: e.target.value.split(',').map(t => t.trim()).filter(Boolean)
        })}
        fullWidth
        margin="normal"
      />
    </Paper>
  );
};

const TickTickImport = ({ onClose, onTasksImported, role }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [mappedTasks, setMappedTasks] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const codeExchangeAttempted = useRef(false);

  useEffect(() => {
    checkConnectionStatus();
  }, []);

  useEffect(() => {
    const handleCode = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      
      if (code && !codeExchangeAttempted.current) {
        codeExchangeAttempted.current = true;
        await handleAuthCode(code);
        window.history.replaceState({}, document.title, window.location.pathname);
      }
    };

    handleCode();
  }, []);

  const checkConnectionStatus = async () => {
    try {
      const response = await axiosInstance.get('/ticktick/status');
      setIsConnected(response.data.connected);
      if (response.data.connected) {
        setStep(1);
        await fetchTasks();
      }
    } catch (err) {
      console.error('Error checking TickTick status:', err);
    }
  };

  const handleAuthCode = async (code) => {
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await axiosInstance.post('/ticktick/exchange-token', { code });
      setIsConnected(true);
      setStep(1);
      await fetchTasks();
    } catch (err) {
      setError('Failed to authenticate with TickTick: ' + (err.response?.data?.error || err.message));
      codeExchangeAttempted.current = false;
    } finally {
      setIsLoading(false);
    }
  };

  const initiateAuth = async () => {
    try {
      sessionStorage.setItem('ticktick_redirect', window.location.pathname);
      const response = await axiosInstance.get('/ticktick/auth-url');
      window.location.href = response.data.url;
    } catch (err) {
      setError('Failed to start authentication: ' + err.message);
    }
  };

  const fetchTasks = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await axiosInstance.get('/ticktick/projects');
      const allTasks = [];
      
      response.data.forEach(projectData => {
        if (projectData.tasks) {
          allTasks.push(...projectData.tasks.map(task => ({
            ...task,
            projectName: projectData.project.name,
            tags: [projectData.project.name, ...(task.tags || [])]
          })));
        }
      });
      
      setTasks(allTasks);
    } catch (err) {
      if (err.response?.status === 401) {
        setIsConnected(false);
        setStep(0);
        setError('TickTick session expired. Please reconnect.');
      } else {
        setError('Failed to fetch TickTick data: ' + err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleTaskSelect = (taskId) => {
    setSelectedTasks(prev => {
      const isSelected = prev.includes(taskId);
      if (isSelected) {
        return prev.filter(id => id !== taskId);
      } else {
        return [...prev, taskId];
      }
    });
  };

  const handleContinueToMapping = () => {
    const mapped = selectedTasks.map(taskId => {
      const tickTickTask = tasks.find(t => t.id === taskId);
      const recurrence = parseRecurrencePattern(tickTickTask.repeatFlag);
      
      return {
        title: tickTickTask.title,
        description: `${tickTickTask.content || ''}\n${tickTickTask.desc || ''}`.trim(),
        points_complete: role === 'dominant' ? 10 : 0,
        points_incomplete: role === 'dominant' ? 5 : 0,
        ...recurrence,
        due_date: tickTickTask.dueDate,
        require_proof: false,
        send_notification: false,
        tags: tickTickTask.tags || [],
        gps_check_in: false,
        submissive_can_complete: false
      };
    });
    
    setMappedTasks(mapped);
    setStep(2);
  };

  const handleFinishImport = async () => {
    try {
      setIsLoading(true);
      const createdTasks = await Promise.all(
        mappedTasks.map(taskData => 
          axiosInstance.post('/tasks', taskData)
        )
      );
      
      const taskResults = createdTasks.map(response => response.data);
      
      if (onTasksImported) {
        onTasksImported(taskResults);
      }

      // Close the modal and navigate
      handleClose();
    } catch (err) {
      setError('Failed to import tasks: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    navigate('/tasks-and-completions');
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Dialog 
      open={true} 
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        Import Tasks from TickTick
        {error && (
          <Alert 
            severity="error" 
            sx={{ mt: 2 }}
            action={
              <Button color="inherit" size="small" onClick={handleClose}>
                Close
              </Button>
            }
          >
            {error}
          </Alert>
        )}
      </DialogTitle>

      <DialogContent>
        {step === 0 && (
          <Paper 
            elevation={1}
            sx={{ 
              borderRadius: 2,
              p: 3,
              textAlign: 'center'
            }}
          >
            <Typography variant="h6" gutterBottom>
              Connect to TickTick
            </Typography>
            <Button variant="contained" onClick={initiateAuth}>
              {isConnected ? 'Reconnect to TickTick' : 'Connect TickTick Account'}
            </Button>
          </Paper>
        )}

        {step === 1 && (
          <Paper 
            elevation={1}
            sx={{ 
              borderRadius: 2,
              overflow: 'hidden'
            }}
          >
            <Box sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Select Tasks to Import
              </Typography>
            </Box>
            <List sx={{ py: 0 }}>
              {tasks.map(task => (
                <ListItem 
                  key={task.id} 
                  divider 
                  sx={{
                    '&:last-child': {
                      borderBottom: 'none'
                    }
                  }}
                >
                  <Checkbox
                    checked={selectedTasks.includes(task.id)}
                    onChange={() => handleTaskSelect(task.id)}
                  />
                  <ListItemText
                    primary={task.title}
                    secondary={
                      <React.Fragment>
                        <div>{task.projectName}</div>
                        {task.dueDate && (
                          <div>Due: {new Date(task.dueDate).toLocaleDateString()}</div>
                        )}
                        {task.tags && task.tags.length > 0 && (
                          <div>Tags: {task.tags.join(', ')}</div>
                        )}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))}
            </List>
            <Box sx={{ p: 2 }}>
              <Button
                variant="contained"
                onClick={handleContinueToMapping}
                disabled={selectedTasks.length === 0}
              >
                Continue with Selected ({selectedTasks.length})
              </Button>
            </Box>
          </Paper>
        )}

        {step === 2 && (
          <Box>
            <Tabs
              value={currentTab}
              onChange={(_, newValue) => setCurrentTab(newValue)}
              sx={{ mb: 2 }}
            >
              {mappedTasks.map((_, index) => (
                <Tab key={index} label={`Task ${index + 1}`} />
              ))}
            </Tabs>
            {mappedTasks.map((task, index) => (
              <Box key={index} hidden={currentTab !== index}>
                <TaskMappingEditor
                  task={task}
                  index={index}
                  role={role}
                  onChange={(index, updatedTask) => {
                    const newMappedTasks = [...mappedTasks];
                    newMappedTasks[index] = updatedTask;
                    setMappedTasks(newMappedTasks);
                  }}
                />
              </Box>
            ))}
            <Box 
              display="flex" 
              justifyContent="space-between" 
              mt={2}
              sx={{
                position: 'sticky',
                bottom: 0,
                bgcolor: 'background.paper',
                py: 2,
                borderTop: 1,
                borderColor: 'divider'
              }}
            >
              <Button
                startIcon={<ArrowBack />}
                onClick={() => setStep(1)}
              >
                Back
              </Button>
              <Button
                startIcon={<Check />}
                variant="contained"
                onClick={handleFinishImport}
              >
                Import Tasks
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TickTickImport;