import React, { createContext, useContext, useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import { Box, Typography, Fade, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { axiosInstance } from './auth';

// Create context for achievement system
const AchievementContext = createContext();

const FullScreenOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(0, 0, 0, 0.85)' 
    : 'rgba(0, 0, 0, 0.75)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: theme.zIndex.achievement || 1600,
  padding: theme.spacing(2),
}));

const AchievementContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' 
    ? theme.palette.background.paper 
    : theme.palette.background.default,
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(4),
  maxWidth: '600px',
  width: '90%',
  textAlign: 'center',
  position: 'relative',
  overflow: 'hidden',
  boxShadow: theme.shadows[10],
  border: `2px solid ${theme.palette.primary.main}`,
  animation: 'achievement-pulse 2s infinite',
  '@keyframes achievement-pulse': {
    '0%': {
      boxShadow: `0 0 0 0 ${theme.palette.primary.main}40`,
    },
    '70%': {
      boxShadow: `0 0 0 20px ${theme.palette.primary.main}00`,
    },
    '100%': {
      boxShadow: `0 0 0 0 ${theme.palette.primary.main}00`,
    },
  },
  backgroundImage: theme.palette.mode === 'dark'
    ? 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))'
    : 'linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))',
}));

const AchievementImage = styled(Box)(({ theme }) => ({
  width: '150px',
  height: '150px',
  margin: '0 auto',
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.mode === 'dark'
    ? theme.palette.background.default
    : theme.palette.background.paper,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `3px solid ${theme.palette.primary.main}`,
  boxShadow: `0 0 15px ${theme.palette.primary.main}40`,
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
}));

// Achievement popup component
const AchievementPopup = ({ achievement, onClose }) => {
  const theme = useTheme();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const successSound = new Audio('/success.mp3');
    successSound.play().catch(console.error);

    const timer = setTimeout(() => {
      onClose();
    }, 7000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <Fade in timeout={2000}>
      <FullScreenOverlay>
        <Confetti
          width={windowSize.width}
          height={windowSize.height}
          recycle={false}
          numberOfPieces={200}
          gravity={0.2}
          colors={[
            theme.palette.primary.main,
            theme.palette.secondary.main,
            theme.palette.primary.light,
            theme.palette.secondary.light,
            '#FFD700' // Keep gold as an accent
          ]}
        />
        <AchievementContainer>
          <Typography
            variant="h4"
            sx={{
              mb: 2,
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '0.1em',
              background: theme.palette.custom?.gradient || 
                `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Achievement Unlocked!
          </Typography>
          <AchievementImage>
            {achievement.image ? (
              <img
                src={`/achievements/${achievement.image}`}
                alt={achievement.name}
                onError={(e) => {
                  e.target.style.display = 'none';
                  const icon = document.createElement('div');
                  icon.innerHTML = '<svg>...</svg>'; // EmojiEventsIcon equivalent
                  e.target.parentNode.appendChild(icon);
                }}
              />
            ) : (
              <EmojiEventsIcon 
                sx={{ 
                  fontSize: 80, 
                  color: theme.palette.mode === 'dark' 
                    ? theme.palette.primary.light 
                    : theme.palette.primary.main 
                }} 
              />
            )}
          </AchievementImage>
          <Typography
            variant="h5"
            sx={{ 
              mb: 1, 
              fontWeight: 'bold',
              color: theme.palette.primary.main
            }}
          >
            {achievement.name}
          </Typography>
          <Typography
            variant="body1"
            sx={{ 
              mb: 2, 
              fontStyle: 'italic',
              color: theme.palette.text.secondary
            }}
          >
            {achievement.description}
          </Typography>
          <Typography
            variant="caption"
            sx={{ 
              display: 'block', 
              mt: 2,
              color: theme.palette.text.secondary,
              opacity: 0.8
            }}
          >
            {achievement.category} - Level {achievement.level}
          </Typography>
        </AchievementContainer>
      </FullScreenOverlay>
    </Fade>
  );
};

// Achievement provider component remains the same
export const AchievementProvider = ({ children, showWizard, showDailyCheckin }) => {
  const [achievementQueue, setAchievementQueue] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const checkNewAchievements = async () => {
      try {
        const response = await axiosInstance.get('/new-achievements');
        if (response.data.length > 0) {
          setAchievementQueue(prev => [...prev, ...response.data]);
        }
      } catch (error) {
        console.error('Error checking achievements:', error);
      }
    };

    const interval = setInterval(checkNewAchievements, 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (achievementQueue.length > 0 && !isProcessing) {
      setIsProcessing(true);
      setTimeout(() => {
        setIsProcessing(true);
      }, 3000);
    }
  }, [achievementQueue, isProcessing]);

  const handleAchievementComplete = async (achievementId) => {
    try {
      await axiosInstance.post(`/achievement-viewed/${achievementId}`);
      setAchievementQueue(prev => prev.slice(1));
      setIsProcessing(false);
    } catch (error) {
      console.error('Error marking achievement as viewed:', error);
    }
  };

  return (
    <AchievementContext.Provider value={{ achievementQueue, setAchievementQueue }}>
      {children}
      {isProcessing && achievementQueue.length > 0 && !showWizard && !showDailyCheckin && (
        <AchievementPopup
          achievement={achievementQueue[0]}
          onClose={() => handleAchievementComplete(achievementQueue[0].id)}
        />
      )}
    </AchievementContext.Provider>
  );
};

export const useAchievements = () => {
  const context = useContext(AchievementContext);
  if (!context) {
    throw new Error('useAchievements must be used within an AchievementProvider');
  }
  return context;
};

export default AchievementProvider;