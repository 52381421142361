import React, { useState, useEffect, useCallback } from 'react';
// For redis integration
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { axiosInstance } from './auth';
import {
  Container, Typography, Button, Checkbox, Dialog, DialogTitle, DialogContent,
  TextField, CircularProgress, Snackbar, Fab,
  Box, Select, MenuItem, FormControl, InputLabel, Chip, Accordion,
  AccordionSummary, AccordionDetails, Switch, FormControlLabel, List, ListItem, ListItemText,
  IconButton, Collapse, Tooltip, Rating, useTheme, Slider, useMediaQuery,
  Paper, Stack
} from '@mui/material';
import {
  Add, ExpandMore, PlayArrow, Pause, Stop, Edit as EditIcon, Delete as DeleteIcon,
  Assignment as AssignIcon, ExpandLess as ExpandLessIcon, 
  LocationOn, AutorenewRounded, CheckCircleOutline
} from '@mui/icons-material';
import { Close as CloseIcon } from '@mui/icons-material';
import moment from 'moment';
import 'moment-timezone';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { examples } from './examples';
import TickTickImport from './TickTickImport';

const POINT_PRESETS = [1, 5, 10, 20, 30, 40, 50];

const getTaskStatusColor = (status) => {
  switch (status) {
    case 'incomplete':
      return 'error.main';
    case 'submitted':
      return 'warning.main';
    case 'approved':
      return 'success.main';
    case 'pending_approval':
      return 'info.main';
    default:
      return 'text.primary';
  }
};

const formatTaskStatus = (status) => {
  if (!status) return '';
  
  // Split by underscore and capitalize each word
  return status
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const Timer = ({ onTimerUpdate }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
  const intervalRef = React.useRef(null);

  const startTimer = () => {
    if (!isRunning) {
      setIsRunning(true);
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
  };

  const pauseTimer = () => {
    if (isRunning) {
      clearInterval(intervalRef.current);
      setIsRunning(false);
    }
  };

  const stopTimer = () => {
    clearInterval(intervalRef.current);
    setIsRunning(false);
    onTimerUpdate(time);
    setTime(0);
  };

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6">Timer: {formatTime(time)}</Typography>
      <Button startIcon={<PlayArrow />} onClick={startTimer} disabled={isRunning}>Start</Button>
      <Button startIcon={<Pause />} onClick={pauseTimer} disabled={!isRunning}>Pause</Button>
      <Button startIcon={<Stop />} onClick={stopTimer}>Stop</Button>
    </Box>
  );
};

const CompletionDots = ({ completionHistory = [] }) => {
  // Ensure we have exactly 10 items, pad with null for missing entries
  const history = Array(10).fill(null).map((_, i) => completionHistory[i] || null);

  const getStatusColor = (status) => {
    switch (status) {
      case 'approved':
        return 'success.main';
      case 'denied':
        return 'error.main';
      case 'pending':
        return 'warning.main';
      default:
        return 'transparent';
    }
  };

  const getTooltipContent = (completion) => {
    if (!completion) return 'No completion data';
    
    const date = new Date(completion.date).toLocaleDateString();
    const status = completion.status.charAt(0).toUpperCase() + completion.status.slice(1);
    const points = completion.points_awarded ? ` (${completion.points_awarded} points)` : '';
    
    return `${status} on ${date}${points}`;
  };

  return (
    <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', my: 0.5 }}>
      {history.map((completion, index) => (
        <Tooltip
          key={index}
          title={getTooltipContent(completion)}
          arrow
        >
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              bgcolor: completion ? getStatusColor(completion.status) : 'transparent',
              border: '1px solid',
              borderColor: completion ? getStatusColor(completion.status) : 'text.disabled',
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.2)',
                boxShadow: 1
              }
            }}
          />
        </Tooltip>
      ))}
    </Box>
  );
};

const TaskForm = ({ 
  onSubmit, 
  task, 
  onClose, 
  role, 
  kinks = [], 
  onApprove, 
  onDeny,
  renderDateTimePicker,
  userTimezone
}) => {  const [title, setTitle] = useState(task?.title || '');
  const [description, setDescription] = useState(task?.description || '');
  const [pointsComplete, setPointsComplete] = useState(task?.points_complete || POINT_PRESETS[2]);
  const [pointsIncomplete, setPointsIncomplete] = useState(task?.points_incomplete || POINT_PRESETS[2]);
  const [isRecurring, setIsRecurring] = useState(task?.is_recurring || false);
  const [recurrencePattern, setRecurrencePattern] = useState(task?.recurrence_pattern || 'none');
  const [weekDay, setWeekDay] = useState(task?.week_day || 'monday');
  const [dueDate, setDueDate] = useState(task?.due_date ? new Date(task.due_date) : null);
  const [requireProof, setRequireProof] = useState(task?.require_proof || false);
  const [sendNotification, setSendNotification] = useState(task?.send_notification || false);
  const [tags, setTags] = useState(task?.tags || []);
  const [tagInput, setTagInput] = useState('');
  const [gpsCheckIn, setGpsCheckIn] = useState(task?.gps_check_in || false);
  const [submissiveCanComplete, setSubmissiveCanComplete] = useState(task?.submissive_can_complete || false);
  
  // New scheduling states
  const [isScheduled, setIsScheduled] = useState(!!task?.do_at);
  const [scheduledTime, setScheduledTime] = useState(task?.do_at ? new Date(task.do_at) : null);
  const [duration, setDuration] = useState(task?.duration || 60);
  const [durationUnit, setDurationUnit] = useState(task?.duration_unit || 'minutes');
  
  const [customRecurrence, setCustomRecurrence] = useState(task?.custom_recurrence || {
    type: 'days',
    interval: 1,
    weekDays: [],
    monthDays: [],
  });

  const recurrenceOptions = [
    { value: 'none', label: 'None' },
    { value: 'daily', label: 'Daily' }, 
    { value: 'weekdays', label: 'Weekdays (Mon-Fri)' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'bi-weekly', label: 'Bi-Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'custom', label: 'Custom' }
  ];
  
  const weekDays = [
    'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'
  ];
  
  const formatDateForSubmission = (date) => {
    if (!date) return null;
    
    // Convert the date to the user's timezone and format with offset
    const momentDate = moment(date).tz(userTimezone);
    return momentDate.format('YYYY-MM-DDTHH:mm:ss.SSS') + 
           momentDate.format('Z').replace(/(\d{2})(\d{2})/, '$1:$2');
  };

  const handleTagInputChange = (e) => {
    const value = e.target.value;
    setTagInput(value);
    if (value.endsWith(' ')) {
      const newTag = value.trim();
      if (newTag && !tags.includes(newTag)) {
        setTags([...tags, newTag]);
      }
      setTagInput('');
    }
  };

const handleSubmit = (e) => {
  e.preventDefault();
  onSubmit({
    id: task?.id,
    title,
    description,
    points_complete: parseInt(pointsComplete, 10),
    points_incomplete: parseInt(pointsIncomplete, 10),
    is_recurring: isRecurring,
    recurrence_pattern: recurrencePattern,
    week_day: weekDay,
    due_date: isRecurring ? 
      (recurrencePattern === 'custom' && customRecurrence.type === 'months' ? 
        formatDateForSubmission(dueDate) : null) 
      : formatDateForSubmission(dueDate),
    require_proof: requireProof,
    send_notification: sendNotification,
    tags: tags,
    gps_check_in: gpsCheckIn,
    submissive_can_complete: submissiveCanComplete,
    custom_recurrence: recurrencePattern === 'custom' ? {
      ...customRecurrence,
      monthDays: customRecurrence.type === 'months' ? undefined : customRecurrence.monthDays,
      start_date: customRecurrence.type === 'months' ? formatDateForSubmission(dueDate) : undefined
    } : null,
    do_at: isScheduled ? formatDateForSubmission(scheduledTime) : null,
    duration: isScheduled ? duration : null,
    duration_unit: isScheduled ? durationUnit : null
  });
  onClose();
};

  const renderActionButtons = () => {
    if (task && task.status === 'pending_approval' && role === 'dominant') {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={() => onDeny && onDeny(task.id)}
          >
            Deny
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => onApprove && onApprove(task.id)}
          >
            Approve
          </Button>
        </Box>
      );
    } else {
      return (
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          {task ? 'Update Task' : (role === 'dominant' ? 'Create Task' : 'Request Task')}
        </Button>
      );
    }
  };

  const handleExampleClick = (example) => {
    const parts = example.text.split(' - ', 2);
    if (parts.length === 2) {
      setTitle(parts[0].trim());
      setDescription(parts[1].trim());
    } else {
      setTitle(example.text.trim());
      setDescription('');
    }
    setTags([...tags, example.category]);
  };

return (
    <form onSubmit={handleSubmit}>
      {!task && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Example Tasks</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ maxHeight: '250px', overflowY: 'auto' }}>
              {Object.entries(examples.tasks.reduce((acc, task) => {
                if (!acc[task.category]) acc[task.category] = [];
                acc[task.category].push(task);
                return acc;
              }, {})).map(([category, tasks]) => (
                <Accordion key={category}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>{category}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {tasks.map((example, index) => (
                        <ListItem key={index} button onClick={() => handleExampleClick(example)}>
                          <ListItemText 
                            primary={example.text.split(' - ', 2)[0]}
                            secondary={example.text.split(' - ', 2)[1] || ''}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      
      <TextField
        fullWidth
        label="Task Name"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        margin="normal"
        required
        multiline
        minRows={1}
        maxRows={4}
      />
      
      <TextField
        fullWidth
        label="Description and details"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        margin="normal"
        multiline
        minRows={2}
        maxRows={20}
      />

      {role === 'dominant' && (
        <>
          <Box sx={{ mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
              <Box sx={{ minWidth: '60px' }}>
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Points</Typography>
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Complete</Typography>
              </Box>
              <Slider
                value={pointsComplete}
                onChange={(_, value) => setPointsComplete(value)}
                step={null}
                marks={POINT_PRESETS.map(point => ({
                  value: point,
                  label: point.toString()
                }))}
                min={POINT_PRESETS[0]}
                max={POINT_PRESETS[POINT_PRESETS.length - 1]}
                sx={{ flex: 1 }}
              />
              <TextField
                type="number"
                value={pointsComplete}
                onChange={(e) => {
                  const value = e.target.value;
                  setPointsComplete(value === '' ? '' : Math.max(0, parseInt(value) || 0));
                }}
                size="small"
                sx={{ width: '60px' }}
                inputProps={{
                  min: 0,
                  max: 9999,
                  inputMode: 'numeric',
                  pattern: '[0-9]*'
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box sx={{ minWidth: '60px' }}>
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Points</Typography>
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>Missed</Typography>
              </Box>
              <Slider
                value={pointsIncomplete}
                onChange={(_, value) => setPointsIncomplete(value)}
                step={null}
                marks={POINT_PRESETS.map(point => ({
                  value: point,
                  label: point.toString()
                }))}
                min={POINT_PRESETS[0]}
                max={POINT_PRESETS[POINT_PRESETS.length - 1]}
                sx={{ flex: 1 }}
              />
              <TextField
                type="number"
                value={pointsIncomplete}
                onChange={(e) => {
                  const value = e.target.value;
                  setPointsIncomplete(value === '' ? '' : Math.max(0, parseInt(value) || 0));
                }}
                size="small"
                sx={{ width: '60px' }}
                inputProps={{
                  min: 0,
                  max: 9999,
                  inputMode: 'numeric',
                  pattern: '[0-9]*'
                }}
              />
            </Box>
          </Box>
        </>
      )}

      {/* Due Date and Recurring section */}
      <Box sx={{ mb: 2, mt: 2 }}>
        {!isRecurring && !isScheduled && (
          renderDateTimePicker(
            dueDate,
            (newValue) => setDueDate(newValue),
            "Due Date",
            true
          )
        )}

      {/* Scheduling section */}
      <Box sx={{ mb: 2 }}>
        <FormControlLabel
          control={
            <Switch
              checked={isScheduled}
              onChange={(e) => {
                setIsScheduled(e.target.checked);
                if (!e.target.checked) {
                  setScheduledTime(null);
                  setDuration(60);
                  setDurationUnit('minutes');
                }
              }}
            />
          }
          label="Schedule"
        />

        {isScheduled && (
          <Box sx={{ mt: 2 }}>
            {renderDateTimePicker(
              scheduledTime,
              (newValue) => setScheduledTime(newValue),
              "Schedule on",
              isScheduled
            )}
            
            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <TextField
                type="number"
                label="Duration"
                value={duration}
                onChange={(e) => setDuration(Math.max(1, parseInt(e.target.value) || 1))}
                inputProps={{ min: 1 }}
                sx={{ flex: 1 }}
              />
              <FormControl sx={{ flex: 1 }}>
                <InputLabel>Unit</InputLabel>
                <Select
                  value={durationUnit}
                  onChange={(e) => setDurationUnit(e.target.value)}
                  label="Unit"
                >
                  <MenuItem value="minutes">Minutes</MenuItem>
                  <MenuItem value="hours">Hours</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}

        <FormControlLabel
          control={
            <Switch
              checked={isRecurring}
              onChange={(e) => setIsRecurring(e.target.checked)}
              disabled={isScheduled}
            />
          }
          label="Recurring Task"
        />

        {isScheduled && isRecurring && (
          <Typography variant="caption" color="error" sx={{ display: 'block', mt: 1 }}>
            Scheduled tasks cannot be recurring
          </Typography>
        )}
      </Box>

      {/* Recurring section */}
      {isRecurring && (
        <>
          <FormControl fullWidth margin="normal">
            <InputLabel>Recurrence Pattern</InputLabel>
            <Select
              value={recurrencePattern}
              onChange={(e) => setRecurrencePattern(e.target.value)}
              label="Recurrence Pattern"
            >
              {recurrenceOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {(recurrencePattern === 'bi-weekly' || recurrencePattern === 'monthly') && (
            renderDateTimePicker(
              dueDate,
              (newValue) => setDueDate(newValue),
              `Select First ${recurrencePattern === 'bi-weekly' ? 'Bi-weekly' : 'Monthly'} Date`,
              true
            )
          )}

          {(recurrencePattern === 'weekly' || recurrencePattern === 'bi-weekly') && (
            <FormControl fullWidth margin="normal">
              <InputLabel>Week Day</InputLabel>
              <Select
                value={weekDay}
                onChange={(e) => setWeekDay(e.target.value)}
                label="Week Day"
              >
                {weekDays.map(day => (
                  <MenuItem key={day} value={day}>
                    {day.charAt(0).toUpperCase() + day.slice(1)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {recurrencePattern === 'custom' && (
            <Box sx={{ mt: 2 }}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Recurrence Type</InputLabel>
                <Select
                  value={customRecurrence.type}
                  onChange={(e) => setCustomRecurrence({
                    ...customRecurrence,
                    type: e.target.value,
                    weekDays: [],
                    monthDays: []
                  })}
                >
                  <MenuItem value="days">Days</MenuItem>
                  <MenuItem value="weeks">Weeks</MenuItem>
                  <MenuItem value="months">Months</MenuItem>
                </Select>
              </FormControl>

              <TextField
                type="number"
                label="Interval"
                value={customRecurrence.interval}
                onChange={(e) => {
                  const value = e.target.value;
                  setCustomRecurrence({
                    ...customRecurrence,
                    interval: value === '' ? '' : Math.max(1, parseInt(value) || 1)
                  });
                }}
                fullWidth
                margin="normal"
                inputProps={{
                  min: 1,
                  inputMode: 'numeric',
                  pattern: '[0-9]*'
                }}
              />

              {customRecurrence.type === 'weeks' && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Week Days</InputLabel>
                  <Select
                    multiple
                    value={customRecurrence.weekDays}
                    onChange={(e) => setCustomRecurrence({
                      ...customRecurrence,
                      weekDays: e.target.value
                    })}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {weekDays.map(day => (
                      <MenuItem key={day} value={day}>
                        <Checkbox checked={customRecurrence.weekDays.indexOf(day) > -1} />
                        <ListItemText primary={day.charAt(0).toUpperCase() + day.slice(1)} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {customRecurrence.type === 'months' && (
                renderDateTimePicker(
                  dueDate,
                  (newValue) => setDueDate(newValue),
                  "Select First Date",
                  true
                )
              )}
            </Box>
          )}
        </>
      )}
      </Box>

      {role === 'dominant' && (
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Advanced Options</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={1}>
              <FormControlLabel
                control={<Switch checked={requireProof} onChange={e => setRequireProof(e.target.checked)} />}
                label="Photo/video Proof Required"
              />
              <FormControlLabel
                control={<Switch checked={gpsCheckIn} onChange={e => setGpsCheckIn(e.target.checked)} />}
                label="GPS Check-in"
              />
              <FormControlLabel
                control={<Switch checked={sendNotification} onChange={e => setSendNotification(e.target.checked)} />}
                label="Send Notification"
              />
              <FormControlLabel
                control={<Switch checked={submissiveCanComplete} onChange={e => setSubmissiveCanComplete(e.target.checked)} />}
                label="Proof Not Required"
              />
            </Stack>
          </AccordionDetails>
        </Accordion>
      )}

      <TextField
        fullWidth
        label="Tags"
        value={tagInput}
        onChange={handleTagInputChange}
        margin="normal"
        helperText="Type a tag and press space to add it"
        name="tags"
      />

      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 2 }}>
        {tags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={() => setTags(tags.filter((t, i) => i !== index))}
            color="primary"
            variant="outlined"
          />
        ))}
      </Box>

      {renderActionButtons()}
    </form>
  );
};

const TaskListItem = ({ task, role, onEdit, onDelete, onAssign, onApprove, onDeny, onComplete, getTaskStatusColor, showDate }) => {
  const [expanded, setExpanded] = useState(false);
  const handleToggle = () => setExpanded(!expanded);

  return (
    <ListItem
      alignItems="flex-start"
      disablePadding
      sx={{ borderBottom: 1, borderColor: 'divider', py: 0.5 }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 1, py: 0.25 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              variant="body2"
              sx={{
                cursor: 'pointer',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                lineHeight: 1.2,
              }}
              onClick={handleToggle}
            >
              {task.title}
              {showDate && (
                <Typography variant="caption" sx={{ ml: 1, color: 'text.secondary' }}>
                  {new Date(task.due_date).toLocaleTimeString()}
                </Typography>
              )}
            </Typography>
            {role === 'dominant' && (
              <Typography sx={{ color: 'text.secondary', fontSize: '0.5rem' }}>
                Points: {task.points_complete} / {task.points_incomplete}
              </Typography>
            )}
            {task.is_recurring && <CompletionDots completionHistory={task.completion_history} />}
          </Box>
          {task.gps_check_in && (
            <Tooltip title="GPS Check-in Required">
              <LocationOn fontSize="small" color="primary" id="gps-indicator" />
            </Tooltip>
          )}
          {task.is_recurring && (
            <Tooltip title="Recurring Task">
              <AutorenewRounded fontSize="small" color="primary" id="recurring-tasks" />
            </Tooltip>
          )}
          {task.submissive_can_complete && (
            <Tooltip title="Submissive Can Complete">
              <CheckCircleOutline fontSize="small" color="primary" id="auto-complete-indicator" />
            </Tooltip>
          )}
          {role === 'dominant' && (
            <>
              {task.status === 'pending_approval' ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onEdit(task)}
                  sx={{ mr: 1 }}
                >
                  Review
                </Button>
              ) : (
                <>
                  <IconButton size="small" onClick={() => onEdit(task)}><EditIcon fontSize="small" /></IconButton>
                  <IconButton size="small" onClick={() => onDelete(task.id)}><DeleteIcon fontSize="small" /></IconButton>
                  {!task.assigned && (
                    <IconButton size="small" onClick={() => onAssign(task.id)}><AssignIcon fontSize="small" /></IconButton>
                  )}
                </>
              )}
            </>
          )}
          <IconButton size="small" onClick={handleToggle}>
            {expanded ? <ExpandLessIcon /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={{ 
            py: 0.5,
            px: 1,
            '& .MuiTypography-body2': {
              lineHeight: 1.2
            }
          }}>
            <Typography variant="body2">{task.description}</Typography>
            <Box sx={{ mt: 0.5 }}>
              <Typography variant="body2">Due: {new Date(task.due_date).toLocaleString()}</Typography>
              {role === 'dominant' && (
                <Typography variant="body2">
                  Points: Complete={task.points_complete} / Incomplete={task.points_incomplete}
                </Typography>
              )}
              <Typography variant="body2">
                Status: <span style={{ color: getTaskStatusColor(task.status) }}>
                  {formatTaskStatus(task.status)}
                </span>
              </Typography>
            </Box>
            <Box sx={{ mt: 0.5 }}>
              {task.tags && task.tags.map((tag, index) => (
                <Chip 
                key={index} 
                label={tag} 
                size="small" 
                sx={{ 
                    mr: 0.5, 
                    mt: 0.5,
                    height: '20px',
                }} 
                />
              ))}
            </Box>
            {role === 'submissive' && task.can_complete && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => onComplete(task)}
                sx={{ mt: 0.5 }}
                className="complete-task-button"
              >
                Complete
              </Button>
            )}
          </Box>
        </Collapse>
      </Box>
    </ListItem>
  );
};

const CompletionForm = ({ show, handleClose, handleSubmit, item, itemType, requireProof }) => {
  const [proof, setProof] = useState(null);
  const [note, setNote] = useState('');
  const [enjoymentRating, setEnjoymentRating] = useState(0);
  const [difficultyRating, setDifficultyRating] = useState(0);
  const [humiliationRating, setHumiliationRating] = useState(0);
  const [timerDuration, setTimerDuration] = useState(0);
  const [location, setLocation] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('id', item.id);
    formData.append('type', itemType);
    if (proof) formData.append('proof', proof);
    const finalNote = item.tags && item.tags.includes('exercise')
      ? `${note}\nExercise duration: ${formatTime(timerDuration)}`
      : note;
    formData.append('note', finalNote);
    formData.append('enjoyment_rating', enjoymentRating);
    formData.append('difficulty_rating', difficultyRating);
    formData.append('humiliation_rating', humiliationRating);
    if (item.tags && item.tags.includes('exercise') && timerDuration > 0) {
      formData.append('timer_duration', timerDuration);
    }
    if (location) {
      formData.append('latitude', location.latitude);
      formData.append('longitude', location.longitude);
    }
    handleSubmit(formData);
  };

  useEffect(() => {
    if (item && item.gps_check_in) {
      navigator.serviceWorker.controller.postMessage({ type: 'GET_LOCATION' });
  
      const handleMessage = (event) => {
        if (event.data && event.data.type === 'LOCATION') {
          setLocation(event.data);
        } else if (event.data && event.data.type === 'LOCATION_ERROR') {
          console.error('Error getting location:', event.data.error);
        }
      };
  
      navigator.serviceWorker.addEventListener('message', handleMessage);
  
      return () => {
        navigator.serviceWorker.removeEventListener('message', handleMessage);
      };
    }
  }, [item]);


  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle>Submit Completion</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          {requireProof && (
            <TextField
              type="file"
              accept="image/*,video/*"
              onChange={(e) => setProof(e.target.files[0])}
              fullWidth
              margin="normal"
              required
            />
          )}
          {item && item.tags && item.tags.includes('exercise') && (
            <Timer onTimerUpdate={setTimerDuration} />
          )}
          <TextField
            label="Note"
            multiline
            rows={3}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            fullWidth
            margin="normal"
          />
          {item && item.gps_check_in && (
            <Box mt={2}>
              <Typography>GPS Location:</Typography>
              {location ? (
                <Typography>
                  Latitude: {location.latitude}, Longitude: {location.longitude}
                </Typography>
              ) : (
                <Typography>Fetching location...</Typography>
              )}
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Typography>Enjoyment:</Typography>
            <Rating
              name="enjoyment"
              value={enjoymentRating}
              onChange={(event, newValue) => setEnjoymentRating(newValue)}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography>Difficulty:</Typography>
            <Rating
              name="difficulty"
              value={difficultyRating}
              onChange={(event, newValue) => setDifficultyRating(newValue)}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography>Humiliation:</Typography>
            <Rating
              name="humiliation"
              value={humiliationRating}
              onChange={(event, newValue) => setHumiliationRating(newValue)}
            />
          </Box>
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            Submit Completion
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Tasks = ({ role, kinks }) => {
  const queryClient = useQueryClient();
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [sortBy, setSortBy] = useState('dueGroups');
  const [groupBy, setGroupBy] = useState('dueDate');
  const [tags, setTags] = useState([]);
  const [showTickTickImport, setShowTickTickImport] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [userTimezone, setUserTimezone] = useState(() => {
    try {
      return moment.tz.guess() || 'UTC';
    } catch (e) {
      console.warn('Error guessing timezone, defaulting to UTC:', e);
      return 'UTC';
    }
  });

  const { data: tasks = [], isLoading } = useQuery({
    queryKey: ['tasks'],
    queryFn: async () => {
      const response = await axiosInstance.get('/tasks');
      return response.data.map(task => ({
        ...task,
        due_date: task.due_date ? new Date(task.due_date) : null,
        tags: task.tags || []
      }));
    },
    staleTime: 30000,
    cacheTime: 3600000,
  });

  useEffect(() => {
    const fetchTimezone = async () => {
      try {
        const response = await axiosInstance.get('/timezone');
        if (response.data.timezone) {
          setUserTimezone(response.data.timezone);
        }
      } catch (error) {
        console.error('Error fetching timezone:', error);
      }
    };
    fetchTimezone();
  }, []);

  useEffect(() => {
    const allTags = [...new Set(tasks.flatMap(task => task.tags || []))];
    setTags(allTags);
  }, [tasks]);

  const formatDateForSubmission = (date) => {
    if (!date) return null;
    const momentDate = moment(date).tz(userTimezone);
    return momentDate.format('YYYY-MM-DDTHH:mm:ss.SSS') + 
           momentDate.format('Z').replace(/(\d{2})(\d{2})/, '$1:$2');
  };

const { mutate: updateTask } = useMutation({
  mutationFn: async (taskData) => {
    const formattedData = {
      ...taskData,
      points_complete: Number(taskData.points_complete),
      points_incomplete: Number(taskData.points_incomplete),
      due_date: formatDateForSubmission(taskData.due_date),
      do_at: taskData.do_at ? formatDateForSubmission(taskData.do_at) : null
    };
    
    const response = await axiosInstance.put(`/tasks/${taskData.id}`, formattedData);
    return response.data;
  },
  onMutate: async (updatedTask) => {
    // Cancel any outgoing refetches
    await queryClient.cancelQueries({ queryKey: ['tasks'] });
    
    // Snapshot the previous value
    const previousTasks = queryClient.getQueryData(['tasks']);

    // Optimistically update to the new value
    queryClient.setQueryData(['tasks'], old => {
      if (!old) return old;
      return old.map(task => {
        if (task.id === updatedTask.id) {
          return {
            ...task,
            ...updatedTask,
            points_complete: Number(updatedTask.points_complete),
            points_incomplete: Number(updatedTask.points_incomplete),
            due_date: updatedTask.due_date ? new Date(updatedTask.due_date) : null,
            // Ensure all other fields are preserved
            tags: updatedTask.tags || task.tags,
            assigned: updatedTask.assigned !== undefined ? updatedTask.assigned : task.assigned,
            status: updatedTask.status || task.status
          };
        }
        return task;
      });
    });

    return { previousTasks };
  },
  onError: (err, updatedTask, context) => {
    // Revert to the previous state on error
    queryClient.setQueryData(['tasks'], context.previousTasks);
    setErrorMessage('Failed to update task. Please try again.');
  },
  onSuccess: (responseData) => {
    // Properly update the cache with the server response
    queryClient.setQueryData(['tasks'], old => {
      if (!old) return old;
      return old.map(task => {
        if (task.id === responseData.id) {
          // Create a properly formatted task object from the response
          const updatedTask = {
            ...task, // Keep any client-side only fields
            ...responseData, // Override with server response
            points_complete: Number(responseData.points_complete),
            points_incomplete: Number(responseData.points_incomplete),
            due_date: responseData.due_date ? new Date(responseData.due_date) : null,
            tags: responseData.tags || [],
            completion_history: responseData.completion_history || task.completion_history || []
          };
          return updatedTask;
        }
        return task;
      });
    });
    
    // Close modal and clear selection
    setShowTaskModal(false);
    setSelectedItem(null);
  },
  onSettled: () => {
    // Always refetch after error or success to ensure we're in sync
    queryClient.invalidateQueries({ queryKey: ['tasks'] });
  }
});

  const { mutate: approveTask } = useMutation({
    mutationFn: async (taskId) => {
      const response = await axiosInstance.post(`/tasks/${taskId}/approve`);
      // Log the response to debug
      console.log('Approve task response:', response.data);
      return response.data;
    },
    onMutate: async (taskId) => {
      await queryClient.cancelQueries({ queryKey: ['tasks'] });
      const previousTasks = queryClient.getQueryData(['tasks']);

      // Log the task being approved
      console.log('Approving task:', taskId);
      console.log('Current tasks:', previousTasks);

      queryClient.setQueryData(['tasks'], old => 
        old.map(task => {
          if (task.id === taskId) {
            console.log('Updating task:', task.id);
            return {
              ...task,
              status: 'incomplete',
              assigned: true
            };
          }
          return task;
        })
      );

      return { previousTasks };
    },
    onError: (err, taskId, context) => {
      console.error('Approve task error:', err);
      queryClient.setQueryData(['tasks'], context.previousTasks);
      setErrorMessage('Failed to approve task. Please try again.');
    },
    onSuccess: (responseData) => {
      console.log('Approve task success:', responseData);
      queryClient.setQueryData(['tasks'], old => 
        old.map(task => {
          if (task.id === responseData.id) {
            console.log('Updating task with response:', responseData);
            return {
              ...task,
              ...responseData,
              due_date: responseData.due_date ? new Date(responseData.due_date) : null
            };
          }
          return task;
        })
      );
      setShowTaskModal(false);
      setErrorMessage('Task approved successfully');
    },
    onSettled: () => {
      console.log('Approve task settled');
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
    }
  });

const { mutate: deleteTask } = useMutation({
    mutationFn: async (taskId) => {
      const response = await axiosInstance.delete(`/tasks/${taskId}`);
      return response.data;
    },
    onMutate: async (deletedTaskId) => {
      await queryClient.cancelQueries({ queryKey: ['tasks'] });
      const previousTasks = queryClient.getQueryData(['tasks']);

      queryClient.setQueryData(['tasks'], old => 
        old.filter(task => task.id !== deletedTaskId)
      );

      return { previousTasks };
    },
    onError: (err, deletedTaskId, context) => {
      queryClient.setQueryData(['tasks'], context.previousTasks);
      setErrorMessage('Failed to delete task. Please try again.');
    },
    onSettled: () => {
      // Always refetch after error or success
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
    }
  });

const { mutate: denyTask } = useMutation({
    mutationFn: async (taskId) => {
      const response = await axiosInstance.post(`/tasks/${taskId}/deny`);
      // Log the response to debug
      console.log('Deny task response:', response.data);
      return response.data;
    },
    onMutate: async (taskId) => {
      await queryClient.cancelQueries({ queryKey: ['tasks'] });
      const previousTasks = queryClient.getQueryData(['tasks']);

      console.log('Denying task:', taskId);
      
      // Optimistically update UI to remove the task
      queryClient.setQueryData(['tasks'], old => 
        old.filter(task => task.id !== taskId)
      );

      return { previousTasks };
    },
    onError: (err, taskId, context) => {
      console.error('Deny task error:', err);
      queryClient.setQueryData(['tasks'], context.previousTasks);
      setErrorMessage('Failed to deny task. Please try again.');
    },
    onSuccess: (responseData) => {
      console.log('Deny task success:', responseData);
      setShowTaskModal(false);
      setErrorMessage('Task denied successfully');
    },
    onSettled: () => {
      console.log('Deny task settled');
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
    }
  });

  const { mutate: submitCompletion } = useMutation({
    mutationFn: async (formData) => {
      await axiosInstance.post('/completions', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    },
    onMutate: async (formData) => {
      await queryClient.cancelQueries({ queryKey: ['tasks'] });
      const previousTasks = queryClient.getQueryData(['tasks']);
      const taskId = formData.get('id');

      queryClient.setQueryData(['tasks'], old => 
        old.map(task => 
          task.id === taskId ? {
            ...task,
            status: 'submitted'
          } : task
        )
      );

      return { previousTasks };
    },
    onError: (err, formData, context) => {
      queryClient.setQueryData(['tasks'], context.previousTasks);
      setErrorMessage('Failed to complete task. Please try again.');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
      setShowCompletionModal(false);
      setSelectedItem(null);
      setErrorMessage('Task completion submitted successfully');
    }
  });

  const { mutate: assignTask } = useMutation({
    mutationFn: async (taskId) => {
      await axiosInstance.post(`/tasks/${taskId}/assign`);
    },
    onMutate: async (taskId) => {
      await queryClient.cancelQueries({ queryKey: ['tasks'] });
      const previousTasks = queryClient.getQueryData(['tasks']);

      queryClient.setQueryData(['tasks'], old => 
        old.map(task => 
          task.id === taskId ? {
            ...task,
            assigned: true,
            status: 'assigned'
          } : task
        )
      );

      return { previousTasks };
    },
    onError: (err, taskId, context) => {
      queryClient.setQueryData(['tasks'], context.previousTasks);
      setErrorMessage('Failed to assign task. Please try again.');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
    }
  });

  const { mutate: createTask } = useMutation({
    mutationFn: async (taskData) => {
      const formattedData = {
        ...taskData,
        due_date: formatDateForSubmission(taskData.due_date),
        do_at: taskData.do_at ? formatDateForSubmission(taskData.do_at) : null
      };
      const response = await axiosInstance.post('/tasks', formattedData);
      return response.data;
    },
    onMutate: async (newTask) => {
      await queryClient.cancelQueries({ queryKey: ['tasks'] });
      const previousTasks = queryClient.getQueryData(['tasks']);

      queryClient.setQueryData(['tasks'], old => {
        const optimisticTask = {
          ...newTask,
          id: `temp-${Date.now()}`,
          due_date: newTask.due_date ? new Date(newTask.due_date) : null,
          tags: newTask.tags || [],
          status: 'incomplete'
        };
        return [...(old || []), optimisticTask];
      });

      return { previousTasks };
    },
    onError: (err, newTask, context) => {
      queryClient.setQueryData(['tasks'], context.previousTasks);
      setErrorMessage('Failed to create task. Please try again.');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
      setShowTaskModal(false);
      setSelectedItem(null);
    }
  });

  const handleCreateOrEditTask = (taskData) => {
    if (taskData.id) {
      updateTask(taskData);
    } else {
      createTask(taskData);
    }
  };

  const handleDeleteTask = (taskId) => {
    deleteTask(taskId);
  };

  const handleApproveTask = (taskId) => {
    approveTask(taskId);
  };

  const handleAssignTask = (taskId) => {
    assignTask(taskId);
  };

  const handleDenyTask = (taskId) => {
    denyTask(taskId);
  };

  const handleCompleteTask = (task) => {
    setSelectedItem({...task, itemType: 'task'});
    setShowCompletionModal(true);
  };

  const handleSubmitCompletion = (formData) => {
    submitCompletion(formData);
  };

  const renderDateTimePicker = (value, onChange, label, required = false) => (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        label={label}
        value={value}
        onChange={onChange}
        timeZone={userTimezone}
        renderInput={(params) => (
          <TextField 
            {...params} 
            fullWidth 
            margin="normal" 
            required={required}
          />
        )}
      />
    </LocalizationProvider>
  );

  const getNextDueDate = (task) => {
      if (!task || !task.due_date) return null;
      
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      if (task.is_recurring) {
          switch (task.recurrence_pattern) {
              case 'daily':
                  return today;
              case 'weekly':
              case 'bi-weekly':
                  const daysUntilWeekDay = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].indexOf(task.week_day.toLowerCase()) - today.getDay();
                  const daysToAdd = daysUntilWeekDay >= 0 ? daysUntilWeekDay : daysUntilWeekDay + 7;
                  const nextDate = new Date(today.getTime() + daysToAdd * 24 * 60 * 60 * 1000);
                  if (task.recurrence_pattern === 'bi-weekly' && daysToAdd < 7) {
                      nextDate.setDate(nextDate.getDate() + 7);
                  }
                  return nextDate;
              case 'monthly':
                  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                  return nextMonth;
              default:
                  return new Date(task.due_date);
          }
      } else {
          return new Date(task.due_date);
      }
  };

  const sortTasks = (taskList) => {
    return [...taskList].sort((a, b) => {
      if (sortBy === 'name') {
        return a.title.localeCompare(b.title);
      } else if (sortBy === 'dueDate') {
        const dateA = getNextDueDate(a);
        const dateB = getNextDueDate(b);
        return dateA - dateB;
      }
      return 0;
    });
  };

  const groupTasks = (tasks) => {
    if (groupBy === 'dueDate') {
      const now = new Date();
      const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
      const endOfWeek = new Date(now.getTime() + (6 - now.getDay()) * 24 * 60 * 60 * 1000);
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  
      return {
        'Due Today': tasks.filter(task => getNextDueDate(task) <= endOfDay),
        'Due This Week': tasks.filter(task => {
          const dueDate = getNextDueDate(task);
          return dueDate > endOfDay && dueDate <= endOfWeek;
        }),
        'Due This Month': tasks.filter(task => {
          const dueDate = getNextDueDate(task);
          return dueDate > endOfWeek && dueDate <= endOfMonth;
        }),
        'Due Later': tasks.filter(task => getNextDueDate(task) > endOfMonth)
      };
    } else if (groupBy === 'tag') {
      return tasks.reduce((acc, task) => {
        task.tags.forEach(tag => {
          if (!acc[tag]) acc[tag] = [];
          acc[tag].push(task);
        });
        return acc;
      }, {});
    } else if (groupBy === 'status') {
      return tasks.reduce((acc, task) => {
        if (!acc[task.status]) acc[task.status] = [];
        acc[task.status].push(task);
        return acc;
      }, {});
    }
    return { 'All Tasks': tasks };
  };

  const filterTasks = (tasks, status) => {
    const now = new Date();
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
    const endOfWeek = new Date(now.getTime() + (6 - now.getDay()) * 24 * 60 * 60 * 1000);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    return tasks.filter(task => {
      if (task.status === 'pending_approval') return false;
      const dueDate = getNextDueDate(task);
      switch (status) {
        case 'dueToday':
          return dueDate <= endOfDay;
        case 'dueThisWeek':
          return dueDate > endOfDay && dueDate <= endOfWeek;
        case 'dueThisMonth':
          return dueDate > endOfWeek && dueDate <= endOfMonth;
        default:
          return true;
      }
    });
  };

  if (isLoading) return <CircularProgress />;

  const pendingApprovalTasks = tasks.filter(task => task.status === 'pending_approval');
  const dueTodayTasks = filterTasks(tasks, 'dueToday');
  const dueThisWeekTasks = filterTasks(tasks, 'dueThisWeek');
  const dueThisMonthTasks = filterTasks(tasks, 'dueThisMonth');

  const renderTaskList = (taskList, showDate = false) => (
    <List sx={{ py: 0 }}>
      {sortTasks(taskList).map(task => (
        <TaskListItem
          key={task.id}
          task={task}
          role={role}
          showDate={showDate}
          onEdit={(task) => {
            setSelectedItem(task);
            setShowTaskModal(true);
          }}
          onDelete={handleDeleteTask}
          onAssign={handleAssignTask}
          onApprove={handleApproveTask}
          onDeny={handleDenyTask}
          onComplete={handleCompleteTask}
          getTaskStatusColor={getTaskStatusColor}
        />
      ))}
    </List>
  );

  const renderGroupedTasks = () => {
    const groupedTasks = groupTasks(tasks);
    return Object.entries(groupedTasks).map(([group, tasks]) => (
      <Accordion key={group}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">{group}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {renderTaskList(tasks)}
        </AccordionDetails>
      </Accordion>
    ));
  };

const renderDueGroups = () => {
  const now = new Date();
  const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
  const endOfWeek = new Date(now.getTime() + (6 - now.getDay()) * 24 * 60 * 60 * 1000);
  const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  const isTaskDue = (task) => {
    // For non-recurring tasks, just check if incomplete
    if (!task.is_recurring) {
      return task.status === 'incomplete' || task.status === 'assigned';
    }

    // For recurring tasks that are marked complete, check if they were completed before today
    if (task.status === 'completed') {
      const lastCompletedDate = new Date(task.last_completed);
      lastCompletedDate.setHours(0, 0, 0, 0);
      return lastCompletedDate < startOfDay;
    }

    // For incomplete recurring tasks, include them
    return true;
  };

  const dueTodayTasks = tasks.filter(task => {
    const dueDate = getNextDueDate(task);
    return task.status !== 'pending_approval' && 
           isTaskDue(task) &&
           dueDate >= startOfDay && 
           dueDate <= endOfDay;
  });

  const dueThisWeekTasks = tasks.filter(task => {
    const dueDate = getNextDueDate(task);
    return task.status !== 'pending_approval' &&
           isTaskDue(task) &&
           dueDate > endOfDay && 
           dueDate <= endOfWeek;
  });

  const dueThisMonthTasks = tasks.filter(task => {
    const dueDate = getNextDueDate(task);
    return task.status !== 'pending_approval' &&
           isTaskDue(task) &&
           dueDate > endOfWeek && 
           dueDate <= endOfMonth;
  });

  const dueLaterTasks = tasks.filter(task => {
    const dueDate = getNextDueDate(task);
    return task.status !== 'pending_approval' &&
           isTaskDue(task) &&
           dueDate > endOfMonth;
  });

  const groups = [
    { title: "Due Today", tasks: dueTodayTasks },
    { title: "Due This Week", tasks: dueThisWeekTasks },
    { title: "Due This Month", tasks: dueThisMonthTasks },
    { title: "Due Later", tasks: dueLaterTasks },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {role === 'dominant' && pendingApprovalTasks.length > 0 && (
        <Paper 
          elevation={1} 
          sx={{ 
            borderRadius: 2,
            overflow: 'hidden'
          }}
        >
          <Accordion defaultExpanded sx={{ boxShadow: 'none' }}>
            <AccordionSummary 
              expandIcon={<ExpandMore />}
              sx={{ 
                py: 0.25,
                px: 2,
                minHeight: 'unset',
                '& .MuiAccordionSummary-content': {
                  my: 0
                }
              }}
            >
              <Typography variant="h6" sx={{ fontSize: '1.1rem' }}>Pending Approval</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 1, py: 0 }}>
              {renderTaskList(pendingApprovalTasks)}
            </AccordionDetails>
          </Accordion>
        </Paper>
      )}
      
      {groups.map(group => (
        group.tasks.length > 0 && (
          <Paper 
            key={group.title} 
            elevation={1}
            sx={{ 
              borderRadius: 2,
              overflow: 'hidden'
            }}
          >
            <Accordion defaultExpanded sx={{ boxShadow: 'none' }}>
              <AccordionSummary 
                expandIcon={<ExpandMore />}
                sx={{ 
                  py: 0.25,
                  px: 2,
                  '& .MuiAccordionSummary-content': {
                    my: 0
                  }
                }}
              >
                <Typography variant="h6" sx={{ fontSize: '1.1rem' }}>{group.title}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ px: 1, py: 0 }}>
                {renderTaskList(group.tasks)}
              </AccordionDetails>
            </Accordion>
          </Paper>
        )
      ))}
    </Box>
  );
};

const renderSortedTasks = () => {
  if (sortBy === 'dueDate') {
    // Group tasks by due date
    const tasksByDate = tasks.reduce((acc, task) => {
      const dueDate = getNextDueDate(task);
      if (!dueDate) return acc;
      
      const dateStr = dueDate.toLocaleDateString();
      if (!acc[dateStr]) acc[dateStr] = [];
      acc[dateStr].push(task);
      return acc;
    }, {});

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {Object.entries(tasksByDate)
          .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
          .map(([date, dateTasks]) => (
            <Paper 
              key={date}
              elevation={1}
              sx={{ 
                borderRadius: 2,
                overflow: 'hidden'
              }}
            >
              <Accordion defaultExpanded sx={{ boxShadow: 'none' }}>
                <AccordionSummary 
                  expandIcon={<ExpandMore />}
                  sx={{ 
                    py: 0.25,
                    px: 2,
                    '& .MuiAccordionSummary-content': {
                      my: 0
                    }
                  }}
                >
                  <Typography variant="h6" sx={{ fontSize: '1.1rem' }}>{date}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ px: 1, py: 0 }}>
                  {renderTaskList(dateTasks)}
                </AccordionDetails>
              </Accordion>
            </Paper>
          ))}
      </Box>
    );
  } else if (sortBy === 'alphabetical') {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Paper 
          elevation={1}
          sx={{ 
            borderRadius: 2,
            overflow: 'hidden'
          }}
        >
          <Accordion defaultExpanded sx={{ boxShadow: 'none' }}>
            <AccordionSummary 
              expandIcon={<ExpandMore />}
              sx={{ 
                py: 0.25,
                px: 2,
                '& .MuiAccordionSummary-content': {
                  my: 0
                }
              }}
            >
              <Typography variant="h6" sx={{ fontSize: '1.1rem' }}>All Tasks</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 1, py: 0 }}>
              {renderTaskList(tasks)}
            </AccordionDetails>
          </Accordion>
        </Paper>
      </Box>
    );
  } else if (sortBy === 'tag') {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {tags.map(tag => (
          <Paper 
            key={tag}
            elevation={1}
            sx={{ 
              borderRadius: 2,
              overflow: 'hidden'
            }}
          >
            <Accordion defaultExpanded sx={{ boxShadow: 'none' }}>
              <AccordionSummary 
                expandIcon={<ExpandMore />}
                sx={{ 
                  py: 0.25,
                  px: 2,
                  '& .MuiAccordionSummary-content': {
                    my: 0
                  }
                }}
              >
                <Typography variant="h6" sx={{ fontSize: '1.1rem' }}>{tag}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ px: 1, py: 0 }}>
                {renderTaskList(tasks.filter(task => task.tags.includes(tag)))}
              </AccordionDetails>
            </Accordion>
          </Paper>
        ))}
      </Box>
    );
  }
};

  return (
    <Container maxWidth={false} sx={{ px: '20px' }}>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={2000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Sort By</InputLabel>
        <Select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          label="Sort By"
        >
          <MenuItem value="dueGroups">Due Groups</MenuItem>
          <MenuItem value="dueDate">Due Date</MenuItem>
          <MenuItem value="tag">Tag</MenuItem>
          <MenuItem value="alphabetical">Alphabetical</MenuItem>
        </Select>
      </FormControl>

      {tasks.length === 0 && (
        <Typography variant="body1">No tasks available, get started by adding one or check out the examples.</Typography>
      )}

      {sortBy === 'dueGroups' ? renderDueGroups() : renderSortedTasks()}

      <Dialog
        open={showTaskModal}
        onClose={() => {
          setShowTaskModal(false);
          setSelectedItem(null);
        }}
        fullScreen={isMobile}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            ...(isMobile && {
              position: 'fixed',
              bottom: 0,
              maxHeight: 'calc(100% - 100px)',
              height: 'auto',
              borderRadius: 0,
            })
          }
        }}
      >
        <Box sx={{ 
          position: 'sticky', 
          top: 0, 
          bgcolor: 'background.paper',
          zIndex: 1,
          borderBottom: 1,
          borderColor: 'divider'
        }}>
          <DialogTitle sx={{ pr: 6 }}> {/* Add padding for close button */}
            {selectedItem
              ? selectedItem.status === 'pending_approval'
                ? 'Review Task Request'
                : 'Edit Task'
              : role === 'dominant'
              ? 'New Task'
              : 'Request Task'}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowTaskModal(false);
              setSelectedItem(null);
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent sx={{ 
          ...(isMobile && {
            pb: '72px', // Add padding at bottom for navigation bar
          })
        }}>
      <TaskForm
        onSubmit={handleCreateOrEditTask}
        onClose={() => {
          setShowTaskModal(false);
          setSelectedItem(null);
        }}
        task={selectedItem}
        role={role}
        kinks={kinks}
        onApprove={handleApproveTask}
        onDeny={handleDenyTask}
        renderDateTimePicker={renderDateTimePicker}
        userTimezone={userTimezone}
      />
        </DialogContent>
      </Dialog>

      <CompletionForm
        show={showCompletionModal}
        handleClose={() => {
          setShowCompletionModal(false);
          setSelectedItem(null);
        }}
        handleSubmit={handleSubmitCompletion}
        item={selectedItem}
        itemType={selectedItem?.itemType}
        requireProof={selectedItem?.require_proof}
      />

<Box sx={{ position: 'fixed', bottom: 90, right: 16, display: 'flex', flexDirection: 'column', gap: 1 }}>
  {role === 'dominant' && (
    <Fab 
      color="secondary"
      aria-label="import from TickTick"
      onClick={() => setShowTickTickImport(true)}
      sx={{ mb: 1 }}
    >
      ✓
    </Fab>
  )}
  <Fab 
    color="primary" 
    aria-label="add" 
    onClick={() => setShowTaskModal(true)}
    id="create-task-button"
  >
    <Add />
  </Fab>
</Box>

  {showTickTickImport && (
    <TickTickImport 
      onClose={() => setShowTickTickImport(false)}
      onTasksImported={() => {
        queryClient.invalidateQueries('tasks');
        setErrorMessage('Tasks imported successfully');
      }}
      role={role}
    />
  )}
    </Container>
  );
};

export default Tasks;

TaskForm.defaultProps = {
  onSubmit: () => {},
  onClose: () => {},
  task: null,
  defaultDateTime: null,
  role: 'submissive'
};

export {
  CompletionForm,
  TaskForm,
};