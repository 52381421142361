import axios from 'axios';
import config from './config';
import { useEffect } from 'react';

// Create a custom axios instance
const axiosInstance = axios.create({
  baseURL: config.API_BASE_URL,
  timeout: 10000,
});

export function isTokenExpired(token) {
  try {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    // Add a 30-day buffer (in milliseconds)
    const thirtyDays = 30 * 24 * 60 * 60 * 1000;
    return decodedToken.exp * 1000 < (Date.now() - thirtyDays);
  } catch (error) {
    console.error("Invalid token format", error);
    return true;
  }
}

let refreshTimeoutId = null;
let isRefreshing = false;
let lastRefreshTime = 0;
const MINIMUM_REFRESH_INTERVAL = 5000; // 5 seconds minimum between refreshes

const clearTokenRefresh = () => {
    if (refreshTimeoutId) {
        clearTimeout(refreshTimeoutId);
        refreshTimeoutId = null;
    }
};

const setupTokenRefresh = () => {
    console.log('Setting up token refresh');
    
    // Prevent rapid consecutive calls
    const now = Date.now();
    if (now - lastRefreshTime < MINIMUM_REFRESH_INTERVAL) {
        console.log('Skipping refresh - too soon since last refresh');
        return;
    }

    if (isRefreshing) {
        console.log('Token refresh already in progress');
        return;
    }
    
    // Clear any existing timeout
    clearTokenRefresh();

    const token = localStorage.getItem('token');
    if (!token) {
        console.log('No token found');
        return;
    }

    try {
        isRefreshing = true;
        const tokenData = JSON.parse(atob(token.split('.')[1]));
        const expirationTime = tokenData.exp * 1000;
        const currentTime = Date.now();
        const timeUntilRefresh = expirationTime - currentTime - (24 * 60 * 60 * 1000);
        
        console.log(`Time until refresh: ${timeUntilRefresh}ms`);
        
        if (timeUntilRefresh > 0) {
            console.log('Setting timeout for token refresh');
            refreshTimeoutId = setTimeout(async () => {
                try {
                    console.log('Executing token refresh');
                    const response = await axiosInstance.post('/refresh-token');
                    const newToken = response.data.access_token;
                    localStorage.setItem('token', newToken);
                    lastRefreshTime = Date.now();
                    isRefreshing = false;
                    // Don't call setupTokenRefresh here anymore
                } catch (error) {
                    console.error('Token refresh failed:', error);
                    localStorage.removeItem('token');
                    localStorage.removeItem('role');
                    window.location.href = '/';
                } finally {
                    isRefreshing = false;
                }
            }, timeUntilRefresh);
        } else {
            console.log('Token already needs refresh');
            isRefreshing = false;
        }
    } catch (error) {
        console.error('Error setting up token refresh:', error);
        isRefreshing = false;
    }
};

// Modify the response interceptor as well
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (originalRequest.url.includes('/ticktick/')) {
            return Promise.reject(error);
        }

        if (error.response?.status === 401 && !originalRequest._retry && !isRefreshing) {
            originalRequest._retry = true;
            
            const now = Date.now();
            if (now - lastRefreshTime < MINIMUM_REFRESH_INTERVAL) {
                return Promise.reject(error);
            }

            try {
                isRefreshing = true;
                const response = await axiosInstance.post('/refresh-token');
                const newToken = response.data.access_token;
                localStorage.setItem('token', newToken);
                originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                lastRefreshTime = now;
                setupTokenRefresh(); 
                return axiosInstance(originalRequest);
            } catch (refreshError) {
                localStorage.removeItem('token');
                localStorage.removeItem('role');
                window.location.href = '/';
                return Promise.reject(refreshError);
            } finally {
                isRefreshing = false;
            }
        }

        return Promise.reject(error);
    }
);

// Create a separate axios instance for video streaming that won't timeout as quickly
const videoAxiosInstance = axios.create({
  baseURL: config.API_BASE_URL,
  timeout: 0, // No timeout for video streaming
  responseType: 'blob', // For handling video streams
});

// Add the same auth interceptor to the video instance
videoAxiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor to handle video streaming
videoAxiosInstance.interceptors.response.use(
  (response) => {
    // If it's a video request, return the raw response
    if (response.config.url.includes('/proxy-video')) {
      return response;
    }
    return response;
  },
  (error) => Promise.reject(error)
);

// Function to handle authentication state and token checks
export function useAuthCheck() {
  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    const currentPath = window.location.pathname;

    // Don't redirect if we're in the TickTick OAuth flow
    if (!token || isTokenExpired(token)) {
      if (currentPath !== '/login' && 
          currentPath !== '/' && 
          !currentPath.includes('/tickticksync')) {
        window.location.href = '/';
      }
    }
  }, []);
}

export const login = async (username, password) => {
    try {
        const response = await axiosInstance.post('/login', { username, password });
        console.log('Login response:', response.data);
        if (!response.data.access_token || !response.data.role) {
            throw new Error('Invalid response from server');
        }
        localStorage.setItem('token', response.data.access_token);
        localStorage.setItem('role', response.data.role);
        
        // Set up token refresh after successful login
        setupTokenRefresh();
        
        return response.data.role;
    } catch (error) {
        console.error('Login failed:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const logout = async () => {
    try {
        await axiosInstance.post('/logout');
    } catch (error) {
        console.error('Logout error:', error);
    } finally {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        // Don't redirect if we're in the TickTick OAuth flow
        if (!window.location.pathname.includes('/tickticksync')) {
            window.location.href = '/';
        }
    }
};

export const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    if (!token) return false;

    try {
        const tokenData = JSON.parse(atob(token.split('.')[1]));
        const isExpired = tokenData.exp * 1000 < Date.now();
        return !isExpired;
    } catch {
        return false;
    }
};

export const getRole = () => {
  return localStorage.getItem('role');
};

export const getToken = () => {
  return localStorage.getItem('token');
};

export const refreshToken = async () => {
    if (isRefreshing) {
        console.log('Token refresh already in progress');
        return;
    }

    try {
        isRefreshing = true;
        const response = await axiosInstance.post('/refresh-token');
        const newToken = response.data.access_token;
        localStorage.setItem('token', newToken);
        setupTokenRefresh(); // Reset the refresh timer
        return newToken;
    } catch (error) {
        console.error('Token refresh failed:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        window.location.href = '/';
        throw error;
    } finally {
        isRefreshing = false;
    }
};

// Add a request interceptor to include the JWT token in all requests
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const registerAccount = async (username, email, password) => {
  try {
    const response = await axiosInstance.post('/register', {
      username,
      email,
      password
    });
    return { ...response.data, requiresLogin: true };
  } catch (error) {
    console.error('Registration failed:', error);
    throw error;
  }
};

// Placeholder function for Google login
export const loginWithGoogle = async () => {
  try {
    const response = await axiosInstance.post('/login-with-google');
    return { success: true, message: 'Google login attempt logged' };
  } catch (error) {
    console.error('Google login failed:', error);
    throw error;
  }
};

const getProxiedVideo = async (videoUrl) => {
  try {
    const response = await videoAxiosInstance.get(`/proxy-video?url=${encodeURIComponent(videoUrl)}`, {
      responseType: 'blob'
    });
    return URL.createObjectURL(response.data);
  } catch (error) {
    console.error('Error fetching video:', error);
    throw error;
  }
};


// Export the custom axios instance
export { axiosInstance, videoAxiosInstance, getProxiedVideo, setupTokenRefresh, clearTokenRefresh };