import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Alert,
  Box,
  Paper,
  Typography,
  IconButton,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Drawer,
  ListItemIcon,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  Snackbar,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Chip,
  Avatar,
  Collapse,
  Divider,
  Tooltip,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit,
  Edit as EditIcon,
  ChevronLeft,
  ChevronRight,
  Dashboard as DashboardIcon,
  ExpandLess,
  ExpandMore,
  People as PeopleIcon,
} from '@mui/icons-material';
import { axiosInstance } from './auth';

const BoardSidebar = ({ 
  boards, 
  selectedBoard, 
  onSelectBoard, 
  onAddBoard, 
  onDeleteBoard, 
  onRenameBoard,
  onShare, 
  collapsed, 
  onToggleCollapse,
  navStyle,
  currentTheme 
}) => {
  const [editingBoard, setEditingBoard] = useState(null);
  const [editingTitle, setEditingTitle] = useState('');
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

BoardSidebar.propTypes = {
  boards: PropTypes.array.isRequired,
  selectedBoard: PropTypes.object,
  onSelectBoard: PropTypes.func.isRequired,
  onAddBoard: PropTypes.func.isRequired,
  onDeleteBoard: PropTypes.func.isRequired,
  onRenameBoard: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  onToggleCollapse: PropTypes.func.isRequired,
  sx: PropTypes.object
};

CommentList.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
    timestamp: PropTypes.string,
    user: PropTypes.shape({
      emoji: PropTypes.string
    })
  }))
};

KanbanItemForm.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  onSave: PropTypes.func.isRequired
};

  const darkerPrimaryColor = theme.palette.primary.dark;

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: collapsed ? 50 : 240,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: collapsed ? 50 : 240,
          boxSizing: 'border-box',
          top: isLargeScreen ? 64 : 41,
          left: isLargeScreen ? (navStyle === 'drawer' ? '240px' : '40px') : 0,
          height: `calc(100vh - ${isLargeScreen ? 0 : 41}px)`,
          zIndex: 1200,
          bgcolor: darkerPrimaryColor, // Use the darker primary color
          color: 'common.white',
          transition: theme.transitions.create(['width', 'left'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          '& .MuiListItem-root': {
            color: 'inherit',
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.08)', // Add hover effect
            },
          },
          '& .MuiListItemIcon-root': {
            color: 'common.white',
          },
          '& .MuiIconButton-root': {
            color: 'common.white',
          },
          '& .MuiListItemText-primary': {
            color: 'common.white',
          },
        },
      }}
    >
      <List>
        <ListItem>
          <ListItemIcon onClick={onToggleCollapse}>
            {collapsed ? <ChevronRight /> : <ChevronLeft />}
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Kanban Boards" />}
        </ListItem>
        
        {boards.map((board) => (
          <ListItem
            key={board.id}
            button
            selected={selectedBoard?.id === board.id}
          >
            {editingBoard === board.id ? (
              <TextField
                autoFocus
                value={editingTitle}
                size="small"
                onBlur={() => {
                  if (editingTitle !== board.title) {
                    onRenameBoard(board.id, editingTitle);
                  }
                  setEditingBoard(null);
                }}
                onChange={(e) => setEditingTitle(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    if (editingTitle !== board.title) {
                      onRenameBoard(board.id, editingTitle);
                    }
                    setEditingBoard(null);
                  }
                }}
              />
            ) : (
              <>
                <ListItemIcon onClick={() => onSelectBoard(board)}>
                  <DashboardIcon />
                </ListItemIcon>
                {!collapsed && (
                  <>
                    <ListItemText 
                      primary={board.title} 
                      onClick={() => onSelectBoard(board)}
                    />
                    <IconButton
                      size="small"
                      onClick={() => {
                        setEditingBoard(board.id);
                        setEditingTitle(board.title);
                      }}
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                    <Tooltip title={board.is_shared ? "Manage Sharing" : "Share Board"}>
                      <IconButton
                        size="small"
                        onClick={() => onShare(board)}
                      >
                        <PeopleIcon fontSize="small" color={board.is_shared ? "primary" : "inherit"} />
                      </IconButton>
                    </Tooltip>
                    <IconButton
                      size="small"
                      onClick={() => onDeleteBoard(board.id)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </>
                )}
              </>
            )}
          </ListItem>
        ))}
        
        <ListItem button onClick={onAddBoard}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          {!collapsed && (
            <ListItemText primary="Add Board" />
          )}
        </ListItem>
      </List>
    </Drawer>
  );
};

// This isnt being used at all, we should check if it's still needed
const TaskSelectionDialog = ({ open, onClose, onAdd, columns, initialColumn }) => {
  const [tasks, setTasks] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState('');
  const [sortBy, setSortBy] = useState('dueDate');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      fetchAvailableTasks();
      setSelectedTasks([]); // Reset selections when dialog opens
      setSelectedColumn(initialColumn || ''); // Use initialColumn if provided
    }
  }, [open, initialColumn]);

  const fetchAvailableTasks = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/tasks');
      // Filter out tasks that are already in kanban
      const availableTasks = response.data.filter(task => !task.kanban_status);
      setTasks(availableTasks);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
    setLoading(false);
  };

  const handleSort = (tasks) => {
    switch (sortBy) {
      case 'dueDate':
        return [...tasks].sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
      case 'alphabetical':
        return [...tasks].sort((a, b) => a.title.localeCompare(b.title));
      case 'category':
        return [...tasks].sort((a, b) => {
          const getFirstTag = (tags) => {
            if (Array.isArray(tags)) return tags[0] || '';
            if (typeof tags === 'string') return tags.split(',')[0] || '';
            return '';
          };
          const catA = getFirstTag(a.tags);
          const catB = getFirstTag(b.tags);
          return catA.localeCompare(catB);
        });
      default:
        return tasks;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ mb: 2 }}>Add Tasks to Kanban</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 1 }}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Sort By</InputLabel>
            <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
              <MenuItem value="dueDate">Due Date</MenuItem>
              <MenuItem value="alphabetical">Alphabetical</MenuItem>
              <MenuItem value="category">Category</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Select Column</InputLabel>
            <Select
              value={selectedColumn}
              onChange={(e) => setSelectedColumn(e.target.value)}
              required
            >
              {columns.map(column => (
                <MenuItem key={column.id} value={column.id}>
                  {column.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <List>
            {handleSort(tasks).map((task) => (
              <ListItem
                key={task.id}
                button
                onClick={() => {
                  const newSelected = selectedTasks.includes(task.id)
                    ? selectedTasks.filter(id => id !== task.id)
                    : [...selectedTasks, task.id];
                  setSelectedTasks(newSelected);
                }}
              >
                <Checkbox
                  checked={selectedTasks.includes(task.id)}
                  onChange={() => {}}
                />
                <ListItemText
                  primary={task.title}
                  secondary={`Due: ${new Date(task.due_date).toLocaleDateString()}`}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            if (selectedColumn) {
              onAdd(tasks.filter(task => selectedTasks.includes(task.id)), selectedColumn);
              onClose();
            }
          }}
          color="primary"
          disabled={!selectedColumn || selectedTasks.length === 0}
        >
          Add Selected
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CommentList = ({ comments }) => {
  return (
    <List>
      {comments?.map((comment) => (
        <ListItem key={comment.id}>
          <ListItemIcon>
            <Avatar>{comment.user.emoji}</Avatar>
          </ListItemIcon>
          <ListItemText
            primary={comment.text}
            secondary={new Date(comment.timestamp).toLocaleString()}
          />
        </ListItem>
      ))}
    </List>
  );
};

const KanbanItemForm = ({ item, onSave }) => {
  const [formData, setFormData] = useState({
    title: item.title || '',
    description: item.description || '',
    tags: item.tags?.join(',') || ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({
      ...item,
      ...formData,
      tags: formData.tags.split(',').map(tag => tag.trim()).filter(Boolean)
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <TextField
        fullWidth
        label="Title"
        value={formData.title}
        onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
        required
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Description"
        value={formData.description}
        onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
        multiline
        rows={3}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Tags (comma-separated)"
        value={formData.tags}
        onChange={(e) => setFormData(prev => ({ ...prev, tags: e.target.value }))}
        sx={{ mb: 2 }}
      />
      <Button type="submit" variant="contained">Save</Button>
    </Box>
  );
};

const ShareBoardDialog = ({ open, onClose, board, onShare }) => {
  const [sharedWith, setSharedWith] = useState(board?.shared_with || null);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Share Board</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel>Share with Partner</InputLabel>
          <Select
            value={sharedWith || ''}
            onChange={(e) => setSharedWith(e.target.value)}
          >
            <MenuItem value="">Don't share</MenuItem>
            <MenuItem value={board?.partner_id}>Share with partner</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => onShare(sharedWith)}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteColumnDialog = ({ open, onClose, column, columns, onDelete }) => {
  const [moveTasksTo, setMoveTasksTo] = useState('');
  const hasOtherColumns = columns.filter(c => c.id !== column?.id).length > 0;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Delete Column</DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 2 }}>
          Are you sure you want to delete the column "{column?.title}"?
        </Typography>
        {hasOtherColumns && (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Move tasks to (optional)</InputLabel>
            <Select
              value={moveTasksTo}
              onChange={(e) => setMoveTasksTo(e.target.value)}
            >
              <MenuItem value="">Remove from kanban</MenuItem>
              {columns
                .filter(c => c.id !== column?.id)
                .map(c => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={() => onDelete(moveTasksTo)} 
          color="error"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const NewColumnDialog = ({ open, onClose, onAdd, title, setTitle }) => (
  <Dialog open={open} onClose={onClose} maxWidth="xs">
    <DialogTitle>New Column</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label="Column Title"
        fullWidth
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        sx={{ mt: 1 }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onAdd} color="primary">
        Add
      </Button>
    </DialogActions>
  </Dialog>
);

const AddColumnButton = ({ position, onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      width: '20px',
      height: '20px',
      padding: '5px',
      alignSelf: 'center',
      mx: 1,
      backgroundColor: 'rgba(0,0,0,0.04)',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.08)',
      },
    }}
  >
    <AddIcon fontSize="small" />
  </IconButton>
);

const TaskDetailsModal = ({ open, task, onClose }) => {
  if (!task) return null;
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{task.title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Description: {task.description}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Due Date: {new Date(task.due_date).toLocaleDateString()}
        </Typography>
        {/* Add more task details as needed */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const PunishmentDetailsModal = ({ open, punishment, onClose }) => {
  if (!punishment) return null;
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{punishment.title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Description: {punishment.description}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Points: {punishment.points}
        </Typography>
        {/* Add more punishment details as needed */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const RewardDetailsModal = ({ open, reward, onClose }) => {
  if (!reward) return null;
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{reward.title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Description: {reward.description}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Points Required: {reward.points_required}
        </Typography>
        {/* Add more reward details as needed */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const ItemSelectionDialog = ({ open, onClose, onAdd, columns, initialColumn }) => {
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState('');
  const [itemType, setItemType] = useState('kanban');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [newKanbanItem, setNewKanbanItem] = useState({ title: '', description: '' });
  const [showNewItemForm, setShowNewItemForm] = useState(false);

  useEffect(() => {
    if (open) {
      if (itemType !== 'kanban') {
        fetchAvailableItems();
      }
      setSelectedItems([]);
      setSelectedColumn(initialColumn || '');
      setError(null);
    }
  }, [open, initialColumn, itemType]);

  const fetchAvailableItems = async () => {
    if (itemType === 'kanban') return;
    
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get(`/${itemType}s`);
      const availableItems = response.data
        .filter(item => !item.kanban_status)
        .map(item => ({
          ...item,
          type: itemType
        }));
      setItems(availableItems);
    } catch (error) {
      console.error(`Error fetching ${itemType}s:`, error);
      setError(`Failed to fetch ${itemType}s`);
    }
    setLoading(false);
  };

  const handleItemTypeChange = (event) => {
    setItemType(event.target.value);
    setSelectedItems([]);
    setShowNewItemForm(event.target.value === 'kanban');
  };

  const handleAdd = () => {
    if (!selectedColumn) {
      setError('Please select a column');
      return;
    }

    if (itemType === 'kanban') {
      if (!newKanbanItem.title) {
        setError('Title is required');
        return;
      }
      const kanbanItem = {
        type: 'kanban',
        title: newKanbanItem.title,
        description: newKanbanItem.description,
        id: Date.now(), // Temporary ID for new items
        kanban_status: `kanban_${columns.find(col => col.id === selectedColumn)?.title.toLowerCase().replace(' ', '_')}`
      };
      onAdd([kanbanItem], selectedColumn);
    } else {
      const selectedItemsData = items
        .filter(item => selectedItems.includes(item.id))
        .map(item => ({
          ...item,
          type: itemType,
          kanban_status: `kanban_${columns.find(col => col.id === selectedColumn)?.title.toLowerCase().replace(' ', '_')}`
        }));

      if (selectedItemsData.length === 0) {
        setError('Please select at least one item');
        return;
      }

      onAdd(selectedItemsData, selectedColumn);
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Add Items to Kanban</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Item Type</InputLabel>
          <Select
            value={itemType}
            onChange={handleItemTypeChange}
            disabled={loading}
          >
            <MenuItem value="kanban">Kanban Item</MenuItem>
            <MenuItem value="task">Tasks</MenuItem>
            <MenuItem value="punishment">Punishments</MenuItem>
            <MenuItem value="reward">Rewards</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Select Column</InputLabel>
          <Select
            value={selectedColumn}
            onChange={(e) => setSelectedColumn(e.target.value)}
            required
            disabled={loading}
          >
            {columns.map(column => (
              <MenuItem key={column.id} value={column.id}>
                {column.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {itemType === 'kanban' ? (
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Title"
              value={newKanbanItem.title}
              onChange={(e) => setNewKanbanItem(prev => ({ ...prev, title: e.target.value }))}
              required
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Description"
              value={newKanbanItem.description}
              onChange={(e) => setNewKanbanItem(prev => ({ ...prev, description: e.target.value }))}
              multiline
              rows={3}
            />
          </Box>
        ) : (
          loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
              <CircularProgress />
            </Box>
          ) : items.length === 0 ? (
            <Alert severity="info">
              No available {itemType}s found
            </Alert>
          ) : (
            <List>
              {items.map((item) => (
                <ListItem
                  key={item.id}
                  button
                  onClick={() => {
                    const newSelected = selectedItems.includes(item.id)
                      ? selectedItems.filter(id => id !== item.id)
                      : [...selectedItems, item.id];
                    setSelectedItems(newSelected);
                  }}
                >
                  <Checkbox
                    checked={selectedItems.includes(item.id)}
                    onChange={() => {}}
                    disabled={loading}
                  />
                  <ListItemText
                    primary={item.title}
                    secondary={item.description}
                  />
                </ListItem>
              ))}
            </List>
          )
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleAdd}
          color="primary"
          disabled={loading || !selectedColumn || (itemType !== 'kanban' && selectedItems.length === 0) || (itemType === 'kanban' && !newKanbanItem.title)}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const KanbanItemCard = ({ 
  item, 
  currentTheme,
  onEdit,
  onDelete,
  provided // Add this to receive drag-drop props
}) => {
  const [showComments, setShowComments] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  // Tag color mapping
  const tagColors = {
    urgent: '#ff4444',
    important: '#ff8800',
    personal: '#00C851',
    work: '#33b5e5',
    study: '#AA66CC',
    health: '#00695c',
    task: '#4CAF50',
    punishment: '#f44336',
    reward: '#2196F3',
    default: '#757575'
  };

  const getTagColor = (tag) => {
    const normalizedTag = tag.toLowerCase();
    return tagColors[normalizedTag] || tagColors.default;
  };

  // Show only first 100 characters of description with ellipsis
  const truncatedDescription = item.description 
    ? item.description.length > 100 
      ? `${item.description.substring(0, 100)}...` 
      : item.description
    : '';

  const handleDelete = () => {
    setShowDeleteDialog(true);
  };

  const confirmDelete = () => {
    onDelete(item.id);
    setShowDeleteDialog(false);
  };

  return (
    <Paper
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      sx={{
        p: 1,
        mb: 1,
        bgcolor: currentTheme === 'dark' ? '#424242' : '#fff',
        color: currentTheme === 'dark' ? '#fff' : 'inherit',
        position: 'relative',
        transform: 'translate(0, 0)', // Add this to fix initial position
        '&:hover .action-buttons': {
          opacity: 0.8,
        },
        // Important: Add these styles for proper drag positioning
        '&.is-dragging': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: 'auto',
          zIndex: 1000,
        }
      }}
    >
      {/* Action Buttons */}
      <Box 
        className="action-buttons"
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          opacity: 0,
          transition: 'opacity 0.2s',
          display: 'flex',
          gap: 1,
          bgcolor: currentTheme === 'dark' ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.8)',
          borderRadius: 1,
          padding: '2px',
          zIndex: 2,
        }}
        onClick={(e) => e.stopPropagation()} 
      >
        <Tooltip title="Edit">
          <IconButton 
            size="small" 
            onClick={(e) => {
              e.stopPropagation();
              onEdit(item);
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton 
            size="small" 
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
            }}
            color="error"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      {/* Title */}
      <Typography variant="h6" sx={{ pr: 6, wordBreak: 'break-word' }}>
        {item.title}
      </Typography>

      {/* Description */}
      <Typography 
        variant="body2" 
        sx={{ 
          mt: 0.5,
          color: currentTheme === 'dark' ? 'rgba(255,255,255,0.7)' : 'text.secondary',
          fontSize: '0.875rem',
          wordBreak: 'break-word'
        }}
      >
        {truncatedDescription}
      </Typography>
      
      {/* Tags */}
      <Box sx={{ 
        mt: 1, 
        display: 'flex', 
        flexWrap: 'wrap', 
        gap: 0.5,
        maxWidth: '100%'
      }}>
        {item.tags?.map((tag) => (
          <Chip
            key={tag}
            label={tag}
            size="small"
            sx={{ 
              bgcolor: getTagColor(tag),
              color: '#fff',
              fontSize: '0.75rem',
              height: '20px',
              maxWidth: '100%',
              '.MuiChip-label': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }
            }}
          />
        ))}
      </Box>

      {/* Comments Section */}
      {item.comments?.length > 0 && (
        <Box sx={{ mt: 1 }}>
          <Button 
            size="small" 
            onClick={(e) => {
              e.stopPropagation();
              setShowComments(!showComments);
            }}
            startIcon={showComments ? <ExpandLess /> : <ExpandMore />}
            sx={{ 
              fontSize: '0.75rem',
              color: currentTheme === 'dark' ? '#fff' : 'inherit'
            }}
          >
            Comments ({item.comments.length})
          </Button>
          
          <Collapse in={showComments}>
            {item.comments.map((comment) => (
              <Box 
                key={comment.id} 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mt: 1,
                  fontSize: '0.75rem',
                  color: currentTheme === 'dark' ? 'rgba(255,255,255,0.7)' : 'text.secondary'
                }}
              >
                <Typography variant="caption">
                  {comment.text}
                </Typography>
              </Box>
            ))}
          </Collapse>
        </Box>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>Delete Item</DialogTitle>
        <DialogContent>
          Are you sure you want to delete "{item.title}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

const KanbanItemDetailsModal = ({ open, item, onClose }) => {
  const [editMode, setEditMode] = useState(false);
  const [newComment, setNewComment] = useState('');

  if (!open) return null;

  const handleAddComment = async (commentText) => {
    if (!item?.id) return;

    try {
      const response = await axiosInstance.post(`/kanban/items/${item.id}/comments`, {
        text: commentText
      });
      
      // Update the item with the new comment
      const updatedComments = [...(item.comments || []), response.data];
      item.comments = updatedComments;
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {editMode ? 'Edit Item' : (item?.title || 'Item Details')}
      </DialogTitle>
      <DialogContent>
        {editMode ? (
          <KanbanItemForm 
            item={item || { title: '', description: '', tags: [] }} 
            onSave={() => setEditMode(false)} 
          />
        ) : (
          <>
            <Typography>{item?.description || 'No description available'}</Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6">Comments</Typography>
            <CommentList comments={item?.comments || []} />
            <TextField
              fullWidth
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Add a comment..."
              multiline
              rows={2}
              sx={{ mt: 2 }}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {!editMode && <Button onClick={() => setEditMode(true)}>Edit</Button>}
        {!editMode && (
          <Button 
            onClick={() => handleAddComment(newComment)}
            disabled={!newComment.trim()}
          >
            Add Comment
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const KanbanView = ({ currentTheme, navStyle }) => {
  const [loading, setLoading] = useState(true);
  const [boards, setBoards] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [columns, setColumns] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [tasksByColumn, setTasksByColumn] = useState({});
  const [items, setItems] = useState({});
  const [showItemSelector, setShowItemSelector] = useState(false);
  const [punishmentDetailsModal, setPunishmentDetailsModal] = useState({ open: false, punishment: null });
  const [rewardDetailsModal, setRewardDetailsModal] = useState({ open: false, reward: null });
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [showTaskSelector, setShowTaskSelector] = useState(false);
  const [showNewColumnDialog, setShowNewColumnDialog] = useState(false);
  const [newColumnTitle, setNewColumnTitle] = useState('');
  const [selectedColumnForAdd, setSelectedColumnForAdd] = useState(null);
  const [insertPosition, setInsertPosition] = useState(null);
  const [deleteColumnDialog, setDeleteColumnDialog] = useState({ open: false, column: null });
  const [shareBoardDialog, setShareBoardDialog] = useState({ open: false, board: null });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [editingColumn, setEditingColumn] = useState(null);
  const [editingTitle, setEditingTitle] = useState('');
  const [taskDetailsModal, setTaskDetailsModal] = useState({ open: false, task: null });
  const [kanbanItemModal, setKanbanItemModal] = useState({ open: false, item: null });
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [error, setError] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setSidebarCollapsed(isMobile);
  }, [isMobile]);

  useEffect(() => {
    console.log('Items state updated:', items);
  }, [items]);
  
  useEffect(() => {
    console.log('Columns state updated:', columns);
  }, [columns]);

  const getContentMargin = () => {
    if (!isLargeScreen) return 0;
    
    let margin = 0;
    if (navStyle === 'drawer') margin += 0;
    else margin += 20;
    
    margin += sidebarCollapsed ? 0 : 0;
    return margin;
  };

  const fetchBoardItems = async (currentBoard) => {
    if (!currentBoard || !currentBoard.id) {
      console.log('No board available');
      return;
    }
    
    try {
      const response = await axiosInstance.get(`/kanban/items?board_id=${currentBoard.id}`);
      if (!response.data) {
        console.log('No items data received');
        return;
      }
  
      const itemsByColumn = {};
      currentBoard.columns.forEach(column => {
        itemsByColumn[column.id] = [];
      });
  
      response.data.forEach(item => {
        if (item.column_id) {
          const column = currentBoard.columns.find(col => col.id === item.column_id);
          if (column) {
            itemsByColumn[column.id].push(item);
          }
        }
      });
  
      console.log('Final itemsByColumn:', itemsByColumn);
      setItems(itemsByColumn);
    } catch (error) {
      console.error('Error fetching board items:', error);
      setError('Failed to load items');
    }
  };

  useEffect(() => {
    if (selectedBoard && selectedBoard.columns) {
      const boardColumns = selectedBoard.columns;
      setColumns(boardColumns);
      
      const itemsByColumn = {};
      boardColumns.forEach(column => {
        if (column && column.id) {
          itemsByColumn[column.id] = [];
        }
      });
      setItems(itemsByColumn);
      
      fetchBoardItems(selectedBoard);
    }
  }, [selectedBoard]);
  
  const fetchBoards = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/kanban/boards');
      if (!response.data) {
        throw new Error('No data received from server');
      }
      
      setBoards(response.data);
      
      if (response.data.length > 0 && !selectedBoard) {
        const firstBoard = response.data[0];
        if (!firstBoard) {
          throw new Error('First board is null');
        }
        
        setSelectedBoard(firstBoard);
        
        if (firstBoard.columns) {
          const boardColumns = firstBoard.columns;
          setColumns(boardColumns);
          
          const itemsByColumn = {};
          boardColumns.forEach(column => {
            if (column && column.id) {
              itemsByColumn[column.id] = [];
            }
          });
          setItems(itemsByColumn);
  
          await fetchBoardItems(firstBoard);
        }
      }
    } catch (error) {
      console.error('Error fetching boards:', error);
      setError('Failed to fetch boards');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBoards();
  }, []); 

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const handleAddColumn = async () => {
    if (!selectedBoard) return;
    
    try {
      const response = await axiosInstance.post('/kanban-columns', {
        title: newColumnTitle,
        position: insertPosition,
        board_id: selectedBoard.id
      });
      
      const newColumn = response.data;
      setColumns(prev => [...prev, newColumn]);
      
      setSelectedBoard(prev => ({
        ...prev,
        columns: [...(prev.columns || []), newColumn]
      }));
      
      setItems(prev => ({
        ...prev,
        [newColumn.id]: []
      }));
      
      setNewColumnTitle('');
      setShowNewColumnDialog(false);
      setInsertPosition(null);
      
    } catch (error) {
      console.error('Error adding column:', error);
    }
  };

  const handleAddBoard = async () => {
    try {
      const response = await axiosInstance.post('/kanban/boards', {
        title: 'New Board',
        position: boards.length
      });
      await fetchBoards();
      setSelectedBoard(response.data);
    } catch (error) {
      console.error('Error adding board:', error);
      setError('Failed to add board');
    }
  };

const handleUpdateColumn = async (columnId) => {
  try {
    await axiosInstance.put(`/kanban-columns/${columnId}`, {
      title: editingTitle
    });
    await fetchBoards();
    setEditingColumn(null);
    setEditingTitle('');
  } catch (error) {
    console.error('Error updating column:', error);
  }
};

  const handleDeleteBoard = async (boardId) => {
    try {
      await axiosInstance.delete(`/kanban/boards/${boardId}`);
      await fetchBoards();
      if (selectedBoard?.id === boardId) {
        setSelectedBoard(boards[0] || null);
      }
    } catch (error) {
      console.error('Error deleting board:', error);
    }
  };

  const handleRenameBoard = async (boardId, newTitle) => {
    try {
      await axiosInstance.put(`/kanban/boards/${boardId}`, {
        title: newTitle
      });
      await fetchBoards();
    } catch (error) {
      console.error('Error renaming board:', error);
      setSnackbar({
        open: true,
        message: 'Failed to rename board',
        severity: 'error'
      });
    }
  };

  const handleShareBoard = async (userId) => {
    if (!shareBoardDialog.board) return;

    try {
      await axiosInstance.put(`/kanban/boards/${shareBoardDialog.board.id}/share`, {
        shared_with: userId
      });
      
      setSnackbar({
        open: true,
        message: userId ? 'Board shared successfully' : 'Board sharing removed',
        severity: 'success'
      });
      
      await fetchBoards();
      setShareBoardDialog({ open: false, board: null });
    } catch (error) {
      console.error('Error sharing board:', error);
      setSnackbar({
        open: true,
        message: 'Failed to share board',
        severity: 'error'
      });
    }
  };
  
  const handleOpenShareDialog = (board) => {
    setShareBoardDialog({ open: true, board });
  };

  const handleBoardOrderChange = async (sourceIndex, destinationIndex) => {
    try {
      const reorderedBoards = [...boards];
      const [removed] = reorderedBoards.splice(sourceIndex, 1);
      reorderedBoards.splice(destinationIndex, 0, removed);
      
      // Update positions
      await Promise.all(reorderedBoards.map((board, index) =>
        axiosInstance.put(`/kanban/boards/${board.id}/position`, {
          position: index
        })
      ));
      
      await fetchBoards();
    } catch (error) {
      console.error('Error reordering boards:', error);
    }
  };

  const handleDeleteColumn = async (columnId, moveTasksTo) => {
    try {
      console.log(`Attempting to delete column ${columnId}`);
  
      if (!columnId) {
        console.error('No column ID provided');
        setSnackbar({ open: true, message: 'Failed to delete column: No ID provided', severity: 'error' });
        return;
      }
  
      // Get all items associated with this column
      const columnTitle = selectedBoard.columns.find(col => col.id === columnId)?.title;
      if (!columnTitle) {
        console.error(`Column with ID ${columnId} not found`);
        setSnackbar({ open: true, message: 'Failed to delete column: Column not found', severity: 'error' });
        return;
      }
      const kanbanStatus = `kanban_${columnTitle.toLowerCase().replace(' ', '_')}`;
      const itemsInColumn = Object.values(items).flat().filter(item => item.kanban_status === kanbanStatus);
  
      if (moveTasksTo) {
        const targetColumn = selectedBoard.columns.find(col => col.id === moveTasksTo);
        if (targetColumn) {
          console.log(`Moving ${itemsInColumn.length} items to column ${targetColumn.title}`);
          await Promise.all(itemsInColumn.map(item =>
            axiosInstance.put(`/${item.type}s/${item.id}/kanban-status`, {
              status: `kanban_${targetColumn.title.toLowerCase().replace(' ', '_')}`
            })
          ));
        }
      } else {
        // If not moving to another column, null the kanban status
        console.log(`Nullifying kanban status for ${itemsInColumn.length} items`);
        await Promise.all(itemsInColumn.map(item =>
          axiosInstance.put(`/${item.type}s/${item.id}/kanban-status`, {
            status: null
          })
        ));
      }
  
      console.log(`Sending DELETE request to /kanban-columns/${columnId}`);
      const response = await axiosInstance.delete(`/kanban-columns/${columnId}`);
      console.log('Delete response:', response.data);
  
      // Update local state
      setItems(prevItems => {
        const newItems = { ...prevItems };
        delete newItems[columnId];
        return newItems;
      });
  
      setSelectedBoard(prevBoard => ({
        ...prevBoard,
        columns: prevBoard.columns.filter(col => col.id !== columnId)
      }));
  
      // Fetch updated board data
      await fetchBoards();
      
      setDeleteColumnDialog({ open: false, column: null });
  
      // Show success message
      setSnackbar({ open: true, message: 'Column deleted successfully', severity: 'success' });
  
    } catch (error) {
      console.error('Error deleting column:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
  
      // Show error message
      setSnackbar({ open: true, message: 'Failed to delete column', severity: 'error' });
    }
  };

  const handleAddItems = async (selectedItems, columnId) => {
    try {
      const column = selectedBoard.columns.find(col => col.id === columnId);
      if (!column) {
        console.error('Column not found:', columnId);
        return;
      }
  
      const updatedItems = await Promise.all(selectedItems.map(async item => {
        const itemData = {
          type: item.type,
          column_id: columnId,
          board_id: selectedBoard.id,
          title: item.title,
          description: item.description,
          tags: item.tags || [],
          reference_id: item.type !== 'kanban' ? item.id : null
        };
  
        const response = await axiosInstance.post('/kanban/items', itemData);
        return response.data;
      }));
  
      setItems(prevItems => ({
        ...prevItems,
        [columnId]: [...(prevItems[columnId] || []), ...updatedItems]
      }));
  
    } catch (error) {
      console.error('Error adding items to column:', error);
      setError('Failed to add items to column');
    }
  };

  const onDragEnd = async (result) => {
    const { source, destination, draggableId } = result;
  
    if (!destination || !selectedBoard) return;
  
    const sourceColumn = selectedBoard.columns.find(col => col.id === parseInt(source.droppableId));
    const destColumn = selectedBoard.columns.find(col => col.id === parseInt(destination.droppableId));
  
    if (!sourceColumn || !destColumn) return;
  
    try {
      const item = items[source.droppableId].find(i => i.id === parseInt(draggableId));
      if (!item) return;
  
      const newStatus = `kanban_${destColumn.title.toLowerCase().replace(' ', '_')}`;
  
      if (item.type === 'kanban') {
        // Update kanban item
        await axiosInstance.put(`/kanban/items/${item.id}`, {
          column_id: parseInt(destination.droppableId),
          kanban_status: newStatus
        });
      } else {
        // Update other item types
        const endpoint = {
          'task': 'tasks',
          'punishment': 'punishments',
          'reward': 'rewards'
        }[item.type];
  
        if (!endpoint) {
          throw new Error(`Invalid item type: ${item.type}`);
        }
  
        await axiosInstance.put(`/${endpoint}/${item.id}/kanban-status`, {
          status: newStatus
        });
      }
  
      // Update local state
      const newItems = { ...items };
      const [removed] = newItems[source.droppableId].splice(
        source.index,
        1
      );
      newItems[destination.droppableId].splice(destination.index, 0, {
        ...removed,
        kanban_status: newStatus
      });
      setItems(newItems);
  
    } catch (error) {
      console.error('Error updating item status:', error);
      setError('Failed to update item position');
    }
  };

const renderItem = (item, index) => { 
  if (!item) return null;

  let itemType = item.type;
  if (!itemType) {
    if (item.recurrence_pattern !== undefined) {
      itemType = 'task';
    } else if (item.points_earned !== undefined) {
      itemType = 'punishment';
    } else if (item.points_required !== undefined) {
      itemType = 'reward';
    }
  }

  const itemWithType = { ...item, type: itemType };

  const handleDelete = async (itemId) => {
    try {
      await axiosInstance.delete(`/kanban/items/${itemId}`);
      setItems(prevItems => {
        const newItems = { ...prevItems };
        Object.keys(newItems).forEach(columnId => {
          newItems[columnId] = newItems[columnId].filter(item => item.id !== itemId);
        });
        return newItems;
      });
    } catch (error) {
      console.error('Error deleting item:', error);
      setSnackbar({ 
        open: true, 
        message: 'Failed to delete item', 
        severity: 'error' 
      });
    }
  };

  const handleEdit = (item) => {
    switch(itemType) {
      case 'kanban':
        setKanbanItemModal({ open: true, item: item });
        break;
      case 'task':
        setTaskDetailsModal({ open: true, task: item });
        break;
      case 'punishment':
        setPunishmentDetailsModal({ open: true, punishment: item });
        break;
      case 'reward':
        setRewardDetailsModal({ open: true, reward: item });
        break;
    }
  };

  return (
    <Draggable
      key={item.id}
      draggableId={String(item.id)}
      index={index}
    >
      {(provided) => (
        <KanbanItemCard
          item={item}
          currentTheme={currentTheme}
          onEdit={handleEdit}
          onDelete={handleDelete}
          provided={provided}
        />
      )}
    </Draggable>
  );
};

  return (
    <Box sx={{ 
      height: isLargeScreen ? 'calc(100vh - 50px)' : 'calc(90vh)',
      display: 'flex',
      position: 'fixed',
      top: isLargeScreen ? 65 : 41,
      left: isLargeScreen ? (navStyle === 'drawer' ? '240px' : '40px') : 0,
      right: 0,
      bottom: 0,
      overflow: 'hidden',
      marginBottom: isLargeScreen ? 0 : 56,
    }}>
      <BoardSidebar
        boards={boards}
        selectedBoard={selectedBoard}
        onSelectBoard={setSelectedBoard}
        onAddBoard={handleAddBoard}
        onDeleteBoard={handleDeleteBoard}
        onRenameBoard={handleRenameBoard}
        onShare={handleOpenShareDialog}
        collapsed={sidebarCollapsed}
        onToggleCollapse={() => setSidebarCollapsed(!sidebarCollapsed)}
        navStyle={navStyle}
        currentTheme={currentTheme}
      />
      
      <Box sx={{ 
        flexGrow: 1,
        ml: `${getContentMargin()}px`,
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'auto',
        overflowY: 'hidden',
      }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Box sx={{ 
            display: 'flex', 
            overflowX: 'auto', 
            p: 1,
            height: '100%',
          }}>
            <AddColumnButton
              position={0}
              onClick={() => {
                setInsertPosition(0);
                setShowNewColumnDialog(true);
              }}
            />
  
            {selectedBoard?.columns?.sort((a, b) => a.position - b.position).map((column, index) => (
              <React.Fragment key={column.id}>
                <Paper sx={{ 
                  minWidth: 280,
                  width: 280,
                  m: 0.5,
                  height: 'auto',
                  minHeight: '50vh',
                  maxHeight: (theme) => `calc(100vh - ${theme.spacing(isMobile ? 8 : 16)})`,
                  display: 'flex',
                  flexDirection: 'column',
                  flexShrink: 0,
                  bgcolor: currentTheme === 'dark' ? '#424242' : 'common.white',
                  color: currentTheme === 'dark' ? 'common.white' : 'text.primary',
                  '& .MuiIconButton-root': {
                    color: 'inherit',
                  },
                  '& .MuiTypography-root': {
                    color: 'inherit',
                  },
                }}>
                  <Box sx={{ 
                    p: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                    {editingColumn === column.id ? (
                      <TextField
                        autoFocus
                        value={editingTitle}
                        size="small"
                        sx={{
                          '& .MuiInputBase-input': {
                            color: 'primary.contrastText',
                          },
                        }}
                        onBlur={() => handleUpdateColumn(column.id)}
                        onChange={(e) => setEditingTitle(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleUpdateColumn(column.id);
                          }
                        }}
                      />
                    ) : (
                      <Typography 
                        variant="h6" 
                        onClick={() => {
                          setEditingColumn(column.id);
                          setEditingTitle(column.title);
                        }}
                        sx={{ cursor: 'pointer' }}
                      >
                        {column.title}
                      </Typography>
                    )}
                    <IconButton 
                      size="small" 
                      onClick={() => setDeleteColumnDialog({ open: true, column })}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
  
                  <Droppable droppableId={String(column.id)}>
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        sx={{ 
                          flexGrow: 1,
                          p: 1,
                          overflowY: 'auto',
                          minHeight: '200px',
                          bgcolor: 'transparent',
                          position: 'relative'
                        }}
                      >
                        {items[column.id]?.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={String(item.id)}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {renderItem(item, index)}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
  
                  <Box sx={{ 
                    p: 1, 
                    borderTop: 1, 
                    borderColor: currentTheme === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                  }}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedColumnForAdd(column.id);
                        setShowItemSelector(true);
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Paper>
  
                <AddColumnButton
                  position={column.position + 1}
                  onClick={() => {
                    setInsertPosition(column.position + 1);
                    setShowNewColumnDialog(true);
                  }}
                />
              </React.Fragment>
            ))}
          </Box>
        </DragDropContext>
  
        {/* Dialogs */}
        <NewColumnDialog
          open={showNewColumnDialog}
          onClose={() => {
            setShowNewColumnDialog(false);
            setNewColumnTitle('');
            setInsertPosition(null);
          }}
          onAdd={handleAddColumn}
          title={newColumnTitle}
          setTitle={setNewColumnTitle}
        />
 
        <KanbanItemDetailsModal
          open={kanbanItemModal.open}
          item={kanbanItemModal.item}
          onClose={() => setKanbanItemModal({ open: false, item: null })}
        />

        <ItemSelectionDialog
          open={showItemSelector}
          onClose={() => {
            setShowItemSelector(false);
            setSelectedColumnForAdd(null);
          }}
          onAdd={handleAddItems}
          columns={selectedBoard?.columns || []}
          initialColumn={selectedColumnForAdd}
        />
  
        <DeleteColumnDialog
          open={deleteColumnDialog.open}
          onClose={() => setDeleteColumnDialog({ open: false, column: null })}
          column={deleteColumnDialog.column}
          columns={selectedBoard?.columns || []}
          onDelete={(moveTasksTo) => handleDeleteColumn(deleteColumnDialog.column?.id, moveTasksTo)}
        />
  
        <TaskDetailsModal
          open={taskDetailsModal.open}
          task={taskDetailsModal.task}
          onClose={() => setTaskDetailsModal({ open: false, task: null })}
        />
  
        <PunishmentDetailsModal
          open={punishmentDetailsModal.open}
          punishment={punishmentDetailsModal.punishment}
          onClose={() => setPunishmentDetailsModal({ open: false, punishment: null })}
        />
  
        <RewardDetailsModal
          open={rewardDetailsModal.open}
          reward={rewardDetailsModal.reward}
          onClose={() => setRewardDetailsModal({ open: false, reward: null })}
        />

        <ShareBoardDialog
          open={shareBoardDialog.open}
          onClose={() => setShareBoardDialog({ open: false, board: null })}
          board={shareBoardDialog.board}
          onShare={handleShareBoard}
        />
  
        <Snackbar
          open={!!error}
          autoHideDuration={2000} 
          onClose={() => setError(null)}
          message={error}
        />

        <Snackbar
          open={snackbar.open}
          autoHideDuration={2000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          message={snackbar.message}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>

      </Box>
    </Box>
  );
};

export default KanbanView;