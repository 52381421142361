import React, { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import { 
  Box, 
  Typography,
  Grid,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
  Snackbar,
  CircularProgress,
  Stack,
  IconButton,
  Tooltip,
  Divider,
  Button,
  Checkbox,
  Tabs,
  Tab,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Chip,
  ToggleButton,
  ToggleButtonGroup,
  Slider,
} from '@mui/material';
import { 
  Lock,
  LockOpen,
  CleaningServices,
  AccessTime,
  Update,
  Casino,
  CalendarMonth,
  Speed,
  Warning,
  NotificationsActive,
  Settings,
  Schedule,
  Notifications,
  Edit,
  Delete,
  Preview,
  Add,
  Check,
  Close,
  Refresh,
  Today,
  DateRange,
  Timeline,
  BarChart,
  Save,
} from '@mui/icons-material';

import { axiosInstance } from './auth';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip, ResponsiveContainer } from 'recharts';

const timeUnits = [
  { value: 'hours', label: 'Hours' },
  { value: 'days', label: 'Days' },
  { value: 'weeks', label: 'Weeks' },
  { value: 'months', label: 'Months' }
];

const EventPreview = ({ events, onClose }) => {
  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Preview />
          <Typography>Scheduled Events Preview</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <List>
          {events.map((event, index) => (
            <ListItem
              key={index}
              sx={{
                borderLeft: 2,
                borderColor: 'primary.main',
                pl: 3,
                mb: 2
              }}
            >
              <ListItemIcon>
                {event.type === 'lock' && <Lock color="primary" />}
                {event.type === 'unlock' && <LockOpen color="primary" />}
                {event.type === 'cleaning' && <CleaningServices color="primary" />}
                {event.type === 'relief' && <Casino color="primary" />}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {event.type.toUpperCase()}
                  </Typography>
                }
                secondary={moment(event.scheduled_time).format('LLLL')}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} startIcon={<Close />}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const RescheduleDialog = ({ event, onClose, onReschedule }) => {
  const [newDateTime, setNewDateTime] = useState(moment(event.scheduled_time));

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Schedule />
          <Typography>Reschedule {event.type} Event</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            label="New Date/Time"
            value={newDateTime}
            onChange={setNewDateTime}
            renderInput={(params) => <TextField {...params} fullWidth sx={{ mt: 2 }} />}
            minDateTime={moment()}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} startIcon={<Close />}>Cancel</Button>
        <Button 
          onClick={() => onReschedule(event.id, newDateTime.toISOString())} 
          variant="contained"
          startIcon={<Check />}
        >
          Reschedule
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const defaultSchedule = {
    mode: 'custom',
    weekdayConfig: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
    },
    customPattern: {
        chastityCycle: {
            value: null,
            unit: null
        },
        freedomCycle: {
            value: null,
            unit: null
        }
    },
    daytimeConfig: {
        lockTime: null,
        unlockTime: null
    },
    cleaningSchedule: {
        enabled: false,
        frequency: {
            value: null,
            unit: null
        }
    },
    reliefSchedule: {
        enabled: false,
        frequency: {
            value: null,
            unit: null
        },
        rewardType: null,
        autoAssignReward: false
    },
    spotChecks: {
        enabled: false,
        startHour: null,
        endHour: null,
        frequency: {
            mode: null,
            value: null,
            unit: null,
            minValue: null,
            maxValue: null
        }
    }
};

const ScheduleSettings = ({ schedule = defaultSchedule, onChange }) => {
    const [localSchedule, setLocalSchedule] = useState({...defaultSchedule, ...schedule});
    const [isDirty, setIsDirty] = useState(false);
    const [previewEvents, setPreviewEvents] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

  useEffect(() => {
      if (schedule) {
          setLocalSchedule({
              mode: schedule.mode || 'custom',
              weekdayConfig: {
                  monday: false,
                  tuesday: false,
                  wednesday: false,
                  thursday: false,
                  friday: false,
                  saturday: false,
                  sunday: false,
                  ...schedule.weekdayConfig
              },
              customPattern: {
                  chastityCycle: {
                      value: 1,
                      unit: 'weeks',
                      ...schedule.customPattern?.chastityCycle
                  },
                  freedomCycle: {
                      value: 1,
                      unit: 'days',
                      ...schedule.customPattern?.freedomCycle
                  }
              },
              daytimeConfig: {
                  lockTime: '09:00',
                  unlockTime: '22:00',
                  ...schedule.daytimeConfig
              },
              cleaningSchedule: {
                  ...defaultSchedule.cleaningSchedule,
                  ...schedule.cleaningSchedule
              },
              reliefSchedule: {
                  ...defaultSchedule.reliefSchedule,
                  ...schedule.reliefSchedule
              },
              spotChecks: {
                  ...defaultSchedule.spotChecks,
                  ...schedule.spotChecks
              }
          });
      }
  }, [schedule]);

    const handleModeChange = (newMode) => {
        setLocalSchedule(prev => ({
            ...prev,
            mode: newMode
        }));
        setIsDirty(true);
    };

  const handleWeekdayChange = (day) => {
      setLocalSchedule(prev => ({
          ...prev,
          mode: 'weekday',
          weekdayConfig: {
              ...prev.weekdayConfig,
              [day]: !prev.weekdayConfig[day]
          }
      }));
      setIsDirty(true);
  };

    const handleCustomPatternChange = (cycleType, field, value) => {
        setLocalSchedule(prev => ({
            ...prev,
            customPattern: {
                ...prev.customPattern,
                [cycleType]: {
                    ...prev.customPattern[cycleType],
                    [field]: value
                }
            }
        }));
        setIsDirty(true);
    };

    const handleDaytimeChange = (field, value) => {
        setLocalSchedule(prev => ({
            ...prev,
            daytimeConfig: {
                ...prev.daytimeConfig,
                [field]: value
            }
        }));
        setIsDirty(true);
    };

    const handlePreviewSchedule = async () => {
        try {
            const response = await axiosInstance.post('/chastity/test-schedule', {
                schedule: localSchedule
            });
            setPreviewEvents(response.data.events);
        } catch (error) {
            console.error('Failed to preview schedule:', error);
        }
    };

    const validateSchedule = (schedule) => {
        if (!schedule.mode) {
            setError('Schedule mode is required');
            return false;
        }
        
        if (schedule.mode === 'weekday' && !Object.values(schedule.weekdayConfig).some(v => v)) {
            setError('At least one weekday must be selected');
            return false;
        }
        
        if (schedule.mode === 'custom') {
            const { chastityCycle, freedomCycle } = schedule.customPattern;
            if (!chastityCycle.value || !chastityCycle.unit || !freedomCycle.value || !freedomCycle.unit) {
                setError('Custom pattern configuration is incomplete');
                return false;
            }
        }
        
        return true;
    };

    const handleSaveChanges = () => {
        if (!validateSchedule(localSchedule)) {
            return;
        }
        
        const updatedSchedule = {
            mode: localSchedule.mode,
            weekdayConfig: {
                monday: localSchedule.weekdayConfig.monday || false,
                tuesday: localSchedule.weekdayConfig.tuesday || false,
                wednesday: localSchedule.weekdayConfig.wednesday || false,
                thursday: localSchedule.weekdayConfig.thursday || false,
                friday: localSchedule.weekdayConfig.friday || false,
                saturday: localSchedule.weekdayConfig.saturday || false,
                sunday: localSchedule.weekdayConfig.sunday || false
            },
            customPattern: {
                chastityCycle: {
                    value: localSchedule.customPattern.chastityCycle.value || 1,
                    unit: localSchedule.customPattern.chastityCycle.unit || 'weeks'
                },
                freedomCycle: {
                    value: localSchedule.customPattern.freedomCycle.value || 1,
                    unit: localSchedule.customPattern.freedomCycle.unit || 'days'
                }
            },
            daytimeConfig: {
                lockTime: localSchedule.daytimeConfig.lockTime || '09:00',
                unlockTime: localSchedule.daytimeConfig.unlockTime || '22:00'
            },
            cleaningSchedule: localSchedule.cleaningSchedule || defaultSchedule.cleaningSchedule,
            reliefSchedule: localSchedule.reliefSchedule || defaultSchedule.reliefSchedule,
            spotChecks: localSchedule.spotChecks || defaultSchedule.spotChecks
        };
        
        try {
            onChange(updatedSchedule);
            setSuccess('Schedule updated successfully');
            setIsDirty(false);
        } catch (err) {
            setError('Failed to update schedule');
            console.error('Update error:', err);
        }
    };


    return (
        <Card>
            <CardContent>
                <Stack spacing={3}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Schedule />
                        <Typography variant="h6">Schedule Configuration</Typography>
                        <Box sx={{ flexGrow: 1 }} />
                    </Stack>

                    <FormControl fullWidth>
                        <InputLabel>Schedule Mode</InputLabel>
                        <Select
                            value={localSchedule.mode}
                            onChange={(e) => handleModeChange(e.target.value)}
                            label="Schedule Mode"
                        >
                            <MenuItem value="countdown">Simple Countdown</MenuItem>
                            <MenuItem value="weekday">Weekday Schedule</MenuItem>
                            <MenuItem value="custom">Custom Pattern</MenuItem>
                            <MenuItem value="daytime">Daily Time-based</MenuItem>
                        </Select>
                    </FormControl>

                    {localSchedule.mode === 'weekday' && (
                        <Paper sx={{ p: 2 }}>
                            <Typography variant="subtitle1" gutterBottom>Active Days</Typography>
                            <Grid container spacing={1}>
                                {Object.keys(localSchedule.weekdayConfig).map((day) => (
                                    <Grid item key={day}>
                                        <ToggleButton
                                            value={day}
                                            selected={localSchedule.weekdayConfig[day]}
                                            onChange={() => handleWeekdayChange(day)}
                                            sx={{ textTransform: 'capitalize' }}
                                        >
                                            {day.slice(0, 3)}
                                        </ToggleButton>
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    )}

                    {localSchedule.mode === 'custom' && (
                        <Paper sx={{ p: 2 }}>
                            <Typography variant="subtitle1" gutterBottom>Custom Pattern</Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="subtitle2" gutterBottom>Chastity Period</Typography>
                                            <Stack spacing={2}>
                                                <TextField
                                                    type="number"
                                                    label="Duration"
                                                    value={localSchedule.customPattern.chastityCycle.value}
                                                    onChange={(e) => handleCustomPatternChange(
                                                        'chastityCycle',
                                                        'value',
                                                        parseInt(e.target.value)
                                                    )}
                                                    inputProps={{ min: 1 }}
                                                />
                                                <FormControl fullWidth>
                                                    <InputLabel>Unit</InputLabel>
                                                    <Select
                                                        value={localSchedule.customPattern.chastityCycle.unit}
                                                        onChange={(e) => handleCustomPatternChange(
                                                            'chastityCycle',
                                                            'unit',
                                                            e.target.value
                                                        )}
                                                        label="Unit"
                                                    >
                                                        {timeUnits.map(unit => (
                                                            <MenuItem key={unit.value} value={unit.value}>
                                                                {unit.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="subtitle2" gutterBottom>Freedom Period</Typography>
                                            <Stack spacing={2}>
                                                <TextField
                                                    type="number"
                                                    label="Duration"
                                                    value={localSchedule.customPattern.freedomCycle.value}
                                                    onChange={(e) => handleCustomPatternChange(
                                                        'freedomCycle',
                                                        'value',
                                                        parseInt(e.target.value)
                                                    )}
                                                    inputProps={{ min: 1 }}
                                                />
                                                <FormControl fullWidth>
                                                    <InputLabel>Unit</InputLabel>
                                                    <Select
                                                        value={localSchedule.customPattern.freedomCycle.unit}
                                                        onChange={(e) => handleCustomPatternChange(
                                                            'freedomCycle',
                                                            'unit',
                                                            e.target.value
                                                        )}
                                                        label="Unit"
                                                    >
                                                        {timeUnits.map(unit => (
                                                            <MenuItem key={unit.value} value={unit.value}>
                                                                {unit.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}

                    {localSchedule.mode === 'daytime' && (
                        <Paper sx={{ p: 2 }}>
                            <Typography variant="subtitle1" gutterBottom>Daily Schedule</Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <TimePicker
                                            label="Lock Time"
                                            value={moment(localSchedule.daytimeConfig.lockTime, 'HH:mm')}
                                            onChange={(newTime) => handleDaytimeChange(
                                                'lockTime',
                                                newTime.format('HH:mm')
                                            )}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <TimePicker
                                            label="Unlock Time"
                                            value={moment(localSchedule.daytimeConfig.unlockTime, 'HH:mm')}
                                            onChange={(newTime) => handleDaytimeChange(
                                                'unlockTime',
                                                newTime.format('HH:mm')
                                            )}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}

                    <Stack direction="row" spacing={1} justifyContent="flex-end">
                        <IconButton
                            onClick={handlePreviewSchedule}
                            disabled={!isDirty}
                        >
                            <Preview />
                        </IconButton>
                        <IconButton
                            onClick={handleSaveChanges}
                            disabled={!isDirty}
                            color="primary"
                        >
                            <Save />
                        </IconButton>
                    </Stack>

                    <Snackbar
                        open={!!error || !!success}
                        autoHideDuration={3000}
                        onClose={() => {
                            setError('');
                            setSuccess('');
                        }}
                    >
                        <Alert 
                            severity={error ? 'error' : 'success'} 
                            onClose={() => {
                                setError('');
                                setSuccess('');
                            }}
                            sx={{ width: '100%' }}
                        >
                            {error || success}
                        </Alert>
                    </Snackbar>
                </Stack>
            </CardContent>

            {previewEvents && (
                <EventPreview 
                    events={previewEvents} 
                    onClose={() => setPreviewEvents(null)} 
                />
            )}
        </Card>
    );
};

const CleaningSchedule = ({ settings, onChange }) => {
  const [showSettingsDialog, setShowSettingsDialog] = useState(false);
  const [localSettings, setLocalSettings] = useState({
    enabled: settings.chastity_schedule?.cleaningSchedule?.enabled || false,
    frequency: {
      value: settings.chastity_schedule?.cleaningSchedule?.frequency?.value || 3,
      unit: settings.chastity_schedule?.cleaningSchedule?.frequency?.unit || 'days'
    }
  });

  const handleEnableChange = (e) => {
    if (e.target.checked) {
      setShowSettingsDialog(true);
    } else {
      handleSave({ enabled: false });
    }
  };

  const handleSave = (settings) => {
    onChange('cleaningSchedule', settings);
    setShowSettingsDialog(false);
  };

  return (
    <Card>
      <CardContent>
        <Stack spacing={3}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <CleaningServices />
            <Typography variant="h6">Cleaning Schedule</Typography>
          </Stack>

          <FormControlLabel
            control={
              <Switch
                checked={localSettings.enabled}
                onChange={handleEnableChange}
              />
            }
            label="Enable Cleaning Schedule"
          />

          <Dialog open={showSettingsDialog} onClose={() => setShowSettingsDialog(false)}>
            <DialogTitle>Cleaning Schedule Settings</DialogTitle>
            <DialogContent>
              <Stack spacing={2} sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Frequency"
                      value={localSettings.frequency.value}
                      onChange={(e) => setLocalSettings({
                        ...localSettings,
                        frequency: {
                          ...localSettings.frequency,
                          value: parseInt(e.target.value)
                        }
                      })}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Unit</InputLabel>
                      <Select
                        value={localSettings.frequency.unit}
                        onChange={(e) => setLocalSettings({
                          ...localSettings,
                          frequency: {
                            ...localSettings.frequency,
                            unit: e.target.value
                          }
                        })}
                        label="Unit"
                      >
                        {timeUnits.map(unit => (
                          <MenuItem key={unit.value} value={unit.value}>
                            {unit.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowSettingsDialog(false)}>Cancel</Button>
              <Button 
                onClick={() => handleSave({ ...localSettings, enabled: true })} 
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          {localSettings.enabled && (
            <Paper sx={{ p: 2, mt: 2 }}>
              <Typography variant="subtitle2" gutterBottom>Current Settings</Typography>
              <List>
                <ListItem>
                  <ListItemText 
                    primary="Frequency"
                    secondary={`Every ${localSettings.frequency.value} ${localSettings.frequency.unit}`}
                  />
                </ListItem>
              </List>
            </Paper>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

const ReliefSchedule = ({ settings, onChange }) => {
  const [showSettingsDialog, setShowSettingsDialog] = useState(false);
  const reliefSchedule = settings?.chastity_schedule?.reliefSchedule || {};
  
  const [localSettings, setLocalSettings] = useState({
    enabled: reliefSchedule.enabled || false,
    frequency: {
      value: reliefSchedule.frequency?.value || 1,
      unit: reliefSchedule.frequency?.unit || 'weeks'
    },
    rewardType: reliefSchedule.rewardType || 'ruined',
    autoAssignReward: reliefSchedule.autoAssignReward || false
  });

  const handleEnableChange = (e) => {
    if (e.target.checked) {
      setShowSettingsDialog(true);
    } else {
      handleSave({ enabled: false });
    }
  };

  const handleSave = (settings) => {
    onChange('reliefSchedule', settings);
    setShowSettingsDialog(false);
  };

  const rewardTypes = [
    { value: 'full', label: 'Full Orgasm' },
    { value: 'ruined', label: 'Ruined Orgasm' },
    { value: 'anal', label: 'Anal Orgasm' },
    { value: 'prostate', label: 'Prostate Milking' }
  ];

  return (
    <Card>
      <CardContent>
        <Stack spacing={3}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Casino />
            <Typography variant="h6">Relief Schedule</Typography>
          </Stack>

          <FormControlLabel
            control={
              <Switch
                checked={localSettings.enabled}
                onChange={handleEnableChange}
              />
            }
            label="Enable Relief Schedule"
          />

          <Dialog open={showSettingsDialog} onClose={() => setShowSettingsDialog(false)}>
            <DialogTitle>Relief Schedule Settings</DialogTitle>
            <DialogContent>
              <Stack spacing={2} sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Frequency"
                      value={localSettings.frequency.value}
                      onChange={(e) => setLocalSettings({
                        ...localSettings,
                        frequency: {
                          ...localSettings.frequency,
                          value: parseInt(e.target.value)
                        }
                      })}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Unit</InputLabel>
                      <Select
                        value={localSettings.frequency.unit}
                        onChange={(e) => setLocalSettings({
                          ...localSettings,
                          frequency: {
                            ...localSettings.frequency,
                            unit: e.target.value
                          }
                        })}
                        label="Unit"
                      >
                        {timeUnits.map(unit => (
                          <MenuItem key={unit.value} value={unit.value}>
                            {unit.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <FormControl fullWidth>
                  <InputLabel>Relief Type</InputLabel>
                  <Select
                    value={localSettings.rewardType}
                    onChange={(e) => setLocalSettings({
                      ...localSettings,
                      rewardType: e.target.value
                    })}
                    label="Relief Type"
                  >
                    {rewardTypes.map(type => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControlLabel
                  control={
                    <Switch
                      checked={localSettings.autoAssignReward}
                      onChange={(e) => setLocalSettings({
                        ...localSettings,
                        autoAssignReward: e.target.checked
                      })}
                    />
                  }
                  label="Auto-assign Reward"
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowSettingsDialog(false)}>Cancel</Button>
              <Button 
                onClick={() => handleSave({ ...localSettings, enabled: true })} 
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          {localSettings.enabled && (
            <Paper sx={{ p: 2, mt: 2 }}>
              <Typography variant="subtitle2" gutterBottom>Current Settings</Typography>
              <List>
                <ListItem>
                  <ListItemText 
                    primary="Frequency"
                    secondary={`Every ${localSettings.frequency.value} ${localSettings.frequency.unit}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText 
                    primary="Relief Type"
                    secondary={rewardTypes.find(t => t.value === localSettings.rewardType)?.label}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText 
                    primary="Auto-assign Reward"
                    secondary={localSettings.autoAssignReward ? 'Yes' : 'No'}
                  />
                </ListItem>
              </List>
            </Paper>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

const SpotChecks = ({ schedule, onChange }) => {
  const [showSettingsDialog, setShowSettingsDialog] = useState(false);
  const [localSettings, setLocalSettings] = useState({
      enabled: schedule?.spotChecks?.enabled || false,
      startHour: schedule?.spotChecks?.startHour || 9,
      endHour: schedule?.spotChecks?.endHour || 22,
      frequency: {
        mode: schedule?.spotChecks?.frequency?.mode || 'fixed',
        value: schedule?.spotChecks?.frequency?.value || 4,
        unit: schedule?.spotChecks?.frequency?.unit || 'hours',
        minValue: schedule?.spotChecks?.frequency?.minValue || 4,
        maxValue: schedule?.spotChecks?.frequency?.maxValue || 8
      }
    });

  const handleEnableChange = (e) => {
    if (e.target.checked) {
      setShowSettingsDialog(true);
    } else {
      handleSave({ enabled: false });
    }
  };

  const handleSave = (settings) => {
    onChange('spotChecks', settings);
    setShowSettingsDialog(false);
  };

  return (
    <Card>
      <CardContent>
        <Stack spacing={3}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Speed />
            <Typography variant="h6">Spot Checks</Typography>
          </Stack>

          <FormControlLabel
            control={
              <Switch
                checked={localSettings.enabled}
                onChange={handleEnableChange}
              />
            }
            label="Enable Spot Checks"
          />

          <Dialog open={showSettingsDialog} onClose={() => setShowSettingsDialog(false)}>
            <DialogTitle>Spot Check Settings</DialogTitle>
            <DialogContent>
              <Stack spacing={2} sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Start Hour"
                      value={localSettings.startHour}
                      onChange={(e) => setLocalSettings({
                        ...localSettings,
                        startHour: parseInt(e.target.value)
                      })}
                      inputProps={{ min: 0, max: 23 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="End Hour"
                      value={localSettings.endHour}
                      onChange={(e) => setLocalSettings({
                        ...localSettings,
                        endHour: parseInt(e.target.value)
                      })}
                      inputProps={{ min: 0, max: 23 }}
                    />
                  </Grid>
                </Grid>

                <FormControl fullWidth>
                  <InputLabel>Check Frequency</InputLabel>
                  <Select
                    value={localSettings.frequency.mode}
                    onChange={(e) => setLocalSettings({
                      ...localSettings,
                      frequency: {
                        ...localSettings.frequency,
                        mode: e.target.value
                      }
                    })}
                    label="Check Frequency"
                  >
                    <MenuItem value="fixed">Fixed Interval</MenuItem>
                    <MenuItem value="random">Random Interval</MenuItem>
                  </Select>
                </FormControl>

                {localSettings.frequency.mode === 'fixed' ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Interval"
                        value={localSettings.frequency.value}
                        onChange={(e) => setLocalSettings({
                          ...localSettings,
                          frequency: {
                            ...localSettings.frequency,
                            value: parseInt(e.target.value)
                          }
                        })}
                        inputProps={{ min: 1 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel>Unit</InputLabel>
                        <Select
                          value={localSettings.frequency.unit}
                          onChange={(e) => setLocalSettings({
                            ...localSettings,
                            frequency: {
                              ...localSettings.frequency,
                              unit: e.target.value
                            }
                          })}
                          label="Unit"
                        >
                          <MenuItem value="hours">Hours</MenuItem>
                          <MenuItem value="days">Days</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Minimum Interval (hours)"
                        value={localSettings.frequency.minValue}
                        onChange={(e) => setLocalSettings({
                          ...localSettings,
                          frequency: {
                            ...localSettings.frequency,
                            minValue: parseInt(e.target.value)
                          }
                        })}
                        inputProps={{ min: 1 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Maximum Interval (hours)"
                        value={localSettings.frequency.maxValue}
                        onChange={(e) => setLocalSettings({
                          ...localSettings,
                          frequency: {
                            ...localSettings.frequency,
                            maxValue: parseInt(e.target.value)
                          }
                        })}
                        inputProps={{ min: 1 }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowSettingsDialog(false)}>Cancel</Button>
              <Button 
                onClick={() => handleSave({ ...localSettings, enabled: true })} 
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          {localSettings.enabled && (
            <Paper sx={{ p: 2, mt: 2 }}>
              <Typography variant="subtitle2" gutterBottom>Current Settings</Typography>
              <List>
                <ListItem>
                  <ListItemText 
                    primary="Active Hours"
                    secondary={`${localSettings.startHour}:00 - ${localSettings.endHour}:00`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText 
                    primary="Frequency"
                    secondary={
                      localSettings.frequency.mode === 'fixed'
                        ? `Every ${localSettings.frequency.value} ${localSettings.frequency.unit}`
                        : `Random between ${localSettings.frequency.minValue} and ${localSettings.frequency.maxValue} hours`
                    }
                  />
                </ListItem>
              </List>
            </Paper>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

const EventsTab = ({ settings, onReschedule, onPreview }) => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchUpcomingEvents = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/chastity/upcoming-events');
      setUpcomingEvents(response.data);
    } catch (error) {
      console.error('Failed to fetch upcoming events:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUpcomingEvents();
  }, []);

  return (
    <Card>
      <CardContent>
        <Stack spacing={3}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Today />
            <Typography variant="h6">Upcoming Events</Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              startIcon={<Preview />}
              variant="outlined"
              onClick={() => onPreview(upcomingEvents)}
              sx={{ mr: 1 }}
            >
              Preview Schedule
            </Button>
            <Button
              startIcon={<Refresh />}
              onClick={fetchUpcomingEvents}
            >
              Refresh
            </Button>
          </Stack>

          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <List>
              {upcomingEvents.map((event) => (
                <ListItem
                  key={event.id}
                  sx={{
                    borderLeft: 2,
                    borderColor: 'primary.main',
                    pl: 3,
                    mb: 2
                  }}
                >
                  <ListItemIcon>
                    {event.type === 'lock' && <Lock color="primary" />}
                    {event.type === 'unlock' && <LockOpen color="primary" />}
                    {event.type === 'cleaning' && <CleaningServices color="primary" />}
                    {event.type === 'relief' && <Casino color="primary" />}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        {event.type.toUpperCase()}
                      </Typography>
                    }
                    secondary={moment(event.scheduled_time).format('LLLL')}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() => onReschedule(event)}
                    >
                      <Edit />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export const ChastitySettings = ({ settings, onUpdate }) => {
  const [activeTab, setActiveTab] = useState(() => {
    return parseInt(localStorage.getItem('chastitySettingsTab') || '0');
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [previewEvents, setPreviewEvents] = useState(null);
  const [rescheduleEvent, setRescheduleEvent] = useState(null);
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    localStorage.setItem('chastitySettingsTab', newValue.toString());
  };

  const handleScheduleChange = async (settingType, settingValue) => {
      try {
          const updatedSchedule = {
              // Preserve the existing mode
              mode: settings.chastity_schedule?.mode || 'custom',
              // Preserve existing configurations
              ...(settings.chastity_schedule || {}),
              // Update the specific setting
              [settingType]: settingValue
          };
          
          const updatedSettings = {
              ...settings,
              chastity_schedule: updatedSchedule
          };
          
          await axiosInstance.put('/settings', updatedSettings);
          onUpdate('chastity_schedule', updatedSchedule);
          setSuccess('Settings updated successfully');
      } catch (error) {
          console.error('Failed to update schedule:', error);
          setError('Failed to update schedule');
      }
  };

  const tabs = [
    { label: 'General', icon: <Settings /> },
    { label: 'Schedule', icon: <Schedule /> },
    { label: 'Cleaning', icon: <CleaningServices /> },
    { label: 'Relief', icon: <Casino /> },
    { label: 'Spot Checks', icon: <Speed /> },
    { label: 'Events', icon: <Today /> }
  ];

  const handleRescheduleEvent = async (eventId, newDateTime) => {
    try {
      await axiosInstance.post(`/chastity/events/${eventId}/reschedule`, {
        new_time: newDateTime
      });
      setSuccess('Event rescheduled successfully');
      setRescheduleEvent(null);
      if (activeTab === 5) {
        const response = await axiosInstance.get('/chastity/upcoming-events');
        setUpcomingEvents(response.data);
      }
    } catch (error) {
      setError('Failed to reschedule event');
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ mb: 2 }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                icon={tab.icon}
                label={tab.label}
                sx={{ minHeight: 48 }}
              />
            ))}
          </Tabs>
        </Paper>

        <Box sx={{ mt: 2 }}>
          {activeTab === 0 && (
            <Card>
              <CardContent>
                <Stack spacing={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={settings.chastity_enabled}
                        onChange={(e) => onUpdate('chastity_enabled', e.target.checked)}
                      />
                    }
                    label="Enable Chastity Mode"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={settings.is_permanent_chastity}
                        onChange={(e) => onUpdate('is_permanent_chastity', e.target.checked)}
                      />
                    }
                    label="Permanent Chastity"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={settings.chastity_wheel}
                        onChange={(e) => onUpdate('chastity_wheel', e.target.checked)}
                      />
                    }
                    label="Enable Chastity Wheel"
                  />
                </Stack>
              </CardContent>
            </Card>
          )}

          {activeTab === 1 && (
            <ScheduleSettings
              schedule={settings.chastity_schedule}
              onChange={handleScheduleChange}
            />
          )}

          {activeTab === 2 && (
            <CleaningSchedule
              settings={settings}
              onChange={handleScheduleChange}
            />
          )}
          
          {activeTab === 3 && (
            <ReliefSchedule
              settings={settings}
              onChange={handleScheduleChange}
            />
          )}
          
          {activeTab === 4 && (
            <SpotChecks
              settings={settings}
              onChange={handleScheduleChange}
            />
          )}

          {activeTab === 5 && (
            <EventsTab
              settings={settings}
              onReschedule={setRescheduleEvent}
              onPreview={setPreviewEvents}
            />
          )}
        </Box>

        {previewEvents !== null && (
          <EventPreview 
            events={previewEvents} 
            onClose={() => setPreviewEvents(null)} 
          />
        )}

        {rescheduleEvent && (
          <RescheduleDialog
            event={rescheduleEvent}
            onClose={() => setRescheduleEvent(null)}
            onReschedule={handleRescheduleEvent}
          />
        )}

        <Snackbar
          open={!!error || !!success}
          autoHideDuration={3000}
          onClose={() => {
            setError('');
            setSuccess('');
          }}
        >
          <Alert 
            severity={error ? 'error' : 'success'} 
            onClose={() => {
              setError('');
              setSuccess('');
            }}
            sx={{ width: '100%' }}
          >
            {error || success}
          </Alert>
        </Snackbar>
      </Box>
    </LocalizationProvider>
  );
};

export default ChastitySettings;