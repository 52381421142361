// At the top of this page put an explanation for the rating options

import React, { useState, useEffect } from 'react';
import { axiosInstance } from './auth';
import {
  Container, Typography, Accordion, AccordionSummary, AccordionDetails, 
  Select, MenuItem, Snackbar, CircularProgress, Card, CardContent,
  Box, Chip, Tooltip, List, Tabs, Tab, FormControl
} from '@mui/material';
import { ExpandMore, EmojiEvents, Gavel, Rule, Task } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { examples } from './examples';
import { useLocation } from 'react-router-dom';
import Discovery from './Discovery';

const submissiveRatingOptions = [
  'select',
  'hard no',
  'uncertain',
  'unsure but interested',
  'if forced',
  'maybe',
  'fantasy/roleplay only',
  'yes',
  'please',
  'need',
];

const dominantRatingOptions = [
  'select',
  'hard no',
  'uncertain',
  'unsure but interested',
  'willing to try',
  'maybe',
  'fantasy/roleplay only',
  'yes',
  'very much yes',
  'mandatory'
];

const ratingEmojis = {
  'select': '',
  'hard no': '🚫',
  'uncertain': '❓',
  'fantasy/roleplay only': '🎭',
  'if forced': '😬',
  'maybe': '🤷',
  'yes': '😊',
  'please': '😍',
  'willing to try': '🤨',
  'unsure but interested': '🤔',
  'very much yes': '🥰',
  'need': '💖',
  'mandatory': '💖'
};

const categoryEmojis = {
  'Humiliation and Degradation': '😳',
  'Fetish Wear': '👠',
  'Impact Play': '🫸',
  'Domestic Service': '🧹',
  'Bondage and Restraints': '⛓️',
  'Roleplay and Power Exchange': '🎭',
  'Extreme Practices': '🚽',
  'Femdom': '👑',
  'Pain and Intense Sensation': '🫦',
  'Sensory Play': '🌟',
  'Anal Play': '🍆',
  'Exhibitionism and Voyeurism': '👀',
  'Body Modification and Marking': '✒️',
  'Mental and Emotional Play': '🧠',
  'Toys and Devices': '🤖',
  'Group Activities': '🫂'
};

const Kinks = () => {
  const theme = useTheme();
  const [kinks, setKinks] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [expandedCategory, setExpandedCategory] = useState(null);
  const [userData, setUserData] = useState(null);
  const [hardNoCategories, setHardNoCategories] = useState(new Set());

  const [filterOption, setFilterOption] = useState('');

  const [tabValue, setTabValue] = useState(0);
  const location = useLocation();
  const [sortedKinks, setSortedKinks] = useState({});

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    switch (hash) {
      default:
        setTabValue(0);
    }
  }, [location]);

useEffect(() => {
  const fetchUserData = async () => {
    console.log('Fetching user data...');
    try {
      const response = await axiosInstance.get('/user-profile');
      console.log('User data received:', response.data);
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setErrorMessage('Failed to fetch user data');
      setIsLoading(false);  // Set loading to false on error
    }
  };

  fetchUserData();
}, []);

useEffect(() => {
  if (userData && !filterOption) {  // Only set if filterOption is empty
    console.log('Setting initial filter option based on userData');
    if (userData.is_switch) {
      setFilterOption(`${userData.user_type === 'dominant' ? 'dom' : 'sub'}_`);
    } else {
      setFilterOption('');  // "Filter by alignment" for non-switch users
    }
  }
}, [userData, filterOption]);

useEffect(() => {
  const fetchKinks = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get('/kinks');
      const filteredKinks = response.data.filter(kink => shouldShowKink(kink, userData));
      setKinks(filteredKinks);
    } catch (error) {
      console.error('Error fetching kinks:', error);
      setErrorMessage('Failed to fetch kinks. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (userData) {
    fetchKinks();
  }
}, [userData]);

useEffect(() => {
  console.log('filterOption changed to:', filterOption);
}, [filterOption]);

useEffect(() => {
  if (!kinks.length || !userData) return;

  console.log('Filtering and sorting with filterOption:', filterOption);

  // First group kinks by category
  const grouped = kinks.reduce((acc, kink) => {
    if (!acc[kink.category]) {
      acc[kink.category] = [];
    }
    acc[kink.category].push(kink);
    return acc;
  }, {});

  // Handle different filter/sort scenarios
  let processedKinks = {};

  if (filterOption === '#f5f5f5') { // Not Filled - Filter
    Object.entries(grouped).forEach(([category, categoryKinks]) => {
      const filteredKinks = categoryKinks.filter(kink => 
        isUnfilled(kink, userData.user_type, userData.is_switch)
      );
      if (filteredKinks.length > 0) {
        processedKinks[category] = filteredKinks;
      }
    });
  } 
  else if (filterOption === '#ffcdd2') { // Limits - Filter
    Object.entries(grouped).forEach(([category, categoryKinks]) => {
      const filteredKinks = categoryKinks.filter(kink => hasHardLimits(kink));
      if (filteredKinks.length > 0) {
        processedKinks[category] = filteredKinks;
      }
    });
  }
  else if (filterOption === 'uncertain') { // Uncertain - Filter
    Object.entries(grouped).forEach(([category, categoryKinks]) => {
      const filteredKinks = categoryKinks.filter(kink => 
        isUncertain(kink, userData.user_type, userData.is_switch)
      );
      if (filteredKinks.length > 0) {
        processedKinks[category] = filteredKinks;
      }
    });
  }
  else if (filterOption.includes('_')) { // Role-based - Sort only
    const role = filterOption.split('_')[0];
    
    // Sort all categories by their total alignment score
    processedKinks = Object.entries(grouped)
      .sort(([, kinksA], [, kinksB]) => {
        const scoreA = kinksA.reduce((sum, kink) => sum + getRoleAlignmentScore(kink, role), 0);
        const scoreB = kinksB.reduce((sum, kink) => sum + getRoleAlignmentScore(kink, role), 0);
        return scoreB - scoreA;
      })
      .reduce((acc, [category, categoryKinks]) => {
        // Sort kinks within each category by their individual scores
        acc[category] = [...categoryKinks].sort((a, b) => {
          const scoreA = getRoleAlignmentScore(a, role);
          const scoreB = getRoleAlignmentScore(b, role);
          return scoreB - scoreA;
        });
        return acc;
      }, {});
  }
  else {
    // Default or color-based sorting - include all kinks
    processedKinks = grouped;
  }

  console.log('Setting processed kinks:', processedKinks);
  setSortedKinks(processedKinks);
}, [filterOption, kinks, userData]);

// Helper function to calculate priority of colors
const getColorPriority = (color) => {
  switch (color) {
    case '#c8e6c9': return 3;  // Strong alignment
    case '#ffe0b2': return 2;  // Partial alignment
    case '#ffcdd2': return 1;  // Hard limits
    default: return 0;
  }
};

const getRoleCardColors = (kink) => {
  if (!userData?.partner) return { domColor: 'inherit', subColor: 'inherit' };

  // Get dom alignment color
  const domUserRating = userData.user_type === 'dominant' 
    ? kink.dominant_giving_rating 
    : kink.submissive_giving_rating;
  const domPartnerRating = userData.user_type === 'dominant'
    ? kink.submissive_receiving_rating
    : kink.dominant_receiving_rating;

  // Get sub alignment color
  const subUserRating = userData.user_type === 'submissive'
    ? kink.submissive_receiving_rating
    : kink.dominant_receiving_rating;
  const subPartnerRating = userData.user_type === 'submissive'
    ? kink.dominant_giving_rating
    : kink.submissive_giving_rating;

  const getDomColor = () => {
    // Hard limits take precedence
    if (domUserRating === 'hard no' || domPartnerRating === 'hard no') {
      return '#ffcdd2';  // Light red
    }
    // Both must have responded for other colors
    if (domUserRating === 'select' || domPartnerRating === 'select') {
      return 'inherit';
    }
    // Strong positive alignment
    if ((['yes', 'please', 'need'].includes(domUserRating) && 
         ['yes', 'very much yes'].includes(domPartnerRating)) ||
        (['yes', 'very much yes'].includes(domUserRating) && 
         ['yes', 'please', 'need'].includes(domPartnerRating))) {
      return '#c8e6c9';  // Light green
    }
    // Partial alignment
    if ((['maybe', 'if forced', 'uncertain but interested', 'willing to try', 'need', 'fantasy/roleplay only', 'yes', 'very much yes'].includes(domUserRating) &&
         ['maybe', 'willing to try', 'uncertain but interested', 'fantasy/roleplay only'].includes(domPartnerRating)) ||
        (['maybe', 'willing to try', 'uncertain but interested', 'fantasy/roleplay only'].includes(domUserRating) &&
         ['maybe', 'if forced', 'uncertain but interested', 'willing to try', 'need', 'fantasy/roleplay only', 'yes', 'very much yes'].includes(domPartnerRating))) {
      return '#ffe0b2';  // Light orange
    }
    return 'inherit';
  };

  const getSubColor = () => {
    // Hard limits take precedence
    if (subUserRating === 'hard no' || subPartnerRating === 'hard no') {
      return '#ffcdd2';  // Light red
    }
    // Both must have responded for other colors
    if (subUserRating === 'select' || subPartnerRating === 'select') {
      return 'inherit';
    }
    // Strong positive alignment
    if ((['yes', 'please', 'need'].includes(subUserRating) && 
         ['yes', 'very much yes'].includes(subPartnerRating)) ||
        (['yes', 'very much yes'].includes(subUserRating) && 
         ['yes', 'please', 'need'].includes(subPartnerRating))) {
      return '#c8e6c9';  // Light green
    }
    // Partial alignment
    if ((['maybe', 'if forced', 'uncertain but interested', 'willing to try', 'need', 'fantasy/roleplay only', 'yes', 'very much yes'].includes(subUserRating) &&
         ['maybe', 'willing to try', 'unsure but interested', 'fantasy/roleplay only'].includes(subPartnerRating)) ||
        (['maybe', 'willing to try', 'unsure but interested', 'fantasy/roleplay only'].includes(subUserRating) &&
         ['maybe', 'if forced', 'uncertain but interested', 'willing to try', 'need', 'fantasy/roleplay only', 'yes', 'very much yes'].includes(subPartnerRating))) {
      return '#ffe0b2';  // Light orange
    }
    return 'inherit';
  };

  return {
    domColor: getDomColor(),
    subColor: getSubColor()
  };
};

const isUnfilled = (kink, userType, isSwitch = false) => {
  if (userType === 'submissive') {
    if (isSwitch) {
      // Both roles must be unfilled for switch users
      return kink.submissive_receiving_rating === 'select' || kink.submissive_giving_rating === 'select';
    }
    return kink.submissive_receiving_rating === 'select';
  } else {
    if (isSwitch) {
      // Both roles must be unfilled for switch users
      return kink.dominant_giving_rating === 'select' || kink.dominant_receiving_rating === 'select';
    }
    return kink.dominant_giving_rating === 'select';
  }
};

// Helper function to check if a kink has any hard limits
const hasHardLimits = (kink) => {
  if (!userData?.partner) return false;

  const userType = userData.user_type;
  const isUserSwitch = userData.is_switch;
  const isPartnerSwitch = userData.partner.is_switch;

  // Helper to check complementary ratings
  const getComplementaryRatings = () => {
    if (userType === 'submissive') {
      return {
        userGiving: kink.submissive_giving_rating,
        userReceiving: kink.submissive_receiving_rating,
        partnerGiving: kink.dominant_giving_rating,
        partnerReceiving: kink.dominant_receiving_rating
      };
    } else {
      return {
        userGiving: kink.dominant_giving_rating,
        userReceiving: kink.dominant_receiving_rating,
        partnerGiving: kink.submissive_giving_rating,
        partnerReceiving: kink.submissive_receiving_rating
      };
    }
  };

  const { userGiving, userReceiving, partnerGiving, partnerReceiving } = getComplementaryRatings();

  // For switch users, check both roles
  if (isUserSwitch) {
    // Check user's ratings in both roles
    if (userGiving === 'hard no' || userReceiving === 'hard no') {
      return true;
    }
  } else {
    // For non-switch users, only check their primary role
    if (userType === 'submissive' && userReceiving === 'hard no') {
      return true;
    }
    if (userType === 'dominant' && userGiving === 'hard no') {
      return true;
    }
  }

  // Check partner's ratings
  if (isPartnerSwitch) {
    // Check partner's ratings in both roles
    if (partnerGiving === 'hard no' || partnerReceiving === 'hard no') {
      return true;
    }
  } else {
    // For non-switch partners, only check their primary role
    if (userData.partner.user_type === 'submissive' && partnerReceiving === 'hard no') {
      return true;
    }
    if (userData.partner.user_type === 'dominant' && partnerGiving === 'hard no') {
      return true;
    }
  }

  return false;
};

// Helper function to check if kink is uncertain for current user
const isUncertain = (kink, userType, isSwitch = false) => {
  if (userType === 'submissive') {
    if (isSwitch) {
      // Check both roles for switch users
      return kink.submissive_receiving_rating === 'uncertain' || 
             kink.submissive_giving_rating === 'uncertain';
    }
    return kink.submissive_receiving_rating === 'uncertain';
  } else {
    if (isSwitch) {
      // Check both roles for switch users
      return kink.dominant_giving_rating === 'uncertain' || 
             kink.dominant_receiving_rating === 'uncertain';
    }
    return kink.dominant_giving_rating === 'uncertain';
  }
};

// Helper function to calculate category score
const calculateCategoryScore = (kinks, filterOption) => {
  return kinks.reduce((score, kink) => {
    const color = filterOption.includes('_') 
      ? determineCardColor(kink, filterOption.split('_')[0])
      : determineCardColor(kink);
    return score + getColorPriority(color);
  }, 0);
};

  const handleAccordionChange = (category) => (event, isExpanded) => {
    setExpandedCategory(isExpanded ? category : null);
  };

  const handleBatchRatingChange = async (category, rating, aspect) => {
    try {
      const categoryKinks = kinks.filter(kink => kink.category === category);
      const updates = categoryKinks.map(kink => ({
        id: kink.id,
        rating: rating,
        aspect: aspect
      }));
      
      // Make all API calls in parallel
      const responses = await Promise.all(
        updates.map(update => axiosInstance.put('/kinks', update))
      );
      
      // Update local state with all responses
      setKinks(prevKinks => {
        const newKinks = [...prevKinks];
        responses.forEach(response => {
          const index = newKinks.findIndex(k => k.id === response.data.id);
          if (index !== -1) {
            newKinks[index] = response.data;
          }
        });
        return newKinks;
      });

      // Check if this creates a new hard no category
      updateHardNoCategories();
    } catch (error) {
      console.error('Error updating batch ratings:', error);
      setErrorMessage('Failed to update category ratings. Please try again.');
    }
  };

  const isCategoryAllHardNo = (categoryKinks) => {
    if (!userData || !categoryKinks.length) return false;

    return categoryKinks.every(kink => {
      if (userData.user_type === 'submissive') {
        const subRating = kink.submissive_receiving_rating === 'hard no';
        const domRating = kink.dominant_giving_rating === 'hard no';
        return subRating || domRating;
      } else {
        const domRating = kink.dominant_giving_rating === 'hard no';
        const subRating = kink.submissive_receiving_rating === 'hard no';
        return domRating || subRating;
      }
    });
  };

const updateHardNoCategories = () => {
  const newHardNoCategories = new Set();
  Object.entries(sortedKinks).forEach(([category, categoryKinks]) => {
    if (isCategoryAllHardNo(categoryKinks)) {
      newHardNoCategories.add(category);
    }
  });
  setHardNoCategories(newHardNoCategories);
};

  useEffect(() => {
    updateHardNoCategories();
  }, [kinks, userData]);

  const handleRatingChange = async (kinkId, rating, aspect) => {
    try {
      const payload = { 
        id: kinkId, 
        rating: rating,
        aspect: aspect
      };
      const response = await axiosInstance.put('/kinks', payload);
      if (response.status === 200) {
        setKinks(prevKinks => prevKinks.map(kink => 
          kink.id === kinkId ? response.data : kink
        ));
      }
    } catch (error) {
      console.error('Error updating kink rating:', error);
      setErrorMessage('Failed to update kink rating. Please try again.');
    }
  };

  const shouldShowKink = (kink, userData) => {
    if (!userData || !userData.partner) return true;
    
    const dominantUser = userData.user_type === 'dominant' ? userData : userData.partner;
    const submissiveUser = userData.user_type === 'submissive' ? userData : userData.partner;
    
    // Helper function to check if a user is male-identified
    const isMaleIdentified = (user) => {
      return user.sex === 'AMAB' || user.sex === 'FTM' || user.gender === 'male';
    };
  
    // Femdom category logic
    if (kink.category === 'Femdom') {
      const domIsMale = isMaleIdentified(dominantUser);
      const subIsMale = isMaleIdentified(submissiveUser);
      
      if (!dominantUser.is_switch && !submissiveUser.is_switch) {
        // If neither is switch, only show if dom isn't male-identified
        return !domIsMale;
      } else {
        // If either is switch, show if at least one user isn't male-identified
        return !domIsMale || !subIsMale;
      }
    }
  
    // Pain and Intense Sensation category specific kinks
    if (kink.category === 'Pain and Intense Sensation') {
      if (kink.name === 'CBT (Cock and Ball Torture)') {
        const subHasCbt = submissiveUser.sex === 'AMAB' || submissiveUser.sex === 'FTM';
        const domHasCbt = dominantUser.sex === 'AMAB' || dominantUser.sex === 'FTM';
        
        // Show if sub has applicable anatomy, or if either is switch and either has applicable anatomy
        return subHasCbt || (dominantUser.is_switch && submissiveUser.is_switch && (subHasCbt || domHasCbt));
      }
      
      if (kink.name === 'Breast Torture') {
        const subHasBreasts = submissiveUser.sex !== 'AMAB' && submissiveUser.sex !== 'FTM';
        const domHasBreasts = dominantUser.sex !== 'AMAB' && dominantUser.sex !== 'FTM';
        
        // Show if sub has breasts, or if either is switch and either has breasts
        return subHasBreasts || (dominantUser.is_switch && submissiveUser.is_switch && (subHasBreasts || domHasBreasts));
      }
    }
  
    // Humiliation and Degradation category specific kinks
    if (kink.category === 'Humiliation and Degradation') {
      if (kink.name === 'Pussy Worship') {
        const domHasPussy = dominantUser.sex !== 'AMAB' && dominantUser.sex !== 'FTM';
        const subHasPussy = submissiveUser.sex !== 'AMAB' && submissiveUser.sex !== 'FTM';
        
        // Show if dom has applicable anatomy, or if either is switch and either has applicable anatomy
        return domHasPussy || (dominantUser.is_switch && submissiveUser.is_switch && (domHasPussy || subHasPussy));
      }
    }
  
    return true;
  };
  
const getRoleAlignmentScore = (kink, role) => {
  if (!userData?.partner) return 0;
  
  let userRating, partnerRating;
  
  if (role === 'dom') {
    // When checking dom alignment, compare user's dom rating with partner's sub rating
    if (userData.user_type === 'dominant') {
      userRating = kink.dominant_giving_rating;
      partnerRating = kink.submissive_receiving_rating;
    } else {
      userRating = kink.submissive_giving_rating;
      partnerRating = kink.dominant_receiving_rating;
    }
  } else {
    // When checking sub alignment, compare user's sub rating with partner's dom rating
    if (userData.user_type === 'submissive') {
      userRating = kink.submissive_receiving_rating;
      partnerRating = kink.dominant_giving_rating;
    } else {
      userRating = kink.dominant_receiving_rating;
      partnerRating = kink.submissive_giving_rating;
    }
  }

  // Check for hard limits first
  if (userRating === 'hard no' || partnerRating === 'hard no') {
    return 0;
  }

  // Both must have responded
  if (userRating === 'select' || partnerRating === 'select') {
    return 0;
  }

  // Strong positive alignment
  if ((['yes', 'please'].includes(userRating) && 
       ['yes', 'very much yes'].includes(partnerRating)) ||
      (['yes', 'very much yes'].includes(userRating) && 
       ['yes', 'please'].includes(partnerRating))) {
    return 3;
  }

  // Partial alignment
  if ((['maybe', 'if forced', 'fantasy/roleplay only', 'yes', 'very much yes'].includes(userRating) &&
       ['maybe', 'willing to try', 'fantasy/roleplay only'].includes(partnerRating)) ||
      (['maybe', 'willing to try', 'fantasy/roleplay only'].includes(userRating) &&
       ['maybe', 'if forced', 'fantasy/roleplay only', 'yes', 'very much yes'].includes(partnerRating))) {
    return 2;
  }

  return 0;
};


  // Helper function to determine which rating options to show for a specific kink
  const getRatingOptions = (kink, aspect, userData, isUserSwitch) => {
    if (!shouldShowKink(kink, userData)) return null;
    
    const dominantUser = userData.user_type === 'dominant' ? userData : userData.partner;
    const submissiveUser = userData.user_type === 'submissive' ? userData : userData.partner;
    
    // Special handling for switch users
    if (isUserSwitch) {
      // For Femdom category
      if (kink.category === 'Femdom') {
        const userIsMale = userData.sex === 'AMAB' || userData.sex === 'FTM' || userData.gender === 'male';
        if (aspect === 'giving' && userIsMale) return null;
        if (aspect === 'receiving' && !userIsMale) return null;
      }
      
      // For anatomy-specific kinks
      if (kink.name === 'CBT (Cock and Ball Torture)') {
        const userHasCbt = userData.sex === 'AMAB' || userData.sex === 'FTM';
        if (aspect === 'receiving' && !userHasCbt) return null;
      }
      
      if (kink.name === 'Breast Torture') {
        const userHasBreasts = userData.sex !== 'AMAB' && userData.sex !== 'FTM';
        if (aspect === 'receiving' && !userHasBreasts) return null;
      }
      
      if (kink.name === 'Pussy Worship') {
        const userHasPussy = userData.sex !== 'AMAB' && userData.sex !== 'FTM';
        if (aspect === 'receiving' && !userHasPussy) return null;
      }
    }
  
    // Return appropriate rating options based on aspect
    return aspect === 'giving' ? dominantRatingOptions : submissiveRatingOptions;
  };

  const isUserSwitch = () => userData?.is_switch || false;
  const isPartnerSwitch = () => userData?.partner?.is_switch || false;

  const getKinkRating = (kink, userType, aspect) => {
    if (userType === 'submissive') {
      return aspect === 'giving' ? kink.submissive_giving_rating : kink.submissive_receiving_rating;
    } else {
      return aspect === 'giving' ? kink.dominant_giving_rating : kink.dominant_receiving_rating;
    }
  };

  const getPartnerRating = (kink, userType, aspect) => {
    if (userType === 'submissive') {
      return aspect === 'giving' ? kink.dominant_receiving_rating : kink.dominant_giving_rating;
    } else {
      return aspect === 'giving' ? kink.submissive_receiving_rating : kink.submissive_giving_rating;
    }
  };

const determineCardColor = (kink, forcedRole = null) => {
  if (!userData) return 'inherit';

  const userType = userData.user_type;
  const isUserSwitchRole = isUserSwitch();
  const isPartnerSwitchRole = isPartnerSwitch();

  const getRatings = (asRole) => {
    let userGiving, userReceiving, partnerGiving, partnerReceiving;

    if (userType === 'submissive') {
      userGiving = kink.submissive_giving_rating;
      userReceiving = kink.submissive_receiving_rating;
      partnerGiving = kink.dominant_giving_rating;
      partnerReceiving = kink.dominant_receiving_rating;
    } else {
      userGiving = kink.dominant_giving_rating;
      userReceiving = kink.dominant_receiving_rating;
      partnerGiving = kink.submissive_giving_rating;
      partnerReceiving = kink.submissive_receiving_rating;
    }

    // If checking alignment as dom
    if (asRole === 'dom') {
      return {
        userRating: userType === 'dominant' ? userGiving : partnerGiving,
        partnerRating: userType === 'dominant' ? partnerReceiving : userReceiving
      };
    }
    // If checking alignment as sub
    if (asRole === 'sub') {
      return {
        userRating: userType === 'submissive' ? userReceiving : partnerReceiving,
        partnerRating: userType === 'submissive' ? partnerGiving : userGiving
      };
    }

    // Default behavior for non-switch users
    return {
      userRating: userType === 'dominant' ? userGiving : userReceiving,
      partnerRating: userType === 'dominant' ? partnerReceiving : partnerGiving
    };
  };

  const { userRating, partnerRating } = getRatings(forcedRole);

  // First check for hard limits - show red even if only one person responded
  if (['hard no'].includes(userRating) || 
      ['hard no'].includes(partnerRating)) {
    return '#ffcdd2';  // Light red
  }

  // Both users must have responded for other colors
  if (userRating === 'select' || partnerRating === 'select') {
    return 'inherit';
  }

  // Strong positive alignment (only if both have responded)
  if ((['yes', 'please'].includes(userRating) && 
       ['yes', 'very much yes'].includes(partnerRating)) ||
      (['yes', 'very much yes'].includes(userRating) && 
       ['yes', 'please'].includes(partnerRating))) {
    return '#c8e6c9';  // Light green
  }

  // Partial alignment (only if both have responded)
  if ((['maybe', 'if forced', 'fantasy/roleplay only', 'yes', 'very much yes'].includes(userRating) &&
       ['maybe', 'willing to try', 'fantasy/roleplay only'].includes(partnerRating)) ||
      (['maybe', 'willing to try', 'fantasy/roleplay only'].includes(userRating) &&
       ['maybe', 'if forced', 'fantasy/roleplay only', 'yes', 'very much yes'].includes(partnerRating))) {
    return '#ffe0b2';  // Light orange
  }

  return 'inherit';
};

  const getExampleCounts = (subcategory) => {
    const subcategoryLower = subcategory.toLowerCase();
    return {
      rewards: examples.rewards.filter(ex => ex.category.toLowerCase() === subcategoryLower).length,
      punishments: examples.punishments.filter(ex => ex.category.toLowerCase() === subcategoryLower).length,
      rules: examples.rules.filter(ex => ex.category.toLowerCase() === subcategoryLower).length,
      tasks: examples.tasks.filter(ex => ex.category.toLowerCase() === subcategoryLower).length
    };
  };

  const ExampleCounts = ({ counts }) => {
    return (
      <Typography 
        variant="caption" 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 0.5,
          color: 'text.secondary',
          fontSize: '0.8rem',
          '& .MuiSvgIcon-root': {
            fontSize: '0.9rem'
          },
          '& span': {
            display: 'flex',
            alignItems: 'center',
            gap: 0.25,
            fontSize: '0.8rem'
          }
        }}
      >
        {counts.rewards > 0 && (
          <span title={`${counts.rewards} rewards`}>
            <EmojiEvents fontSize="inherit" /> {counts.rewards}
          </span>
        )}
        {counts.punishments > 0 && (
          <span title={`${counts.punishments} punishments`}>
            <Gavel fontSize="inherit" /> {counts.punishments}
          </span>
        )}
        {counts.rules > 0 && (
          <span title={`${counts.rules} rules`}>
            <Rule fontSize="inherit" /> {counts.rules}
          </span>
        )}
        {counts.tasks > 0 && (
          <span title={`${counts.tasks} tasks`}>
            <Task fontSize="inherit" /> {counts.tasks}
          </span>
        )}
      </Typography>
    );
  };

const getCategoryStats = (categoryKinks) => {
  const userIsSwitch = isUserSwitch();
  
  // For switch users, each kink counts as two possible entries (as dom and as sub)
  const total = categoryKinks.length * (userIsSwitch ? 2 : 1);
  
  const filled = categoryKinks.reduce((count, kink) => {
    if (!userData) return count;
    
    const userType = userData.user_type;
    
    if (userIsSwitch) {
      // Count both roles separately for switch users
      let filledCount = 0;
      if (userType === 'submissive') {
        if (kink.submissive_receiving_rating !== 'select') filledCount++;
        if (kink.submissive_giving_rating !== 'select') filledCount++;
      } else {
        if (kink.dominant_giving_rating !== 'select') filledCount++;
        if (kink.dominant_receiving_rating !== 'select') filledCount++;
      }
      return count + filledCount;
    } else {
      // For non-switch users, only count their primary role
      if (userType === 'submissive') {
        return count + (kink.submissive_receiving_rating !== 'select' ? 1 : 0);
      } else {
        return count + (kink.dominant_giving_rating !== 'select' ? 1 : 0);
      }
    }
  }, 0);
  
  const alignmentScore = categoryKinks.reduce((score, kink) => {
    const color = filterOption.includes('_')
      ? determineCardColor(kink, filterOption.split('_')[0])
      : determineCardColor(kink);
    
    switch(color) {
      case '#c8e6c9': return score + 3;  // Strong alignment
      case '#ffe0b2': return score + 2;  // Partial alignment
      case '#ffcdd2': return score + 1;  // Hard limits
      default: return score;
    }
  }, 0);

  return { filled, total, alignmentScore };
};

  const renderRatingSelect = (kink, aspect) => {
      if (!userData) return null;
      
      // Non-switch submissives can only rate receiving
      if (userData.user_type === 'submissive' && !userData.is_switch && aspect === 'giving') {
          return null;
      }
      
      // Non-switch dominants can only rate giving
      if (userData.user_type === 'dominant' && !userData.is_switch && aspect === 'receiving') {
          return null;
      }
      
      const value = getKinkRating(kink, userData.user_type, aspect);
      const options = aspect === 'giving' ? dominantRatingOptions : submissiveRatingOptions;
      const userLabel = aspect === 'giving' ? 'Giving:' : 'Receiving:';
      const partnerLabel = aspect === 'giving' ? 'Receiving:' : 'Giving:';
      
      return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
              <Typography variant="caption" sx={{ minWidth: '60px' }}>
                  {userLabel}
              </Typography>
              <Select
                  value={value || 'select'}
                  onChange={(e) => handleRatingChange(kink.id, e.target.value, aspect)}
                  sx={{ 
                      minWidth: 120,
                      color: theme.palette.mode === 'dark' && determineCardColor(kink) === 'inherit' ? 'white' : 'black',
                      '& .MuiSelect-select': { py: 0.5 }
                  }}
                  MenuProps={{
                      PaperProps: {
                          style: {
                              maxHeight: 500,
                              backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
                          },
                      },
                  }}
              >
                  {options.map((option) => (
                      <MenuItem 
                          key={option} 
                          value={option}
                          sx={{ 
                              py: 0.5,
                              color: theme.palette.mode === 'dark' ? 'white' : 'black',
                          }}
                      >
                          {ratingEmojis[option]} {option}
                      </MenuItem>
                  ))}
              </Select>
              {userData?.partner && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <Typography variant="caption" sx={{ ml: 1 }}>
                          {partnerLabel}
                      </Typography>
                      {renderPartnerRating(kink, aspect)}
                  </Box>
              )}
          </Box>
      );
  };

  const renderPartnerRating = (kink, aspect) => {
    if (!userData?.partner) return null;
    
    // Get the complementary rating
    const complementaryRating = aspect === 'giving' 
      ? getKinkRating(kink, userData.partner.user_type, 'receiving')
      : getKinkRating(kink, userData.partner.user_type, 'giving');
    
    if (!complementaryRating) return null;
  
    return (
      <Tooltip title={`${userData.partner.username}'s rating: ${complementaryRating}`} arrow>
        <Chip
          label={ratingEmojis[complementaryRating] || '🤔'}
          size="small"
          variant="outlined"
        />
      </Tooltip>
    );
  };

const renderKinkContent = (kink) => {
  const isInHardNoCategory = hardNoCategories.has(kink.category);
  const { domColor, subColor } = getRoleCardColors(kink);
  const hasGradient = domColor !== 'inherit' && subColor !== 'inherit' && domColor !== subColor;

  // Style configuration
  const cardStyle = {
    mb: 1,
    ...(isInHardNoCategory 
      ? { backgroundColor: '#f5f5f5', opacity: 0.7 }
      : hasGradient 
        ? {
            background: `linear-gradient(180deg, 
              ${domColor} 0%, 
              ${domColor} 30%, 
              mix-blend-multiply 50%,
              ${subColor} 70%, 
              ${subColor} 100%)`
          }
        : { backgroundColor: domColor !== 'inherit' ? domColor : subColor }
    ),
    position: 'relative',
    '&::before': hasGradient ? {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: `linear-gradient(180deg, 
        ${domColor} 0%,
        ${domColor}80 40%,
        ${subColor}80 60%,
        ${subColor} 100%)`,
      opacity: 0.9,
      zIndex: 0,
    } : {},
    '& .MuiCardContent-root': {
      position: 'relative',
      zIndex: 1
    },
    '& .MuiTypography-root': {
      color: (domColor !== 'inherit' || subColor !== 'inherit') ? 'black !important' : undefined,
      textDecoration: isInHardNoCategory ? 'line-through' : 'none',
    },
    '& .MuiTypography-secondary': {
      color: (domColor !== 'inherit' || subColor !== 'inherit') ? 'rgba(0, 0, 0, 0.7) !important' : undefined
    },
    '& .MuiChip-root': {
      color: (domColor !== 'inherit' || subColor !== 'inherit') ? 'black !important' : undefined
    }
  };

  return (
    <Card 
      key={kink.id} 
      sx={cardStyle}
    >
      <CardContent sx={{ py: 1, '&:last-child': { pb: 1 } }}>
        <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
          {kink.name}
        </Typography>
        <Typography variant="body2" sx={{ mb: 1, fontSize: '0.8rem' }}>
          {kink.description}
        </Typography>
        <ExampleCounts counts={getExampleCounts(kink.name)} />
        
        <Box sx={{ mt: 2 }}>
          {isUserSwitch() ? (
            <>
              {renderRatingSelect(kink, 'giving')}
              {renderRatingSelect(kink, 'receiving')}
            </>
          ) : (
            renderRatingSelect(kink, userData?.user_type === 'submissive' ? 'receiving' : 'giving')
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

const BatchRatingControls = ({ category, categoryKinks }) => {
  const isHardNoCategory = hardNoCategories.has(category);
  return (
    <Card sx={{ mb: 1, backgroundColor: isHardNoCategory ? '#f5f5f5' : 'background.paper' }}>
      <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 1, py: '4px', '&:last-child': { pb: '4px' } }}>
        <Typography sx={{fontSize: '0.7rem'}} >Set All:</Typography>
        {userData?.user_type === 'submissive' ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}><Typography sx = {{fontSize: '0.5rem'}}>Receiving:</Typography><Select size="small" sx={{ minWidth: 100, '.MuiSelect-select': { py: 0.5, fontSize: '0.5rem' } }} value="select" onChange={(e) => handleBatchRatingChange(category, e.target.value, 'receiving')}>{submissiveRatingOptions.map(option => (<MenuItem key={option} value={option} sx={{ py: 0.5, fontSize: '0.5rem' }}>{ratingEmojis[option]} {option}</MenuItem>))}</Select></Box>
            {isUserSwitch() && <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}><Typography variant="caption">Giving:</Typography><Select size="small" sx={{ minWidth: 100, '.MuiSelect-select': { py: 0.5, fontSize: '0.5rem' } }} value="select" onChange={(e) => handleBatchRatingChange(category, e.target.value, 'giving')}>{submissiveRatingOptions.map(option => (<MenuItem key={option} value={option} sx={{ py: 0.5, fontSize: '0.5rem' }}>{ratingEmojis[option]} {option}</MenuItem>))}</Select></Box>}
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}><Typography sx = {{fontSize: '0.5rem'}}>Giving:</Typography><Select size="small" sx={{ minWidth: 100, '.MuiSelect-select': { py: 0.5, fontSize: '0.5rem' } }} value="select" onChange={(e) => handleBatchRatingChange(category, e.target.value, 'giving')}>{dominantRatingOptions.map(option => (<MenuItem key={option} value={option} sx={{ py: 0.5, fontSize: '0.5rem' }}>{ratingEmojis[option]} {option}</MenuItem>))}</Select></Box>
            {isUserSwitch() && <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}><Typography variant="caption">Receiving:</Typography><Select size="small" sx={{ minWidth: 100, '.MuiSelect-select': { py: 0.5, fontSize: '0.5rem' } }} value="select" onChange={(e) => handleBatchRatingChange(category, e.target.value, 'receiving')}>{dominantRatingOptions.map(option => (<MenuItem key={option} value={option} sx={{ py: 0.5, fontSize: '0.5rem' }}>{ratingEmojis[option]} {option}</MenuItem>))}</Select></Box>}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Container maxWidth="md">
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs"
        sx={{ width: '100%' }}
      >
        <Tab label="Kinks" />
        <Tab label="Discovery" />
      </Tabs>
{tabValue === 0 ? (
  <>
<FormControl fullWidth sx={{ mb: 2 }}>
  <Select
    value={filterOption}
    displayEmpty
    onChange={(event) => {
      console.log('New selection:', event.target.value);
      setFilterOption(event.target.value);
    }}
  >
    {isUserSwitch() ? (
      [
        <MenuItem key="dom" value="dom_">Sort by Dominant Alignment</MenuItem>,
        <MenuItem key="sub" value="sub_">Sort by Submissive Alignment</MenuItem>,
        <MenuItem key="uncertain" value="uncertain">Uncertain</MenuItem>,
        <MenuItem key="limits" value="#ffcdd2">Limits</MenuItem>,
        <MenuItem key="not-filled" value="#f5f5f5">Unfilled</MenuItem>
      ]
    ) : (
      [
        <MenuItem key="empty" value=""><em>Filter by</em></MenuItem>,
        <MenuItem key="aligned" value="#c8e6c9">Most Aligned</MenuItem>,
        <MenuItem key="partial" value="#ffe0b2">Partially Aligned</MenuItem>,
        <MenuItem key="uncertain" value="uncertain">Uncertain</MenuItem>,
        <MenuItem key="limits" value="#ffcdd2">Limits</MenuItem>,
        <MenuItem key="not-filled" value="#f5f5f5">Unfilled</MenuItem>
      ]
    )}
  </Select>
</FormControl>
  
{Object.entries(sortedKinks).map(([category, categoryKinks]) => {
  const stats = getCategoryStats(categoryKinks);
  
  return (
    <Accordion 
      key={category}
      expanded={expandedCategory === category}
      onChange={handleAccordionChange(category)}
      disableGutters
      sx={{
        opacity: hardNoCategories.has(category) ? 0.7 : 1,
      }}
        >
      <AccordionSummary 
        expandIcon={<ExpandMore />}
        sx={{ 
          minHeight: 48,
          '& .MuiAccordionSummary-content': { 
            my: 0,
            justifyContent: 'space-between',
            alignItems: 'center'
          }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography sx={{ fontSize: '1.2rem' }}>
            {categoryEmojis[category] || '🎯'}
          </Typography>
          <Typography variant="subtitle1">{category}</Typography>
        </Box>
        <Typography variant="body2" color="text.secondary">
          {stats.filled}/{stats.total}
        </Typography>
      </AccordionSummary>
      
      <AccordionDetails sx={{ py: 0.6 }}>
        <BatchRatingControls category={category} categoryKinks={categoryKinks} />
        <List sx={{ py: 0 }}>
          {categoryKinks.map(kink => renderKinkContent(kink))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
})}
</>
) : (
  // Discovery content
  <Discovery />
)}
      
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={2000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
    </Container>
  );
};

export default Kinks;