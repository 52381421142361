import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import { Snackbar } from '@mui/material';
import { useWindowSize } from 'react-use';

const SnackbarWithConfetti = ({ open, message, severity, onClose, autoHideDuration = 6000 }) => {
  const [showConfetti, setShowConfetti] = useState(false);
  const { width, height } = useWindowSize();

  useEffect(() => {
    if (open && severity === 'success') {
      setShowConfetti(true);
      const timer = setTimeout(() => {
        setShowConfetti(false);
      }, autoHideDuration);
      return () => clearTimeout(timer);
    }
  }, [open, severity, autoHideDuration]);

  return (
    <>
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          recycle={false}
          numberOfPieces={200}
          gravity={0.3}
        />
      )}
      <Snackbar
        open={open}
        message={message}
        onClose={onClose}
        autoHideDuration={autoHideDuration}
      />
    </>
  );
};

export default SnackbarWithConfetti;