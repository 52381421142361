import React, { useState, useEffect, useCallback } from 'react';
import { axiosInstance } from './auth';
import {
  // Core components
  Container, Box, Stack,
  // Typography and Text
  Typography, TextField,
  // Feedback & Progress
  CircularProgress, Snackbar, Rating,
  // Dialogs & Modals
  Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText,
  // Navigation & Actions
  Fab, Button, IconButton,
  // Layout & Organization
  Card, CardContent, Accordion, AccordionSummary, AccordionDetails,
  List, ListItem, ListItemText, Collapse,
  // Form Controls
  Switch, FormControl, FormControlLabel, Select, MenuItem, InputLabel, Slider,
  // Display Elements
  Chip, Tooltip,
  // Theme & Responsive
  useTheme, useMediaQuery
} from '@mui/material';

import {
  // Action Icons
  Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Assignment as AssignIcon, Casino,
  // Navigation Icons
  ExpandMore, ExpandLess as ExpandLessIcon,
  // Status Icons
  Camera, LocationOn, AutorenewRounded, CheckCircleOutline,
  // Media Control Icons
  PlayArrow, Pause, Stop,
  // Dialog Icons
  Close as CloseIcon
} from '@mui/icons-material';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import WheelOfPrizes from './WheelOfPrizes';
import { examples } from './examples';

const POINT_PRESETS = [50, 100, 250, 500, 1000];

const getTaskStatusColor = (status) => {
  switch (status) {
    case 'incomplete':
      return 'error.main';
    case 'submitted':
      return 'warning.main';
    case 'approved':
      return 'success.main';
    case 'pending_approval':
      return 'info.main';
    default:
      return 'text.primary';
  }
};

const formatTaskStatus = (status) => {
  if (!status) return '';
  return status
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const Timer = ({ onTimerUpdate }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
  const intervalRef = React.useRef(null);
const startTimer = () => {
    if (!isRunning) {
      setIsRunning(true);
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
  };

  const pauseTimer = () => {
    if (isRunning) {
      clearInterval(intervalRef.current);
      setIsRunning(false);
    }
  };

  const stopTimer = () => {
    clearInterval(intervalRef.current);
    setIsRunning(false);
    onTimerUpdate(time);
    setTime(0);
  };

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6">Timer: {formatTime(time)}</Typography>
      <Button startIcon={<PlayArrow />} onClick={startTimer} disabled={isRunning}>Start</Button>
      <Button startIcon={<Pause />} onClick={pauseTimer} disabled={!isRunning}>Pause</Button>
      <Button startIcon={<Stop />} onClick={stopTimer}>Stop</Button>
    </Box>
  );
};

const generateRandomPoints = () => {
  const pointValues = [1, 100, 250, 500, 1000];
  return pointValues[Math.floor(Math.random() * pointValues.length)];
};

const replaceNames = (text, submissiveName, dominantName) => {
  
  if (typeof text !== 'string') {
    console.warn('Invalid text input:', text);
    return '';
  }
  
  const capitalizedSubmissive = submissiveName 
    ? submissiveName.charAt(0).toUpperCase() + submissiveName.slice(1) 
    : 'Submissive';
  
  const capitalizedDominant = dominantName
    ? dominantName.charAt(0).toUpperCase() + dominantName.slice(1)
    : 'Dominant';

  const result = text
    .replace(/\b(submissive|Sub|sub|sub's)\b/g, (match) => {
      if (match === "sub's") return `${capitalizedSubmissive}'s`;
      return capitalizedSubmissive;
    })
    .replace(/\b(dom|dominant|Dom|Dom's|dom's)\b/g, (match) => {
      if (match === "Dom's" || match === "dom's") return `${capitalizedDominant}'s`;
      return capitalizedDominant;
    });

  return result;
};

const randomizeNumbers = (text) => {
  if (typeof text !== 'string') return '';
  
  return text.replace(/(\d+x\d*|\d*x\d+|(?<=\s)x(?=\s))/g, (match) => {
    let min, max;
    
    if (match === 'x') {
      min = 1;
      max = 40;
    } else if (match.startsWith('x')) {
      // x30 pattern
      max = parseInt(match.substring(1));
      min = 1;
    } else if (match.endsWith('x')) {
      // 20x pattern
      min = parseInt(match.substring(0, match.length - 1));
      max = min > 40 ? 2 * min : 40;
    } else {
      // 5x12 pattern
      const [before, after] = match.split('x').map(num => parseInt(num));
      min = before;
      max = after;
    }
    
    if (min > max) [min, max] = [max, min];
    
    let result = Math.floor(Math.random() * (max - min + 1)) + min;
    
    if (match === 'x' && result >= 10) {
      result = Math.round(result / 5) * 5;
    }
    
    return ` ${result} `;
  });
};

const CompletionDots = ({ completionHistory = [] }) => {
  // Ensure we have exactly 10 items, pad with null for missing entries
  const history = Array(10).fill(null).map((_, i) => completionHistory[i] || null);

  const getStatusColor = (status) => {
    switch (status) {
      case 'approved':
        return 'success.main';
      case 'denied':
        return 'error.main';
      case 'pending':
        return 'warning.main';
      default:
        return 'transparent';
    }
  };

  const getTooltipContent = (completion) => {
    if (!completion) return 'No completion data';
    
    const date = new Date(completion.date).toLocaleDateString();
    const status = completion.status.charAt(0).toUpperCase() + completion.status.slice(1);
    const points = completion.points_awarded ? ` (${completion.points_awarded} points)` : '';
    
    return `${status} on ${date}${points}`;
  };

  return (
    <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', my: 0.5 }}>
      {history.map((completion, index) => (
        <Tooltip
          key={index}
          title={getTooltipContent(completion)}
          arrow
        >
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              bgcolor: completion ? getStatusColor(completion.status) : 'transparent',
              border: '1px solid',
              borderColor: completion ? getStatusColor(completion.status) : 'text.disabled',
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.2)',
                boxShadow: 1
              }
            }}
          />
        </Tooltip>
      ))}
    </Box>
  );
};

const RewardForm = ({ onSubmit, reward, onClose, kinks = [], userNames, availablePoints }) => {
  const [title, setTitle] = useState(reward ? reward.title : '');
  const [description, setDescription] = useState(reward ? reward.description : '');
  const [category, setCategory] = useState(reward ? reward.category : '');
  const [pointsRequired, setPointsRequired] = useState(reward ? reward.points_required : POINT_PRESETS[0]);
  const [dueDate, setDueDate] = useState(reward && reward.due_date ? new Date(reward.due_date) : null);
  const [requireProof, setRequireProof] = useState(reward ? reward.require_proof : false);
  const [examplesExpanded, setExamplesExpanded] = useState(false);
  const [isScheduled, setIsScheduled] = useState(reward?.do_at ? true : false);
  const [scheduledTime, setScheduledTime] = useState(reward?.do_at ? new Date(reward.do_at) : null);
  const [duration, setDuration] = useState(reward?.duration || 60);
  const [durationUnit, setDurationUnit] = useState(reward?.duration_unit || 'minutes');
  const [assignNow, setAssignNow] = useState(true);
  const [tagInput, setTagInput] = useState('');
  const canAssign = availablePoints >= pointsRequired;

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      id: reward ? reward.id : null,
      title,
      description,
      category,
      points_required: pointsRequired,
      due_date: dueDate ? dueDate.toISOString().slice(0, 19).replace('T', ' ') : null,
      require_proof: requireProof,
      assign_now: assignNow && canAssign,
      do_at: isScheduled ? scheduledTime?.toISOString() : null,
      duration: isScheduled ? duration : null,
      duration_unit: isScheduled ? durationUnit : null
    });
    onClose();
  };

  const getFilteredExamples = useCallback(() => {
    let filteredExamples = examples.rewards.filter(example => {
      const kink = kinks.find(k => k.name.toLowerCase() === example.category.toLowerCase());
      if (!kink) return false;
  
      // Check primary role compatibility
      const submissiveReceiving = kink.submissive_receiving_rating;
      const dominantGiving = kink.dominant_giving_rating;
  
      // Exclude if either role has hard limits or uncertain
      if (['hard no'].includes(submissiveReceiving) || 
          ['hard no'].includes(dominantGiving)) {
        return false;
      }
  
      return true;
    });
    
    return filteredExamples.sort((a, b) => {
      const aKink = kinks.find(k => k.name.toLowerCase() === a.category.toLowerCase());
      const bKink = kinks.find(k => k.name.toLowerCase() === b.category.toLowerCase());
      
      const getKinkScore = (kink) => {
        if (!kink) return 0;
        
        // High alignment: dominant very much yes/yes with submissive please/yes
        if ((['very much yes', 'yes'].includes(kink.dominant_giving_rating) && 
             ['please', 'yes', 'need'].includes(kink.submissive_receiving_rating))) {
          return 3;
        }
        
        // Medium alignment: maybe/willing to try with maybe/if forced
        if ((['maybe', 'willing to try', 'uncertain but interested'].includes(kink.dominant_giving_rating) && 
             ['maybe', 'if forced', 'uncertain but interested'].includes(kink.submissive_receiving_rating))) {
          return 2;
        }
        
        // Low alignment: fantasy/roleplay only
        if (kink.dominant_giving_rating === 'fantasy/roleplay only' || 
            kink.submissive_receiving_rating === 'fantasy/roleplay only') {
          return 1;
        }
        
        return 0;
      };
  
      return getKinkScore(bKink) - getKinkScore(aKink);
    });
  }, [kinks]);

  const filteredExamples = getFilteredExamples();

  const handleExampleClick = (example) => {
    const parts = example.text.split(' - ', 2);
    if (parts.length === 2) {
      setTitle(replaceNames(
        parts[0].trim(),
        userNames.submissive?.username || '',
        userNames.dominant?.username || ''
      ));
      setDescription(
        randomizeNumbers(
          replaceNames(
            parts[1].trim(),
            userNames.submissive?.username || '',
            userNames.dominant?.username || ''
          )
        )
      );
    } else {
      setTitle(replaceNames(
        example.text.trim(),
        userNames.submissive?.username || '',
        userNames.dominant?.username || ''
      ));
      setDescription('');
    }
    setCategory(example.category);
    setExamplesExpanded(false);
  };

  const handleTagInputChange = (e) => {
    const value = e.target.value;
    setTagInput(value);
    if (value.endsWith(' ')) {
      const newTag = value.trim();
      if (newTag && !category.includes(newTag)) {
        setCategory(category ? `${category}, ${newTag}` : newTag);
      }
      setTagInput('');
    }
  };

  const getKinkAlignment = (kink) => {
    // High alignment
    if (['yes', 'very much yes'].includes(kink.dominant_giving_rating) && 
        ['yes', 'please', 'need'].includes(kink.submissive_receiving_rating)) {
      return '#4caf50';
    }
    // Medium alignment
    if (['maybe', 'wililng to try', 'unsure but interested'].includes(kink.dominant_giving_rating) && 
        ['maybe', 'if forced', 'uncertain', 'unsure but interested'].includes(kink.submissive_receiving_rating)) {
      return '#8bc34a';
    }
    // Fantasy/roleplay alignment
    if (kink.dominant_giving_rating === 'fantasy/roleplay only' || 
        kink.submissive_receiving_rating === 'fantasy/roleplay only') {
      return '#ffeb3b';
    }
    return 'inherit';
  };
  
  const getKinkEmoji = (rating) => {
    switch (rating) {
      case 'hard no': return '🚫';
      case 'if forced': return '😬';
      case 'maybe': return '🤔';
      case 'yes': return '😊';
      case 'please': return '😍';
      case 'very much yes': return '🥰';
      case 'need': return '💖';
      case 'willing to try': return '🤨';
      case 'fantasy/roleplay only': return '🎭';
      case 'uncertain': return '❓';
      case 'unsure but interested': return '🤔';
      default: return '❓';
    }
  };

return (
  <form onSubmit={handleSubmit}>
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>

    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Accordion 
        expanded={examplesExpanded}
        onChange={(_, isExpanded) => setExamplesExpanded(isExpanded)}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">Example Rewards</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
            {kinks.filter(kink => 
              !['hard no', 'uncertain'].includes(kink.dominant_giving_rating) && 
              !['hard no', 'uncertain'].includes(kink.submissive_receiving_rating) &&
              filteredExamples.some(example => example.category.toLowerCase() === kink.name.toLowerCase())
            ).map((kink) => (
              <Accordion key={kink.name}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Typography sx={{ 
                      flexGrow: 1, 
                      color: getKinkAlignment(kink) === 'inherit' ? 'text.primary' : getKinkAlignment(kink) 
                    }}>
                      {kink.name}
                    </Typography>
                      <Tooltip title={`${userNames.dominant.name}: Giving ${getKinkEmoji(kink.dominant_giving_rating)}, Receiving ${getKinkEmoji(kink.dominant_receiving_rating)}
                      ${userNames.submissive.name}: Giving ${getKinkEmoji(kink.submissive_giving_rating)}, Receiving ${getKinkEmoji(kink.submissive_receiving_rating)}`}>
                        <Typography>
                          {getKinkEmoji(kink.dominant_giving_rating)} {getKinkEmoji(kink.submissive_receiving_rating)}
                        </Typography>
                      </Tooltip>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {filteredExamples
                      .filter(example => example.category.toLowerCase() === kink.name.toLowerCase())
                      .map((example, index) => {
                        const parts = example.text.split(' - ', 2);
                        const title = parts[0] || '';
                        const description = parts[1] || '';
                        const processedTitle = replaceNames(
                          title.trim(),
                          userNames.submissive?.username || '',
                          userNames.dominant?.username || ''
                        );
                        const processedDescription = description 
                          ? randomizeNumbers(
                              replaceNames(
                                description.trim(),
                                userNames.submissive?.username || '',
                                userNames.dominant?.username || ''
                              )
                            )
                          : '';
                        const displayText = description 
                          ? `${processedTitle} - ${processedDescription}`
                          : processedTitle;
                        return (
                          <ListItem 
                            key={index} 
                            button 
                            onClick={() => handleExampleClick({
                              ...example,
                              text: displayText
                            })}
                          >
                            <ListItemText primary={displayText} />
                          </ListItem>
                        );
                      })}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>

      <TextField
        fullWidth
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        margin="normal"
        required
      />

    <TextField
      fullWidth
      label="Description"
      value={description}
      onChange={(e) => setDescription(e.target.value)}
      margin="normal"
      multiline
      rows={3}
    />

    <TextField
      fullWidth
      label="Category"
      value={tagInput}
      onChange={handleTagInputChange}
      margin="normal"
      helperText="Type a category and press space to add it"
    />

    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1 }}>
      {category.split(',').map((tag, index) => (
        tag.trim() && (
          <Chip
            key={index}
            label={tag.trim()}
            onDelete={() => {
              const tags = category.split(',').filter((_, i) => i !== index);
              setCategory(tags.join(','));
            }}
            color="primary"
            variant="outlined"
          />
        )
      ))}
    </Box>

    <Box sx={{ mb: 1 }}>
      <Typography>
        Points Required: ({availablePoints} Available)
      </Typography>
      <Slider
        value={pointsRequired}
        onChange={(_, value) => setPointsRequired(value)}
        step={null}
        marks={POINT_PRESETS.map(point => ({
          value: point,
          label: point.toString()
        }))}
        min={POINT_PRESETS[0]}
        max={POINT_PRESETS[POINT_PRESETS.length - 1]}
      />
      <TextField
        type="number"
        value={pointsRequired}
        onChange={(e) => {
          const value = parseInt(e.target.value);
          if (!isNaN(value)) {
            setPointsRequired(Math.max(0, Math.min(10000, value)));
          }
        }}
        size="small"
        fullWidth
        sx={{ mt: 1 }}
      />
    </Box>

    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>Advanced Options</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <FormControlLabel
            control={
              <Switch
                checked={requireProof}
                onChange={(e) => setRequireProof(e.target.checked)}
              />
            }
            label="Require Proof"
          />
          <FormControlLabel
            control={
              <Switch
                checked={assignNow}
                onChange={(e) => setAssignNow(e.target.checked)}
                disabled={!canAssign}
              />
            }
            label={canAssign ? "Assign Now" : "Not enough points to assign"}
          />

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>Timing</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={isScheduled}
                  onChange={(e) => {
                    setIsScheduled(e.target.checked);
                    if (!e.target.checked) {
                      setScheduledTime(null);
                      setDuration(60);
                      setDurationUnit('minutes');
                    }
                  }}
                />
              }
              label="Scheduler"
            />
            
            {isScheduled && (
              <Box sx={{ mt: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="Schedule on"
                    value={scheduledTime}
                    onChange={(newValue) => setScheduledTime(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" required={isScheduled} />}
                  />
                </LocalizationProvider>
                
                <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                  <TextField
                    type="number"
                    label="Duration"
                    value={duration}
                    onChange={(e) => setDuration(Math.max(1, parseInt(e.target.value) || 1))}
                    inputProps={{ min: 1 }}
                    sx={{ flex: 1 }}
                  />
                  <FormControl sx={{ flex: 1 }}>
                    <InputLabel>Unit</InputLabel>
                    <Select
                      value={durationUnit}
                      onChange={(e) => setDurationUnit(e.target.value)}
                      label="Unit"
                    >
                      <MenuItem value="minutes">Minutes</MenuItem>
                      <MenuItem value="hours">Hours</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            )}
          </Box>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Complete by"
              value={dueDate}
              onChange={setDueDate}
              renderInput={(params) => (
                <TextField {...params} fullWidth margin="normal" />
              )}
            />
          </LocalizationProvider>
        </Stack>
      </AccordionDetails>
    </Accordion>

    <Button 
      type="submit" 
      variant="contained" 
      color="primary" 
      fullWidth 
      sx={{ mt: 2 }}
    >
      {reward ? 'Update Reward' : 'Create Reward'}
    </Button>
  </Box>
</form>
  );
};

const CompletionForm = ({ onSubmit, onClose }) => {
  const [note, setNote] = useState('');
  const [enjoymentRating, setEnjoymentRating] = useState(0);
  const [difficultyRating, setDifficultyRating] = useState(0);
  const [humiliationRating, setHumiliationRating] = useState(0);
  const [orgasmCountSub, setOrgasmCountSub] = useState(0);
  const [orgasmCountDom, setOrgasmCountDom] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      note,
      enjoyment_rating: enjoymentRating,
      difficulty_rating: difficultyRating,
      humiliation_rating: humiliationRating,
      orgasm_count_sub: orgasmCountSub,
      orgasm_count_dom: orgasmCountDom
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        label="Note"
        value={note}
        onChange={(e) => setNote(e.target.value)}
        margin="normal"
        multiline
        rows={4}
      />
      <Box sx={{ mt: 2 }}>
        <Typography>Enjoyment:</Typography>
        <Rating
          name="enjoyment"
          value={enjoymentRating}
          onChange={(event, newValue) => {
            setEnjoymentRating(newValue);
          }}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>Difficulty:</Typography>
        <Rating
          name="difficulty"
          value={difficultyRating}
          onChange={(event, newValue) => {
            setDifficultyRating(newValue);
          }}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>Humiliation:</Typography>
        <Rating
          name="humiliation"
          value={humiliationRating}
          onChange={(event, newValue) => {
            setHumiliationRating(newValue);
          }}
        />
      </Box>
      <TextField
        fullWidth
        label="Submissive Orgasm Count"
        type="number"
        value={orgasmCountSub}
        onChange={(e) => setOrgasmCountSub(Number(e.target.value))}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Dominant Orgasm Count"
        type="number"
        value={orgasmCountDom}
        onChange={(e) => setOrgasmCountDom(Number(e.target.value))}
        margin="normal"
      />
      <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
        Submit Completion
      </Button>
    </form>
  );
};

const Rewards = ({ role, points, setPoints, kinks, sortBy, groupBy }) => {
  const [rewards, setRewards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showRewardModal, setShowRewardModal] = useState(false);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [selectedReward, setSelectedReward] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [userNames, setUserNames] = useState({ dominant: '', submissive: '' });
  const [wheelPunishments, setWheelPunishments] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [rewardToDelete, setRewardToDelete] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchRewards = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/rewards`);
      setRewards(response.data || []);
    } catch (error) {
      console.error('Error fetching rewards:', error);
      if (error.response && error.response.status === 404) {
        setErrorMessage('Rewards endpoint not found. Please check server configuration.');
      } else {
        setErrorMessage('Failed to fetch rewards. Please try again.');
      }
      setRewards([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchRewards();
    fetchUserNames();
  }, [fetchRewards]);
  
  const fetchUserNames = async () => {
    try {
      const response = await axiosInstance.get(`/user-names`);
      setUserNames({
        submissive: { 
          name: response.data.submissive.username, 
          id: response.data.submissive.id 
        },
        dominant: { 
          name: response.data.dominant.username, 
          id: response.data.dominant.id 
        }
      });
    } catch (error) {
      console.error('Error fetching user names:', error);
      setErrorMessage('Failed to fetch user names. Please try again.');
    }
  };

  const handleCreateOrEditReward = async (rewardData) => {
    try {
      if (rewardData.id) {
        await handleEditReward(rewardData);
      } else {
        await handleCreateReward(rewardData);
      }
    } catch (error) {
      console.error('Error handling reward:', error);
      setErrorMessage('Failed to handle reward. Please try again.');
    }
  };

  const handleCreateReward = async (rewardData) => {
    try {
      await axiosInstance.post(`/rewards`, rewardData);
      fetchRewards();
      setShowRewardModal(false);
    } catch (error) {
      console.error('Error creating reward:', error);
      setErrorMessage('Failed to create reward. Please try again.');
    }
  };

  const handleEditReward = async (rewardData) => {
    try {
      await axiosInstance.put(`/rewards/${rewardData.id}`, rewardData);
      fetchRewards();
      setShowRewardModal(false);
    } catch (error) {
      console.error('Error editing reward:', error);
      setErrorMessage('Failed to edit reward. Please try again.');
    }
  };

  const handleDeleteReward = async (rewardId) => {
    setRewardToDelete(rewardId);
    setDeleteDialogOpen(true);
  };
  
  const confirmDeleteReward = async () => {
    try {
      await axiosInstance.delete(`/rewards/${rewardToDelete}`);
      fetchRewards();
      setDeleteDialogOpen(false);
      setRewardToDelete(null);
    } catch (error) {
      console.error('Error deleting reward:', error);
      setErrorMessage('Failed to delete reward. Please try again.');
    }
  };

  const handleAssignReward = async (rewardId) => {
    try {
      const response = await axiosInstance.post(`/rewards/${rewardId}/assign`);
      setPoints(response.data.new_points);
      fetchRewards();
    } catch (error) {
      console.error('Error assigning reward:', error);
      if (error.response && error.response.status === 400) {
        setErrorMessage("Not enough points to assign reward");
      } else {
        setErrorMessage('Failed to assign reward. Please try again.');
      }
    }
  };

  const handleRemoveAllRewards = async () => {
    try {
      const response = await axiosInstance.post('/remove-all-rewards');
      if (response.status === 200) {
        setPoints(response.data.new_points);
        await fetchRewards();
        setErrorMessage('All rewards have been removed and points returned to the submissive.');
      }
    } catch (error) {
      console.error('Error removing all rewards:', error);
      setErrorMessage('Failed to remove all rewards. Please try again.');
    }
    setOpenConfirmDialog(false);
  };

  const handleRandomReward = async () => {
    try {
      if (!userNames.submissive.name || !userNames.dominant.name) {
        const response = await axiosInstance.get('/user-names');
        setUserNames(response.data);
      }

      const filteredExamples = getFilteredExamples();

      if (filteredExamples.length === 0) {
        setErrorMessage('No eligible rewards found. Please check your kink settings.');
        return;
      }

      let rewardCreated = false;
      let attempts = 0;
      const maxAttempts = 1;

      while (!rewardCreated && attempts < maxAttempts) {
        attempts++;
        const randomReward = filteredExamples[Math.floor(Math.random() * filteredExamples.length)];
        
        if (!randomReward || !randomReward.text) {
          continue;
        }

        let randomRewardText = randomReward.text;
        randomRewardText = randomizeNumbers(randomRewardText);
        const replacedText = replaceNames(randomRewardText, userNames.submissive.name, userNames.dominant.name);
        let [title, description] = replacedText.split(' - ');
        if (!description) {
          title = replacedText;
          description = replacedText;
        }

        const rewardData = {
          title: title.trim(),
          description: description.trim(),
          category: randomReward.category,
          points_required: generateRandomPoints(),
          require_proof: Math.random() < 0.5,
        };

        if (Math.random() < 0.5) {
          const dueDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
          rewardData.due_date = dueDate.toISOString().slice(0, 19).replace('T', ' ');
        }

        try {
          const response = await axiosInstance.post(`/rewards`, rewardData);
          if (response.status === 201 && response.data) {
            const createdReward = response.data;
            const assignResponse = await axiosInstance.post(`/rewards/${createdReward.id}/assign`);
            if (assignResponse.status === 200) {
              setPoints(assignResponse.data.new_points);
              await fetchRewards();
              rewardCreated = true;
            }
          } else {
            console.log('Unexpected response status or missing data. Trying again...');
          }
        } catch (error) {
          console.error('Error creating or assigning reward:', error);
          if (attempts === maxAttempts) {
            throw error;
          }
        }
      }

      if (!rewardCreated) {
        setErrorMessage('Unable to create a suitable reward after multiple attempts. Please try again.');
      }
    } catch (error) {
      console.error('Error in handleRandomReward:', error);
      setErrorMessage('An error occurred while creating a random reward. Please try again.');
    }
  };

  const getFilteredExamples = useCallback(() => {
    let filteredExamples = examples.rewards.filter(example =>
      kinks.some(kink => kink.name.toLowerCase() === example.category.toLowerCase() && 
                 kink.submissive_rating !== 'hard no' && 
                 kink.dominant_rating !== 'hard no')
    );
    return filteredExamples.sort((a, b) => {
      const aKink = kinks.find(k => k.name.toLowerCase() === a.category.toLowerCase());
      const bKink = kinks.find(k => k.name.toLowerCase() === b.category.toLowerCase());
      const aRelevance = (aKink && aKink.dominant_rating === 'very much yes' && aKink.submissive_rating === 'please') ? 2 :
                         (aKink && (aKink.dominant_rating === 'yes' || aKink.submissive_rating === 'yes')) ? 1 : 0;
      const bRelevance = (bKink && bKink.dominant_rating === 'very much yes' && bKink.submissive_rating === 'please') ? 2 :
                         (bKink && (bKink.dominant_rating === 'yes' || bKink.submissive_rating === 'yes')) ? 1 : 0;
      return bRelevance - aRelevance;
    });
  }, [kinks]);

  const handleCompleteReward = async (rewardId, completionData) => {
    try {
      await axiosInstance.post(`/rewards/${rewardId}/complete`, completionData);
      fetchRewards();
      setShowCompletionModal(false);
    } catch (error) {
      setErrorMessage('Failed to complete reward. Please try again.');
    }
  };

  const sortRewards = (rewardsToSort) => {
    return rewardsToSort.sort((a, b) => {
      switch (sortBy) {
        case 'dateCreated':
          return new Date(b.created_at) - new Date(a.created_at);
        case 'points':
          return b.points_required - a.points_required;
        case 'name':
          return a.title.localeCompare(b.title);
        default:
          return 0;
      }
    });
  };

  const groupRewards = (rewards) => {
    if (groupBy === 'none') return { 'All Rewards': sortRewards(rewards) };
    
    return rewards.reduce((acc, reward) => {
      let key;
      if (groupBy === 'tag') {
        key = reward.category || 'Uncategorized';
      } else if (groupBy === 'status') {
        key = reward.assigned ? (reward.completed ? 'Completed' : 'Assigned') : 'Unassigned';
      }
      if (!acc[key]) acc[key] = [];
      acc[key].push(reward);
      return acc;
    }, {});
  };

  const fetchWheelPunishments = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/wheel-punishments');
      if (Array.isArray(response.data) && response.data.length > 0) {
        setWheelPunishments(response.data);
      } else {
        console.error('Unexpected response format:', response.data);
        setErrorMessage('Failed to fetch wheel punishments. Unexpected data format.');
      }
    } catch (error) {
      console.error('Error fetching wheel punishments:', error);
      setErrorMessage('Failed to fetch wheel punishments. Please try again.');
    }
  }, []);

  useEffect(() => {
    if (role === 'submissive' && rewards.length === 0) {
      console.log('Fetching wheel punishments...');
      fetchWheelPunishments();
    }
  }, [role, rewards.length, fetchWheelPunishments]);

  const modifyPunishmentContent = (punishment, userNames) => {

    if (!punishment || (typeof punishment.title !== 'string' && typeof punishment.text !== 'string')) {
      console.warn('Invalid punishment object:', punishment);
      return { ...punishment, title: 'Invalid Punishment' };
    }

    let title, description;
    if (punishment.title) {
      title = punishment.title;
      description = punishment.description || '';
    } else {
      const parts = punishment.text.split(' - ');
      title = parts[0].trim();
      description = parts.length > 1 ? parts.slice(1).join(' - ').trim() : '';
    }

    let modifiedTitle = replaceNames(
      title,
      userNames?.submissive?.name || '',
      userNames?.dominant?.name || ''
    );

    modifiedTitle = randomizeNumbers(modifiedTitle);

    let modifiedDescription = '';
    if (description) {
      modifiedDescription = replaceNames(
        description,
        userNames?.submissive?.name || '',
        userNames?.dominant?.name || ''
      );

      modifiedDescription = randomizeNumbers(modifiedDescription);
    }

    const result = { 
      ...punishment, 
      title: modifiedTitle, 
      description: modifiedDescription || modifiedTitle 
    };

    return result;
  };

  const modifiedWheelPunishments = wheelPunishments.map(punishment => 
    modifyPunishmentContent(punishment, userNames)
  );

  const handleSpinTheWheel = async (selectedPunishment) => {
    
    if (!selectedPunishment) {
      console.error('Invalid punishment selected:', selectedPunishment);
      setErrorMessage('Failed to spin the wheel. Invalid punishment selected.');
      return;
    }

    try {
      const requestData = {
        punishment_id: selectedPunishment.id,
        processed_title: selectedPunishment.title,
        processed_description: selectedPunishment.description,
        category: selectedPunishment.category || 'General'
      };

      const response = await axiosInstance.post('/spin-the-wheel', requestData);

      if (response.status === 200) {
        setPoints(response.data.new_points);
        await fetchRewards();
        setErrorMessage('A new reward has been added based on your spin!');
      }
    } catch (error) {
      console.error('Error spinning the wheel:', error);
      setErrorMessage('Failed to spin the wheel. Please try again.');
    }
  };

  if (isLoading) return <CircularProgress />;

  const renderRewardItem = (reward) => (
    <Accordion key={reward.id}>
      <AccordionSummary 
        expandIcon={<ExpandMore />}
        sx={{
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
        }}
      >
        <Typography sx={{ color: 'text.primary' }}>{reward.title}</Typography>
        <Box>
          {reward.require_proof && <Camera color="secondary" sx={{ mr: 1 }} />}
          {role === 'dominant' && (
            <>
              <IconButton onClick={(e) => {
                e.stopPropagation();
                setSelectedReward(reward);
                setShowRewardModal(true);
              }}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={(e) => {
                e.stopPropagation();
                handleDeleteReward(reward.id);
              }}>
                <DeleteIcon />
              </IconButton>
              {!reward.assigned && (
                <IconButton onClick={(e) => {
                  e.stopPropagation();
                  handleAssignReward(reward.id);
                }}>
                  <AssignIcon />
                </IconButton>
              )}
            </>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2">{reward.description}</Typography>
        <Typography variant="body2">Points Required: {reward.points_required}</Typography>
        <Typography variant="body2">
          Due Date: {reward.due_date ? new Date(reward.due_date).toLocaleString() : 'Not set'}
        </Typography>
        <Typography variant="body2">
          Requires Proof: {reward.require_proof ? 'Yes' : 'No'}
        </Typography>
        <Chip label={reward.category} size="small" sx={{ mt: 1 }} />
        {role === 'submissive' && reward.assigned && !reward.completed && (
          <Button onClick={() => {
            setSelectedReward(reward);
            setShowCompletionModal(true);
          }}>
            Complete
          </Button>
        )}
      </AccordionDetails>
    </Accordion>
  );
  return (
    <Container maxWidth={false} disableGutters>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={2000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
      <Card sx={{ mt: 2, mb: 2 }}>
        <CardContent sx={{ p: 0 }}>
          <Typography variant="h6" sx={{ p: 2, pb: 1 }}>Rewards</Typography>
          {role === 'dominant' && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpenConfirmDialog(true)}
              sx={{ ml: 2, mb: 1 }}
            >
              Remove All Rewards
            </Button>
          )}
          {rewards.length === 0 && role === 'submissive' ? (
            <Box sx={{ p: 2 }}>
              {points >= 500 && modifiedWheelPunishments.length > 0 ? (
                <WheelOfPrizes 
                  segments={modifiedWheelPunishments.map(p => p.title)} 
                  onFinished={(selectedSegment) => {
                    const selectedPunishment = modifiedWheelPunishments.find(p => p.title === selectedSegment);
                    handleSpinTheWheel(selectedPunishment);
                  }}
                />
              ) : (
                <Typography variant="body1" sx={{ textAlign: 'center', py: 4 }}>
                  {points < 500 
                    ? `No rewards yet, either make at least 500 points to spin the wheel or hope that ${userNames.dominant?.name || 'your Dominant'} is feeling generous`
                    : 'No rewards available at the moment.'}
                </Typography>
              )}
            </Box>
          ) : (
            Object.entries(groupRewards(rewards)).map(([group, groupRewards]) => (
              <Accordion 
                key={group} 
                defaultExpanded 
                sx={{ 
                  '&.MuiAccordion-root': { 
                    padding: 0,
                    '&:before': {
                      display: 'none',
                    },
                  },
                  '&.MuiPaper-root': {
                    boxShadow: 'none',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                  },
                }}
              >
                <AccordionSummary 
                  expandIcon={<ExpandMore />}
                  sx={{ 
                    '&.MuiAccordionSummary-root': { 
                      padding: '0 8px',
                      minHeight: 48,
                      '&.Mui-expanded': {
                        minHeight: 48,
                      },
                    },
                    '& .MuiAccordionSummary-content': { 
                      margin: '12px 0',
                      '&.Mui-expanded': {
                        margin: '12px 0',
                      },
                    }
                  }}
                >
                  <Typography>{group}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  {sortRewards(groupRewards).map(renderRewardItem)}
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </CardContent>
      </Card>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this reward?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDeleteReward} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Confirm Remove All Rewards</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove all rewards? This action will unassign all rewards and return the points to the submissive.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)}>Cancel</Button>
          <Button onClick={handleRemoveAllRewards} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={showRewardModal} 
        onClose={() => {
          setShowRewardModal(false);
          setSelectedReward(null);
        }}
        fullScreen={isMobile}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            ...(isMobile && {
              position: 'fixed',
              bottom: 0,
              maxHeight: 'calc(100% - 100px)',
              height: 'auto',
              borderRadius: 0,
            })
          }
        }}
      >
        <DialogTitle>
          {selectedReward ? 'Edit Reward' : 'Create New Reward'}
        </DialogTitle>
        <DialogContent>
          <RewardForm
            onSubmit={handleCreateOrEditReward}
            onClose={() => {
              setShowRewardModal(false);
              setSelectedReward(null);
            }}
            reward={selectedReward}
            kinks={kinks}
            userNames={userNames}
            availablePoints={points}
          />
        </DialogContent>
      </Dialog>

      <Dialog 
        open={showCompletionModal} 
        onClose={() => {
          setShowCompletionModal(false);
          setSelectedReward(null);
        }}
      >
        <DialogTitle>Complete Reward</DialogTitle>
        <DialogContent>
          <CompletionForm
            onSubmit={(completionData) => handleCompleteReward(selectedReward.id, completionData)}
            onClose={() => {
              setShowCompletionModal(false);
              setSelectedReward(null);
            }}
          />
        </DialogContent>
      </Dialog>

      {role === 'dominant' && (
        <>
          <Fab 
            color="primary" 
            aria-label="add" 
            sx={{ position: 'fixed', bottom: 90, right: 16 }} 
            onClick={() => {
              setSelectedReward(null);
              setShowRewardModal(true);
            }}
          >
            <AddIcon />
          </Fab>
          <Fab 
            color="secondary" 
            aria-label="random" 
            sx={{ position: 'fixed', bottom: 90, right: 80 }} 
            onClick={handleRandomReward}
          >
            <Casino />
          </Fab>
        </>
      )}
    </Container>
  );
};

const TaskListItem = ({ task, role, onEdit, onDelete, onAssign, onApprove, onDeny, onComplete, getTaskStatusColor, showDate }) => {
  const [expanded, setExpanded] = useState(false);
  const handleToggle = () => setExpanded(!expanded);

  return (
    <ListItem
      alignItems="flex-start"
      disablePadding
      sx={{ borderBottom: 1, borderColor: 'divider', py: 0.5 }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 1, py: 0.25 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              variant="body2"
              sx={{
                cursor: 'pointer',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                lineHeigh: 1.2,
              }}
              onClick={handleToggle}
            >
              {task.title}
              {showDate && (
                <Typography variant="caption" sx={{ ml: 1, color: 'text.secondary' }}>
                  {new Date(task.due_date).toLocaleTimeString()}
                </Typography>
              )}
            </Typography>
            {task.is_recurring && <CompletionDots completionHistory={task.completion_history} />}
          </Box>
          {task.gps_check_in && (
            <Tooltip title="GPS Check-in Required">
              <LocationOn fontSize="small" color="primary" id="gps-indicator" />
            </Tooltip>
          )}
          {task.is_recurring && (
            <Tooltip title="Recurring Task">
              <AutorenewRounded fontSize="small" color="primary" id="recurring-tasks" />
            </Tooltip>
          )}
          {task.submissive_can_complete && (
            <Tooltip title="Submissive Can Complete">
              <CheckCircleOutline fontSize="small" color="primary" id="auto-complete-indicator" />
            </Tooltip>
          )}
          {role === 'dominant' && (
            <>
              {task.status === 'pending_approval' ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onEdit(task)}
                  sx={{ mr: 1 }}
                >
                  Review
                </Button>
              ) : (
                <>
                  <IconButton size="small" onClick={() => onEdit(task)}><EditIcon fontSize="small" /></IconButton>
                  <IconButton size="small" onClick={() => onDelete(task.id)}><DeleteIcon fontSize="small" /></IconButton>
                  {!task.assigned && (
                    <IconButton size="small" onClick={() => onAssign(task.id)}><AssignIcon fontSize="small" /></IconButton>
                  )}
                </>
              )}
            </>
          )}
          <IconButton size="small" onClick={handleToggle}>
            {expanded ? <ExpandLessIcon /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={{ 
            py: 0.5,
            px: 1,
            '& .MuiTypography-body2': {
              lineHeight: 1.2
            }
          }}>
            <Typography variant="body2">{task.description}</Typography>
            <Box sx={{ mt: 0.5 }}>
              <Typography variant="body2">Due: {new Date(task.due_date).toLocaleString()}</Typography>
              <Typography variant="body2">
                Status: <span style={{ color: getTaskStatusColor(task.status) }}>
                  {formatTaskStatus(task.status)}
                </span>
              </Typography>
            </Box>
            <Box sx={{ mt: 0.5 }}>
              {task.tags && task.tags.map((tag, index) => (
                <Chip 
                  key={index} 
                  label={tag} 
                  size="small" 
                  sx={{ 
                    mr: 0.5, 
                    mt: 0.5,
                    height: '20px',
                  }} 
                />
              ))}
            </Box>
            {role === 'submissive' && task.can_complete && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => onComplete(task)}
                sx={{ mt: 0.5 }}
                className="complete-task-button"
              >
                Complete
              </Button>
            )}
          </Box>
        </Collapse>
      </Box>
    </ListItem>
  );
};

export default Rewards;

export {
  RewardForm,
  CompletionForm,
  POINT_PRESETS,
  Timer,
  CompletionDots,
  TaskListItem,
  getTaskStatusColor,
  formatTaskStatus,
  formatTime,
  replaceNames,
  randomizeNumbers,
  generateRandomPoints
};