import { createTheme } from '@mui/material/styles';
import { axiosInstance } from './auth';
import SnackbarWithConfetti from './SnackbarWithConfetti';

const SCREEN_SIZE_THRESHOLDS = {
  small: { maxWidth: 400, scalingFactor: 0.7 }, // iPhone 12XR
  extraSmall: { maxWidth: 375, scalingFactor: 0.5 }, // iPhone SE
};

const getScalingFactor = () => {
  const screenWidth = window.innerWidth;
  for (const [_, { maxWidth, scalingFactor }] of Object.entries(SCREEN_SIZE_THRESHOLDS)) {
    if (screenWidth <= maxWidth) {
      return scalingFactor;
    }
  }
  return 1; // Default scaling factor for larger screens
};

const scaleTypography = (theme, scalingFactor) => {
  const scaledTypography = {};

  for (const [key, value] of Object.entries(theme.typography)) {
    if (typeof value === 'object' && value.fontSize) {
      scaledTypography[key] = {
        ...value,
        fontSize: `calc(${value.fontSize} * ${scalingFactor})`,
      };
    } else {
      scaledTypography[key] = value;
    }
  }

  return {
    ...theme,
    typography: scaledTypography,
  };
};

const getSystemTheme = () => {
  if (typeof window !== 'undefined') {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  }
  return 'light'; // Default if window is not available
};

const setupThemeListener = (callback) => {
  if (typeof window !== 'undefined') {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    
    // Modern browsers
    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', callback);
    }
    // Older browsers
    else if (mediaQuery.addListener) {
      mediaQuery.addListener(callback);
    }

    return () => {
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', callback);
      }
      else if (mediaQuery.removeListener) {
        mediaQuery.removeListener(callback);
      }
    };
  }
  return () => {}; // Return empty cleanup if window is not available
};

// Starfield for login screen
const applyStarfieldStyles = (theme) => ({
  '.starfield': {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    width: '100%',
    overflow: 'hidden',
    zIndex: -1,
  },
  '.stars': {
    width: '1px',
    height: '1px',
    borderRadius: '50%',
    position: 'absolute',
    animation: 'twinkle 5s infinite ease-in-out',
  },
  '@keyframes twinkle': {
    '0%, 100%': { opacity: 1 },
    '50%': { opacity: 0.5 },
  },
  // Default dark/light theme star colors
  ...(theme.palette.mode === 'light'
    ? { '.starfield .stars': { backgroundColor: 'gray' } }
    : { '.starfield .stars': { backgroundColor: 'white' } }),
  // Specific theme star colors based on palette
  // '.starfield.sissy .stars': { backgroundColor: '#ff4081' },
  // '.starfield.pastel .stars': { backgroundColor: '#8e99f3' },
  '.starfield.seaGreen .stars': { backgroundColor: '#80cbc4' },
  // '.starfield.autumn .stars': { backgroundColor: '#ffa726' },
  '.starfield.lavender .stars': { backgroundColor: '#b39ddb' },
  '.starfield.mint .stars': { backgroundColor: '#80cbc4' },
  '.starfield.skyBlue .stars': { backgroundColor: '#4fc3f7' },
  '.starfield.sunset .stars': { backgroundColor: '#ffb74d' },
  '.starfield.olive .stars': { backgroundColor: '#afb42b' },
});

const applyBubbleStyles = (theme) => ({
  body: {
    minHeight: '100vh',
  },
  '.MuiContainer-root': {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'fixed',
      top: '40px',
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: theme === 'sissy' 
        ? 'url("/themes/sissy.jpg")'
        : 'url("/themes/donuts.jpg")',
      backgroundPosition: 'left center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto 100%',
      opacity: 0.20,
      zIndex: -1,
      pointerEvents: 'none',
      width: '100%',
      height: 'calc(100vh - 40px)',
    }
  }
});

// Common Palette Segment
const commonPalette = (mode) => ({
  text: {
    primary: mode === 'light' ? '#0d0d0d' : '#ffffff',
    secondary: mode === 'light' ? '#424242' : '#e0e0e0',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
});

// Function to Define Palette
const definePalette = (mode, primaryMain, secondaryMain, backgroundDefault, backgroundPaper, customGradient) => ({
  mode,
  primary: {
    main: primaryMain,
    contrastText: mode === 'light' ? '#ffffff' : '#000000',
  },
  secondary: {
    main: secondaryMain,
    contrastText: mode === 'light' ? '#ffffff' : '#000000',
  },
  background: {
    default: backgroundDefault,
    paper: backgroundPaper,
  },
  custom: {
    gradient: customGradient,
  },
  ...commonPalette(mode),
});

// Base Theme Enhancements with Customizations
const baseTheme = {
  typography: {
    fontFamily: `'Nunito', 'Helvetica', 'Arial', sans-serif`,
    h1: {
      fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' },
      fontWeight: 700,
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    h2: {
      fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' },
      fontWeight: 600,
    },
    h3: {
      fontSize: { xs: '1.1rem', sm: '1.3rem', md: '1.5rem' },
      fontWeight: 600,
    },
    body1: {
      fontSize: { xs: '0.875rem', sm: '0.9rem', md: '1rem' },
      lineHeight: 1.5,
    },
    body2: {
      fontSize: { xs: '0.75rem', sm: '0.8rem', md: '0.875rem' },
      lineHeight: 1.43,
    },
    button: {
      fontSize: { xs: '0.8rem', sm: '0.85rem', md: '0.875rem' },
      fontWeight: 600,
    },
    caption: {
      fontSize: { xs: '0.65rem', sm: '0.7rem', md: '0.75rem' },
      lineHeight: 1.66,
    },
    subtitle1: {
      fontSize: { xs: '0.9rem', sm: '0.95rem', md: '1rem' },
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: { xs: '0.8rem', sm: '0.85rem', md: '0.875rem' },
      fontWeight: 500,
    },
  },
  components: {
      MuiSnackbar: {
      defaultProps: {
        component: SnackbarWithConfetti
      },
      styleOverrides: {
        root: {
          '& .MuiSnackbarContent-root': {
            backgroundColor: '#333333',
            color: '#ffffff',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
          borderRadius: '12px',
          padding: { xs: '6px 12px', sm: '8px 16px' },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#4fc3f7',
          fontSize: { xs: '1.25rem', sm: '1.5rem' },
          '&:hover': {
            color: '#29b6f6',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: { xs: '12px', sm: '16px' },
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: '#ff5722',
          color: '#ffffff',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: { xs: '6px 0', sm: '8px 0' },
          '& input': {
            padding: { xs: '10px', sm: '12px' },
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: { xs: 8, sm: 10 },
          borderRadius: 5,
        },
        colorPrimary: {
          backgroundColor: '#c8e6c9',
        },
        bar: {
          borderRadius: 5,
          backgroundColor: '#388e3c',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60%', // Adjust size relative to the circular progress
            height: '60%',
            backgroundImage: 'url("/login-white.png")',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(45deg, #f3e5f5 30%, #fce4ec 90%)',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: '#f3e5f5',
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        root: {
          color: '#FFD700',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#333333',
          color: '#ffffff',
          fontSize: { xs: '0.75rem', sm: '0.875rem' },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(45deg, #ff8a65 30%, #ffb74d 90%)',
          color: '#ffffff',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: { xs: '0px', sm: '16px', md: '24px' },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '20px',  // Adjust this value for more or less rounding
          backgroundColor: '#ffffff',
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
    achievement: 1600,
  },
  custom: {
    globalGradient: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
  },
  styles: {
    'html, body': { overflowX: 'hidden'},
  }
};

// Theme Definitions with Enhanced Contrast and Customizations
const lightTheme = createTheme({
  ...baseTheme,
  palette: definePalette('light', '#1976d2', '#dc004e', '#f5f5f5', '#ffffff', 'linear-gradient(45deg, #1976d2 30%, #1e88e5 90%)'),
  components: {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          zIndex: baseTheme.zIndex.snackbar,
          '& .MuiSnackbarContent-root': {
            backgroundColor: '#1976d2',
            color: '#ffffff',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: '#1976d2',
        },
      },
    },
    MuiNavigationRail: {
      styleOverrides: {
        root: {
          background: '#000000',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: '#1976d2',
          color: '#ffffff',
          '&:hover': {
            background: '#1565c0',
          },
        },
        containedSecondary: {
          background: '#dc004e',
          color: '#ffffff',
          '&:hover': {
            background: '#b71c1c',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#1976d2',
          size: 60,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});

const darkTheme = createTheme({
  ...baseTheme,
  palette: definePalette('dark', '#90caf9', '#f48fb1', '#121212', '#1e1e1e', 'linear-gradient(45deg, #303030 30%, #444444 90%)'),
  components: {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          zIndex: baseTheme.zIndex.snackbar,
          '& .MuiSnackbarContent-root': {
            backgroundColor: '#90caf9',
            color: '#000000',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(45deg, #303030 30%, #444444 90%)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: 'linear-gradient(45deg, #90caf9 30%, #42a5f5 90%)',
          color: '#000000',
          '&:hover': {
            background: 'linear-gradient(45deg, #42a5f5 30%, #90caf9 90%)',
          },
        },
        containedSecondary: {
          background: 'linear-gradient(45deg, #f48fb1 30%, #f06292 90%)',
          color: '#000000',
          '&:hover': {
            background: 'linear-gradient(45deg, #f06292 30%, #f48fb1 90%)',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#90caf9',
          size: 60,
        },
      },
    },
   MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#2e2e2e',
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.05))',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.4)',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        '[data-theme="dark"] .rbc-calendar': {
          '& *': {
            borderColor: `${theme.palette.divider} !important`,
          }
        }
      })
    }
  },
});

const neonTheme = createTheme({
  ...baseTheme,
  palette: definePalette('dark', '#8e24aa', '#cddc39', '#1c1c1c', '#2e2e2e', 'linear-gradient(45deg, #8e24aa 30%, #cddc39 90%)'),
  components: {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          zIndex: baseTheme.zIndex.snackbar,
          '& .MuiSnackbarContent-root': {
            backgroundColor: '#8e24aa',
            color: '#ffffff',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minHeight: '100vh',
        },
        '.starfield': {
          display: 'none'
        },
        // Target the main content container
        '.MuiContainer-root': {
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'fixed',
            top: '40px', // Account for AppBar height
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: 'url("/themes/neon.jpg")',
            backgroundPosition: 'left center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto 100%',
            opacity: 0.30,
            zIndex: -1,
            pointerEvents: 'none',
            width: '100%', // Add explicit width
            height: 'calc(100vh - 40px)', // Add explicit height
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: 'linear-gradient(45deg, #8e24aa 30%, #cddc39 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #cddc39 30%, #8e24aa 90%)',
          },
        },
        containedSecondary: {
          background: 'linear-gradient(45deg, #cddc39 30%, #8e24aa 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #8e24aa 30%, #cddc39 90%)',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#8e24aa',
          size: 60,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#2e2e2e',
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.05))',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.4)',
        },
      },
    },
  },
});

const midnightTheme = createTheme({
  ...baseTheme,
  palette: definePalette('dark', '#424242', '#f44336', '#1c1c1c', '#303030', 'linear-gradient(45deg, #424242 30%, #f44336 90%)'),
  components: {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          zIndex: baseTheme.zIndex.snackbar,
          '& .MuiSnackbarContent-root': {
            backgroundColor: '#424242',
            color: '#ffffff',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minHeight: '100vh',
        },
        '.starfield': {
          display: 'none'
        },
        // Target the main content container
        '.MuiContainer-root': {
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'fixed',
            top: '40px', // Account for AppBar height
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: 'url("/themes/midnight.jpg")',
            backgroundPosition: 'left center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto 100%',
            opacity: 0.30,
            zIndex: -1,
            pointerEvents: 'none',
            width: '100%', // Add explicit width
            height: 'calc(100vh - 40px)', // Add explicit height
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: 'linear-gradient(45deg, #424242 30%, #f44336 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #f44336 30%, #424242 90%)',
          },
        },
        containedSecondary: {
          background: 'linear-gradient(45deg, #f44336 30%, #424242 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #424242 30%, #f44336 90%)',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#f44336',
          size: 60,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#303030',
          backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.05))',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.4)',
        },
      },
    },
  },
});

const tokyoTheme = createTheme({
  ...baseTheme,
  palette: definePalette('dark', '#f57c00', '#d32f2f', '#2c2c2c', '#3e3e3e', 'linear-gradient(45deg, #f57c00 30%, #d32f2f 90%)'),
  components: {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          zIndex: baseTheme.zIndex.snackbar,
          '& .MuiSnackbarContent-root': {
            backgroundColor: '#f57c00',
            color: '#ffffff',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minHeight: '100vh',
        },
        '.starfield': {
          display: 'none'
        },
        // Target the main content container
        '.MuiContainer-root': {
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'fixed',
            top: '40px', // Account for AppBar height
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: 'url("/themes/tokyo.jpg")',
            backgroundPosition: 'left center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto 100%',
            opacity: 0.50,
            zIndex: -1,
            pointerEvents: 'none',
            width: '100%', // Add explicit width
            height: 'calc(100vh - 40px)', // Add explicit height
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: 'linear-gradient(45deg, #f57c00 30%, #d32f2f 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #d32f2f 30%, #f57c00 90%)',
          },
        },
        containedSecondary: {
          background: 'linear-gradient(45deg, #d32f2f 30%, #f57c00 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #f57c00 30%, #d32f2f 90%)',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#f57c00',
          size: 60,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#3e3e3e',
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(0, 0, 0, 0.1))',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
        },
      },
    },
  },
});

const sissyTheme = createTheme({
  ...baseTheme,
  palette: definePalette('light', '#ff4081', '#f50057', '#fce4ec', '#fff0f5', 'linear-gradient(45deg, #ff4081 30%, #f50057 90%)'),
  components: {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          zIndex: baseTheme.zIndex.snackbar,
          '& .MuiSnackbarContent-root': {
            // backgroundColor: '#ff4081',
            color: '#ffffff',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        ...applyBubbleStyles('sissy'),
      }
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: '#ff4081',
          color: '#ffffff',
          '&:hover': {
            background: '#f50057',
          },
        },
        containedSecondary: {
          background: 'linear-gradient(45deg, #f06292 30%, #f48fb1 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #f48fb1 30%, #f06292 90%)',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#ff4081',
          size: 60,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});

const pastelTheme = createTheme({
  ...baseTheme,
  palette: definePalette('light', '#9575cd', '#64b5f6', '#e8eaf6', '#f3e5f5', 'linear-gradient(45deg, #9575cd 30%, #64b5f6 90%)'),
  components: {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          zIndex: baseTheme.zIndex.snackbar,
          '& .MuiSnackbarContent-root': {
            // backgroundColor: '#9575cd',
            color: '#ffffff',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        ...applyBubbleStyles('pastel'),
      }
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: 'linear-gradient(45deg, #9575cd 30%, #7e57c2 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #7e57c2 30%, #9575cd 90%)',
          },
        },
        containedSecondary: {
          background: 'linear-gradient(45deg, #64b5f6 30%, #42a5f5 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #42a5f5 30%, #64b5f6 90%)',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#9575cd',
          size: 60,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});

const seaGreenTheme = createTheme({
  ...baseTheme,
  palette: definePalette('light', '#4db6ac', '#80cbc4', '#e0f2f1', '#b2dfdb', 'linear-gradient(45deg, #4db6ac 30%, #00695c 90%)'),
  components: {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          zIndex: baseTheme.zIndex.snackbar,
          '& .MuiSnackbarContent-root': {
            backgroundColor: '#4db6ac',
            color: '#ffffff',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minHeight: '100vh',
        },
        // Target the main content container
        '.MuiContainer-root': {
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'fixed',
            top: '40px', // Account for AppBar height
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: 'url("/themes/seagreen.jpg")',
            backgroundPosition: 'left center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto 100%',
            opacity: 0.30,
            zIndex: -1,
            pointerEvents: 'none',
            width: '100%', // Add explicit width
            height: 'calc(100vh - 40px)', // Add explicit height
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: 'linear-gradient(45deg, #4db6ac 30%, #00796b 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #00796b 30%, #4db6ac 90%)',
          },
        },
        containedSecondary: {
          background: 'linear-gradient(45deg, #26a69a 30%, #00897b 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #00897b 30%, #26a69a 90%)',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#4db6ac',
          size: 60,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});

const autumnTheme = createTheme({
  ...baseTheme,
  palette: definePalette('light', '#ff7043', '#ffa726', '#f5f5f5', '#ff7043', 'linear-gradient(45deg, #ff7043 30%, #f4511e 90%)'),
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            backgroundColor: '#ffffff',
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: '#ffffff',
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#ffffff',
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minHeight: '100vh',
        },
        // Target the main content container
        '.MuiContainer-root': {
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'fixed',
            top: '40px', // Account for AppBar height
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: 'url("/themes/leaves.png")',
            backgroundPosition: 'left center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto 100%',
            opacity: 0.10,
            zIndex: -1,
            pointerEvents: 'none',
            width: '100%', // Add explicit width
            height: 'calc(100vh - 40px)', // Add explicit height
          }
        }
      }
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          zIndex: baseTheme.zIndex.snackbar,
          '& .MuiSnackbarContent-root': {
            color: '#ffffff',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: 'linear-gradient(45deg, #ff7043 30%, #bf360c 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #bf360c 30%, #ff7043 90%)',
          },
        },
        containedSecondary: {
          background: 'linear-gradient(45deg, #ffa726 30%, #ff9800 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #ff9800 30%, #ffa726 90%)',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#ff7043',
          size: 60,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#ffffff',
            color: '#000000',
          }
        }
      }
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: '#ff7043',
          '& .MuiBottomNavigationAction-root': {
            color: 'rgba(255, 255, 255, 0.7)',
            '&.Mui-selected': {
              color: '#ffffff'
            }
          },
          '& .MuiSvgIcon-root': {
            color: 'rgba(255, 255, 255, 0.7)',
          },
          '& .Mui-selected .MuiSvgIcon-root': {
            color: '#ffffff'
          },
          '& .MuiBottomNavigationAction-label': {
            color: 'rgba(255, 255, 255, 0.7)',
            '&.Mui-selected': {
              color: '#ffffff'
            }
          }
        }
      }
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: 'rgba(255, 255, 255, 0.7)',
          '&.Mui-selected': {
            color: '#ffffff'
          },
          '& .MuiSvgIcon-root': {
            color: 'inherit'
          },
          '& .MuiBottomNavigationAction-label': {
            color: 'inherit'
          }
        }
      }
    },
  }
});

const lavenderTheme = createTheme({
  ...baseTheme,
  palette: definePalette('light', '#7e57c2', '#b39ddb', '#f3e5f5', '#e1bee7', 'linear-gradient(45deg, #7e57c2 30%, #5e35b1 90%)'),
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            backgroundColor: '#ffffff',
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: '#ffffff',
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#ffffff',
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minHeight: '100vh',
        },
        // Target the main content container
        '.MuiContainer-root': {
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'fixed',
            top: '40px', // Account for AppBar height
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: 'url("/themes/sissy2.jpg")',
            backgroundPosition: 'left center',
            backgroundRepeat: 'no-repeat',
            // backgroundSize: 'auto 100%',
            opacity: 0.10,
            zIndex: -1,
            pointerEvents: 'none',
            width: '100%', // Add explicit width
            height: 'calc(100vh - 40px)', // Add explicit height
          }
        }
      }
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          zIndex: baseTheme.zIndex.snackbar,
          '& .MuiSnackbarContent-root': {
            color: '#ffffff',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: 'linear-gradient(45deg, #7e57c2 30%, #5e35b1 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #5e35b1 30%, #7e57c2 90%)',
          },
        },
        containedSecondary: {
          background: 'linear-gradient(45deg, #b39ddb 30%, #9575cd 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #9575cd 30%, #b39ddb 90%)',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#7e57c2',
          size: 60,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});

const mintTheme = createTheme({
  ...baseTheme,
  palette: definePalette('light', '#26a69a', '#80cbc4', '#e0f2f1', '#b2dfdb', 'linear-gradient(45deg, #26a69a 30%, #00796b 90%)'),
  components: {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          zIndex: baseTheme.zIndex.snackbar,
          '& .MuiSnackbarContent-root': {
            backgroundColor: '#26a69a',
            color: '#ffffff',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minHeight: '100vh',
        },
        // Target the main content container
        '.MuiContainer-root': {
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'fixed',
            top: '40px', // Account for AppBar height
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: 'url("/themes/mint.jpg")',
            backgroundPosition: 'left center',
            backgroundRepeat: 'no-repeat',
            //backgroundSize: 'auto 100%',
            opacity: 0.2,
            zIndex: -1,
            pointerEvents: 'none',
            width: '100%', // Add explicit width
            height: 'calc(100vh - 40px)', // Add explicit height
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: 'linear-gradient(45deg, #26a69a 30%, #00796b 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #00796b 30%, #26a69a 90%)',
          },
        },
        containedSecondary: {
          background: 'linear-gradient(45deg, #80cbc4 30%, #4db6ac 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #4db6ac 30%, #80cbc4 90%)',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#26a69a',
          size: 60,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
          opactiy: 0.3,
        },
      },
    },
  },
});

const skyBlueTheme = createTheme({
  ...baseTheme,
  palette: definePalette('light', '#4fc3f7', '#29b6f6', '#e1f5fe', '#b3e5fc', 'linear-gradient(45deg, #4fc3f7 30%, #0288d1 90%)'),
  components: {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          zIndex: baseTheme.zIndex.snackbar,
          '& .MuiSnackbarContent-root': {
            // backgroundColor: '#4fc3f7',
            color: '#ffffff',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minHeight: '100vh',
        },
        // Target the main content container
        '.MuiContainer-root': {
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'fixed',
            top: '40px', // Account for AppBar height
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: 'url("/themes/mountains.jpg")',
            backgroundPosition: 'left center',
            backgroundRepeat: 'no-repeat',
            //backgroundSize: 'auto 100%',
            opacity: 0.10,
            zIndex: -1,
            pointerEvents: 'none',
            width: '100%', // Add explicit width
            height: 'calc(100vh - 40px)', // Add explicit height
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: 'linear-gradient(45deg, #4fc3f7 30%, #0288d1 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #0288d1 30%, #4fc3f7 90%)',
          },
        },
        containedSecondary: {
          background: 'linear-gradient(45deg, #29b6f6 30%, #039be5 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #039be5 30%, #29b6f6 90%)',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#4fc3f7',
          size: 60,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});

const sunsetTheme = createTheme({
  ...baseTheme,
  palette: definePalette('light', '#ff8a65', '#ffb74d', '#fff8e1', '#ffecb3', 'linear-gradient(45deg, #ff8a65 30%, #f4511e 90%)'),
  components: {
    MuiSnackbar: {
      styleOverrides: {
        root: {
           zIndex: baseTheme.zIndex.snackbar,          
          '& .MuiSnackbarContent-root': {
            backgroundColor: '#ff8a65',
            color: '#ffffff',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: 'linear-gradient(45deg, #ff8a65 30%, #f4511e 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #f4511e 30%, #ff8a65 90%)',
          },
        },
        containedSecondary: {
          background: 'linear-gradient(45deg, #ffb74d 30%, #ff9800 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #ff9800 30%, #ffb74d 90%)',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#ff8a65',
          size: 60,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});

const oliveTheme = createTheme({
  ...baseTheme,
  palette: definePalette('light', '#827717', '#afb42b', '#f0f4c3', '#dce775', 'linear-gradient(45deg, #827717 30%, #cddc39 90%)'),
  components: {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          zIndex: baseTheme.zIndex.snackbar,
          '& .MuiSnackbarContent-root': {
            backgroundColor: '#827717',
            color: '#ffffff',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: 'linear-gradient(45deg, #827717 30%, #cddc39 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #cddc39 30%, #827717 90%)',
          },
        },
        containedSecondary: {
          background: 'linear-gradient(45deg, #afb42b 30%, #c0ca33 90%)',
          color: '#ffffff',
          '&:hover': {
            background: 'linear-gradient(45deg, #c0ca33 30%, #afb42b 90%)',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#827717',
          size: 60,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});

// Exporting Themes
export const themes = {
  light: lightTheme,
  dark: darkTheme,
  neon: neonTheme,
  midnight: midnightTheme,
  tokyo: tokyoTheme,
  sissy: sissyTheme,
  pastel: pastelTheme,
  seaGreen: seaGreenTheme,
  autumn: autumnTheme,
  lavender: lavenderTheme,
  mint: mintTheme,
  skyBlue: skyBlueTheme,
  sunset: sunsetTheme,
  olive: oliveTheme,
};

Object.values(themes).forEach((theme) => {
  if (![
    autumnTheme,
    midnightTheme,
    tokyoTheme,
    neonTheme,
    mintTheme,
    skyBlueTheme,
    sissyTheme,
    pastelTheme,
    lavenderTheme
  ].includes(theme)) {
    theme.components = {
      ...theme.components,
      MuiCssBaseline: {
        styleOverrides: applyStarfieldStyles(theme),
      },
    };
  }
});

// Theme Utility Functions
export const getTheme = (themeName) => {
  const baseTheme = themes[themeName] || themes.light;
  const scalingFactor = getScalingFactor();

  return scaleTypography(baseTheme, scalingFactor);
};

export const getThemeNames = () => {
  return Object.keys(themes);
};

const setupScreenSizeListener = (callback) => {
  if (typeof window !== 'undefined') {
    window.addEventListener('resize', callback);

    return () => {
      window.removeEventListener('resize', callback);
    };
  }
  return () => {}; // Return empty cleanup if window is not available
};

export const initializeTheme = (onChange) => {
  const handleThemeChange = (e) => {
    const newTheme = e.matches ? 'dark' : 'light';
    if (onChange) {
      onChange(newTheme);
    }
  };

  const handleScreenSizeChange = () => {
    if (onChange) {
      onChange(getSystemTheme());
    }
  };

  const cleanupThemeListener = setupThemeListener(handleThemeChange);
  const cleanupScreenSizeListener = setupScreenSizeListener(handleScreenSizeChange);

  return {
    initialTheme: getSystemTheme(),
    cleanup: () => {
      cleanupThemeListener();
      cleanupScreenSizeListener();
    },
  };
};

// API Calls for User Themes
export const fetchUserTheme = async () => {
  try {
    const response = await axiosInstance.get('/user-theme');
    // If no theme is set, use system theme
    if (!response.data.theme) {
      return 'system';
    }
    return response.data.theme;
  } catch (error) {
    console.error('Error fetching user theme:', error);
    return 'system'; // Default to system theme instead of light
  }
};

export const fetchSubmissiveTheme = async () => {
  try {
    const response = await axiosInstance.get('/partner-theme');
    return response.data.theme;
  } catch (error) {
    console.error('Error fetching submissive theme:', error);
    return 'light'; // Default theme
  }
};

export const updateUserTheme = async (newTheme) => {
  try {
    // If setting to system theme, clear the stored preference
    if (newTheme === 'system') {
      await axiosInstance.post('/user-theme', { theme: null });
      return getSystemTheme();
    }
    await axiosInstance.post('/user-theme', { theme: newTheme });
    return newTheme;
  } catch (error) {
    console.error('Error updating user theme:', error);
    throw error;
  }
};

export const updateSubmissiveTheme = async (newTheme) => {
  try {
    await axiosInstance.post(`/set-sub-theme`, { theme: newTheme });
    return newTheme;
  } catch (error) {
    console.error('Error updating submissive theme:', error);
    throw error;
  }
};

// Exporting Theme Utilities
const themeExports = {
  getTheme,
  getThemeNames,
  fetchUserTheme,
  updateUserTheme,
  fetchSubmissiveTheme,
  updateSubmissiveTheme,
  initializeTheme,
};

export const zIndex = {
  ...baseTheme.zIndex,
};

export default themeExports;